import * as uuid from 'uuid';

declare var BABYLON;
export class SmokeEffect {
    pemit
    smoke
    erate
    erate_step
    constructor(private pos, private scene) { 
        this.createEmitterSource(pos);
        //this.addSmokeEffect();
    }
    startSmoke(){
        this.smoke.start(this.pemit);
    }
    createEmitterSource(pos) {
        let id='pe_'+uuid.v4();
        let particleEmitter = BABYLON.MeshBuilder.CreateBox(id, { size: 0.05 }, this.scene);
        particleEmitter.position = new BABYLON.Vector3(pos[0],pos[1],pos[2]);        
        particleEmitter.isVisible = false;
        this.pemit = particleEmitter;
    }
    addSmokeEffect(cbck=null) {
        let smoke = BABYLON.ParticleHelper.CreateAsync("smoke", this.scene).then((set) => {
            set.emitter = this.pemit;            
            this.smoke = set;
            this.smoke.start(this.pemit);
            this.erate=this.getEmitRate();
            this.erate_step=this.erate/3
            if(cbck){
                cbck();
            }
        })
    }
    setLayerMask(m=0x10000000){
        for (const sys of this.smoke.systems) {
            sys.layerMask=m;                
        }
    }
    putoffStep(cbck){
        let sys=this.smoke.systems[0];
        sys.emitRate-=this.erate_step;
        if(sys.emitRate<0.33){
            sys.emitRate=0;
            cbck("done","0%")
        }else{
            let p = Math.round(sys.emitRate*100/this.erate)
            cbck("",p+'%');
        }
    }
    putoff(){
        let sys=this.smoke.systems[0];
        let anim=()=>{
            if(sys.emitRate>0){
                sys.emitRate-=0.5
                requestAnimationFrame(anim)
            }
        }
        requestAnimationFrame(anim)

    }
    getEmitRate(){
        let r=null
        if(this.smoke&&this.smoke.systems){
            let sys=this.smoke.systems[0];
            r=sys.emitRate;
        }
        return r
        
    }
}