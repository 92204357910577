export const object_path = {
    "inexxt": { "sina_crn_building": "./sina_crn_building.glb" },
};

export const object_geom = {
    "inexxt": {
        "shapes": [
            { "sina_crn_building": { "model": "sina_crn_buildings", "material": "custom-included", "size": 1 } },
    
            { "dfx-2ND FLOOR": { "model": "2ND FLOOR", "material": "custom-included", "size": 1 } },
            { "dfx-GROUND FLOO": { "model": "GROUND FLOO", "material": "custom-included", "size": 1 } },
            { "dfx-OTHERS": { "model": "OTHERS", "material": "custom-included", "size": 1 } },
            { "dfx-Planting": { "model": "Planting", "material": "custom-included", "size": 1 } },
            { "dfx-T/0 ROOF-X SPACE": { "model": "T/0 ROOF-X SPACE", "material": "custom-included", "size": 1 } },
            { "dfx-U/S JOIST MULTIP ROOM": { "model": "U/S JOIST MULTIP ROOM", "material": "custom-included", "size": 1 } },
            { "dfx-U/S JOIST-1ST FLOOR": { "model": "U/S JOIST-1ST FLOOR", "material": "custom-included", "size": 1 } },
            { "dfx-U/S ROOF-2ND FLOOR": { "model": "U/S ROOF-2ND FLOOR", "material": "custom-included", "size": 1 } }
        ],
        "objects": [
            { "type": "static", "parent": "", "name": "sina_crn_building", "model": "sina_crn_building", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
  
            { "type": "static", "parent": "", "name": "2ND FLOOR", "model": "dfx-2ND FLOOR", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "GROUND FLOO", "model": "dfx-GROUND FLOO", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "OTHERS", "model": "dfx-OTHERS", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "Planting", "model": "dfx-Planting", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "T/0 ROOF-X SPACE", "model": "dfx-T/0 ROOF-X SPACE", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "U/S JOIST MULTIP ROOM", "model": "dfx-U/S JOIST MULTIP ROOM", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "U/S JOIST-1ST FLOOR", "model": "dfx-U/S JOIST-1ST FLOOR", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } },
            { "type": "static", "parent": "", "name": "U/S ROOF-2ND FLOOR", "model": "dfx-U/S ROOF-2ND FLOOR", "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } }
        ],
    }
};