import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { SelectionModel } from '@angular/cdk/collections';
import { KHASystemService } from '../services/systems/kha-systems.service';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, AfterViewInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Input() shipCompartment: any
  @Input() compComments: any
  @Input() selectedKhaSystems: any
  @Input() selectedGroupData: any
  @Input() selectedLayoutList: any
  @Input() LayoutMode: any;
  @Input() selectedTagItem: any;
  @Input() layoutHeading: any;
  isCommentTyped = false;
  addUserCard: boolean = false;
  addCommentCard: boolean = true;
  displayedColumns: string[];
  dataSource: any;
  usersDetails: any = [];
  userList: any = [];
  selection = new SelectionModel<any>(false, []);
  shipCompartmentDetails: any = [];
  isActivityTyped = false;
  compActivity: any = [];
  activityMessage: any;
  activityDetail: any = [];
  loginUserdata: { fName: any; lName: any; email: any; };
  activityMessageIdx: any;
  broadcastIframeSubs: any;
  deleteIdx: any;
  notificationNo: any;
  surveyNo: any;
  materialNo: any;
  status: any = {};
  statusId: any;
  adminEmailId: any;
  // public allColors: any[] = [
  //   { value: '#FF0000', name: 'BLOCKED' },
  //   { value: '#FFBF00', name: 'INPROGRESS' },
  //   { value: '#00FF00', name: 'COMPLETED' },
  // ];
  allColors: any = [];
  colorchanges: any;
  StatusName: any;
  startDate: any;
  broadcastSubs: any;
  uploadFile: any =[];
  fileExtension: any;
  zoomImage: any;
  extraLargeImage: boolean = false;
  constructor(private khaConfig: KHASystemService, public communicationServ: CommunicationService, private configService: ConfigService) {

  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngAfterViewInit(): void {
    this.getUserData();
    console.log("tagListComment", this.LayoutMode, this.selectedLayoutList);
    if (this.LayoutMode) {
      // this.getCompartmentDetails();
      console.log("tagListComment", this.selectedLayoutList);
      if (this.selectedTagItem) {
        this.getLayoutTag()
      }

    } else {
      this.getCompartmentDetails();
    }

  }
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if (data.src === "kys") {
        if (data.event === 'statusData') {
          this.allColors = data.data;
          console.log("this.allColors", this.allColors)
        }
      }
    })
    this.broadcastIframeSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "commentScreen") {
          if (data.event === 'deleteComment') {
            this.saveData()
          }
          if (data.event === 'NotesDelete') {
            this.cancel.emit(false)
          }
        }
        })
  }
  onCancel(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false
    } else {
      this.isActivityTyped = false
    }
  }
  onComment(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else {
      this.isCommentTyped = false;
    }
  }
  onClose() {
    this.cancel.emit(false)
  }
  onSave(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false;

      this.saveData();
    } else {
      this.isActivityTyped = false;
      this.activityDetail.unshift({
        "userName": this.loginUserdata.fName + ' ' + this.loginUserdata.lName,
        "activityMessage": this.activityMessage,
        "loginEmail": this.loginUserdata.email,
        "dataTime": this.getDateTime()
      })
      this.activityMessage = ""
      this.saveData();
    }
  }
  onActivity(e) {
    if (e.target.value.trim() != '') {
      this.isActivityTyped = true;
      this.activityMessage = e.target.value
    } else {
      this.isActivityTyped = false;
    }
  }
  saveData() {
      if (this.deleteIdx != undefined || this.deleteIdx != null) {
        this.activityDetail.splice(this.deleteIdx, 1)
      }
      this.deleteIdx = null;
      if (this.LayoutMode) {
        if (this.selectedTagItem) {
          // var urldata = this.configService.project_config.compartmentComment + this.shipCompartmentDetails[0]._id;
          var CommentScreendata = this.getCommentScreen();
          this.broadcastInfo({ src: 'comment', event: 'update-comment', data: CommentScreendata });
        } else {
          var CommentScreendata = this.getCommentScreen();
          this.broadcastInfo({ src: 'comment', event: 'create-comment', data: CommentScreendata });
        }
      } else {
        if (this.khaConfig.isComponentDragged) {
          var CommentScreendata = this.getCommentScreen();
          this.broadcastInfo({ src: 'comment', event: 'temporary-comment', data: CommentScreendata });
        } else {
          if (this.shipCompartmentDetails.length != 0) {
            var urldata = this.configService.project_config.compartmentComment + this.shipCompartmentDetails[0]._id;
            var CommentScreendata = this.getCommentScreen();
            this.configService.putData(urldata, CommentScreendata).subscribe(result => {
            })
            this.broadcastInfo({ src: 'comment', event: 'commentsData', data: CommentScreendata });
          } else {
            var CommentScreendata = this.getCommentScreen();
            this.configService.postData(this.configService.project_config.compartmentComment, CommentScreendata).subscribe(result => {
              this.broadcastInfo({ src: 'comment', event: 'commentsData', data: CommentScreendata });
              this.getCompartmentDetails()
            })
          }
        }
      }
    
      if(this.layoutHeading == ''){
        var CommentScreendata = this.getCommentScreen()
        this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutName' , data: CommentScreendata});
      }
  }
  getLayoutTag() {
    if (this.selectedLayoutList) {
      if (this.selectedLayoutList['tagPoints']) {
        if (this.selectedLayoutList['tagPoints'].length > 0) {
          this.selectedLayoutList['tagPoints'].forEach(element => {
            if (element['id'] == this.selectedTagItem['id']) {
              if (element.tagComments) {
                console.log("comments", element, element.tagComments)
                this.compComments = element.tagComments.comments ? element.tagComments.comments : '';
                this.activityDetail = element.tagComments.activityMessage ? element.tagComments.activityMessage : [];
                this.userList = element.tagComments.userList ? element.tagComments.userList : [];
                this.notificationNo = element.tagComments.notificationNo ? element.tagComments.notificationNo : '';
                this.surveyNo = element.tagComments.surveyNo ? element.tagComments.surveyNo : '';
                this.materialNo = element.tagComments.materialNo ? element.tagComments.materialNo : '';
                this.statusId = element.tagComments.statusId ? element.tagComments.statusId : '';
                this.status = element.tagComments.status ? element.tagComments.status : {};
                this.startDate = element.tagComments.startDate ? element.tagComments.startDate : new Date();
                this.uploadFile = element.tagComments.uploadFile ? element.tagComments.uploadFile : [];
                if (this.status) {
                  this.colorchanges = this.status.value ? this.status.value : '';
                  this.StatusName = this.status.name ? this.status.name : '';
                }
              }
            }
          });
        }
      }
    }
  }

  getCompartmentDetails() {
    this.configService.getData(this.configService.project_config.compartmentComment).subscribe(result => {
      this.shipCompartmentDetails = result.filter(obj => {
        return obj.compartMentCode == this.selectedKhaSystems.code && obj.compartMentId == this.selectedKhaSystems.id
      })

      // if(this.userList[0] == undefined){
      //   this.userList.splice(0,1)
      //   this.userList.push(this.loginUserdata)
      // }
      if (this.shipCompartmentDetails.length != 0) {
        var shipCommentData = this.shipCompartmentDetails[0]
          console.log("compComments", this.shipCompartmentDetails[0])
          this.compComments = shipCommentData.comments ? shipCommentData.comments : '';
          this.activityDetail = shipCommentData.activityMessage ? shipCommentData.activityMessage : [];
          this.userList = shipCommentData.userList ? shipCommentData.userList : [];
          this.notificationNo = shipCommentData.notificationNo ? shipCommentData.notificationNo : '';
          this.surveyNo = shipCommentData.surveyNo ? shipCommentData.surveyNo : '';
          this.materialNo = shipCommentData.materialNo ? shipCommentData.materialNo : '';
          this.statusId = shipCommentData.statusId ? shipCommentData.statusId : '';
          this.startDate = shipCommentData.startDate ? shipCommentData.startDate : new Date();
          this.uploadFile = shipCommentData.uploadFile ? shipCommentData.uploadFile : [];
          if (shipCommentData.email != this.configService.accountEmail) {
            this.userList.push(shipCommentData)
          }
        if (this.shipCompartmentDetails[0].adminEmailId) {
          this.adminEmailId = this.shipCompartmentDetails[0].adminEmailId;
        }
        if (this.shipCompartmentDetails[0].status) {
          this.StatusName = this.shipCompartmentDetails[0].status.name;
          this.colorchanges = this.shipCompartmentDetails[0].status.value;
          this.status = this.shipCompartmentDetails[0].status
        }
      } else {
        this.adminEmailId = this.configService.accountEmail;
      }
    })

  }
  UserChecked() {
    if (this.shipCompartmentDetails.length != 0) {
      this.usersDetails.forEach(element => {
        this.userList.forEach(ele => {
          if (ele.email == element.email) {
            element['isChecked'] = true;
          }
        })
      });
    }
  }
  getUserData() {
    this.configService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
        console.log(this.usersDetails)
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        if (userData.length > 0) {
          this.loginUserdata = {
            "fName": userData[0]['First Name'],
            "lName": userData[0]['Last Name'],
            "email": userData[0].email
          }
          this.userList.push(this.loginUserdata);
        }
      },
      error => {
        console.error('Request failed with error');
      })
  }

  addUserSelected(selectData, $event: any) {
    $event.stopPropagation();
    var userData: any = [];
    userData = selectData;
    var data = {
      "fName": userData['First Name'],
      "lName": userData['Last Name'],
      "email": userData.email
    }
    if (this.userList.length != 0) {
      var userDataIdx = this.userList.findIndex(obj => obj.email == selectData.email)
      if (userDataIdx == -1) {
        this.userList.push(data)
      } else {
        this.userList.splice(userDataIdx, 1)
      }
    } else {
      this.userList.push(data);
    }
    if (this.shipCompartmentDetails.length != 0) {
      var urldata = this.configService.project_config.compartmentComment + this.shipCompartmentDetails[0]._id;
      var CommentScreendata = this.getCommentScreen();
      this.configService.putData(urldata, CommentScreendata).subscribe(result => {
      })
    } else {
      var CommentScreendata = this.getCommentScreen()
      this.configService.postData(this.configService.project_config.compartmentComment, CommentScreendata).subscribe(result => {
        this.getCompartmentDetails()
      })
    }
  }
  getCommentScreen() {
    var commentScreenData = {
      "comments": this.compComments,
      "userList": this.userList,
      "compartMentId": this.selectedKhaSystems.id,
      "compartMentCode": this.selectedKhaSystems.code,
      "activityMessage": this.activityDetail,
      "notificationNo": this.notificationNo,
      "surveyNo": this.surveyNo,
      "materialNo": this.materialNo,
      "status": this.status,
      "statusId": this.statusId,
      "adminEmailId": this.adminEmailId,
      'startDate': this.startDate ? this.startDate : new Date(),
      'uploadFile': this.uploadFile
    }
    console.log("this.shipCompartmentDetails[0]", commentScreenData)
    return commentScreenData
  }
  editActivity(i) {
    this.activityMessageIdx = i;
    this.deleteIdx = null;
  }
  saveActivity(i) {
    this.activityMessageIdx = null;
    this.activityDetail[i].dataTime = this.getDateTime()
    this.saveData();
  }
  deleteActivity(i) {
    this.broadcastInfo({ src: 'material', event: 'commentDelate-popup', data: "", sub: null });
    this.deleteIdx = i
  }
  CancelActivity() {
    this.activityMessageIdx = null
  }
  getDateTime() {
    var date = new Date();
    var dateTime = date.toString()
    var data = dateTime.split(' ')
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes > 10 ? 0 + minutes : minutes
    var FullDateTime = data[1] + " " + data[2] + "," + data[3] + " " + hours + ":" + minutes + " " + ampm
    return FullDateTime
  }

  statusChange(e) {
    var colr = e.split(' ')
    var selectData = this.allColors.filter(t => t._id == e);
    this.colorchanges = selectData[0].value;
    this.StatusName = selectData[0].name;
    this.statusId = e;
    this.status = {
      'value': this.colorchanges,
      "name": this.StatusName
    }
    this.isCommentTyped = true;
  }
  
  notificationNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  surveyNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  materialNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  createStatus() {
    this.broadcastInfo({ src: "sidepanel", event: "createStatus", data: "" })
  }
  onDelete(){
    this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutNotesDelete', data: this.selectedLayoutList._id,'notesId': this.selectedTagItem['id'], sub: null });
  }

  url: any; //Angular 11, for stricter type
	msg = "";
  formData = new FormData()
	
	//selectFile(event) { //Angular 8
	selectFile(event: any) { //Angular 11, for stricter type
		// // if(!event.target.files[0] || event.target.files[0].length == 0) {
		// // 	this.msg = 'You must select an image';
		// // 	return;
		// // }
		
		// // var mimeType = event.target.files[0].type;
		
		// // if (mimeType.match(/image\/*/) == null) {
		// // 	this.msg = "Only images are supported";
		// // 	return;
		// // }
		
		// var reader = new FileReader();
		// reader.readAsDataURL(event.target.files[0]);
		
		// reader.onload = (_event) => {
		// 	this.msg = "";
		// 	this.url = reader.result; 
		// }
    console.log(event.target.files)
    for(var i=0;i < event.target.files.length; i++){
      this.formData.append('file',event.target.files[i])
    }
    if(this.formData.has('file')){
      this.configService.postUploadData(this.configService.project_config.fileUpload, this.formData).subscribe(result => {
        this.formData = new FormData()
        if(result.uploadPath.length){
          result.uploadPath.forEach(ele => {
            this.uploadFile.push(ele.uploadPath)
           })
           this.saveData()
           if(result.invaildData){
            this.broadcastInfo({ src: 'material', event: 'uploadFailedWarning' , data: result.invaildData});
           }
        } else {
          this.broadcastInfo({ src: 'material', event: 'uploadFailedWarning' , data: result.invaildData});
        }
      })
    }
	}
  deleteFile(data , i){
    var fileDelete ={
      'filePath': data
    }
    this.configService.postUploadData(this.configService.project_config.fileUpload + '/delete', fileDelete).subscribe(result => {
      this.formData = new FormData()
      this.uploadFile.splice(i,1)
      //this.uploadFile.push(result.uploadPath)
      this.saveData()
    })
  }
  imageView(data){
    this.zoomImage = data
    this.extraLargeImage = true;
  } 

  getExention(data){
   this.fileExtension= data.split('/').pop().split('.')[1];
   var checkimg = (this.fileExtension.toLowerCase()).trim();
   if (checkimg.match(/(\jpg|\png|\JPG|\PNG|\jpeg|\JPEG|\mp4|\MP4|\flv|\FLV|\mkv|\MKV|\gif)$/)) {
     return true;
   } else if (checkimg.match(/(\pdf|\doc|\docx)$/)) {
     return false;
   }
  }

  fileOpen(data){
    window.open(data)
  }
  onCloseImg(){
    this.extraLargeImage = false
  }
}