import { Injectable, HostListener, ɵɵstyleMapInterpolate1 } from '@angular/core';
import * as hfx_model_dir from "../../../assets/smls/data/hfx_model_dir.json";
import * as systems from "../../../assets/smls/data/kha_systems.json";
import * as compartments from "../../../assets/smls/data/kha_compartments.json";
import * as ernMapping from "../../../assets/smls/data/ern_mapping.json";
import { CommunicationService } from "../../services/communication.service";
import { ControllerService } from "../../services/controller.service";
import { element } from 'protractor';
import { CutOffEffect } from '../../services/effects/cutoff-effect.service';
import { threadId } from 'worker_threads';
declare var BABYLON;
const constLabelWidth = 140; // 1555 - window.innerWidth;
const constLabelHeight = 75; // 815 - window.innerHeight;

@Injectable()
export class KHASystemService {
  private config: any;
  distance: any = 25;
  datauxview;
  dfx;
  game_engine;
  scene;
  selectedDemo;
  rightclick: boolean = true;
  isDrag: boolean = false;
  khamode = "system";
  systemSubComps: any = {};
  broadcastSubs
  khasystem;
  selectedKha;
  khamaterials;
  khadisplayrules;
  hideWall = false;
  MaterialStatusData: any = [];
  clear_compartments: boolean = true;//default - true
  multi_compartments: boolean = !true;//default - false
  private _jsonURL = '../assets/project.config.json';
  slms_mode = false;
  cut_mode = false;
  isComponentDragged = false;
  isDrawState = false;
  groupList: any = [];
  exitRouteOn = false;
  matrixDataList = [
    { 'name': '02', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': '01', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': 'M', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': '1', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': '2', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': true },
    { 'name': '3', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': '4', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    { 'name': '5', 'deck': 'solid', 'hull': 'solid', 'bulkheads': 'solid', 'sections': 'solid', 'visible': false },
    // { 'name': 'otherDeck', 'hull': 'transparent', 'bulkheads': 'solid', 'sections': 'solid', 'visible': true },
  ];
  // defaultDeckList:any=["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3", "deck_4","deck_5"];
  constructor(private CommunicationServ: CommunicationService, private ctrlServ: ControllerService) {
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data) => {
        if (data.src === "sidepanel") {

          if (data.event === 'showMatObjectTag') {
            this.showMatObjectTag(data.key, data.data);
          }
          if (data.event === 'focusSubSystem') {
            this.focusCompartment(data.key)
          }
          if (data.event === 'cut-mode') {
            this.cut_mode = data.key;
            if (!this.cut_mode) {
              this.clearCutOuts();
            } else {
              if (this.draw_select_mode && this.last_cselect) {
                this.cutFromPath();
              }
            }
            /* this.draw_select_mode=this.cut_mode;
            if(!this.draw_select_mode){
              this.clearSections();
            } */
          }
          if (data.event === 'draw-mode') {
            this.draw_select_mode = data.key;
            //this.cut_mode=this.draw_select_mode;
            /* if(!this.draw_select_mode){
              this.clearSections();
            } */
          }
          if (data.event === 'pause-cut') {
            this.draw_select_mode = data.key;
            this.cut_mode = !!this.draw_select_mode;
            /* if(!this.draw_select_mode){
              this.clearSections();
            } */
          }
          if (data.event === 'apply-cut') {
            if (this.selectedMarker) {
              this.cutFromPath(this.selectedMarker)
            }
          }
          if (data.event === 'remove-cut') {
            if (this.selectedMarker) {
              this.removeCutOut(this.selectedMarker)
            }
          }
          if (data.event === 'clear-cuts') {
            let markers = this.layoutData['markers'] || [];
            this.isDrawState = markers.length > 0 ? true : false;
            this.clearCutOuts();
            this.clearSections(true);
          }
          if (data.event === 'remove-marker') {
            if (this.selectedMarker) {
              this.deleteMarker(this.selectedMarker)
            }
          }
          if (data.event === 'clear-markers') {
            let markers = this.layoutData['markers'] || [];
            this.isDrawState = markers.length > 0 ? true : false;
            //this.clearCutOuts();
            this.clearSections();
          }
          if (data.event === 'apply-marker-color') {
            if (this.selectedMarker) {
              this.applyMarkerColor(this.selectedMarker, data.data);
            } else {
              this.markerColor = data.data;
            }
          }
          if (data.event === 'apply-marker-width') {
            this.marker_width = data.data;
          }
          if (data.event === 'apply-marker-shape') {
            this.draw_select_type = data.data;
            this.marker_select_mode = !true;
            if (data.data === 'select') {
              this.marker_select_mode = true;
            }
            this.cselect = null;
          }
          if (data.event === 'showhide-labels') {
            this.showHideDeckLabels(data.key);
          }
          if (data.event === 'showhide-ruler') {
            this.showHideRuler(data.key);
          }
          if (data.event === 'showhide-fl') {
            this.showHideFramesAndLongs(data.key);
          }
          if (data.event === 'draw-mode-fill') {
            this.draw_select_fill = (data.key);
            if (this.selectedMarker) {
              this.toggleMarkerFill(this.selectedMarker, this.draw_select_fill);
            }
          }
          if (data.event === 'exit-route-on') {
            this.exitRouteOn = (data.key);
            if (!this.exitRouteOn) {
              this.hideExitRoutes();
            }
          }
          if (data.event === 'undo-marker') {
            this.undoMarker();
          }
          if (data.event === 'marker-roundoff') {
            this.roundOffMarker();
          }
        }
        if (data.src === "controlpanel") {
          if (data.event === 'reset_drag') {
            let c = data['key'];
            if (c === 'system') {
              this.showSystemShip(true);
            }
            if (c === 'compartment') {
              !this.slms_mode ? this.showSystemShip(false) : null;
            }
          }
          if (data.event === 'loadcompartment') {
            let c = data['data'];
            this.khamode = "compartment";
            c.wallmode = this.slms_mode;
            this.processandloadComparment(c.code, data['key']);
          }
          if (data.event === 'process-system') {
            let c = data['data'];
            this.khamode = "preset";
            //c.wallmode = this.slms_mode;
            this.processSystem('custom', c, {}, () => {
              this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
            });
          }
          if (data.event === 'clear-system') {
            let c = data['data'];
            this.khamode = "preset";
            c.code.forEach(ele => {
              this.clearElements(ele);
            })

          }
          if (data.event === 'groupMultipleLoadCompartment') {
            this.processGroupMutipleComparment();
          }

          // if (data.event === 'loadsystem') {
          //   let c = data['data'];
          //   this.khamode = "system"
          //   this.changeKHA(c);
          // }

          if (data.event === 'loadsection') {
            this.khamode = "section"
            let c = data['data'];
            //-todo
          }
          if (data.event === 'showhidesubsystem') {
            let boo = data['data'];
            this.showHideSubSystem(data['key'], true);
          }
          if (data.event === 'focusSubSystem') {
            let boo = data['data'];
            this.focusSubSystem(data['key'])
          }
          if (data.event === 'showhidecompartment') {
            let boo = data['data'];
            this.showHideCompartment(data['key'], boo);
          }
          if (data.event === 'showhidecompartmentwall') {
            let boo = data['data'];
            this.showHideCompartmentWall(data['key'], boo);
          }
          if (data.event === 'clearkha') {
            this.khamode = data['key']
            if (this.datauxview) {
              this.clearAllElements();
            }
          }
          if (data.event === 'togglehull') {
            let boo = data['data'];
            this.hideWall = boo;
            //this.showHideHull();
          }
          if (data.event === 'showsystemcolors') {
            let sysmode = data['key'];
            if (sysmode === 'system') {
              let el = this.getChild(this.khasystemship, "ERNs");
              this.applyMaterial(null, 'regular', null, el);
              this.hideWall = false;

              if (this.ctrlServ.selectedSysComp.length > 0) {
                // this.toggleSysComp(this.ctrlServ.selectedSysComp);
              } else {
                let boo = this.ctrlServ.showSysComp[this.khasystem].length > 0 ? true : false;
                if (boo) {
                  let s = this.ctrlServ.showSysComp[this.khasystem];
                  if (!s) return;
                  this.showERNObjectFor(s, false);
                } else {
                  this.resetSubSystems();
                }
              }
              //this.showHideHull();
              //this.showHideERNCmpt(false);
              //this.showAllComps(this.khasystemship);
              //this.ctrlServ.updateComps([])
            }
            if (sysmode === 'status') {
              //this.showAllComps(this.khasystemship);
              this.showAllSubSystems();
              // this.ctrlServ.updateComps([]);
              let status = data['data'];
              console.log(" showsystemcolors  ")
              this.loadSystemStatus(status);
            }
          }
          if (data.event === 'showhideSubSystemStatus') {
            let key = data['data'];
            if (key) {
              this.toggleSubSystemStatus(key, data['key']);
            }
          }
        }
      })
    this.initCanvas();
  }

  /**
  * initCanvas
  */
  selectedHitPosition: any;
  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      if (event.which == 1) {
        this.isDrag = true;
        this.rightclick = false;
      }
      if (event.which == 3) {
        this.isDrag = true;
        this.rightclick = true;
        try {
          if (this.dfx) {
            const hit = this.dfx.getHitPosition();
            let mesh = hit.pickedMesh;
            if (mesh != null) {
              let view = { "target": hit.pickedPoint, "distance": 90, "yaw": 205, "pitch": 12 };
              let dfx = this.datauxview.getDatascape();
              let cameraProps = dfx.getCamera();
              view['distance'] = Number(cameraProps['distance']['value'].toFixed(4));
              view['yaw'] = Number(cameraProps['yaw_deg']['value'].toFixed(4));
              view['pitch'] = Number(cameraProps['pitch_deg']['value'].toFixed(4));
              this.selectedHitPosition = view;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    })
    canvas.addEventListener("pointerup", (event) => {
      if (event.which == 1) {
        this.isDrag = false;
        this.rightclick = false;
      }
      if (event.which == 3) {
        this.isDrag = false;
        setTimeout(() => {
          this.rightclick = false;
          delete this.selectedHitPosition;
        }, 500);
      }
    })
  }

  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  /**
  * move camera
  */
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let _view = view;
    dfx.moveCamera(_view);
  }

  clearScene() {
    let scene = this.scene;
    scene.meshes.forEach((m) => {
      m.dispose();
    })
  }
  clearPointer() {
    console.log("clearPointer", this.layoutData['tagPoints'])
    if (this.layoutData['tagPoints']) {
      if (this.layoutData['tagPoints'].length > 0) {
        this.layoutData['tagPoints'].forEach((e, i) => {
          console.log("clearPointer", e);
          this.hidePointerData(e.id);

        })
      }
    }

  }
  clearElements(e) {
    let dx = this.datauxview
    if (!dx) {
      return;
    }
    let elems = dx.getElementsid();
    let dfx = dx.getDatascape();
    elems.forEach((id) => {
      if (!id.includes('ehfx')) {
        let el = dx.getElementId(id);
        if (!id.includes('ernhfx')) {
          if (id.includes(e)) {
            delete this.compartments_store[e]
            dfx.remove(el);
            delete dx._allElements[id];
          }
        }
      }
    })
  }
  clearAllElements(hideship = false) {
    let dx = this.datauxview
    if (!dx) {
      return;
    }
    let elems = dx.getElementsid();
    let dfx = dx.getDatascape();
    elems.forEach((id) => {
      if (!id.includes('ehfx')) {
        let el = dx.getElementId(id);
        if (!id.includes('ernhfx')) {
          dfx.remove(el);
          delete dx._allElements[id];
        }
      }
    })
    // this.clearScene();
  }
  removeCompartment(id) {
    let dx = this.datauxview;
    let dfx = dx.getDatascape();
    let objs = this.compartments_store[id];
    objs.forEach((obj) => {
      let el = this.datauxview.getElementId(obj.name);
      if (el) {
        dfx.remove(el);
        delete dx._allElements[obj.name];
      }
    })
    this.compartments_store[id] = undefined;
    delete this.compartments_store[id];
  }
  removeCompartments(except = null) {
    let objs = Object.keys(this.compartments_store);
    objs.forEach((obj) => {
      if (obj !== except) {
        this.removeCompartment(obj);
      }
    })
  }
  meshes;
  changeKHA(sys) {
    this.clearAllElements();
    this.processSystems(sys);
  }
  meshMap = {};
  loadShipSystems(arr, cbck = null) {
    let dfx = this.datauxview.getDatascape();
    let prefix = window.location.host.includes('localhost') ? 'http://localhost:5000' : "";
    //prefix='https://navyscs.azurewebsites.net';
    prefix = 'https://nodesdms.azurewebsites.net';
    let prop = {
      "type": "static",
      "parent": "",
      "name": "land2",
      "model": "land2",
      "place": {
        "pos": { "x": 0, "y": 0, "z": 0 },
        "rot": { "x": 0, "y": 0, "z": 0 }
      }
    };
    let def = {};
    let models = [];
    let elements = [];
    arr.forEach((m, i) => {
      def['system_' + i] = prefix + m
      let narr = m.split("/")
      let name = narr[narr.length - 1];
      name = name.split(".glb")[0];
      let model = 'root_' + name;
      let nobj = {};
      nobj[name] = { "model": model, "material": "custom-included", "size": 1 }
      models.push(nobj);
      let p = Object.assign({}, prop, { "name": name, "model": name })
      elements.push(p);
    })
    dfx.addDynamicShapes(def).then(() => {
      console.log("GLB loaded")
      this.datauxview._createModels(models, (boo) => {
        this.datauxview.addElements(elements, (objs) => {

          let scene = this.scene;
          scene.materials.forEach((m) => {
            if (m.metallic) {
              m.metallic = 0;
              m.roughness = 0.5;

            }
            m.mid = m.name;
          })

          scene.meshes.forEach((m) => {
            if (m._material) {
              this.meshMap[m.id] = m._material.name;
            }

          })
          //this.showLoading = !true;
          // this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
          if (cbck) {

            cbck(objs);

          }
        }, (id, pointer) => { })
      }, (id, pointer) => { })
    }, (e) => {
      console.log("LOAD ERROR", e);
      // this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "error" });
      alert("Model not available!")
    })
  }

  findObject(obj: any = {}, key, values, strict = false) {
    const result = [];
    const recursiveSearch = (obj: any = {}) => {
      if (!obj || typeof obj !== 'object') {
        return;
      };
      values.forEach((value) => {
        if (strict) {
          if (obj[key] === (value)) {
            result.push(obj);
          };
        } else {
          if (obj[key].includes(value)) {
            result.push(obj);
          };
        }

      });
      if (obj.type != 'system') {
        let nodes = obj.compartments || obj.systems;
        nodes.forEach(function (node) {
          recursiveSearch(node);
        });
      }

    }
    recursiveSearch(obj);
    return result;
  }

  systems_store = {}
  processSystem(dir, s, mats, cbck) {
    let dfx = this.datauxview.getDatascape()
    this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: s });
    this.selectedDemo = s;
    let arr = null;//this.findObject(dir,"name",[s])||[];
    let narr = [];
    if (s.code.length > 0) {
      s.code.forEach(code => {
        narr.push("/HFX_by_systems/HFX_" + code + ".glb");//["/systems/"+dir+"/"+s+"/"+s+".glb"];
      })
    } else {
      return;
    }
    //arr.forEach((item)=>{narr.push(item.path.split('./').join("/"))})
    let mat = mats[s];
    this.loadShipSystems(narr, (objs) => {
      this.systems_store[s] = objs;
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          this.enableMouseEvent(el);
          this.postProcessSystem(dir, s, el);
          if (mat) {
            this.applyMaterial(el, mat, dir);
          } else {
            if (dir === 'eer') {
              this.applyMaterial(el, null, dir);
            }
          }
          dfx.setElementScale(el, [1, 1, -1]);
        }
      })
      cbck();
    });
  }

  getSystem(sys) {
    let sarr = systems['default'][sys];
    return sarr;
  }

  getSystemFiles(sys) {
    let dir = hfx_model_dir['default'];
    let arr = this.findObject(dir, "name", [sys]) || [];
    let narr = [];
    arr.forEach((item) => { narr.push(item.path) })
    return narr;
  }

  cancelLoading = false;
  cancelSystemLoading() {
    this.broadcastInfo({ src: 'khaservice', event: 'cancel-loading', data: "" });
    this.cancelLoading = true;
    this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
  }

  ernkhasystemship
  setSystemConfig(sys) {
    this.khasystem = sys;
    this.khadisplayrules = systems['default']['rules'][sys];
    this.ctrlServ.showElement("ernhfx");
    let ernhfx = this.datauxview.getElementId("ernhfx");
    this.ernkhasystemship = this.dfx.getElementMesh(ernhfx).getChildren()[0];
  }

  eventInitialized = false;
  processSystems(sys) {
    // this.getSysDefalutPosition(this.khasystemship);
    // this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
    // this.applyDisplayRule_ERNStruct();
    this.hideAllSubSystems();
    this.showAllSubSystems();
    this.getSystemCompStatus();
    if (this.ctrlServ.selectedSysComp.length > 0) {
      this.showHideSubSystem(this.ctrlServ.selectedSysComp, true, true);
    } else {
      let systems = Object.keys(this.khadisplayrules);
      this.ctrlServ.updateSysComps(systems, true);
    }
    if (this.filter_deck && this.selectedKha) {
      // this.showSystemsFDeck();
    }

    if (!this.eventInitialized) {
      this.eventInitialized = true;
      // this.enableMouseEvent(ernhfx);
    }
    if (this.dragMode) {
      // this.enableDrag();
    }
    // this.createMatObject('cube', { pickedPoint: { x: 0, y: 0, z: 0 } }, '');
  }

  mergeIfMultiRules(s) {
    if (s.length === 1) {
      return this.khadisplayrules ? this.khadisplayrules[s[0]] : null;;
    }
    let multi_rule = {
      "show": {
        "Hull": { "limits": [] },
        "Superstruct": { "limits": [] },
        "Compartments": { "limits": [] },
        "ERNs": { "limits": [] }
      }
      , "hide": {
      }
      , "highlight": {
      }
      , "focus": { "target": { "x": 1.22, "y": 13.22, "z": -90.22 }, "distance": 20, "yaw": -111, "pitch": 43 }
    }
    s.forEach((o, idx) => {
      let rules = this.khadisplayrules ? this.khadisplayrules[o] : null;
      if (rules) {
        if (idx === s.length - 1) {
          multi_rule.focus = rules.focus;
        }
        if (rules.show) {
          let keys = Object.keys(rules.show)
          keys.forEach((key) => {
            if (!(key === 'Hull' || key === 'Superstruct')) {
              if (!multi_rule.show[key]) {
                multi_rule.show[key] = { "limits": [] }
              }
              let l = multi_rule.show[key] ? multi_rule.show[key].limits : [];
              multi_rule.show[key].limits = l.concat(rules.show[key].limits);
            }
          })
        }
        if (rules.highlight) {
          let keys = Object.keys(rules.highlight)
          keys.forEach((key) => {
            if (!(key === 'Hull' || key === 'Superstruct')) {
              if (!multi_rule.highlight[key]) {
                multi_rule.highlight[key] = { "limits": [] }
              }
              let l = multi_rule.highlight[key] ? multi_rule.highlight[key].limits : [];
              multi_rule.highlight[key].limits = l.concat(rules.highlight[key].limits);
            }
          })
        }
      }
    })
    return multi_rule;
  }
  showHideSubSystem(_s, boo, nsc = false) {
    let s = this.ctrlServ.showSysComp[this.khasystem];
    if (nsc) {
      s = _s;
      this.ctrlServ.updateComps(s);
      this.ctrlServ.updateSysComps(s, true);
    }
    if (!s) {
      return;
    }
    if (!boo || s.length === 0) {
      this.resetSubSystems();
      return
    }
    //this.ctrlServ.updateComps(s);
    /* if(s.length===1){
      this.showERNObjectFor(s[0]);
    }else{
      s.forEach((item)=>{
        this.showERNObjectFor(item,false);
      })
    } */
    this.showERNObjectFor(s);
  }
  showHideSubSystemAll(boo) {
    let systems = Object.keys(this.khadisplayrules);
    if (boo) {
      this.ctrlServ.updateComps(systems);
      this.ctrlServ.updateSysComps(systems, boo);
      this.showERNObjectFor(systems, !boo);
    } else {
      this.ctrlServ.updateComps([]);
      this.ctrlServ.updateSysComps(systems, boo);
      this.showAllSubSystems();
      // this.datauxview.Camera('home');
    }
  }
  resetSubSystems() {
    if (this.ernkhasystemship) {
      this.ctrlServ.updateComps([]);
      // this.showAllSubSystems();
      this.hideAllSubSystems();
      if (this.hideWall) {
        //this.showHideHull();
      }
    }
  }
  focusSubSystem(id) {
    let rules = this.khadisplayrules ? this.khadisplayrules[id[0]] : null;
    if (rules) {
      let foc = rules.focus
      this.moveCameraTo(null, foc);
    }
  }
  showHideMultipleSystems(khaList) {
    if (khaList.length > 0) {
      let boo = this.ctrlServ.showSysComp[this.khasystem].length > 0 ? true : false;
      if (boo) {
        let s = this.ctrlServ.showSysComp[this.khasystem];
        if (!s) return;
        this.showERNObjectFor(s, false);
      } else {
        this.resetSubSystems();
      }
      khaList.forEach(_sys => {
        let sys = _sys['kha'];
        if (_sys.status) {
          let khadisplayrules = systems['default']['rules'][sys];
          for (let m in khadisplayrules) {
            let rules = khadisplayrules[m];
            this.highlightComps(this.khasystemship, rules.highlight);
          }
        }
      });
    }
  }
  xshowHideSubSystem(s, boo) {
    s.forEach((_s) => {
      let objs = this.systems_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          // let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          // mesh.setEnabled(boo);

          let mat = this.khamaterials[_s];
          if (!boo) {
            //mat=mat+"_transparent";
            mat = "transparent";
          }
          this.applyMaterial(el, mat, this.khasystem);

          //let profile=boo?"regular":"transparent";
          //this.dfx.modify(el,{profile})
          /* let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          mesh.setEnabled(boo); */
          /* mesh.getChildMeshes().forEach((m) => {
            m.isVisible = boo;
          }) */
        }
      })
    })
  }
  showHideCompartment(s, boo) {
    s.forEach((_s) => {
      let objs = this.compartments_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          mesh.setEnabled(boo);
        }
      })
    })
  }
  compartment_filters = ['a__Anonymous', 'Boat_and_Boat_Handling', 'Cableways_Envelope', 'Ceilings', 'Deck_Coverings', 'Default', 'Format-Title_Block-Rev_Column', 'Landing_Markings', 'Linings', 'Maintenance_Envelope', 'Maintenance_Envelope_DFX', 'Minor_Structure', 'Text-Notes-Tables']
  showHideCompartmentWall(s, boo) {
    s.forEach((_s) => {
      let objs = this.compartments_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          let meshes = mesh.getChildMeshes();
          meshes.forEach((m) => {
            let str = m.name.toLowerCase();
            let act = str.includes('bulkheads_') || str.includes("_plating") || str.includes("_stiffeners") || str.includes("geometry") || str.includes("door") || str.includes("insulation")
            if (!act) {
              act = this.compartment_filters.some((item) => {
                let s = item.toLowerCase();
                return str.includes(s);
              })
            }
            if (act) {
              if (m.isEnabled()) {
                m.isVisible = boo;
              }
            }
          })
        }
      })
    })
  }

  getCompartments() {
    let dir = compartments['default'];
    let arr = Object.keys(dir);
    //let obj={};
    this.compartments_list = [];
    arr.forEach((item) => {
      //obj[item]=dir[item];
      this.compartments_list.push({ name: dir[item], code: item, selected: false });
    })
    //this.compartments_store=dir;
    return arr;
  }

  hideAllCompartments() {
    Object.keys(this.compartments_store).forEach((c) => {
      let objs = this.compartments_store[c];
      objs.forEach(obj => {
        this.hide(obj.name);
      });
    })
  }

  showCompartments(c, boo = true) {
    let objs = this.compartments_store[c];
    if (!objs) {
      return
    }
    objs.forEach(obj => {
      if (boo) {
        this.show(obj.name);
      } else {
        this.hide(obj.name);
      }
    });
  }

  compartments_store = {};
  compartments_list;
  processandloadComparment(c = null, boo = false) {
    if (!this.compartments_list) {
      this.getCompartments();
      this.broadcastInfo({ src: 'khaservice', event: 'update-compartments', data: this.compartments_list });
    }
    this.showMatObjectTag(null, false);
    // if clear_compartments true every component it will fresh load
    // if (this.clear_compartments) {
    //   // this.clearAllElements();
    //   if (boo) {
    //     !this.slms_mode ? this.showSystemShip(false) : null;
    //     if(this.compartments_store){
    //       let compList =Object.keys(this.compartments_store);
    //       if(compList.includes(c)){
    //         let el = this.datauxview.getElementId(c);
    //         this.focusElement(el);
    //         return;
    //       }
    //     }
    //     this.loadCompartment(c);
    //   }
    // } else {
    // if (!this.multi_compartments) {
    //   this.hideAllCompartments();
    // }
    if (boo) {
      var compState = true;
      // if( this.layoutData){
      //   if(this.layoutData['compartments']){
      //   let idx =  this.layoutData['compartments'].findIndex(e=> e.code==c);
      //   if(idx>-1){
      //     compState=false;
      //   }
      // }
      // }
      // if( this.groupList){
      //   let idx =  this.groupList.findIndex(e=> e.code==c);
      //   if(idx>-1){
      //     compState=false;
      //   }
      // }
      if (this.compartments_store) {
        let compList = Object.keys(this.compartments_store);
        if (compList.includes(c)) {
          let el = this.datauxview.getElementId("HFX_" + c);
          this.focusElement(el);
          return;
        } else {
          !this.slms_mode ? this.showSystemShip(false) : null;
          // if(compState){
          this.loadCompartment(c);
          // }                                 
        }
      } else {
        !this.slms_mode ? this.showSystemShip(false) : null;
        // if(compState){
        this.loadCompartment(c);
        // }
        // if(this.groupList.length>0){
        //   this.groupPartVisible();
        // }
      }
      // if (this.compartments_store[c]) {
      //   this.showCompartments(c);
      //   setTimeout(() => {
      //     this.updateHomePosition();
      //   }, 1000);
      // } else {
      //   !this.slms_mode ? this.showSystemShip(false) : null;
      //   this.loadCompartment(c);
      // }
    } else {
      if (this.multi_compartments) {
        this.showCompartments(c, false);
      }
    }
    // }
  }

  /* *
 * Group for load mutiple compartments
 * * */
  processGroupMutipleComparment() {
    // this.layoutData = layout || {};
    if (this.groupList.length > 0) {
      if (!this.compartments_list) {
        this.getCompartments();
        this.broadcastInfo({ src: 'khaservice', event: 'update-compartments', data: this.compartments_list });
      }
      this.showMatObjectTag(null, false);
      // this.showSystemShip(false);
      this.defDragMode = this.dragMode;
      this.loadGroupMultipleCompartment();
    }

  }
  groupMode = false;
  loadGroupMultipleCompartment() {
    let compartments = this.groupList || [];
    let comLength = compartments.length - 1;
    var loadedComp: any = [];
    // if (!this.dragMode) {
    //   this.dragMode = true;
    // }
    // if (this.groupIndex <= comLength) {
    // let c = compartments[this.groupIndex];
    compartments.forEach(c => {
      let state = false;
      if (loadedComp.length > 0) {
        let idx = loadedComp.findIndex(e => e == c.code);
        if (idx == -1) {
          loadedComp.push(c.code);
          state = true;
        } else {
          state = false;
        }
      } else {
        state = true;
        loadedComp.push(c.code);
      }
      console.log(c, state, loadedComp);
      if (state) {
        this.loadCompartment(c.code, (obj) => {
          console.log(obj);
          // this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
          this.groupPartVisible();
          // this.groupIndex++;
          // this.loadGroupMultipleCompartment();
          setTimeout(() => {
            this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'home' });
          }, 500);

        })
      }

    })

    // } else {
    // this.groupIndex = 0;
    // if (comLength > 1) {
    // setTimeout(() => {
    //   this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'home' });
    // },500);
    // }
    // if (!this.defDragMode) {
    //   this.removeDragAll();
    // }
    // this.dragMode = this.defDragMode;
    console.log("process completed!!");
    // }
  }
  updateStatusGroup(code, id) {
    let compartments = this.groupList || [];
    // let components = this.layoutData['components'] || [];
    let dfx = this.datauxview.getDatascape();
    console.log("updateStatusGroup-compartments", compartments)
    compartments.forEach((c) => {
      let key = 'HFX_' + c.code;
      let child = this.datauxview.getElementId(key);
      if (!child) {
        return
      }
      let mesh = dfx.getElementMesh(child).getChildren()[0];
      if (!mesh) {
        return
      }
      let meshes = mesh.getChildren();
      meshes.forEach((m) => {
        let mname = m.id;
        const comp = compartments.find(el => el.id === mname);
        // console.log(mname,compartments,comp)
        if (comp) {
          if (m) {
            // console.log("solid",m)
            // m._alpha =1;
            // m.position.y = Number(comp['position']['y'].toFixed(4));
            // m.position.z = Number(comp['position']['z'].toFixed(4));
            // let mat = this.scene.getMaterialByName(m.mid);;
            // m.material =mat;
            let mat = this.scene.getMaterialByName(this.meshMap[this.getMeshName(m.id)]);
            m.material = mat;
          }
        } else {
          if (m) {
            // console.log("transparent",m)
            let mat = this.scene.getMaterialByName('transparent');
            m.material = mat;
          }
        }
      })
    })
  }
  /* *
   * Layout for load mutiple compartments
   * * */
  layoutData = {};
  defDragMode = false;
  layoutName: any;
  layoutCompartmentLeanth: any;

  processMutipleComparment(layout, boo = false) {
    this.layoutData = layout || {};
    if (!this.compartments_list) {
      this.getCompartments();
      this.broadcastInfo({ src: 'khaservice', event: 'update-compartments', data: this.compartments_list });
    }
    this.showMatObjectTag(null, false);
    // this.showSystemShip(false);
    this.defDragMode = this.dragMode;
    this.loadMultipleCompartment();
  }

  index = 0;
  loadMultipleCompartment() {
    let tooltipObj = this.layoutData['tagPoints'] || [];
    let compartments = this.layoutData['compartments'] || [];
    // this.getLayoutCompartment(); 
    //tooltipObj.length>0?tooltipObj.filter((arr, index, self) => index === self.findIndex((t) => (t.code === arr.code))):[];
    //
    console.log("tooltipObj.compartments", compartments)
    let markers = this.layoutData['markers'] || [];
    this.layoutName = 'layout';
    // if (this.layoutName && markers.length < 0) {
    //   this.applyCutMarkersFromData(markers);
    // }
    this.layoutCompartmentLeanth = compartments.length;
    let comLength = compartments.length - 1;
    if (!this.dragMode) {
      this.dragMode = true;
    }
    if (this.index <= comLength) {
      let c = compartments[this.index];
      this.loadCompartment(c.code, (obj) => {
        this.index++;
        this.loadMultipleCompartment();
      })
    } else {
      this.index = 0;
      if (comLength <= 1) {
        setTimeout(() => {
          this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'home' });
        });
      }
      if (!this.defDragMode) {
        this.removeDragAll();
      }
      this.dragMode = this.defDragMode;
      
      const data = this.applyCutMarkersFromData(markers);
      if (data) {
        this.reasignPartsPosition();
      }else{
        this.reasignPartsPosition();
      }

      console.log("process completed!!");
    }
  }
  getPartMesh(e, cbck) {
    let dfx = this.datauxview.getDatascape();
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    let mainComponents = [];
    let code = e.code;

    if (code === '--') {
      if (e['name'].includes("marker_")) {
        let arrSplit = e['name'].split("_");
        let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
        if (id) {
          if (this.cselects && this.cselects[id]) {
            // console.log("cselects",this.cselects[id])
            cbck(this.cselects[id][1]);
          }
        }

      } else {
        hfx.getChildren()[0].getChildMeshes().forEach((_mesh) => {
          let name = this.getMeshName(_mesh.name);
          if (name == e.partId) {
            const found = mainComponents.some(el => el === name);
            if (!found) {
              mainComponents.push(name);
              cbck(_mesh);
            }
          }
        })
      }

    } else {
      if (e['name'].includes("marker_")) {
        let arrSplit = e['name'].split("_");
        let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
        if (id) {
          if (this.cselects && this.cselects[id]) {
            // console.log("cselects",this.cselects[id])
            cbck(this.cselects[id][1]);
          }
        }

      } else {
        let key = 'HFX_' + e.code;
        let child = this.datauxview.getElementId(key);
        if (!child) {
          cbck();
        }
        if (child != undefined) {
          let mesh = dfx.getElementMesh(child).getChildren()[0];
          if (!mesh) {
            cbck();
          }
          let meshes = mesh.getChildren();
          meshes.forEach((m) => {
            let mname = this.getMeshName(m.name);
            if (mname == e.partId) {
              cbck(m);
            }
          })
        }
      }
    }
  }
  loadedCompartment: any = [];
  getLayoutCompartment(layoutData) {

    // standardsList.filter((li, idx, self) => self.map(itm => itm.Grade+itm.Domain).indexOf(li.Grade+li.Domain) === idx)
    let tagCompartment = layoutData['tagPoints'].length > 0 ? layoutData['tagPoints'].filter((arr, index, self) =>
      index === self.findIndex((t) => (t.code === arr.code))) : [];
    let component = layoutData['components'] || [];
    if (component.length > 0) {
      component.forEach((element, i) => {
        let code = element.name.split('+')[0].split('_')[1];
        layoutData['components'][i]['code'] = code;
      });
    }
    let componentCompartment = layoutData['components'].length > 0 ? layoutData['components'].filter((arr, index, self) =>
      index === self.findIndex((t) => (t.code == arr.code))) : [];
    let compartmentCombin = tagCompartment.concat(componentCompartment);
    let filterCompartment = compartmentCombin.length > 0 ? compartmentCombin.filter((arr, index, self) =>
      index === self.findIndex((t) => (t.code == arr.code))) : [];
    let compartmentList = filterCompartment.length > 0 ? filterCompartment.filter((arr, index, self) => arr.code != '--') : [];
    // console.log("getLayoutCompartment",tagCompartment, componentCompartment,compartmentCombin,compartmentList);
    this.loadedCompartment = compartmentList;
    return compartmentList;
  }

  storedMeshComps = [];
  shipPointerList = [];
  reasignPartsPosition() {
    var compartments = this.layoutData['compartments'] || [];
    if (compartments.length == 0) {
      compartments = this.getLayoutCompartment(this.layoutData);
    }
    let components = this.layoutData['components'] || [];
    let tooltipObj = this.layoutData['tagPoints'] || [];
    console.log("tooltipObj.compartments", compartments)
    let dfx = this.datauxview.getDatascape();
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    let mainComponents = [];
    this.storedMeshComps = [];
    this.shipPointerList = [];
    let markerData = [];
    if (compartments.length > 0) {
      compartments.forEach((c) => {
        let key = 'HFX_' + c.code;
        let child = this.datauxview.getElementId(key);
        if (!child) {
          tooltipObj.forEach(e => {
            if (e['code'] === '--') {
              if (e['name'].includes("marker_")) {
                let arrSplit = e['name'].split("_");
                let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                if (id) {
                  if (this.cselects && this.cselects[id]) {
                    this.createMatObjectPoint(e, this.cselects[id][1]);
                  }
                }
              } else {
                if (hfx && hfx.getChildren()) {
                  const _mesh = hfx.getChildren()[0].getChildMeshes().find(mesh => this.getMeshName(mesh.name) === e.partId)
                  if (_mesh) {
                    let name = this.getMeshName(_mesh.name);
                    let idname = name + '_' + e.id;
                    const found = mainComponents.some(el => el === idname);
                    if (!found) {
                      mainComponents.push(idname);
                      this.storedMeshComps.push(name);
                      this.createMatObjectPoint(e, _mesh);
                    }
                  }
                }
              }
            }
          })
          return
        } else {
          let mesh = dfx.getElementMesh(child) ? dfx.getElementMesh(child).getChildren()[0] : '';
          if (!mesh) {
            return
          }
          let meshes = mesh.getChildren();
          meshes.forEach((m) => {
            let mname = this.getMeshName(m.name);
            const comp = components.find(el => el.name === mname);
            if (comp) {
              if (m) {
                m.position.x = Number(comp['position']['x'].toFixed(4));
                m.position.y = Number(comp['position']['y'].toFixed(4));
                m.position.z = Number(comp['position']['z'].toFixed(4));
              }
            }
            if (tooltipObj) {
              if (tooltipObj.length != 0) {
                tooltipObj.forEach(e => {
                  if (e['code'] === '--') {
                    if (e['name'].includes("marker_")) {
                      if (e['partMeshId'] == m.id) {
                        let arrSplit = e['name'].split("_");
                        let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                        if (id) {
                          if (this.cselects && this.cselects[id]) {
                            this.createMatObjectPoint(e, this.cselects[id][1]);
                          }
                        }
                      } else {
                        if (markerData.length == 0) {
                          markerData.push(e.name);
                          let arrSplit = e['name'].split("_");
                          let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                          if (id) {
                            if (this.cselects && this.cselects[id]) {
                              this.createMatObjectPoint(e, this.cselects[id][1]);
                            }
                          }
                        } else {
                          if (markerData) {
                            var data = markerData.find(marker => marker == e.name)
                            if (!data) {
                              markerData.push(e.name);
                              let arrSplit = e['name'].split("_");
                              let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                              if (id) {
                                if (this.cselects && this.cselects[id]) {
                                  this.createMatObjectPoint(e, this.cselects[id][1]);
                                }
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (hfx && hfx.getChildren()) {
                        const _mesh = hfx.getChildren()[0].getChildMeshes().find(mesh => this.getMeshName(mesh.name) === e.partId)
                        if (_mesh) {
                          let name = this.getMeshName(_mesh.name);
                          let idname = name + '_' + e.id;
                          const found = mainComponents.some(el => el === idname);
                          if (!found) {
                            mainComponents.push(idname);
                            this.storedMeshComps.push(name);
                            this.createMatObjectPoint(e, _mesh);
                          }
                        }
                      }
                    }
                  } else {
                    if (e['name'].includes("marker_")) {
                      if (e['partMeshId'] == m.id) {
                        let arrSplit = e['name'].split("_");
                        let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                        if (id) {
                          if (this.cselects && this.cselects[id]) {
                            this.createMatObjectPoint(e, this.cselects[id][1]);
                          }
                        }
                      } else {
                        if (markerData.length == 0) {
                          markerData.push(e.name);
                          let arrSplit = e['name'].split("_");
                          let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                          if (id) {
                            if (this.cselects && this.cselects[id]) {
                              this.createMatObjectPoint(e, this.cselects[id][1]);
                            }
                          }
                        } else {
                          if (markerData) {
                            var data = markerData.find(marker => marker == e.name)
                            if (!data) {
                              markerData.push(e.name);
                              let arrSplit = e['name'].split("_");
                              let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                              if (id) {
                                if (this.cselects && this.cselects[id]) {
                                  this.createMatObjectPoint(e, this.cselects[id][1]);
                                }
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (mname == e.partId) {
                        this.storedMeshComps.push(mname);
                        this.createMatObjectPoint(e, m);
                      } else {
                        let mtl = this.scene.getMaterialByID("transparent");
                        m.material = mtl;
                      }
                    }
                  }
                })
              } else {
                let mtl = this.scene.getMaterialByID("transparent");
                m.material = mtl;
              }

            }
          })
        }

      })
    } else {
      if (tooltipObj.length > 0) {
        let decks = this.khasystemship;
        tooltipObj.forEach(e => {
          if (e['code'] === '--') {
            if (e['name'].includes("marker_")) {
              let arrSplit = e['name'].split("_");
              let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
              if (id) {
                if (this.cselects && this.cselects[id]) {
                  this.createMatObjectPoint(e, this.cselects[id][1]);
                }
              }

            } else {
              if (hfx && hfx.getChildren()) {
                hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {

                  const _mesh = hfx.getChildren()[0].getChildMeshes().find(mesh => this.getMeshName(mesh.name) === e.partId)
                  if (_mesh) {
                    let name = this.getMeshName(_mesh.name);
                    let idname = name + '_' + e.id;
                    const found = mainComponents.some(el => el === idname);
                    if (!found) {
                      mainComponents.push(idname);
                      this.storedMeshComps.push(name);
                      this.createMatObjectPoint(e, _mesh);
                    }
                  }


                })
              }
            }
          } else {
            let key = 'HFX_' + e.code;
            let child = this.datauxview.getElementId(key);
            if (!child) {
              if (e['name'].includes("marker_")) {
                let arrSplit = e['name'].split("_");
                let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                if (id) {
                  if (this.cselects && this.cselects[id]) {
                    this.createMatObjectPoint(e, this.cselects[id][1]);
                  }
                }

              } else {
                if (hfx && hfx.getChildren()) {
                  const _mesh = hfx.getChildren()[0].getChildMeshes().find(mesh => this.getMeshName(mesh.name) === e.partId)
                  if (_mesh) {
                    let name = this.getMeshName(_mesh.name);
                    let idname = name + '_' + e.id;
                    const found = mainComponents.some(el => el === idname);
                    if (!found) {
                      mainComponents.push(idname);
                      this.storedMeshComps.push(name);
                      this.createMatObjectPoint(e, _mesh);
                    }
                  }
                }
              }
            }
            let mesh = dfx.getElementMesh(child) ? dfx.getElementMesh(child).getChildren()[0] : '';
            if (!mesh) {
              return
            }
            let meshes = mesh.getChildren();
            meshes.forEach((m) => {
              let mname = this.getMeshName(m.name);
              if (e['name'].includes("marker_")) {
                let arrSplit = e['name'].split("_");
                let id = arrSplit.length == 3 ? arrSplit[0] + "_" + arrSplit[1] : '';
                if (id) {
                  if (this.cselects && this.cselects[id]) {
                    // console.log("cselects",this.cselects[id])
                    // this.shipPointerList.push(e)
                    this.createMatObjectPoint(e, this.cselects[id][1]);
                  }
                }

              } else {
                if (mname == e.partId) {
                  this.storedMeshComps.push(mname);
                  this.createMatObjectPoint(e, m);
                }
              }
            })
          }
        })
        // if (decks) {
        //   tooltipObj.forEach(e=>{
        //   decks.getChildren((pmesh) => {

        //   console.log("tooltipObj-pmesh",tooltipObj,pmesh,this.defaultDeckList.includes(e.partMeshId))
        //   })
        // })
        // }
      }

    }
    console.log("this.shipPointerList", this.shipPointerList)
  }

  /* * *
   * till Lyaout 
   * * */

  loadCompartment(c, cbck = null, focus = true) {
    if (this.compartments_list && compartments['default'][c]) {
      console.log("loadCompartment ")
      let narr = ["/HFX_by_compartments/HFX_" + c + ".glb"];
      this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
      // if (this.clear_compartments) {
      //   this.compartments_store = {};
      // }
      this.clearDrag();
      this.loadShipSystems(narr, (objs) => {
        this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
        this.compartments_store[c] = objs;
        console.log("loadShipSystems", this.compartments_store)
        // if(this.groupList.length>0){
        //   this.groupPartVisible();
        // }
        objs.forEach((obj) => {
          let el = this.datauxview.getElementId(obj.name);
          if (el) {
            this.enableMouseEvent(el);
            //this.enableDrag(obj.name);
            this.dfx.setElementScale(el, [1, 1, -1]);
            if (this.layoutName == 'layout') {
              if (this.layoutCompartmentLeanth <= 1) {
                if (focus) {
                  if (!this.groupMode) {
                    this.focusElement(el);
                  }
                }
              }
            } else {
              if (focus) {
                if (!this.groupMode) {
                  this.focusElement(el);
                }
              }
            }
            if (this.slms_mode) {
              this.showHideCompartmentWall([c], false);
            }
            setTimeout(() => {
              if (this.dragMode) {
                this.enableDrag();
              }
              if (focus) {
                this.updateHomePosition();
              }
              if (this.compartments_store) {
                this.broadcastInfo({ src: 'khaservice', event: 'slmsSidePanel', data: this.compartments_store });
              }
              // if(!this.groupMode){
              this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
              // }

            }, 3000);
          }
        })

        if (cbck) {
          cbck(objs);
        }
      });
    } else {
      // alert("Data for compartment " + c + " not available!")
    }
  }


  groupPartVisible() {
    let compartments = this.groupList || [];
    // let components = this.layoutData['components'] || [];
    let dfx = this.datauxview.getDatascape();

    compartments.forEach((c) => {
      let key = 'HFX_' + c.code;
      let child = this.datauxview.getElementId(key);
      if (!child) {
        return
      }
      let mesh = dfx.getElementMesh(child).getChildren()[0];
      if (!mesh) {
        return
      }
      let meshes = mesh.getChildren();
      meshes.forEach((m) => {
        let mname = m.id;
        const comp = compartments.find(el => el.id === mname);
        // console.log(mname,compartments,comp)
        if (comp) {
          if (m) {

            // console.log("solid",m)
            // m._alpha =1;
            // m.position.y = Number(comp['position']['y'].toFixed(4));
            // m.position.z = Number(comp['position']['z'].toFixed(4));
          }
        } else {
          if (m) {
            // console.log("transparent",m)
            let mat = this.scene.getMaterialByName('transparent');
            m.material = mat;
          }
        }
      })
    })
    setTimeout(() => {
      this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
    }, 3500);

  }
  /**
   *
   * load compartments of a Deck
   */
  loadCompartmentsInDeck(d) {
    this.multi_compartments = true;
    let comps = this.getCompartmentsInDeck(d);
    let loop = () => {
      if (!comps.length) {
        this.updateHomePosition();
        this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: d });
        return
      }
      let c = comps.shift()
      if (compartments['default'][c]) {
        let narr = ["/HFX_by_compartments/HFX_" + c + ".glb"];
        this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
        this.loadShipSystems(narr, (objs) => {
          this.compartments_store[c] = objs;
          objs.forEach((obj) => {
            let el = this.datauxview.getElementId(obj.name);
            if (el) {
              this.enableMouseEvent(el);
              this.dfx.setElementScale(el, [1, 1, -1]);
              //this.focusElement(el);
              setTimeout(() => {
                if (this.dragMode) {
                  this.enableDrag();
                }
              }, 1000);
            }
          })
          loop();
        });
      } else {
        // alert("Data for compartment " + c + " not available!")
      }
    }
    loop()
  }
  getCompartmentsInDeck(d) {
    if (!this.compartments_list) {
      this.getCompartments();
    }
    let comps = []
    this.compartments_list.forEach((c) => {
      let code = c.code.substr(0, 1);
      if (code === '0') {
        code = c.code.substr(0, 2);
      }
      if (code && code === d) {
        comps.push(c.code);
      }
    })
    return comps;
  }
  /* * * *
  * show the object
  * * * */
  show(name) {
    let el = this.datauxview.getElementId(name);
    if (el) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(el, _obj);
      this.focusElement(el);
    }
  }
  /* * * *
  * hide the object
  * * * */
  hide(name) {
    let el = this.datauxview.getElementId(name);
    if (el) {
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(el, _obj);
    }
  }
  toggleSubSystemStatus(comp, boo) {
    let subSystems = this.systemSubComps[comp];
    if (subSystems.length > 0) {
      let mat;
      subSystems.forEach(m => {
        if (boo) {
          mat = this.scene.getMaterialByName(comp);
        } else {
          mat = this.scene.getMaterialByName('transparent');
        }
        if (mat) {
          if (m['mesh'].material) {
            m['mesh'].material = mat;
          }
        }
      })
    }
  }
  /**
   * method for get material component status
   */
  getSystemCompStatus(cbck = null) {
    try {
      let self = this;
      let mid = "transparent";
      let colors = ['red', 'yellow', 'transparent'];
      this.systemSubComps = { 'red': [], 'yellow': [], 'transparent': [] };
      let mesh = this.getChild(this.ernkhasystemship, "ERNs");
      let transparent_mat = this.scene.getMaterialByName(mid);
      let red_mat = this.scene.getMaterialByName('red');
      let yellow_mat = this.scene.getMaterialByName('yellow');
      // let material = { 'red': red_mat, 'yellow': yellow_mat, 'transparent': transparent_mat };

      if (transparent_mat && red_mat && yellow_mat) {
        mesh.getChildMeshes().forEach((m) => {
          if (m.material) {
            const item = this.MaterialStatusData.find(el => el.name === m.id && el.khasystem === this.khasystem);
            if (item) {
              let mat = item['material'] || 'transparent';
              let obj = { id: m.id, mesh: m }
              self.systemSubComps[mat].push(obj);
            } else {
              let mat = colors[Math.floor(Math.random() * colors.length)];
              let obj = { id: m.id, mesh: m }
              self.systemSubComps[mat].push(obj);
            }
          }
        })
      }
      if (cbck) {
        cbck(true);
      }
    } catch (e) {
      console.log(e);
      if (cbck) {
        cbck(true);
      }
    }
  }

  /**
   * method for apply material for subcomponents
   */
  statusMode = false;
  loadSystemStatus(status) {
    console.log("system status change");
    this.statusMode = true;
    Object.keys(this.systemSubComps).forEach((comp) => {
      let boo = status[comp];
      let subSystems = this.systemSubComps[comp];
      if (subSystems.length > 0) {
        let mat;
        subSystems.forEach(m => {
          if (boo) {
            mat = this.scene.getMaterialByName(comp);
          } else {
            mat = this.scene.getMaterialByName('transparent');
          }
          if (mat) {
            if (m['mesh'].material) {
              m['mesh'].material = mat;
            }
          }
        })
      }
    })
  }

  /**
   * method for update status for selected comp
   */
  updateSystemStatus(m, status) {
    let mat = this.scene.getMaterialByName(status);
    if (mat) {
      if (m['mesh'].material) {
        m['mesh'].material = mat;
      }
    }
  }

  /**
   * method for show only selected comp
   */
  toggleSysComp(_comps) {
    for (let sys of Object.keys(this.systems_store)) {
      let objs = this.systems_store[sys];
      if (objs) {
        objs.forEach((obj) => {
          let el = this.datauxview.getElementId(obj.name);
          if (el) {
            let boo = false;
            let mat;
            let comp = _comps.find(el => obj.name.endsWith(el))
            if (comp) {
              boo = true;
              mat = this.khamaterials[comp];
            }
            if (!boo) {
              //mat=mat+"_transparent";
              mat = "transparent";
            }
            /*let mesh = this.dfx.getElementMesh(el).getChildren()[0];
            mesh.setEnabled(boo);*/
            this.applyMaterial(el, mat, this.khasystem);
          }
        })
      }
    }
    this.ctrlServ.updateComps(_comps);
  }

  /**
   * method for get current target for compartment home
   */
  updateHomePosition() {
    let dfx = this.datauxview.getDatascape();
    let cameraProps = dfx.getCamera();
    let view = { "target": { "x": -1.1, "y": 10, "z": -30 }, "distance": 60, "yaw": 140, "pitch": 12 };
    view['target']['x'] = Number(cameraProps['targetX']['value'].toFixed(4));
    view['target']['y'] = Number(cameraProps['targetY']['value'].toFixed(4));
    view['target']['z'] = Number(cameraProps['targetZ']['value'].toFixed(4));
    view['distance'] = Number(cameraProps['distance']['value'].toFixed(4));
    view['yaw'] = Number(cameraProps['yaw_deg']['value'].toFixed(4));
    view['pitch'] = Number(cameraProps['pitch_deg']['value'].toFixed(4));
    this.broadcastInfo({ src: 'khaservice', event: 'updatehomeview', data: view });
  }

  initSystems(sys = 'firesafety') {
    // this.processandloadComparment();
    // this.processSystems(sys)
  }

  shipDefPosition: any = {};
  getSysDefalutPosition(mesh) {
    this.shipDefPosition = {};
    let obj = {};
    let dfx = this.datauxview.getDatascape();
    let meshes = mesh.getChildren();
    meshes.forEach((msh) => {
      let _meshes = msh.getChildMeshes();
      _meshes.forEach((m) => {
        let mname = this.getMeshName(m.name);
        obj[mname] = { x: m.position.x, y: m.position.y, z: m.position.z };
      });
    });
    this.shipDefPosition = JSON.parse(JSON.stringify(obj));
  }

  clearDragCurrentState(layoutArr) {
    return layoutArr.map(el => { el.active = false; return el; });
  }

  updateAbsolutePosition(tagPoints) {
    if (tagPoints.length > 0) {
      tagPoints.forEach((ele, idx) => {
        this.getPartMesh(ele, (m) => {
          if (m != undefined) {
            let childMesh = m.getChildren();
            if (childMesh.length > 0) {
              childMesh.forEach(element => {
                let id = "element-" + ele.id;
                if (id == element.name) {
                  console.log(" tagPoints[idx]['hit']", tagPoints[idx]['hit']);
                  console.log("childMesh", element._absolutePosition);
                  tagPoints[idx]['hit'] = { x: element._absolutePosition.x, y: element._absolutePosition.y, z: element._absolutePosition.z }
                }
              })
            }
          }
        })

        // if(this.layoutPointMesh.length>0){
        // this.layoutPointMesh.forEach(element => {
        //   let id= "element-"+ele.id;
        //   if(id==element.name){
        //     tagPoints[idx]['hit']={x:element._absolutePosition.x,y:element._absolutePosition.y,z:element._absolutePosition.z}
        //   }
        // });
        // }
      });
      return tagPoints
    } else {
      return [];
    }

  }

  // getloadedComponents(){
  //   let codeList = Object.keys(this.compartments_store);
  //   var compoent = [];
  //   if(codeList.length>0){
  //     codeList.forEach(element => {
  //       compoent.push(element)
  //     });
  //   }
  //   return compoent;
  // }
  shipDragobj: any = {};
  layoutComponents: any = [];
  layoutDragedComps: any = [];
  layoutPointMesh: any = [];
  enableElementDrag(key, children = true) {
    let dfx = this.datauxview.getDatascape();
    let child = this.datauxview.getElementId(key);
    let code = key.split('_')[1];
    if (!child) {
      return
    }
    let mesh = dfx.getElementMesh(child).getChildren()[0];
    if (!mesh) {
      return
    }
    let meshes = children ? mesh.getChildren() : [mesh];
    meshes.forEach((m) => {
      let mname = this.getMeshName(m.name)
      let b = dfx.getDragBehavior([0, 1, 0]);
      b.useObjectOrienationForDragging = false;
      b.updateDragPlane = false;
      m.addBehavior(b);

      b.onDragObservable.add((event) => {
        this.isDrag = true;
        if (this.rightclick) {
          b.releaseDrag();
          this.rightclick = true;
          this.isDrag = false;
        }
      })

      let dragStartPos = {}
      b.onDragStartObservable.add((event) => {
        let pos = { x: m.position.x, y: m.position.y, z: m.position.z }
        dragStartPos = JSON.parse(JSON.stringify(pos))
      })

      b.onDragEndObservable.add((event) => {
        console.log("mesh", m, "child", m.getChildren())
        this.layoutPointMesh = m.getChildren();
        let endPos = { x: m.position.x, y: m.position.y, z: m.position.z }
        let x = this.getDifference(dragStartPos['x'], endPos.x);
        let y = this.getDifference(dragStartPos['y'], endPos.y);
        let z = this.getDifference(dragStartPos['z'], endPos.z);
        if (x > 0 || y > 0 || z > 0) {
          this.isComponentDragged = true;
          let obj = { code: code, name: mname, position: { x: endPos.x, y: endPos.y, z: endPos.z } };
          const idx = this.layoutComponents.findIndex(el => el.name === mname);
          if (idx > -1) {
            this.layoutComponents[idx] = obj;
            this.layoutDragedComps = this.clearDragCurrentState(this.layoutDragedComps);
            this.layoutDragedComps.push(Object.assign({}, obj, { Mesh: m, active: true }));
          } else {
            this.layoutComponents.push(obj);
            this.layoutDragedComps = this.clearDragCurrentState(this.layoutDragedComps);
            let _obj = { code: code, name: mname, position: { x: 0, y: 0, z: 0 }, Mesh: m, active: false }
            this.layoutDragedComps.push(_obj);
            this.layoutDragedComps.push(Object.assign({}, obj, { Mesh: m, active: true }));
          }
        } else {
          this.moveCameraTo(null, this.selectedHitPosition);
        }
        // console.log("layoutComponents ", this.layoutDragedComps, this.layoutComponents)
      })
      this.shipDragobj[mname] = [m, b];
    })
  }
  getDifference(a, b) {
    return a > b ? a - b : b - a;
    // return Math.abs(a - b);
  }
  enableSystemDrag(mesh) {
    let dfx = this.datauxview.getDatascape();
    let meshes = mesh.getChildren();
    meshes.forEach((msh) => {
      let _meshes = msh.getChildMeshes();
      _meshes.forEach((m) => {
        let mname = this.getMeshName(m.name)
        let b = dfx.getDragBehavior([0, 1, 0]);
        b.useObjectOrienationForDragging = false;
        b.updateDragPlane = false;
        m.addBehavior(b);
        b.onDragObservable.add((event) => {
          this.isDrag = true;
          if (this.rightclick) {
            b.releaseDrag();
            this.rightclick = true;
            this.isDrag = false;
          }
        })
        let dragStartPos = {}
        b.onDragStartObservable.add((event) => {
          let rot = m.rotation;
          let pos = m.position;
          dragStartPos = JSON.parse(JSON.stringify(pos))
        })
        b.onDragEndObservable.add((event) => {
          let rot = m.rotation;
          let endPos = m.position;
          let x = this.getDifference(dragStartPos['x'], endPos.x);
          let y = this.getDifference(dragStartPos['y'], endPos.y);
          let z = this.getDifference(dragStartPos['z'], endPos.z);
          if (x > 0 || y > 0 || z > 0) {
            console.log("updateTagPosition", m, this.shipTagMesh);
            if (this.shipTagMesh) {
              if (m.id == this.shipTagMesh.id) {
                this.updateTagPosition({ x, y, z }, dragStartPos, endPos);
              }
            } else {
              this.updateTagPosition({ x, y, z }, dragStartPos, endPos);
            }

          } else {
            this.moveCameraTo(null, this.selectedHitPosition);
          }
        })
        this.shipDragobj[mname] = [m, b];
      })
    })
  }

  dragMode = false;
  enableDrag() {
    //this.clearDrag();
    //this.dragMode=true;
    let store = this.khamode === 'system' ? this.systems_store : this.compartments_store;
    if (this.khamode === 'system') {
      this.enableSystemDrag(this.khasystemship);
      return;
    }
    for (let m in store) {
      let objs = store[m];
      objs.forEach((obj) => {
        this.enableElementDrag(obj.name, this.khamode === 'compartment')
      })
    }
  }

  removeDragAll() {
    //this.dragMode=false;
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      if (this.shipDragobj[key]) {
        this.shipDragobj[key][0].removeBehavior(this.shipDragobj[key][1]);
        this.shipDragobj[key][1] = null;
      }
    });
  }

  getCompartmentPosition(mname) {
    let pos = { x: 0, y: 0, z: 0 };
    if (Object.keys(this.layoutData).length === 0) {
      return pos;
    } else {
      let components = this.layoutData['components'] || [];
      const comp = components.find(el => el.name === mname);
      if (comp) {
        pos.x = Number(comp['position']['x'].toFixed(4));
        pos.y = Number(comp['position']['y'].toFixed(4));
        pos.z = Number(comp['position']['z'].toFixed(4));
      }
      return pos;
    }
  }

  resetDragElements() {
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      let m = this.shipDragobj[key];
      let pos = this.khamode === 'system' ? this.shipDefPosition[key] : this.getCompartmentPosition(key);
      if (this.khamode === 'system') {
        this.sysmoveDefTagPosition();
      }
      if (m) {
        m[0].position.x = Number(pos['x'].toFixed(4));
        m[0].position.y = Number(pos['y'].toFixed(4));
        m[0].position.z = Number(pos['z'].toFixed(4));
      }
    });
  }
  clearDrag(boo = true) {
    this.removeDragAll();
    if (boo) {
      this.resetDragElements();
    }
    this.shipDragobj = {};
  }
  applyMaterial(el, mid, sys, _mesh = null, _pick = null) {
    let mesh = _mesh ? _mesh : this.dfx.getElementMesh(el);
    if (sys === 'eer') {
      // this.applyMaterialForEER(mesh);
    }
    let _mid = mid
    if (_mesh && mid === 'regular') {
      _mid = _mesh.mid ? _mesh.mid : 'transparent';
    }
    let mat = this.scene.getMaterialByName(_mid);
    if (mat) {
      if (mesh.material) {
        mesh.material = mat;
        if (_pick !== null) {
          mesh.isPickable = _pick
        }
      }
      mesh.getChildMeshes().forEach((m) => {
        if (m.material) {
          if (_mesh && mid === 'regular') {
            _mid = m.mid ? m.mid : _mid;
            mat = this.scene.getMaterialByName(_mid);
          }
          m.material = mat;
          if (_pick !== null) {
            m.isPickable = _pick
          }
        }
      })
    }
  }

  // applySubSystemMaterial(mesh, status, mid, sys) {
  //   let self = this;
  //   let colors = ['red', 'yellow', 'transparent'];
  //   //let mesh = this.dfx.getElementMesh(el);
  //   /* if (sys === 'eer') {
  //     this.applyMaterialForEER(mesh);
  //   } */
  //   let transparent_mat = this.scene.getMaterialByName(mid);
  //   let red_mat = this.scene.getMaterialByName('red');
  //   let yellow_mat = this.scene.getMaterialByName('yellow');
  //   let material = { 'red': red_mat, 'yellow': yellow_mat, 'transparent': transparent_mat };

  //   if (transparent_mat && red_mat && yellow_mat) {
  //     if (mesh.material) {
  //       mesh.material = transparent_mat;
  //     }
  //     mesh.getChildMeshes().forEach((m) => {
  //       if (m.material) {
  //         let mat = colors[Math.floor(Math.random() * colors.length)];
  //         self.systemSubComps[mat].push(m);
  //         let boo = status[mat];
  //         if (boo) {
  //           m.material = material[mat];
  //         } else {
  //           m.material = material['transparent'];
  //         }
  //       }
  //     })
  //   }
  //   // console.log("systemSubComps ", this.systemSubComps)
  // }

  /**
  * method for get center position of child mesh
  */
  getAverageCenter(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = [0, 0, 0]
    meshes.forEach((mesh) => {
      let pt = mesh.getBoundingInfo().boundingSphere.centerWorld
      c[0] += pt.x; c[1] += pt.y; c[2] += pt.z;
    });
    return { x: c[0] / l, y: c[1] / l, z: c[2] / l }
  }

  /* * * * *
  * focus particluar element
  * * * * * */
  focusElement(elem) {
    if (elem) {
      let dfx = this.datauxview.getDatascape();
      let mesh = dfx.getElementMesh(elem);
      let def = { "target": this.getAverageCenter(mesh), "distance": this.distance };
      dfx.moveCamera(def);
    }
  }

  // applyMaterialForEER(m) {
  //   let mats = systems['default']['materials']['eer'];
  //   let meshes = m.getChildren()[0].getChildren();
  //   meshes.forEach((mesh) => {
  //     let d = this.getDeckCode(mesh.name);
  //     let mat = this.scene.getMaterialByName(mats[d]);
  //     if (mat) {
  //       if (mesh.material) {
  //         mesh.material = mat;
  //       }
  //       mesh.getChildMeshes().forEach((m) => {
  //         if (m.material) {
  //           m.material = mat;
  //         }
  //       })
  //     }
  //   })
  // }

  postProcessSystem(sys, sub, obj) {
    this.enableMouseEvent(obj);
    let limits = systems['default']['limits'];
    let pro;
    if (limits) {
      pro = limits[sys];
    }
    if (pro) {
      let decks = pro["decks"];
      let compt = pro["compartments"];
      let sublimit = pro["sublimit"];
      let hasSubLim = sublimit && sublimit[sub];
      let m = this.dfx.getElementMesh(obj).getChildren()[0];
      let meshes = m.getChildren();
      meshes.forEach((mesh) => {
        let d = this.getDeckCode(mesh.name);
        let c = this.getCompartmentCode(mesh.name);
        let boo = true;
        if (compt) {
          boo = compt.includes(c);
        } else if (decks) {
          boo = decks.includes(d);
        }
        if (hasSubLim) {
          if (sublimit["compartments"]) {
            boo = sublimit["compartments"].includes(c);
          } else if (sublimit["decks"]) {
            boo = sublimit["decks"].includes(d);
          }
        }
        mesh.setEnabled(boo);
      })
    }
  }
  hasCompartmentCode(n) {
    let boo = true;
    return (n.includes("HFX_"))
  }
  getDeckCode(name) {
    if (!this.hasCompartmentCode(name)) {
      return name;
    }
    let c = this.getCompartmentCode(name);
    if (!c) {
      return null;
    }
    let d = c.substr(0, 1)
    d = d === '0' ? c.substr(0, 2) : d;
    return d;
  }

  getCompartmentCode(name) {
    let str: any = String(name).split(".");
    str = str[str.length - 1];
    let arr = str.split("_");
    let c = arr[1].split("+")[0];
    return c;
  }

  destroy() {
    this.broadcastSubs.unsubscribe();
    this.clearDrag()
    this.clearAllElements();
  }
  profiles: any = {
    "regular": { material: 'transparentZ', transition: 0, 'selectable': false },
    "transparent": { material: 'transparentZ' },
    "red": { material: 'red' },
    "pink": { material: 'see_me_pink' },
    "amber": { material: 'amber' },
    "green": { material: 'green' },
    "hidden": { material: 'hidden_obj', visible: false }
  }
  getDynamicCubeConfig(shape, id) {
    let dfx = this.dfx;
    let opt = {
      shape: shape,
      id: id,
      size: 1,
      profiles: this.profiles,
    }
    return dfx.model(opt);
  }
  onHoverElem
  enableMouseEvent(el) {
    this.dfx.attach(el, {
      actions: {
        hoverOn: [(evt, elem) => {
          if (this.isDrag === false) {
            // this.onHover(evt);
            // this.addObserver(elem)
          }
        }],
        hoverOff: [(evt, elem) => {
          //this.offHover();
        }],
        pickLeft: [(evt, elem) => {
          if (evt.button === 0) {
            // let props = this.dfx.props(el);
            // let comp = props.id;
            // let systems = ernMapping['default'];
            // let selectedKha = this.ctrlServ.selectedKha;
            // if (selectedKha) {
            //   let components = systems[selectedKha];
            //   for (let key of Object.keys(components)) {
            //     if (comp.includes(key)) {
            //       let ernCategory = (Object.keys(components[key])[0])
            //       // let ernNumber = components[key][ernCategory];
            //       // this.ctrlServ.selectedErn = ernCategory;
            //       // this.broadcastInfo({ src: 'khaservice', event: 'loadERNTreeModel', data: ernCategory, ern: ernNumber });
            //       this.broadcastInfo({ src: 'khaservice', event: 'loadNSCTreeModel', data: ernCategory });
            //       break;
            //     }
            //   }
            // }
          }
        }],
      }
    });
  }

  getTagVal(a, b) {
    if (Math.sign(a) === 1) {
      return a + b;
    } else if (Math.sign(a) === 0) {
      if (Math.sign(b) === 0) {
        return a;
      } else {
        return a + b;
      }
    } else {
      return a - b;
    }
  }

  updateTagPosition(dragPos, startPos, endPos) {
    let elem = this.datauxview.getElementId('materialPointer');
    if (elem) {
      let pos = { x: 0, y: 0, z: 0 };
      if (startPos['x'] < endPos['x']) {
        if (Math.sign(this.sysTagPickedPoint['x']) === 1) {
          pos.x = this.sysTagPickedPoint['x'] - dragPos['x'];
        } else {
          pos.x = this.sysTagPickedPoint['x'] - dragPos['x'];
        }
      } else {
        if (Math.sign(this.sysTagPickedPoint['x']) === -1) {
          pos.x = Math.sign(endPos['x']) === -1 ? this.sysTagPickedPoint['x'] + dragPos['x'] : this.sysTagPickedPoint['x'] + dragPos['x'];
        } else {
          pos.x = this.sysTagPickedPoint['x'] + dragPos['x'];
        }
      }
      pos.y = this.getTagVal(this.sysTagPickedPoint['y'], dragPos['y']);
      if (startPos['z'] < endPos['z']) {
        if (Math.sign(this.sysTagPickedPoint['z']) === 1) {
          pos.z = this.sysTagPickedPoint['z'] - dragPos['z'];
        } else {
          pos.z = this.sysTagPickedPoint['z'] + dragPos['z'];
        }
      } else {
        if (Math.sign(this.sysTagPickedPoint['z']) === -1) {
          pos.z = Math.sign(endPos['z']) === -1 ? this.sysTagPickedPoint['z'] - dragPos['z'] : this.sysTagPickedPoint['z'] - dragPos['z'];
        } else {
          pos.z = this.sysTagPickedPoint['z'] - dragPos['z'];
        }
      }
      // console.log("updateTagPosition ", this.sysTagPickedPoint, dragPos, pos);
      this.sysTagPickedPoint['x'] = pos.x;
      this.sysTagPickedPoint['y'] = pos.y;
      this.sysTagPickedPoint['z'] = pos.z;
      this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z } } });
    }
  }

  sysmoveDefTagPosition() {
    let elem = this.datauxview.getElementId('materialPointer');
    if (elem) {
      let pos = { x: 0, y: 0, z: 0 };
      pos['x'] = this.sysDefTagPickedPoint['x'];
      pos['y'] = this.sysDefTagPickedPoint['y'];
      pos['z'] = this.sysDefTagPickedPoint['z'];
      this.sysTagPickedPoint['x'] = this.sysDefTagPickedPoint['x'];
      this.sysTagPickedPoint['y'] = this.sysDefTagPickedPoint['y'];
      this.sysTagPickedPoint['z'] = this.sysDefTagPickedPoint['z'];
      this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z } } });
    }
  }
  matTagClear() {
    if (this.datauxview) {
      var elem = this.datauxview.getElementId('materialPointer');
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
        delete this.selectedMatObjTag;
        // delete this.shipTagMesh;
        this.removeObserver(elem);
        // this.hideHighlightSelection();
      }
    }
  }
  selectedTagMesh: any;
  sysTagPickedPoint: any = {};
  sysDefTagPickedPoint: any = {};
  shipTagMesh: any;
  createMatObject(shape, e, props) {
    if (this.datauxview) {
      var elem = this.datauxview.getElementId('materialPointer');
      var pos = e.pickedPoint;
      this.sysTagPickedPoint = pos;
      this.sysDefTagPickedPoint = JSON.parse(JSON.stringify(pos));
      var size = 0.1;
      this.shipTagMesh = e.pickedMesh;
      if (!elem) {
        let profiles = this.profiles;
        let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
        this.datauxview.addElement(shape, 'materialPointer', profiles, settings);
        let buildModel = this.datauxview.getElementId('materialPointer');
        this.dfx.modify(buildModel, { profile: 'regular' });
      } else {
        if (this.selectedMatObjTag) {
          // if (this.selectedMatObjTag.toLowerCase().indexOf(props.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            // delete this.shipTagMesh;
            this.removeObserver(elem);
          }
          // }
        }
        this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } });
      }
      // setTimeout(() => {     
      this.showMatObjectTag(props, true);
      // }, 1000);
    }
  }
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  layoutTagList: any = [];
  tagStatus: any[] = [];

  updateFloorColor() {
    if (this.layoutData) {
      let tooltipObj = this.layoutData['tagPoints'] || [];
      if (tooltipObj.length > 0) {
        let mainComponents = [];
        tooltipObj.forEach(ele => {
          if (ele['code'] === '--') {
            let ehfx = this.datauxview.getElementId("ehfx");
            let hfx = this.dfx.getElementMesh(ehfx);
            hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {
              let name = this.getMeshName(mesh.name);
              if (name == ele.partId) {
                const found = mainComponents.some(el => el === name);
                if (!found) {
                  let color = ele['groupColor'] || null;
                  let mname = this.ctrlServ.getMeshName(ele['partMeshId']);
                  let floorColor = this.checkDeckFloor(mname);
                  this.highlightSelection(mesh, 4, color, false, floorColor);
                }
              }
            })
          }



        });
      }
    }

  }
  showLayoutTagData: any[];
  showLayoutTagDataIdx: any;
  createMatObjectPoint(ele, m) {
    if (!m) return;
    let color = ele['groupColor'] ? ele['groupColor'].toLowerCase() : null;
    let mname = this.ctrlServ.getMeshName(ele['partMeshId']);
    let floorColor = this.checkDeckFloor(mname);
    this.highlightSelection(m, 4, color, false, floorColor);
    let material = '';
    if (ele.tagComments) {
      if (ele.tagComments.status) {
        let idx = this.tagStatus.findIndex(e => e.name == ele.tagComments.status.name);
        if (idx > -1) {
          material = this.tagStatus[idx]['value'];
        } else {
          material = '#ff0000';
        }
      }
    }

    var pos = ele.hit;
    var size = 0.5;
    let prof = this.profiles[material] ? material : this.getNewMaterial(material);
    // if (this.datauxview) {
    // var elem = this.datauxview.getElementId(ele.id);
    // if (elem) {
    //   // this.dfx.remove(elem)
    //   delete this.datauxview._allElements[ele.id];
    // }
    // elem = this.datauxview.getElementId(ele.id);
    // var pos = ele.hit;
    // var size = 0.5;
    // let prof = material;

    if (this.datauxview) {
      var elem = this.datauxview.getElementId(ele.id);
      if (!elem) {
        console.log('showLayoutTag', "true");
        // this.dfx.remove(elem);
        // delete this.datauxview._allElements[ele.id];
        let profiles = this.profiles;
        let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
        this.datauxview.addElement(ele.shape, ele.id, profiles, settings);
        let buildModel = this.datauxview.getElementId(ele.id);
        // let matColor = this.getColor("#FFFFFF");

        let cmesh = this.dfx.getElementMesh(buildModel);
        if (color === material.toLowerCase()) {
          material = this.invertColor(material)
          // cmesh.outlineWidth = 0.1;
          // cmesh.outlineColor = matColor;
          // cmesh.renderOutline = true; 
        }
        //cmesh.outlineColor = "#FFFFFF";
        let mtl = this.getNewMaterial(material);
        cmesh.material = mtl;
        //this.dfx.modify(buildModel, { profile: prof });
        if (ele.code != "--") {
          if (ele['name'].includes("marker_")) {
            // let arrSplit=e['name'].split("_");
            // let id=arrSplit.length==3?arrSplit[0]+"_"+arrSplit[1]:'';
            // if(id){
            //   if(this.cselects&&this.cselects[id]){

            //   }
            // }
            this.shipPointerList.push(ele);
          } else {
            cmesh.setParent(m);

          }
        } else {
          if (ele.code == "--") {

            this.shipPointerList.push(ele);
          }

        }

        // this.datauxview.getDatascape().attach(buildModel, {
        //   actions: {
        //     pidkUp: [(evt, elem) => {
        //       if (evt.button === 0) {
        //         let props = this.dfx.props(elem);
        //       console.log('elem',elem,props,this.layoutData['tagPoints'])
        //       }
        //     }],

        //   }
        // });
        // this.dfx.setAsParent(m, [cmesh]);
        // cmesh.parent=m
        // if (!this.showLayoutTagData) {
        //   this.showLayoutTag(ele, true);
        // }
        // this.showLayoutTag(ele, true);
        if (this.showLayoutTagData) {
          this.showLayoutTag(this.showLayoutTagData, true);
          this.broadcastInfo({ src: 'khaservice', event: 'updateActiveCard', data: this.showLayoutTagDataIdx });
        } else {
          this.showLayoutTag(ele, true);
        }
        // if(this.layoutTagList.length>0){
        //   let idx = this.layoutTagList.findIndex(e=> e.id==ele.id);
        //   if(idx==-1){
        //     this.layoutTagList.push(ele);
        //     this.showLayoutTag(ele, true);
        //   }
        // }else{
        //   this.layoutTagList.push(ele);
        //   this.showLayoutTag(ele, true);
        // }
      } else {
        console.log('showLayoutTag', "else");
        this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } });
        // this.dfx.modify(elem, { profile: prof });
        console.log('showLayoutTag points', ele);
        this.showLayoutTag(ele, true)
      }
    }
    //   if (!elem) {
    //     let profiles = this.profiles;
    //     let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
    //     this.datauxview.addElement(ele.shape, ele.id, profiles, settings);
    //     let buildModel = this.datauxview.getElementId(ele.id);
    //     // let matColor = this.getColor("#FFFFFF");

    //     let cmesh = this.dfx.getElementMesh(buildModel);
    //     if (color === material.toLowerCase()) {
    //       material = this.invertColor(material)
    //       // cmesh.outlineWidth = 0.1;
    //       // cmesh.outlineColor = matColor;
    //       // cmesh.renderOutline = true; 
    //     }
    //     //cmesh.outlineColor = "#FFFFFF";
    //     let mtl = this.getNewMaterial(material);
    //     cmesh.material = mtl;
    //     //this.dfx.modify(buildModel, { profile: prof });
    //     if (ele.code != "--") {
    //       if (ele['name'].includes("marker_")) {
    //         // let arrSplit=e['name'].split("_");
    //         // let id=arrSplit.length==3?arrSplit[0]+"_"+arrSplit[1]:'';
    //         // if(id){
    //         //   if(this.cselects&&this.cselects[id]){

    //         //   }
    //         // }
    //         this.shipPointerList.push(ele);
    //       } else {
    //         cmesh.setParent(m);

    //       }
    //     } else {
    //       if (ele.code == "--") {

    //         this.shipPointerList.push(ele);
    //       }

    //     }

    //     // this.datauxview.getDatascape().attach(buildModel, {
    //     //   actions: {
    //     //     pidkUp: [(evt, elem) => {
    //     //       if (evt.button === 0) {
    //     //         let props = this.dfx.props(elem);
    //     //       console.log('elem',elem,props,this.layoutData['tagPoints'])
    //     //       }
    //     //     }],

    //     //   }
    //     // });
    //     // this.dfx.setAsParent(m, [cmesh]);
    //     // cmesh.parent=m
    //     // if (!this.showLayoutTagData) {
    //     //   this.showLayoutTag(ele, true);
    //     // }
    //     // this.showLayoutTag(ele, true);
    //     if (this.showLayoutTagData) {
    //       this.showLayoutTag(this.showLayoutTagData, true);
    //       this.broadcastInfo({ src: 'khaservice', event: 'updateActiveCard' , data: this.showLayoutTagDataIdx});
    //     }else{
    //       this.showLayoutTag(ele, true);
    //     }
    //     // if(this.layoutTagList.length>0){
    //     //   let idx = this.layoutTagList.findIndex(e=> e.id==ele.id);
    //     //   if(idx==-1){
    //     //     this.layoutTagList.push(ele);
    //     //     this.showLayoutTag(ele, true);
    //     //   }
    //     // }else{
    //     //   this.layoutTagList.push(ele);
    //     //   this.showLayoutTag(ele, true);
    //     // }
    //   } else {
    //     this.dfx.modify(elem, { profile: prof });
    //     this.showLayoutTag(ele, true);
    //   }
    // }
  }
  // hidePointerMarker(data){
  //   if (data['name'].includes("marker_")) {
  //     console.log("hidePointerData", data.name)
  //     this.hidePointerData(data.id)
  //   }
  // }
  hidePointerData(data) {
    let buildModel = this.datauxview.getElementId(data);
    console.log("hidePointerData", data, buildModel, this.datauxview._allElements)
    if (buildModel) {

      this.dfx.modify(buildModel, { profile: 'hidden' });
      // this.dfx.remove(buildModel);
      delete this.datauxview._allElements[data];
    }

  }
  updateMatObjectPoint(tagList, group) {
    if (!tagList) return;
    let partId = this.getMeshName(tagList['partMeshId']);
    let code = tagList['code'];
    let color = group['color'] || null;
    let dfx = this.datauxview.getDatascape();
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    let mainComponents = [];

    if (code === '--') {
      hfx.getChildren()[0].getChildMeshes().forEach((_mesh) => {
        let name = this.getMeshName(_mesh.name);
        if (name == partId) {
          const found = mainComponents.some(el => el === name);
          if (!found) {
            mainComponents.push(name);
            let floorColor = this.checkDeckFloor(partId);
            this.highlightSelection(_mesh, 4, color, false, floorColor);
          }
        }
      })
    } else {
      let key = 'HFX_' + code;
      let child = this.datauxview.getElementId(key);
      if (!child) {
        return
      }
      let mesh = dfx.getElementMesh(child).getChildren()[0];
      if (!mesh) {
        return
      }
      let meshes = mesh.getChildren();
      meshes.forEach((m) => {
        let mname = this.getMeshName(m.name);
        if (mname == partId) {
          let floorColor = this.checkDeckFloor(partId);
          this.highlightSelection(m, 4, color, false, floorColor);
        }
      })
    }
  }

  showMatObjectTagMesh(e) {
    let elemId = 'HFX_' + e.model[0];
    let el = this.datauxview.getElementId(elemId);
    if (!el) {
      return;
    }
    if (el) {
      this.showMatObjectTag(e.name, true);
    }
  }

  showLayoutTag(ele, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId(ele.id);
    if (!el) {
      return;
    }
    if (flag) {
      // let dom: any = document.getElementById(ele.parentId+'_'+ele.id);
      if (this.layoutTagList.length > 0) {
        let idx = this.layoutTagList.findIndex(e => e.id == ele.id);
        if (idx == -1) {
          this.layoutTagList.push(ele);
          this.addObserver(el);
        }
      } else {
        this.layoutTagList.push(ele);
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementById(ele.id);
      if (dom) {
        dom.style.display = "none";
        let idx = this.layoutTagList.findIndex(e => e.id == ele.id);
        if (idx > -1) {
          this.layoutTagList.splice(idx, 1);
          this.removeObserver(el);
        }

      }
    }

  }
  /* * * *
  * show only selected laydown tag
  * * * */
  selectedMatObjTag: any;
  showMatObjectTag(element, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId('materialPointer');
    if (!el) {
      return;
    }
    if (flag) {
      if (this.selectedMatObjTag) {
        if (this.selectedMatObjTag.toLowerCase().indexOf(element.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            delete this.shipTagMesh;
            this.removeObserver(el);
          }
        } else {
          this.selectedMatObjTag = element;
          this.addObserver(el);
        }
      } else {
        this.selectedMatObjTag = element;
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
        delete this.selectedMatObjTag;
        delete this.shipTagMesh;
        this.removeObserver(el);
      }
    }
  }

  /**
  * add an Observer attach to 3d object
  */
  addObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().attach(elem, {
      actions: {},
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  };

  /**
   * Remove an Observer detach to 3d object
   */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /***
   * tag observer properties
   */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);
    let elem = this.datauxview.getElementId(id);
    if (elem) {
      let props = this.datauxview.getDatascape().props(elem);
      var pop = document.getElementsByClassName("mat_nm_label")[0];
      if (this.selectedMatObjTag) {
        if (props.id == "materialPointer") {
          this.objObserver(pop, position2D, props);
        }
      }
      if (this.layoutTagList.length > 0) {
        let idx = this.layoutTagList.findIndex(e => e.id == id);
        if (idx > -1) {
          pop = document.getElementById(id);
          this.objObserver(pop, position2D, props);
        }

      }
    }

  }

  /****
   * observable popup Tag
   */
  objObserver(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constLabelHeight) + 'px';
          // pop.innerHTML = this.selectedMatObjTag;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          // if (pop != null) {
          pop.style.display = 'none';
          // }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }
  matSystem: any;
  onHover(evt, el) {
    let nl: any = document.getElementsByClassName("mat_nm_label")[0];
    // let mesh = evt.meshUnderPointer
    let mesh = this.dfx.getElementMesh(el);
    // if (mesh) {
    //   if(this.materielElem){
    //     if(this.materielElem==mesh.name){
    //       nl.style.display = "none";
    //       delete this.materielElem;
    //     }else{
    //       this.materielElem= mesh.name;
    //       let name = mesh.name.split('-')[1]
    //       nl.innerHTML = name;
    //       nl.style.display = "block";
    //       let rec = nl.getBoundingClientRect();
    //       nl.style.left = evt.x + "px";
    //       nl.style.top = (evt.y - rec.height) - 10 + "px";
    //     }
    //         }else{
    //           this.materielElem= mesh.name;
    //           let name = mesh.name.split('-')[1]
    //           nl.innerHTML = name;
    //           nl.style.display = "block";
    //           let rec = nl.getBoundingClientRect();
    //           nl.style.left = evt.x + "px";
    //           nl.style.top = (evt.y - rec.height) - 10 + "px";
    //         }
    //   // let str = mesh.name;
    //   // let narr = str.split(".");
    //   // let nstr = narr[narr.length - 1];
    //   // let a = nstr.split("+")
    //   // let name = a[1] + " (" + (a[0].split("_")[1]) + ")";
    // }
  }
  offHover() {
    let nl: any = document.getElementsByClassName("mat_nm_label")[0];
    nl.style.display = "none"
  }
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    return arr[arr.length - 1];
  }
  /**
   * V3 related code - start
   */
  getChild(mesh, c) {
    if (!mesh) return null;
    let arr = mesh.getChildren();
    let a = null;
    arr.some((m) => {
      let name = this.getMeshName(m.name);
      if (c === name) {
        a = m;
        return
      }
    })
    return a
  }
  getChildContains(mesh, c) {
    if (!mesh) return null;
    let arr = mesh.getChildren();
    let a = null;
    arr.some((m) => {
      let name = this.getMeshName(m.name);
      if (name.includes(c)) {
        a = m;
        return
      }
    })
    return a
  }
  getNodeFrom(parent, tree) {
    if (!tree.length) {
      return parent;
    } else {
      let node = tree.shift();
      let child = this.getChild(parent, node);
      return this.getNodeFrom(child, tree);
    }
  }
  showCompsERN(node, filter) {
    let c = node.getChildren();
    c.forEach((m) => {
      let name = this.getMeshName(m.name);
      let deck = this.getDeckCode(name);
      if (deck === name && m.getChildren().length) {
        this.showCompsERN(m, filter);
      } else {
        if (filter.includes(deck) || filter.length === 0) {
          // n.setEnabled(false)
          this.enableComps(m, true);
        } else {
          // n.setEnabled(true);
          this.enableComps(m, false);
        }
      }

    })
  }
  showComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let skip = key === 'Hull' || key === 'Superstruct';
      if (skip) {
        return false;
      }
      let node = this.getChild(hfx, key);
      let subnodes = node.getChildren();
      let filter = obj[key].limits.slice();
      this.filter_deck.forEach(element => {
        filter = this.applyDeckFilter(key, element, filter)
        subnodes.forEach((n) => {
          if (key === 'ERNs') {
            this.showCompsERN(n, filter)
          } else {
            let name = this.getMeshName(n.name);
            let deck = key === 'Compartments' ? this.getDeckCode(name) : name;
            if (filter.includes(deck) || filter.length === 0) {
              // n.setEnabled(false)
              this.enableComps(n, true);
            } else {
              // n.setEnabled(true);
              this.enableComps(n, false);
            }
          }
        })
      });

    })
  }
  hideComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let node = this.getChild(hfx, key);
      let subnodes = node.getChildren();
      let filter = obj[key].limits;
      filter.forEach((n) => {
        let comp = this.getNodeFrom(node, n.slice(0));
        if (comp) {
          comp.setEnabled(false);
          //this.enableComps(comp,false)
        }
      })
    })
  }
  highlightComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let node = this.getChild(hfx, key);
      let filter = obj[key].limits;
      filter.forEach((n) => {
        let _mesh = this.getNodeFrom(node, n.slice(0));
        if (_mesh) {
          /* _mesh.outlineWidth = 0.025;
          _mesh.outlineColor = BABYLON.Color3.Teal();
          _mesh.renderOutline = true; */
          this.enableComps(_mesh, true);
          /* _mesh.getChildMeshes().forEach((mesh) => {

            mesh.outlineWidth = 0.025;
            mesh.outlineColor = BABYLON.Color3.Teal();
            mesh.renderOutline = true;
          }); */
        }
      })
    })
  }
  hideAllComps(hfx) {
    let meshes = hfx.getChildMeshes();
    meshes.forEach((mesh) => {
      if (mesh.setEnabled) {
        mesh.setEnabled(false);
        mesh.vstate = !true
      }
      if (mesh.renderOutline) {
        mesh.renderOutline = false;
      }
    })
  }
  showAllComps(parent) {
    let meshes = parent.getChildren();
    meshes.forEach((mesh) => {
      mesh.setEnabled(true);
      mesh.vstate = true
      if (mesh.renderOutline) {
        mesh.renderOutline = false;
      }
      this.showAllComps(mesh);
    })
  }
  showAllSubSystems() {
    // if (this.filter_deck) {
    //   this.showSystemsFDeck()
    //   return
    // }

    /* let ern = this.getChild(this.khasystemship, 'ERNs');
    let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    let cmprt = this.getChild(this.khasystemship, 'Compartments');
    this.showAllComps(ern);
    this.showAllComps(ern_ex);
    this.showAllComps(cmprt); */
    if (this.khadisplayrules) {
      for (let m in this.khadisplayrules) {
        let rules = this.khadisplayrules[m];
        this.highlightComps(this.ernkhasystemship, rules.highlight);
      }
    }
  }
  hideAllSubSystems() {
    let ern = this.getChild(this.ernkhasystemship, 'ERNs');
    if (!ern) return;
    this.hideAllComps(ern);
    // let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    // let cmprt = this.getChild(this.khasystemship, 'Compartments');     
    // this.hideAllComps(ern_ex);  
    // this.hideAllComps(cmprt);    
  }
  showSystemInDeck(parent) {
    let meshes = parent.getChildMeshes();
    let boo = true;
    meshes.forEach((mesh) => {
      let name = this.getMeshName(mesh.name);
      let deck = this.getDeckCode(name);
      this.filter_deck.forEach(e => {
        boo = deck === e;
        if (mesh.vstate) {
          mesh.setEnabled(boo);
        }
      })

    })
  }
  showSystemsFDeck() {
    let ern = this.getChild(this.ernkhasystemship, 'ERNs');
    if (!ern) return;
    this.showSystemInDeck(ern);
    // let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    // let cmprt = this.getChild(this.khasystemship, 'Compartments');      
    // this.showSystemInDeck(ern_ex);  
    // this.showSystemInDeck(cmprt);       
  }
  khasystemship
  showERNObjectFor(id, boo = true) {
    let rules = this.mergeIfMultiRules(id);//this.khadisplayrules?this.khadisplayrules[id]:null;
    if (!rules) {
      return;
    }
    let hfx = this.ernkhasystemship;
    if (boo) {
      //this.hideAllComps(hfx);
      // this.moveCameraTo(null, rules.focus);
    }
    //this.showComps(hfx, rules.show)
    // this.hideComps(hfx,rules.hide)
    this.hideAllSubSystems();
    this.highlightComps(hfx, rules.highlight);

    if (this.filter_deck.length > 0 && this.selectedKha) {
      this.showSystemsFDeck();
    }

    /* if(id.length>1&&!this.hideWall){
      this.hideWall=true;
      this.broadcastInfo({ src: 'khaservice', event: 'showhull', data: true });
    } */
    /* if(this.hideWall){
      this.showHideHull()
    } */
  }
  showSystemShip(boo) {
    if (!this.datauxview) {
      return
    }
    let ernhfx = this.datauxview.getElementId("ernhfx");
    if (ernhfx) {
      let hfx = this.khasystemship || this.dfx.getElementMesh(ernhfx).getChildren()[1];;
      hfx.setEnabled(boo);
    }
  }
  enableComps(parent, boo) {
    parent.setEnabled(boo);
    parent.vstate = boo;
    parent.getChildMeshes().forEach((m) => {
      m.setEnabled(boo);
      m.vstate = boo;
    })
  }
  ship_struct_comps = {
    "Hull": {
      "Hull": [["_exterior_port"], ["_exterior_starboard"]],
      "Superstruct": [
        ["deck_1", "exterior_1"], ["deck_1", "exterior_1_tops"], ["deck_1", "exterior_1J"], ["deck_1", "exterior_1J_tops"]
        , ["deck_01", "exterior_01"], ["deck_01", "exterior_01_tops"], ["deck_01", "exterior_01J"], ["deck_01", "exterior_01J_tops"]
        , ["deck_02", "exterior_02E"]
        , ["funnel"], ["intakes"], ["RAS_trunk"]
      ]
    },
    "Sections": {
      "Hull": [["watertight_bulkheads"]]
    }
  }
  filter_deck = ['2'];
  struct_state_def = {
    "Hull": "hide",
    "Decks": "transparent",
    "Bulkheads": "transparent",
    "Sections": "transparent"
  }
  struct_state_deck = {
    "Hull": "hide",
    "Decks": "solid",
    "Bulkheads": "transparent",
    "Sections": "transparent"
  }
  struct_state_slms_1 = {
    "Hull": "solid",
    "Decks": "solid",
    "Bulkheads": "solid",
    "Sections": "solid"
  }
  otherDecks = "transparent";
  struct_state_current = this.clone(this.struct_state_def);
  applyDisplayRule_Struct(def = false, disp = null) {
    if (def) {
      this.struct_state_current = this.clone(this.struct_state_def);
    }
    let o = disp ? disp : this.struct_state_current;
    this.struct_state_current = o;
    for (let m in o) {
      this["applyState_" + m](o[m]);
    }
  }

  applyDisplayRuleFor_Struct(m) {
    let s = this.struct_state_current[m];
    if (s) {
      if (this.slms_mode && m === 'Decks') {
        let state = s === 'hide' ? false : true;
        this.applyState_Decks_slms(s, state);
        this.reapplyState_slms();
      } else {
        this["applyState_" + m](s);
        // this.applyDisplayRuleFor_OtherDecks();
      }
    }
  }
  applyDisplayRuleFor_Deck(filter_deck = false) {
    if (this.slms_mode) {
      // this.focusCompartment()
      this.applyState_Decks_slms(this.struct_state_slms_1["Decks"], filter_deck);
      this.reapplyState_slms();
      this.updateFloorColor();

      // this.applyDisplayRuleFor_OtherDecks();
      // if (this.filter_deck.length>0 && this.selectedKha) {
      //   this.showSystemsFDeck();
      // }
      return;
    }
    let rule = this.filter_deck.length > 0 ? this.struct_state_deck : this.struct_state_def;
    this.applyDisplayRule_Struct(false, this.struct_state_deck);
    //this.hideAllComps(this.khasystemship);
    //this.showERNObjectFor("");
    this.showAllSubSystems();
    this.showHideSubSystem("", true);
    if (this.filter_deck.length > 0) {
      this.showSystemsFDeck();
    }
  }
  applyDeckFilter(key, d, filter) {
    let f = filter;
    if (d !== null) {
      if (key === 'ERNs' || key === 'Compartments') {
        f = filter.includes(d) ? [d] : [];
      }
    }
    return f;
  }

  /* * *
   * system code starts
   * * */
  applyDisplayRule_ERNStruct(def = false, disp = null) {
    if (def) {
      this.struct_state_current = this.clone(this.struct_state_def);
    }
    let o = disp ? disp : this.struct_state_current;
    for (let m in o) {
      this["applyERNState_" + m](o[m]);
    }
  }

  applyERNState_Hull(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["_exterior_port", "_exterior_starboard"]
    hcomps.forEach((comp) => {
      let c = this.getChild(hull, comp);
      this.applyCompState(c, prf);
    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02", "funnel", "intakes", "RAS_trunk", "flight_deck", "forecastle", "weatherdeck_mid"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_') && comp !== "weatherdeck_mid") {
        let cs = this.getChild(ss, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("exterior") || name.includes("hangar_top") || name.includes("bridge_top") || name.includes("bridge_wings")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }
    })
  }
  applyERNState_Decks(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_')) {
        let cs = this.getChild(hull, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("deck_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(hull, comp);
        this.applyCompState(c, prf);
      }
    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_')) {
        let cs = this.getChild(ss, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("deck_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }
    })
  }
  applyERNState_Bulkheads(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_')) {
        let cs = this.getChild(hull, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("bulkheads_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(hull, comp);
        this.applyCompState(c, prf);
      }
    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_')) {
        let cs = this.getChild(ss, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("bulkheads_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }
    })
  }
  applyERNState_Sections(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let c = this.getChild(hull, 'watertight_bulkheads');
    this.applyCompState(c, prf);
  }

  /* * *
  * system code end
  * * */
  // applyState_Hull(state, name, dataList) {
  //   // let prf = state === 'solid' ? 'regular' : state;
  //   let decks = this.khasystemship;
  //   let hcomps = ["exterior", "interior", "funnel", "intakes", "RAS_trunk", "flight_deck", "forecastle", "weatherdeck"];
  //   // this.filter_deck.forEach((e)=>{
  //   //   let filter = 'deck_' + (e|| "");
  //   if (decks) {
  //     decks.getChildren((pmesh) => {
  //       let comp = this.getMeshName(pmesh.name);
  //       let filterdeck = comp.split('_')[1];
  //       let prf;
  //       if(name == ''){
  //         if (this.filter_deck.includes(filterdeck)) {
  //           dataList.forEach(element => {
  //             if(element.name == filterdeck){
  //               prf = element.hull === 'solid' ? 'regular' : element.hull;
  //             }
  //           })
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             const found = hcomps.some(el => name.includes(el));
  //             if (found) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //         // } else {
  //         //   pmesh.getChildren().forEach((c) => {
  //         //     let name = this.getMeshName(c.name);
  //         //     const found = hcomps.some(el => name.includes(el));
  //         //     if (found) {
  //         //       let prf1=prf=="regular"?'transparent':prf;
  //         //       this.applyCompState(c, prf1);
  //         //     }
  //         //   })
  //         // }
  //       } else {
  //         if (name == filterdeck) {
  //           prf = state === 'solid' ? 'regular' : state;
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             const found = hcomps.some(el => name.includes(el));
  //             if (found) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //       }

  //     })
  //   }
  //   // })


  // }

  // applyState_Decks(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let decks = this.khasystemship;
  //   // this.filter_deck.forEach((e)=>{
  //   // let filter = 'deck_' + (e || "");
  //   if (decks) {
  //     decks.getChildren((pmesh) => {
  //       let comp = this.getMeshName(pmesh.name);
  //       let filterdeck = comp.split('_')[1];
  //       if (this.filter_deck.includes(filterdeck)) {
  //         pmesh.getChildren().forEach((c) => {
  //           let name = this.getMeshName(c.name);
  //           if (name.includes("deck_")) {
  //             this.applyCompState(c, prf);
  //           }
  //         })
  //       } else {
  //         pmesh.getChildren().forEach((c) => {
  //           let name = this.getMeshName(c.name);
  //           if (name.includes("deck_")) {
  //             let prf1=prf=="regular"?'transparent':prf;
  //             this.applyCompState(c, prf1);
  //           }
  //         })
  //       }
  //     })
  //   }
  //   // });
  // }

  // applyState_Bulkheads(state, name, dataList) {
  //   // let prf = state === 'solid' ? 'regular' : state;
  //   let decks = this.khasystemship;
  //   // this.filter_deck.forEach((e)=>{
  //   //   let filter = 'deck_' + (e || "");
  //   if (decks) {
  //     decks.getChildren((pmesh) => {
  //       let comp = this.getMeshName(pmesh.name);
  //       let filterdeck = comp.split('_')[1];
  //       let prf;
  //       if(name == ''){
  //         if (this.filter_deck.includes(filterdeck)) {
  //           dataList.forEach(element => {
  //             if(element.name == filterdeck){
  //               prf = element.bulkheads === 'solid' ? 'regular' : element.bulkheads;
  //             }
  //           })
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             if (name.includes("bulkheads_")) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //         // } else {
  //         //   pmesh.getChildren().forEach((c) => {
  //         //     let name = this.getMeshName(c.name);
  //         //     const found = hcomps.some(el => name.includes(el));
  //         //     if (found) {
  //         //       let prf1=prf=="regular"?'transparent':prf;
  //         //       this.applyCompState(c, prf1);
  //         //     }
  //         //   })
  //         // }
  //       } else {
  //         if (name == filterdeck) {
  //           prf = state === 'solid' ? 'regular' : state;
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             if (name.includes("bulkheads_")) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //       }

  //     })
  //   }
  //   // });
  // }

  // applyState_Sections(state, name, dataList) {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let decks = this.khasystemship;
  //   // this.filter_deck.forEach((e)=>{
  //   //   let filter = 'deck_' + (e || "");
  //   if (decks) {
  //     decks.getChildren((pmesh) => {
  //       let comp = this.getMeshName(pmesh.name);
  //       let filterdeck = comp.split('_')[1];
  //       let prf;
  //       if(name == ''){
  //         if (this.filter_deck.includes(filterdeck)) {
  //           dataList.forEach(element => {
  //             if(element.name == filterdeck){
  //               prf = element.sections === 'solid' ? 'regular' : element.sections;
  //             }
  //           })
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             if (name.includes("section_")) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //         // } else {
  //         //   pmesh.getChildren().forEach((c) => {
  //         //     let name = this.getMeshName(c.name);
  //         //     const found = hcomps.some(el => name.includes(el));
  //         //     if (found) {
  //         //       let prf1=prf=="regular"?'transparent':prf;
  //         //       this.applyCompState(c, prf1);
  //         //     }
  //         //   })
  //         // }
  //       } else {
  //         if (name == filterdeck) {
  //           prf = state === 'solid' ? 'regular' : state;
  //           pmesh.getChildren().forEach((c) => {
  //             let name = this.getMeshName(c.name);
  //             if (name.includes("section_")) {
  //               this.applyCompState(c, prf);
  //             }
  //           })
  //         }
  //       }

  //     })
  //   }
  //   // })
  // }
  applyState_Hull(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let decks = this.khasystemship;
    let hcomps = ["exterior", "interior", "funnel", "intakes", "RAS_trunk", "flight_deck", "forecastle", "weatherdeck"];
    // this.filter_deck.forEach((e)=>{
    //   let filter = 'deck_' + (e|| "");
    if (decks) {
      decks.getChildren((pmesh) => {
        let comp = this.getMeshName(pmesh.name);
        let filterdeck = comp.split('_')[1];
        if (this.filter_deck.includes(filterdeck)) {
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            const found = hcomps.some(el => name.includes(el));
            if (found) {
              this.applyCompState(c, prf);
            }
          })
        } else {
          if (this.filter_deck.includes('6')) {
            if (filterdeck == '5') {
              pmesh.getChildren().forEach((c) => {
                let name = this.getMeshName(c.name);
                if (name.includes("section_")) {
                  this.applyCompState(c, prf);
                }
              })
              return
            }
          }
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            const found = hcomps.some(el => name.includes(el));
            if (found) {
              let prf1 = prf == "regular" ? 'transparent' : prf;
              this.applyCompState(c, prf1);
            }
          })
        }
      })
    }
    // })


  }

  applyState_Decks(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let decks = this.khasystemship;
    // this.filter_deck.forEach((e)=>{
    // let filter = 'deck_' + (e || "");
    if (decks) {
      decks.getChildren((pmesh) => {
        let comp = this.getMeshName(pmesh.name);
        let filterdeck = comp.split('_')[1];
        if (this.filter_deck.includes(filterdeck)) {
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("deck_")) {
              this.applyCompState(c, prf);
            }
          })
        } else {
          if (this.filter_deck.includes('6')) {
            if (filterdeck == '5') {
              pmesh.getChildren().forEach((c) => {
                let name = this.getMeshName(c.name);
                if (name.includes("section_")) {
                  this.applyCompState(c, prf);
                }
              })
              return
            }
          }
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("deck_")) {
              let prf1 = prf == "regular" ? 'transparent' : prf;
              this.applyCompState(c, prf1);
            }
          })
        }
      })
    }
    // });
  }

  applyState_Bulkheads(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let decks = this.khasystemship;
    // this.filter_deck.forEach((e)=>{
    //   let filter = 'deck_' + (e || "");
    if (decks) {
      decks.getChildren((pmesh) => {
        let comp = this.getMeshName(pmesh.name);
        let filterdeck = comp.split('_')[1];
        if (this.filter_deck.includes(filterdeck)) {
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("bulkheads_")) {
              this.applyCompState(c, prf);
            }
          })
        } else {
          if (this.filter_deck.includes('6')) {
            if (filterdeck == '5') {
              pmesh.getChildren().forEach((c) => {
                let name = this.getMeshName(c.name);
                if (name.includes("section_")) {
                  this.applyCompState(c, prf);
                }
              })
              return
            }
          }
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("bulkheads_")) {
              let prf1 = prf == "regular" ? 'transparent' : prf;
              this.applyCompState(c, prf1);
            }
          })
        }
      })
    }
    // });
  }

  applyState_Sections(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let decks = this.khasystemship;
    // this.filter_deck.forEach((e)=>{
    //   let filter = 'deck_' + (e || "");
    if (decks) {
      decks.getChildren((pmesh) => {
        let comp = this.getMeshName(pmesh.name);
        let filterdeck = comp.split('_')[1];
        if (this.filter_deck.includes(filterdeck)) {
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("section_")) {
              this.applyCompState(c, prf);
            }
          })
        } else {
          if (this.filter_deck.includes('6')) {
            if (filterdeck == '5') {
              pmesh.getChildren().forEach((c) => {
                let name = this.getMeshName(c.name);
                if (name.includes("section_")) {
                  this.applyCompState(c, prf);
                }
              })
              return
            }
          }
          pmesh.getChildren().forEach((c) => {
            let name = this.getMeshName(c.name);
            if (name.includes("section_")) {
              let prf1 = prf == "regular" ? 'transparent' : prf;
              this.applyCompState(c, prf1);
            }
          })
        }
      })
    }
    // })
  }
  reapplyState_slms() {
    let ss = this.struct_state_current['Sections'];
    let bh = this.struct_state_current['Bulkheads'];
    let hull = this.struct_state_current['Hull'];
    // if (this.deckHideList.length > 0) {
    this.applyState_Sections(ss);
    this.applyState_Bulkheads(bh);
    this.applyState_Hull(hull);
    // // this.applyState_Sections(ss, '',this.matrixDataList);
    // this.applyState_Bulkheads(bh, '',this.matrixDataList);
    // this.applyState_Hull(hull, '', this.matrixDataList);
    // }
    // if(this.groupList.length>0){
    //   this.applyState_group();
    // }

  }
  focusCompartment(data) {
    // let prf = state === 'solid' ? 'regular' : state;
    // console.log("focusCompartment",this.datauxview.getElementId('element-ehfx.model-mhfx.HFX.model-mhfx.HFX.deck_2.model-mhfx.HFX.deck_2.2JA1'))
    this.filter_deck.forEach((e) => {
      let filter = 'deck_' + (e || "");
      let decks = this.khasystemship;
      if (decks) {
        decks.getChildren((pmesh) => {
          // if (filter_deck) {
          //   this.applyCompState(pmesh, prf);
          // }
          // console.log("decks",pmesh.name)
          let comp = this.getMeshName(pmesh.name);
          if (comp.includes(filter)) {

            let cs = pmesh;
            if (!cs) {
              return false;
            }
            cs.getChildren().forEach((c) => {
              // console.log("cs",c.name)
              if (c.name.includes(data.code)) {

                let dfx = this.datauxview.getDatascape();
                let def = { "target": c.getBoundingInfo().boundingSphere.centerWorld, "distance": this.distance };
                console.log(c, def);
                dfx.moveCamera(def);
              }
            })
            // let dfx = this.datauxview.getDatascape();
            // let mesh = dfx.getElementMesh(elem); 
            // let def = { "target": this.getAverageCenter(mesh), "distance": this.distance };
            // dfx.moveCamera(def);
          }
        })
      }
    });
  }

  deckHideMap() {
    if (this.filter_deck.length > 0) {
      let idx = this.filter_deck.findIndex(e => e == '02');
      if (idx > -1) {
        return [];
      } else {
        idx = this.filter_deck.findIndex(e => e == '01');
        if (idx > -1) {
          return ["deck_02"];
        } else {
          idx = this.filter_deck.findIndex(e => e == '1');
          if (idx > -1) {
            return ["deck_02", "deck_01"];
          } else {
            idx = this.filter_deck.findIndex(e => e == 'M');
            if (idx > -1) {
              return ["deck_02", "deck_01", "deck_1"];
            } else {
              idx = this.filter_deck.findIndex(e => e == '2');
              if (idx > -1) {
                return ["deck_02", "deck_01", "deck_1", "deck_M"];
              } else {
                idx = this.filter_deck.findIndex(e => e == '3');
                if (idx > -1) {
                  return ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2"];
                } else {
                  idx = this.filter_deck.findIndex(e => e == '4');
                  if (idx > -1) {
                    return ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3"];
                  } else {
                    idx = this.filter_deck.findIndex(e => e == '5');
                    if (idx > -1) {
                      return ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3", "deck_4"];
                    } else {
                      idx = this.filter_deck.findIndex(e => e == '6');
                      if (idx > -1) {
                        return ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3", "deck_4"];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      return [];
    }

  }
  deckHideList: any = [];
  applyState_Decks_slms(state = 'solid', filter_deck = false) {
    this.deckHideList = [];
    let prf = state === 'solid' ? 'regular' : state;
    let decks = this.khasystemship;
    this.deckHideList = this.deckHideMap();
    if (decks) {
      decks.getChildren((pmesh) => {
        if (filter_deck) {
          this.applyCompState(pmesh, prf);
        }
        let comp = this.getMeshName(pmesh.name);
        // this.filter_deck.forEach(e=>{
        // let filter = 'deck_' + (e || "");
        let filderdeck = comp.split('_')[1];
        if (this.filter_deck.includes(filderdeck)) {
          console.log(filderdeck, comp);
          let cs = pmesh;
          if (!cs) {
            return false;
          }
          cs.getChildren().forEach((c) => {
            this.applyCompState(c, prf);
          })
        } else {
          if (this.filter_deck.includes('6')) {
            if (filderdeck == '5') {
              console.log(filderdeck, comp);
              let cs = pmesh;
              if (!cs) {
                return false;
              }
              cs.getChildren().forEach((c) => {
                this.applyCompState(c, prf);
              });
              return;
            }
          }
          console.log(filderdeck, comp, this.otherDecks);
          // let idx = deckHideList.findIndex(e=> e==comp);
          let c = pmesh;
          // if(idx>-1){
          if (this.deckHideList != undefined) {
            if (this.deckHideList.length > 0) {
              if (this.deckHideList.includes(comp)) {
                this.applyCompState(c, filderdeck ? 'hide' : prf);
              } else {
                let prf1 = this.otherDecks == 'hide' ? 'hide' : this.otherDecks == 'solid' ? 'regular' : 'transparent';
                this.applyCompState(c, prf1);
              }
            } else {
              let filderdeck = comp.split('_')[1];
              if (this.filter_deck.includes(filderdeck)) {
              } else {
                let prf1 = this.otherDecks == 'hide' ? 'hide' : this.otherDecks == 'solid' ? 'regular' : 'transparent';
                this.applyCompState(c, prf1);
              }
            }
          }
          // }

          // if (filderdeck  === '02') {
          //   let comps = ["deck_02"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '01') {
          //   let comps = ["deck_02"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '1') {
          //   let comps = ["deck_02", "deck_01"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === 'M') {
          //   let comps = ["deck_02", "deck_01", "deck_1"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '2') {
          //   let comps = ["deck_02", "deck_01", "deck_1", "deck_M"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '3') {
          //   let comps = ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '4') {
          //   let comps = ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else if (filderdeck === '5') {
          //   let comps = ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3", "deck_4"];
          //   if (comps.includes(comp)) {
          //     this.applyCompState(c, filderdeck ? 'hide' : prf);
          //   } else {
          //     this.applyCompState(c, 'transparent');
          //   }
          // }
          // else {
          //   this.applyCompState(c, filderdeck ? 'hide' : prf);
          // }
        }
        // })

        // decks.getChildren((pmesh) => {
        //   let comp = this.getMeshName(pmesh.name);
        //   if (deckHideList.includes(comp)) {
        //     this.applyCompState(c, filderdeck ? 'hide' : prf);
        //   }else{
        //     this.applyCompState(c, 'transparent');
        //   }
        // })

      });
      if (this.appMode == 'kys-quiz-mode') {
        this.showHideDeckLabels(false);
      }

    }
  }
  applyLayermask_comp(mesh, mask) {
    let meshes = mesh.getChildMeshes()
    meshes.forEach((m) => {
      m.layerMask = mask;
    })
  }
  applyLayermask_Decks(filter_deck, mask = null) {
    let decks = this.khasystemship;
    let filter = 'deck_' + (filter_deck || "");
    let m1 = 0x10000000;
    let m2 = 0x20000000;
    if (mask) {
      m1 = m2 = mask;
    }
    if (decks) {
      decks.getChildren((pmesh) => {
        let comp = this.getMeshName(pmesh.name);
        let mask = m2;
        // let filderdeck = comp.split('_')[1];
        if (comp === filter) {
          mask = m1
        }
        pmesh.layerMask = mask
        this.applyLayermask_comp(pmesh, mask);
      })
    }
  }

  applyDisplayRuleFor_OtherDecks() {
    let prf = this.otherDecks === 'solid' ? 'regular' : this.otherDecks;
    let decks = this.khasystemship;
    this.filter_deck.forEach(e => {
      // let filter = 'deck_' + (e || "");
      // let comps = ["deck_02", "deck_01", "deck_1", "deck_M", "deck_2", "deck_3", "deck_4", "deck_5"];
      // let otherDecks = [];

      // let idx = comps.findIndex(el => el === filter);
      // if (idx > -1) {
      //   otherDecks = comps.filter((c, i) => idx < i);
      // }
      if (decks) {
        decks.getChildren((pmesh) => {
          let comp = this.getMeshName(pmesh.name);
          let filterdeck = comp.split('_')[1];
          if (!(this.filter_deck.includes(filterdeck))) {
            if (this.deckHideList && this.deckHideList.length > 0) {
              if (!(this.deckHideList.includes(comp))) {
                this.applyCompState(pmesh, prf);
              }
            } else {
              this.applyCompState(pmesh, prf);
            }
          }
        })
      }
    })
  }

  /* * *
  * old code
  * */
  // applyState_Hull(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let hull = this.getChild(this.khasystemship, 'Hull');
  //   let hcomps = ["_exterior_port", "_exterior_starboard"]
  //   hcomps.forEach((comp) => {
  //     let c = this.getChild(hull, comp);
  //     this.applyCompState(c, prf);
  //   })
  //   let ss = this.getChild(this.khasystemship, 'Superstruct');
  //   // hcomps = ["deck_1", "deck_01", "deck_02", "funnel", "intakes", "RAS_trunk", "flight_deck", "forecastle", "weatherdeck_mid"]
  //   hcomps = [ "deck_1", "deck_01", "deck_02","funnel", "intakes", "RAS_trunk"]
  //   hcomps.forEach((comp) => {
  //     if (comp.includes('deck_') && comp !== "weatherdeck_mid") {
  //       let cs = this.getChild(ss, comp);
  //       cs.getChildren().forEach((c) => {
  //         let name = this.getMeshName(c.name);
  //         if (name.includes("exterior")/* || name.includes("hangar_top") || name.includes("bridge_top") || name.includes("bridge_wings")*/) {
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(ss, comp);
  //       this.applyCompState(c, prf);
  //     }
  //   })
  // }

  // applyState_Decks(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let hull = this.getChild(this.khasystemship, 'Hull');
  //   let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
  //   let filter='deck_';
  //   hcomps.forEach((comp) => {
  //     let c = this.getChild(hull, comp);
  //     this.applyCompState(c, prf);
  //     if (comp.includes(filter)) {
  //       let cs = this.getChild(hull, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         let name = this.getMeshName(c.name);
  //         if (name.includes("deck_")) {
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(hull, comp);
  //       this.applyCompState(c, prf);
  //     }

  //   })
  //   let ss = this.getChild(this.khasystemship, 'Superstruct');
  //   hcomps = ["deck_1", "deck_01", "deck_02"]

  //   hcomps.forEach((comp) => {
  //     if (comp.includes(filter)) {
  //       let cs = this.getChild(ss, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         let name = this.getMeshName(c.name);
  //         if (name.includes("deck_")) {
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(ss, comp);
  //       this.applyCompState(c, prf);
  //     }

  //   })
  // }
  // applyState_Bulkheads(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let hull = this.getChild(this.khasystemship, 'Hull');
  //   let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
  //   let filter='deck_';
  //   hcomps.forEach((comp) => {
  //     if (comp.includes(filter)) {
  //       let cs = this.getChild(hull, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         let name = this.getMeshName(c.name);
  //         if (name.includes("bulkheads_")) {
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(hull, comp);
  //       this.applyCompState(c, prf);
  //     }

  //   })
  //   let ss = this.getChild(this.khasystemship, 'Superstruct');
  //   hcomps = ["deck_1", "deck_01", "deck_02"]
  //   if(this.filter_deck&&this.slms_mode){
  //     hcomps=['deck_'+this.filter_deck];
  //   }
  //   hcomps.forEach((comp) => {
  //     if (comp.includes('deck_')) {
  //       let cs = this.getChild(ss, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         let name = this.getMeshName(c.name);
  //         if (name.includes("bulkheads_")) {
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(ss, comp);
  //       this.applyCompState(c, prf);
  //     }

  //   })
  // }
  // applyState_Sections(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let hull = this.getChild(this.khasystemship, 'Hull');
  //   let c = this.getChild(hull, 'watertight_bulkheads');
  //   this.applyCompState(c, prf);
  // }  

  // applyState_Decks_slms(state = 'solid') {
  //   let prf = state === 'solid' ? 'regular' : state;
  //   let hull = this.getChild(this.khasystemship, 'Hull');
  //   let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
  //   let filter='deck_'+(this.filter_deck||"");
  //   hcomps.forEach((comp) => {
  //     let c = this.getChild(hull, comp);
  //     this.applyCompState(c, prf);
  //     if (comp.includes(filter)) {
  //       let cs = this.getChild(hull, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         // let name = this.getMeshName(c.name);
  //         // if (name.includes("deck_")) {
  //         //   this.applyCompState(c, prf);
  //         // }
  //         this.applyCompState(c, prf);
  //       })
  //     } else {
  //       let c = this.getChild(hull, comp);
  //       this.applyCompState(c, this.filter_deck?'hide':prf);
  //     }

  //   })
  //   let ss = this.getChild(this.khasystemship, 'Superstruct');
  //   hcomps = ["deck_1", "deck_01", "deck_02"]

  //   hcomps.forEach((comp) => {
  //     let c = this.getChild(ss, comp);
  //     this.applyCompState(c, prf);
  //     if (comp.includes(filter)) {
  //       let cs = this.getChild(ss, comp);
  //       if(!cs){
  //         return false;
  //       }
  //       cs.getChildren().forEach((c) => {
  //         // let name = this.getMeshName(c.name);
  //         // if (name.includes("deck_")) {
  //         if (!c.name.includes("exterior")){
  //           this.applyCompState(c, prf);
  //         }
  //       })
  //     } else {
  //       let c = this.getChild(ss, comp);
  //       this.applyCompState(c, this.filter_deck?'hide':prf);
  //     }
  //   })
  // } 

  /**
   *  old code end  
   */

  // showHideERNCmpt(boo) {
  //   let prf = boo ? 'transparent' : 'regular';
  //   let hull = this.getChild(this.khasystemship, 'ERNs_exterior');
  //   //hull.setEnabled(!this.hideWall);
  //   this.applyMaterial(null, prf, null, hull, !this.hideWall);
  //   let ss = this.getChild(this.khasystemship, 'Compartments');
  //   //ss.setEnabled(!this.hideWall);
  //   this.applyMaterial(null, prf, null, ss, !this.hideWall);
  // }

  applyCompState(c, prf) {
    // if(c!=null){
    if (prf === 'hide') {
      c.setEnabled(false);
    } else {
      if (!c.sliced) {
        c.setEnabled(true);
      }
      this.applyMaterial(null, prf, null, c/* , prf === 'regular' */);
    }
    // }
  }

  /**
   * V3 related code - end
   */
  /**
   *
   * change background color
   */
  // bg_state = {
  //   "black": { r: 0, g: 0, b: 0 },
  //   "darkblue": { r: 0.08, g: 0.08, b: 0.16 },
  //   "grey": { r: 0.35, g: 0.35, b: 0.35 }
  // }
  // changeBackgroundColor(s) {
  //   let col = this.bg_state[s];
  //   if (col) {
  //     for (let m in col) {
  //       this.scene.clearColor[m] = col[m];
  //     }
  //   }
  // }
  // resetBackgroundColor() {
  //   this.changeBackgroundColor('black');
  // }

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  // applyDisplayRuleFor_Section(name) {
  //   // console.log("systems_store",this.compartments_store,name,this.compartments_list );
  //   //   objs.forEach((obj) => {
  //   //     let el = this.datauxview.getElementId(obj.name);
  //   //   let m = this.dfx.getElementMesh(obj).getChildren()[0];
  //   //   let meshes = m.getChildren();
  //   //   meshes.forEach((mesh) => {
  //   //     let d = this.getDeckCode(mesh.name);
  //   //   }
  //   // }
  //   this.compartments_list.forEach(element => {
  //     // console.log(element);
  //     if (element.code.toLowerCase().includes(name.toLowerCase())) {
  //       // console.log();
  //       if (element.code[1].toLowerCase() == name.toLowerCase()) {
  //         //console.log(this.getSectionCode('1ADAT'));
  //       }
  //     }
  //   });
  //   // console.log(this.getSectionCode(name))
  // }
  // getSectionCode(c) {
  //   if (!c) {
  //     return null;
  //   }
  //   let d = c.substr(0, 1);
  //   d = d === '0' ? c.substr(2, 1) : c.substr(1, 1);
  //   return d;
  // }
  /**
   * cutoff-effect
   */
  cutoutServ: CutOffEffect
  cutouts = [];
  initCutOff() {
    this.cutoutServ = new CutOffEffect();
  }
  applyCutoutAt(key, pos, hull, d = null, fid = null) {
    if (!hull) {
      return
    }
    let name = this.getMeshName(hull.name);
    /* if(!name.includes('exterior')){
     return
    } */
    this.cutouts.push(hull);
    let ihull = this.getChildContains(hull.parent, 'interior');
    if (ihull) {
      ihull.isVisible = false;
    }
    ihull = this.getChildContains(hull.parent, 'inside');
    if (ihull) {
      ihull.isVisible = false;
    }
    if (fid !== null) {
      this.inorm = hull.getFacetNormal(fid);
      this.draw_plane = this.getNormPlane(this.inorm)
    }
    if (!d) {
      d = this.getCutDim(true);
    }
    /* if(this.cutoutServ.updateMode&&this.cutoutServ.spots.length){
      this.cutoutServ.updateCutPos(pos.clone(),null,d);
    }else{
     this.cutoutServ.cutTarget(pos.clone(),hull,this.scene,this.draw_plane,d,this.inorm)
    } */
    let slicer = this.cselects[key][5]
    this.cutoutServ.cutTargetAt(hull, pos, this.scene, d, slicer || null);
  }
  clearCutOuts() {
    /* let lights=this.scene.lights
    lights.forEach((light)=>{
      if(light.getClassName()=='SpotLight'){
        light.dispose();//setEnabled(false);
      }
    }) */
    //
    this.cutouts.forEach((wall) => {
      this.resetWallMats(wall);
      //  this.removeCutOut()
    })
    //this.cutoutServ.spots=[]    
  }
  resetWallMats(wall) {
    //this.cutoutServ.resetMat(this.scene,wall);
    let ihull = this.getChildContains(wall.parent, 'interior');
    if (ihull) {
      ihull.isVisible = true;
    }
    ihull = this.getChildContains(wall.parent, 'inside');
    if (ihull) {
      ihull.isVisible = true;
    }
    let csg = this.cutoutServ.csgArr[wall.name];
    if (csg) {
      wall.sliced = null;
      wall.setEnabled(true);
      csg.dispose();
      this.cutoutServ.csgArr[wall.name] = undefined;
      delete this.cutoutServ.csgArr[wall.name];

    }
  }
  cutFromPath(pid = null, onlydraw = false) {
    if (!this.last_cselect) {
      if (!pid) {
        return
      }
    }
    if (!this.draw_wall) {
      return
    }
    let p
    let key = this.last_cselect
    if (pid) {
      p = this.cselects[pid][1];
      key = pid;
    } else {
      p = this.cselects[this.last_cselect][1];
    }
    if (p) {
      let pos = p.getBoundingInfo().boundingSphere.centerWorld;
      let d = this.getCutDim(false, pid, onlydraw);
      //this.cutoutServ.cutoff_angle=1; 
      this.cselects[key][2] = true;
      this.applyCutoutAt(key, pos, this.draw_wall, d);

      let markers = this.layoutData['markers'] || [];
      if (markers.length > 0) {
        const found = markers.some(el => el.uid == pid && el.cutoff == this.cselects[key][2]);
        this.isDrawState = !found;
      }
    }
  }
  addShapeFromPath(pid = null, onlydraw = false) {
    if (!this.last_cselect) {
      if (!pid) {
        return
      }
    }
    if (!this.draw_wall) {
      return
    }
    let p
    let key = this.last_cselect
    if (pid) {
      p = this.cselects[pid][1];
      key = pid;
    } else {
      p = this.cselects[this.last_cselect][1];
    }
    if (p) {
      let pos = p.getBoundingInfo().boundingSphere.centerWorld;
      let d = this.getCutDim(false, pid, onlydraw);
      let slicer = this.cutoutServ.createSlicer(pos, d, this.scene, key + "_slicer");
      this.cselects[pid][5] = slicer;
    }
  }
  removeCutOut(pid) {
    let select = this.cselects[pid]
    if (select) {
      if (!select[2]) {
        this.removeSection(pid);
        return;
      }
      select[2] = false;
    }
    this.removeSection(pid);
    this.clearCutOuts();
    let drawState = this.isDrawState;
    for (const [key, value] of Object.entries(this.cselects)) {
      let render = value[2];
      if (render) {
        this.draw_wall = this.ctrlServ.getChildMeshEx('ehfx', value[4]);
        if (!this.draw_wall) {
          value[4] = value[4].split("_sliced")[0]
          this.draw_wall = this.ctrlServ.getChildMeshEx('ehfx', value[4]);
        }
        this.draw_plane = value[3]
        this.cutFromPath(key);
      }

      let markers = this.layoutData['markers'] || [];
      if (markers.length > 0) {
        const found = markers.some(el => el.uid == pid && el.cutoff == this.cselects[key][2]);
        if (!drawState) {
          drawState = !found;
        }
      }
    }
    this.isDrawState = drawState;
  }
  /**
   * draw selection
   */
  cselects = {};
  cselects_length = 0;
  cselect = null
  draw_select_mode = null;
  draw_select_type = 'rect';
  draw_select_fill = false;
  last_cselect = null
  draw_wall = null;
  draw_plane = 'z';
  ipick = null;
  inorm = null;
  selectedMarker = null;
  marker_select_mode = false;
  /**
   * 
   * method to generate UID
   * TODO - repeated use, put it under utility service
   */
  getUID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
  }
  marker_width = 1;
  circle_center;
  current_fill;
  marker_history = [];
  current_marker = [];
  drawSection(pos, opts = null, assignUID = true, color = null) {
    let myPath = [pos, pos];
    let uid = 'marker_' + this.getUID();
    let hascut = false;
    let cutplane = this.draw_plane;
    let r = this.marker_width / 40;
    let fill = this.draw_select_fill;
    let inorm = this.inorm;
    let _position = [pos];
    let isEdit = true
    if (opts) {
      isEdit = false
      myPath = this.objToVect(opts.path);
      _position = myPath;
      uid = opts.uid
      hascut = opts.cutoff;
      cutplane = opts.cutplane;
      r = opts.mwidth;
      fill = opts.fill;
      inorm = opts.inorm
    }

    let options = {
      path: myPath, //vec3 array,
      updatable: false,
      radius: r
    }
    console.log("CreateTube", uid, options, this.scene);

    let tube = BABYLON.MeshBuilder.CreateTube(uid, options, this.scene);
    //this.cselects_length++
    let col = color || this.markerColor || "#ff0000";
    tube.material = this.getMarkerMaterial(col);
    if (assignUID) {
      this.cselect = uid;
    }
    this.last_cselect = this.selectedMarker = uid;
    if (!this.draw_wall) {
      return
    }
    this.circle_center = pos;
    this.cselects[uid] = [_position, tube, hascut, cutplane, this.getMeshName(this.draw_wall.name), null, null, col, r, fill, { x: inorm.x, y: inorm.y, z: inorm.z }, isEdit]
    tube.isPickable = false;
    this.marker_history.push(uid);
    if (Object.keys(this.layoutData).length > 0) {
      let idx = this.layoutData['markers'].findIndex(e => e['uid'] == uid);
      if (idx == -1) {
        this.current_marker.push(uid);
      }

    }
    console.log("drawSection", this.marker_history)
    if (opts) {
      //fill for shape from data
      let _pos = tube.getBoundingInfo().boundingSphere.centerWorld;
      let fillshape = this.cutoutServ.createFillShape(_pos, _position, cutplane, this.scene, uid);
      fillshape.isPickable = false;
      fillshape.material = tube.material;
      //this.current_fill=fillshape.name
      //this.filledMarker(this.cselect,fpath,tube.material);
      fillshape.parent = tube;
      let _d = inorm[opts.cutplane] || inorm['_' + opts.cutplane]
      fillshape.position[opts.cutplane] += -0.03 * Math.sign(_d);
      if (!fill) {
        fillshape.setEnabled(false);
      }
    }
    console.log("this.cselects[uid] ", this.cselects[uid]);
  }
  updateSection(pos) {
    let instance = this.cselects[this.cselect][1];
    let finstance = this.scene.getMeshByName(this.cselect + "_fill")
    let path = this.cselects[this.cselect][0];
    let r = this.cselects[this.cselect][8];
    let fpath = [];
    if (this.cut_mode) {
      let p0 = path[0]
      path = this.getRectCoords(p0, pos);
    } else {
      if (this.draw_select_type === 'circle') {
        let p0 = this.circle_center;
        path = this.getCircleData(p0, pos, this.draw_plane);
      }
      if (this.draw_select_type === 'rect') {
        let p0 = path[0]
        path = this.getRectCoords(p0, pos);
      }
      if (this.draw_select_type === 'free' || this.draw_select_type === 'polygon') {
        path.push(pos);
      }
    }
    fpath = path.slice();
    //console.log(path)
    instance.dispose()
    if (finstance) {
      finstance.dispose()
    }
    let options = {
      path: path, //vec3 array,
      updatable: false,
      radius: r
    }
    let tube = BABYLON.MeshBuilder.CreateTube(this.cselect, options, this.scene);

    this.cselects[this.cselect][1] = tube
    this.cselects[this.cselect][0] = path
    this.cselects[this.cselect][2] = false;
    let col = this.cselects[this.cselect][7]
    tube.material = this.getMarkerMaterial(col);
    tube.isPickable = false;
    let _pos = tube.getBoundingInfo().boundingSphere.centerWorld;
    let fill = this.cutoutServ.createFillShape(_pos, fpath, this.draw_plane, this.scene, this.cselect);
    //fill.isPickable=false;
    fill.material = tube.material;
    //this.filledMarker(this.cselect,fpath,tube.material);
    fill.parent = tube;
    fill.position[this.draw_plane] += -0.03 * Math.sign(this.inorm[this.draw_plane]);
    if (!this.draw_select_fill) {
      fill.setEnabled(false);
    }
    this.current_fill = fill.name;
    //console.log('Fill',fill.name);

    if (this.draw_select_type === 'circle') {
      tube.position[this.draw_plane] = -0.1 * Math.sign(this.inorm[this.draw_plane]);
      //console.log(tube.name,tube.position);
    }

  }
  filledMarker(id, fpath, mat) {
    let fill = BABYLON.MeshBuilder.CreateRibbon(id + "_fill", { pathArray: [fpath], closeArray: true, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, this.scene);
    fill.material = mat;
    fill.isPickable = false;
    fill.position[this.draw_plane] = -0.05 * Math.sign(this.inorm[this.draw_plane]);
    return fill;
  }
  toggleMarkerFill(pid, boo) {
    if (!pid) {
      return
    }
    let sect = this.cselects[pid];
    if (sect) {
      if (sect[2]) {
        return;
      }
      let tube = sect[1];
      let fill = tube.getChildren()[0];
      if (fill) {
        fill.setEnabled(boo);
        sect[9] = boo;
      }
      sect[11] = true;
    }
  }
  clearSections(cuts = false) {
    for (const [key, value] of Object.entries(this.cselects)) {
      this.removeCutOut(key);
      let del = !value[2]
      if (cuts) {
        del = value[2]
      }
      if (del) {
        let tube = value[1];
        tube.dispose();
        let slicer = value[5];
        if (slicer) {
          slicer.dispose();
        }
        this.cselects[key] = null
        delete this.cselects[key];
      }
    }
    //this.cselects={};

    this.last_cselect = null;
    this.selectedMarker = null;
    this.showMatObjectTag(null, false);
  }
  removeSection(pid) {
    let sect = this.cselects[pid];
    if (sect) {
      if (sect[2]) {
        this.removeCutOut(pid);
        return;
      }
      let tube = sect[1];
      tube.dispose();
      let slicer = sect[5];
      if (slicer) {
        slicer.dispose();
      }
      this.cselects[pid] = null;
      delete this.cselects[pid];
      this.showMatObjectTag(null, false);
    }
  }
  deleteMarker(pid) {
    let sect = this.cselects[pid];
    if (sect) {
      this.removeSection(pid)
      //this.removeCutOut(pid);
      this.updateMarkerHistory(pid);
    }
    this.selectedMarker = null;
    this.last_cselect = null;
    if (this.draw_select_type === 'polygon') {
      this.cselect = null;
    }
    let markers = this.layoutData['markers'] || [];
    if (markers.length > 0) {
      const found = markers.some(el => el.uid == pid);
      this.isDrawState = Object.keys(this.cselects).length === 0 ? true : found;
    } else {
      if (Object.keys(this.cselects).length === 0) this.isDrawState = false;
    }
  }
  updateMarkerHistory(pid) {
    let idx = this.marker_history.indexOf(pid);
    this.marker_history.splice(idx, 1);
  }
  undoMarker() {
    console.log("undoMarker", this.layoutData)
    if (this.marker_history.length) {
      let pid = this.marker_history[this.marker_history.length - 1];
      if (this.layoutData && this.layoutData['markers'] && this.layoutData['markers'].length > 0) {
        let idx = this.layoutData['markers'].findIndex(e => e['uid'] == pid);
        if (idx == -1) {
          if (this.draw_select_mode) {
            this.deleteMarker(pid);
          } else if (this.cut_mode) {
            this.removeCutOut(pid);
          }
        }
      } else {
        if (this.draw_select_mode) {
          this.deleteMarker(pid);
        } else if (this.cut_mode) {
          this.removeCutOut(pid);
        }
      }


    }
  }
  roundOffMarker(pid = null) {
    let mid = pid || this.selectedMarker;
    let p = [];
    if (mid) {
      p = (this.cselects[mid][0] || []).slice();
      let l = p.length;
      if (l >= 3) {
        let ctrl = p.slice(l - 3)
        let bz = BABYLON.Curve3.CreateQuadraticBezier(ctrl[0], ctrl[1], ctrl[2], 10);
        let pbz = bz.getPoints();
        if (pbz.length) {
          p.splice(l - 2, 2, ...pbz)
          this.cselects[mid][0] = p;
          this.updateSection(ctrl[2]);
        }
      }
    }
    //return p;
  }
  markerColor = "#ff0000"
  xapplyMarkerColor(pid, color = '#ff0000') {
    let sect = this.cselects[pid];
    if (sect && sect[1]) {
      let col = this.getHexToRGB(color)
      sect[1].color = this.dfx.color3(col)
      sect[7] = col;
    }
  }
  applyMarkerColor(pid, color = '#ff0000') {
    let sect = this.cselects[pid];
    this.markerColor = color
    if (sect && sect[1]) {
      let mat = this.getMarkerMaterial(color)
      sect[1].material = mat;
      if (sect[1].getChildren()[0]) {
        sect[1].getChildren()[0].material = mat;
      }
      sect[7] = color;
    }
  }
  getMarkerMaterial(_color) {
    let matid = 'mark_mat_' + _color;
    let mat = this.scene.getMaterialByName(matid);
    if (mat) {
      return mat;
    }
    let color = this.getHexToRGB(_color);
    let cmat = new BABYLON.PBRCustomMaterial(matid, this.scene)
    cmat.metallic = 0;
    cmat.roughness = 0.4;
    cmat.specularColor = this.dfx.color3([0, 0, 0]);
    cmat.albedoColor = this.dfx.color3(color);
    return cmat;
  }
  getHexToRGB(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
  }
  getHitPosition(x = null, y = null, skipfill = false) {
    return this.scene.pick(x || this.scene.pointerX, y || this.scene.pointerY, (mesh) => {
      if (this.isMarker(mesh)) {
        if (skipfill && mesh.name.includes("_fill") && mesh.name != this.current_fill) {
          return true;
        }
        return false
      }
      return mesh.isEnabled() && mesh.isVisible && mesh.isPickable;
    });
  }
  doDraw() {
    let pos;
    let pick = this.getHitPosition(null, null, true);
    // console.log(pick)
    if (!pick.hit) {
      return false;
    }
    if (this.isMarker(pick.pickedMesh, true)) {
      return false;
    }
    let n = pick.pickedMesh.getFacetNormal(pick.faceId);
    this.draw_plane = this.getNormPlane(n);
    this.inorm = n;
    pos = pick.pickedPoint.clone();
    console.log("pointer Data", pos)
    if (this.cselect) {
      console.log("DO-DRAW update")
      this.ipick[1] = [this.scene.pointerX, this.scene.pointerY]
      //console.log("DO-DRAW update",pick.pickedMesh.name);       
      this.updateSection(pos);
    } else {

      console.log("DO-DRAW start")
      this.ipick = [[this.scene.pointerX, this.scene.pointerY]]
      this.draw_wall = pick.pickedMesh;
      let canv = this.datauxview.rendererCanvas.nativeElement;
      this.dfx.getCamera().detachControl(canv);
      this.drawSection(pos);
    }

    console.log('pos', pos)
    return true;
  }
  stopDraw() {
    let pos;
    let pick = this.getHitPosition(null, null, true);
    let _pos = null;
    console.log("DO-DRAW stop");
    if (this.draw_select_type === 'polygon' && pick.hit) {
      pos = pick.pickedPoint.clone();
      let d = this.circle_center.subtract(pos).length();
      if (d < 0.2 && d > 0) {
        _pos = this.circle_center;
      } else {
        //this.updateSection(pos);
        return true;
      }

    }

    this.isDrawState = true;
    let canv = this.datauxview.rendererCanvas.nativeElement;
    this.dfx.getCamera().attachControl(canv);

    if (!pick.hit || !this.ipick[1]) {
      this.current_fill = null;
      this.deleteMarker(this.cselect);
      this.cselect = null;
      return false;
    }
    pos = _pos || pick.pickedPoint.clone();
    if (this.cselect) {
      console.log("DO-DRAW update")
      this.updateSection(pos)
    }
    this.addShapeFromPath(this.cselect)
    this.cselect = null;
    if (this.cut_mode) {
      this.cutFromPath();
    }
    this.current_fill = null;
    return false
  }
  applyCutMarkersFromData(data) {
    data.forEach((obj) => {
      // console.log("obj", obj)
      this.draw_wall = this.ctrlServ.getChildMeshEx('ehfx', obj.pid);
      this.draw_plane = obj.cutplane;
      this.drawSection(null, obj, false, obj.color);
      this.addShapeFromPath(obj.uid, !obj.cutoff);
      if (obj.cutoff) {
        this.cutFromPath(obj.uid)
      }
    })
    return data;
  }
  getCutMarkersData() {
    let arr = this.cselects;
    let markers = []
    for (const [key, value] of Object.entries(this.cselects)) {

      // console.log("VectToObj", value, value[0])
      if (value[4] && value[4].includes("marker_")) {
        let pid = value[4].split('_');
        value[4] = this.cselects[pid[0] + '_' + pid[1]][4];
      }
      let obj = {
        "uid": key, "path": this.VectToObj(value[0]), "cutoff": value[2], "cutplane": value[3], "pid": value[4], "note": value[6] || "",
        "color": value[7], "mwidth": value[8], "fill": value[9], "inorm": value[10], 'isEdit': value[11]
      }
      if (obj.path.length > 1) {
        if (value[11]) {
          markers.push(obj);
        }
      }


    }
    return markers;
  }
  isMarker(mesh, excludefill = false) {
    let boo = false;
    if (mesh && mesh.name) {
      boo = (mesh.name.includes("marker_"))
      if (boo && excludefill) {
        boo = !(mesh.name.includes("_fill"))
      }
    }
    return boo;
  }

  getNormPlane(n) {
    let ort = 'z';
    if (Math.abs(n.x) > 0.5) {
      ort = 'x'
    }
    if (Math.abs(n.y) > 0.5) {
      ort = 'y'
    }
    return ort;
  }
  getRectCoords(v1, v2) {
    let p0 = v1;
    let p2 = v2;
    let pick1 = this.getHitPosition(this.ipick[1][0], this.ipick[0][1], true);// this.scene.pick(this.ipick[1][0], this.ipick[0][1])
    let pick3 = this.getHitPosition(this.ipick[0][0], this.ipick[1][1], true); // this.scene.pick(this.ipick[0][0], this.ipick[1][1])
    console.log('getRectCoords')
    console.log(pick1);
    console.log(pick3);
    let p1, p3;
    if (!pick1.hit) {
      p1 = this.dfx.vector3([p2.x, p0.y, p0.z])
    } else {
      if (this.draw_plane === 'x') {
        p1 = this.dfx.vector3([pick1.pickedPoint.x, p0.y, p2.z])
      }
      if (this.draw_plane === 'z') {
        p1 = this.dfx.vector3([p2.x, p0.y, pick1.pickedPoint.z])
      }
      if (this.draw_plane === 'y') {
        p1 = this.dfx.vector3([p2.x, pick1.pickedPoint.y, p0.z])
      }
      //p1=pick1.pickedPoint;
    }
    if (!pick3.hit) {
      p3 = this.dfx.vector3([p0.x, p2.y, p0.z])
    } else {
      //p3=pick3.pickedPoint;
      if (this.draw_plane === 'x') {
        p3 = this.dfx.vector3([pick3.pickedPoint.x, p2.y, p0.z])
      }
      if (this.draw_plane === 'z') {
        p3 = this.dfx.vector3([p0.x, p2.y, pick3.pickedPoint.z])
      }
      if (this.draw_plane === 'y') {
        p3 = this.dfx.vector3([p0.x, pick3.pickedPoint.y, p2.z])
      }
    }

    return [p0, p1, p2, p3, p0]
  }
  getCutDim(def = false, pid = null, onlydraw = false) {
    let scale = 1;//1/15.39;
    if (!this.last_cselect || def) {
      if (!pid) {
        return { width: scale, height: scale, depth: scale }
      }
    }
    let path = this.cselects[pid || this.last_cselect][0];
    let p0 = path[0];
    let p1 = path[2];
    if (this.draw_select_mode || onlydraw) {
      let mesh = this.cselects[pid || this.last_cselect][1];
      let bb = mesh.getBoundingInfo().boundingBox;
      p0 = bb.minimum;
      p1 = bb.maximum;
    }
    let dp = p1.subtract(p0);
    let w, h, d;
    w = 1 * scale; h = 1 * scale; d = 1 * scale;
    if (this.draw_plane === 'x') {
      d = Math.abs(dp.z) * scale;
      h = Math.abs(dp.y) * scale;
      w = 1 * scale;
    }
    if (this.draw_plane === 'z') {
      w = Math.abs(dp.x) * scale;
      h = Math.abs(dp.y) * scale;
      d = 1 * scale;
    }
    if (this.draw_plane === 'y') {
      w = Math.abs(dp.x) * scale;
      d = Math.abs(dp.z) * scale;
      h = 1 * scale;
    }
    return { width: w, height: h, depth: d }
  }
  objToVect(arr) {
    let nv = []
    arr.forEach((a, i) => {
      nv[i] = this.dfx.vector3(a);
    })
    return nv;
  }
  VectToObj(arr) {
    let nv = []
    if (arr[0] != null) {
      arr.forEach((a, i) => {
        nv[i] = { x: a.x, y: a.y, z: a.z };
      })
    }
    return nv;
  }
  hi_meshes = {}
  highlightSelection(_mesh, size = 6, color = null, clear = false, floorColor = false) {
    console.log("highlightSelection", _mesh, clear, this.hi_meshes, _mesh.name)

    if (clear) {
      if (this.hi_meshes[_mesh.name]) {
        // this.hideHighlightSelection();
      }

    }
    if (!_mesh) {
      return
    }
    if (!this.hi_meshes[_mesh.name]) {
      _mesh['defMat'] = _mesh.material;
      this.hi_meshes[_mesh.name] = _mesh;
    }
    // _mesh['defMat'] = _mesh.material;

    this.showOutline(_mesh, size, color, floorColor);
    _mesh.getChildMeshes().forEach((mesh) => {
      // mesh['defMat'] = mesh.material;
      this.showOutline(mesh, size, color, floorColor);
    });
  }
  hideHighlightSelection(_mesh = null) {
    if (!_mesh) {
      let keys = Object.keys(this.hi_meshes);
      keys.forEach((name) => {
        let mesh = this.hi_meshes[name];
        let mname = this.getMeshName(name);
        const found = this.storedMeshComps.some(el => el === mname);
        if (!found) {
          this.hideHighlightSelection(mesh);
          // delete  this.hi_meshes[mesh.name];
          // this.hideOutline(mesh);
          // if (mesh && this.isMarker(mesh)) {
          //   return
          // }
          // mesh.material = mesh['defMat'] ? mesh['defMat'] : this.scene.getMaterialByID("regular");

          // mesh.getChildMeshes().forEach((_mesh) => {
          //   this.hideOutline(_mesh);
          //   _mesh.material = _mesh['defMat'] ? _mesh['defMat'] : this.scene.getMaterialByID("regular");
          // });
        }
      })
      // this.hi_meshes = {};
      return;
    } else {
      this.hideOutline(_mesh);
      if (_mesh && this.isMarker(_mesh)) {
        return
      }
      _mesh.material = _mesh['defMat'] ? _mesh['defMat'] : this.scene.getMaterialByID("regular");

      _mesh.getChildMeshes().forEach((mesh) => {
        this.hideOutline(mesh);
        mesh.material = mesh['defMat'] ? mesh['defMat'] : this.scene.getMaterialByID("regular");
      });
      delete this.hi_meshes[_mesh.name];
    }



  }

  showOutline(mesh, size = 0.1, color = null, floorColor = false) {
    /* mesh.outlineWidth = size||0.1;
    mesh.outlineColor = color||BABYLON.Color3.Teal();
    mesh.renderOutline = true; */
    mesh.enableEdgesRendering();
    mesh.edgesWidth = size || 6.0;
    let matColor = this.getColor(color);
    mesh.edgesColor = matColor || new BABYLON.Color4(1, 0.0, 0.0, 1);
    if (floorColor) {
      let mtl = this.getNewMaterial(color) || this.scene.getMaterialByID("red");
      mesh.material = mtl;
    }
  }
  hideOutline(mesh) {
    /* mesh.renderOutline = false; */
    mesh.disableEdgesRendering();
  }

  getColor(hex) {
    if (!hex) return null;
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let color = result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
    var r = color[0];
    var g = color[1];
    var b = color[2];
    return new BABYLON.Color4(r, g, b, 1);
  }
  getNewMaterial(_color) {
    let matid = 'group_' + _color;
    let mat = this.scene.getMaterialByName(matid);
    if (mat) {
      return mat;
    }
    let color = this.getHexToRGBColor(_color);
    let cmat = new BABYLON.PBRCustomMaterial(matid, this.scene)
    cmat.metallic = 0;
    cmat.roughness = 0.4;
    cmat.specularColor = this.dfx.color4([0, 0, 0, 0]);
    cmat.albedoColor = this.dfx.color4(color);
    return cmat;
  }
  getHexToRGBColor(hex) {
    if (!hex) return [1, 0.0, 0.0, 1];
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255, 1] : [0, 0, 0, 0];
  }

  checkDeckFloor(name) {
    const found = this.ctrlServ.shipComp['compList'].some(el => el.code === name)
    return found;
  }
  groupColorChanges(data) {
    this.layoutData = data;
    this.reasignPartsPosition()
  }

  layoutFilterChanges(data) {
    let layoutData = data;
    this.broadcastInfo({ src: 'khaservice', event: 'hideLayoutTag', data: data });
    this.filterReasignPartsPosition(layoutData)
  }
  filterReasignPartsPosition(layoutData) {
    let compartments = layoutData['compartments'] || [];
    let components = layoutData['components'] || [];
    let tooltipObj = layoutData['tagPoints'] || [];
    let dfx = this.datauxview.getDatascape();
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    // let compartments = tooltipObj.length>0?tooltipObj.filter((arr, index, self) =>
    // index === self.findIndex((t) => (t.code === arr.code))):[];
    // console.log("tooltipObj.compartments",compartments)
    let mainComponents = [];
    this.storedMeshComps = [];
    this.shipPointerList = []
    let transparentMtl = this.scene.getMaterialByID("transparent");
    let redMtl = this.scene.getMaterialByID("red");

    // tooltipObj.forEach(e => {
    // if (e['code'] === '--') {
    //   if (hfx && hfx.getChildren()) {
    //     hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {
    //       let name = this.getMeshName(mesh.name);
    //       if (name == e.partId) {
    //         const found = mainComponents.some(el => el === name);
    //         if (!found) {
    //           mainComponents.push(name);                
    //           mesh.material = redMtl;
    //         }else {                          
    //           mesh.material = transparentMtl;
    //         }
    //       }
    //     })
    //   }
    // }
    // else{
    compartments.forEach((c) => {
      let key = 'HFX_' + c.code;
      let child = this.datauxview.getElementId(key);
      let mesh = dfx.getElementMesh(child).getChildren()[0];
      if (!mesh) {
        return
      }

      let meshes = mesh.getChildren();
      meshes.forEach((cm) => {
        if (tooltipObj.length > 0) {
          let mname = this.getMeshName(cm.name);
          const found = tooltipObj.some(e => e.partId === mname);
          var data = tooltipObj.filter(e => e.partId === mname)
          console.log(found)
          if (found) {
            //cm.material = redMtl;
            this.createMatObjectPoint(data[0], cm)
          } else {
            this.hideHighlightFilterSelection(cm)
          }
        } else {
          this.hideHighlightFilterSelection(cm)
        }

      })

    })

    // }
    // })
  }
  getCircleData(cent, cp, plane) {
    let mySinus = [];
    let r = cp.subtract(cent).length();
    let radius = r;
    let x, y, z;
    for (let i = -Math.PI; i <= Math.PI; i += Math.PI / 32) {
      if (plane === 'z') {
        x = cent.x + radius * Math.cos(i)
        y = cent.y + radius * Math.sin(i)
        z = cent.z;
      }
      if (plane === 'x') {
        x = cent.x;
        y = cent.y + radius * Math.cos(i);
        z = cent.z + radius * Math.sin(i);
      }
      if (plane === 'y') {
        x = cent.x + radius * Math.cos(i);
        y = cent.y;
        z = cent.z + radius * Math.sin(i);
      }
      mySinus.push(new BABYLON.Vector3(x, y, z));
    }
    return mySinus;
  }
  /**
   * show hide deck labels
   */
  appMode: any;
  showHideDeckLabels(boo) {
    //['02','01','1','M','2','3','4','5']
    // if(this.appMode != 'kys-quiz-mode'&&!boo){
    ['2'].forEach((d) => {
      let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'label_cont_' + d);
      if (lcont) {
        lcont.setEnabled(boo);
      }
    })
    // }


  }


  hideHighlightFilterSelection(_mesh = null) {
    this.hideOutline(_mesh);
    if (_mesh && this.isMarker(_mesh)) {
      return
    }
    _mesh.material = this.scene.getMaterialByID("transparent");

    _mesh.getChildMeshes().forEach((mesh) => {
      this.hideOutline(mesh);
      mesh.material = this.scene.getMaterialByID("transparent");
    });
  }
  showHideRuler(boo) {
    let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'ruler');
    if (lcont) {
      lcont.setEnabled(boo);
    }
  }

  invertColor(col) {
    col = col.toLowerCase();
    const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    let inverseColor = '#'
    col.replace('#', '').split('').forEach(i => {
      const index = colors.indexOf(i)
      inverseColor += colors.reverse()[index]
    })
    return inverseColor
  }

  showHideFrames(boo) {
    let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'frames');
    if (lcont) {
      lcont.setEnabled(boo);
    }
  }
  showHideLongitudinals(boo) {
    let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'longitudinals');
    if (lcont) {
      lcont.setEnabled(boo);
    }
  }
  showHideFramesAndLongs(boo) {
    this.showHideFrames(boo);
    this.showHideLongitudinals(boo)
  }
  hideExitRoutes() {
    let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'exit_routes');
    if (lcont) {
      lcont.getChildMeshes().forEach((m) => {
        m.setEnabled(false);
      })
    }
  }
  showExitRoute(id) {
    let lcont = this.ctrlServ.getChildMeshEx('ehfx', 'exit_routes');
    if (lcont) {
      lcont.getChildMeshes().forEach((m) => {
        let n = this.getMeshName(m.name);
        if (n === 'path_' + id) {
          m.setEnabled(true);
        }

      })
    }
  }
  focusStaticElement(ele) {
    // if (element) {
    //   let el = this.datauxview.getElementId(element);
    //   if (el) {
    //     let dfx = this.datauxview.getDatascape();
    //     // let def = { "target": dfx.getAbsElemCenter(el), "distance": this.distance };
    //     let def = { "target": dfx.getAbsElemCenter(el) };
    //     dfx.moveCamera(def);
    //   }

    this.getPartMesh(ele, (m) => {
      if (m != undefined) {
        let childMesh = m.getChildren();
        if (childMesh.length > 0) {
          childMesh.forEach(element => {
            let id = "element-" + ele.id;
            if (id == element.name) {
              // console.log(" tagPoints[idx]['hit']", tagPoints[idx]['hit']);
              console.log("childMesh", element._absolutePosition);
              let dfx = this.datauxview.getDatascape();
              // let def = { "target": dfx.getAbsElemCenter(el), "distance": this.distance };
              let def = { "target": { x: element._absolutePosition.x, y: element._absolutePosition.y, z: element._absolutePosition.z } };
              dfx.moveCamera(def);
              // tagPoints[idx]['hit'] = { x: element._absolutePosition.x, y: element._absolutePosition.y, z: element._absolutePosition.z }
            }
          })
        }
      }
    })
    // }
  }

}
