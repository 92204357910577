import { DemoAvatar } from '../services/demo-avatar.service';

export class DemoGameService {
  datauxview: any;
  dfx: any;
  avatar: any;
  communicationServ: any;
  broadcastSubs: any;

  constructor(datauxview, _communicationServ = null) {
    if (!this.datauxview) {
      this.datauxview = datauxview;
      this.communicationServ = _communicationServ;
      this.dfx = this.datauxview.getDatascape();
      this.serviceInit();

      window.addEventListener('keydown', (event) => {
        this.handleKeyboardDownEvent(event);
      });

      window.addEventListener('keyup', (event) => {
        this.handleKeyboardUpEvent(event);
      });

      window.addEventListener('pointerdown', (event) => {
        if (event.target['nodeName'] === 'CANVAS') {
          this.onMouseDownN()
        }
      });

      window.addEventListener("pointerup", (event) => {
        this.onMouseUpN()
      });

      window.addEventListener("pointermove", (event) => {
        this.onMouseMoveN()
      });

    }
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * 
  * method for communication service receive instance
  * * */
  serviceInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
      })
  }

  /**
   * KYS GAME MODE - START
   */
  aId
  cam
  cray
  gray
  fray
  last_valid_pos
  follow_target
  ft_start

  /* *
  * initialization for game mode
  * * */
  initGameMode() {
    //  this.ft_start = this.dfx.vector3([0.0572, 13.9747, -125.3028])
    this.ft_start = this.dfx.vector3([0, 0.3, 0]);
    this.startPathAnimDemo();
  }

  /* *
   * code to initialize the 
   * * */
  avatarArray: any = [];
  async startPathAnimDemo() {
    let apos = this.ft_start; //avt.absolutePosition;
    if (!this.follow_target) {
      this.cam = this.dfx.getCamera()
      this.ft_start = apos.clone();

      let dummy = await new DemoAvatar(this.datauxview, this.communicationServ).createAvatar('_dummy_', '', apos, 'p1');
      // let dummy = await new GameService(this.datauxview, this.communicationServ).createAvatar('./slms/path-anim-1a.glb', 'root_path-anim-1a', apos, 'p1');
      let mesh = this.dfx.getElementMesh(dummy);
      mesh.isPickable = false;

      /**
       * apply Collision Detection
       * add below code to seperate method 
       */
      this.cray = this.dfx.attachRay({ handle: dummy, position: [0, 0, 0.6], direction: [0, 0, 1], length: 2, showray: !true });
      this.gray = this.dfx.attachRay({ handle: dummy, position: [0, -0.6, 0], direction: [0, -1, 0], length: 3, showray: !true });
      this.fray = this.dfx.attachRay({ handle: dummy, position: [0, -1.0, 0.75], direction: [0, 0, 1], length: 0.25, showray: !true });
      /* mesh.checkCollisions=true;
       mesh.ellipsoid = new BABYLON.Vector3(1, 1.5, 1);
       mesh.ellipsoidOffset = new BABYLON.Vector3(0, 1.5, 0);  */
      this.follow_target = dummy;
      //mesh.setParent(avt);
    }

    let mesh = this.dfx.getElementMesh(this.follow_target);
    let cam = this.cam;
    cam.targetX.value = apos.x;
    cam.targetY.value = apos.y;
    cam.targetZ.value = apos.z;
    cam.distance.value = 4;
    cam.yaw_deg.value = 0.001;
    cam.pitch_deg.value = 0.001;
    cam.minZ = 0.01;
    let ds = 5 / 60;
    // this.moveCameraTo(null,{target:apos,distance:10,yaw:0.001,pitch:0.001});

    // this.prepareVenueForAnim();
    let anim = () => {
      let stop = false;
      if (this.mo_state_d) {
        mesh.rotation.y = (cam.yaw_deg.value) * this.deg_rad;
        let yang = mesh.rotation.y
        let dz = ds * Math.cos(yang) * this.mo_state_d;
        let dx = ds * Math.sin(yang) * this.mo_state_d;
        let gpick = this.dfx.pickWithRay({ ray: this.gray, predicate: null, fastcheck: !true });
        if (gpick && gpick.hit) {
          let name = gpick.pickedMesh.name;
          let y = gpick.pickedPoint.y
          let _y = y + 0.3;
          mesh.position.y = _y;
        } else {
          mesh.position = this.last_valid_pos;
          stop = true;
        }

        let objects = ['cube', 'sphere', 'cube1', 'sphere1'];
        let pick = this.dfx.pickWithRay({ ray: this.cray, predicate: null, fastcheck: !true });
        let fpick = this.dfx.pickWithRay({ ray: this.fray, predicate: null, fastcheck: !true });
        if (pick && pick.hit) {
          let name = pick.pickedMesh.name.toLowerCase();
          const found = objects.some(el => name.includes(el));
          if (found) {
            stop = true;
          }
        }

        if (!stop) {
          if (fpick && fpick.hit) {
            let name = fpick.pickedMesh.name.toLowerCase();
            const found = objects.some(el => name.includes(el));
            if (found) {
              stop = true;
            }
          }
        }

        if (this.isHatch) {
          let d = this.isHatch.pcenter.subtract(mesh.position).length();
          if (d < 4) {
            mesh.position.x = this.isHatch.pcenter.x;
            mesh.position.z = this.isHatch.pcenter.z;
            mesh.position.y = this.isHatch.pcenter.y;
            stop = true;
            this.isHatch = false;
            console.log("CLIMB DOWN --- HATCH")
          }
        }

        if (!stop) {
          mesh.position.x += dx;
          mesh.position.z += dz;
          this.last_valid_pos = mesh.position.clone();
        }
        //mesh.moveWithCollisions(new BABYLON.Vector3(dx, 0, dz));   

      }

      let apos = mesh.position;
      cam.targetX.value = apos.x;
      cam.targetY.value = apos.y;
      cam.targetZ.value = apos.z;
      cam.distance.value = 4;
      this.aId = requestAnimationFrame(anim);
    }
    this.aId = requestAnimationFrame(anim);
  }

  mo_state_d = 0;
  deg_rad = Math.PI / 180;
  isHatch;
  anim_mode = true;
  onMouseDownN(d = 1) {
    this.mo_state_d = d;
  }

  onMouseMoveN() {
    if (!this.mo_state_d) {
      return;
    }
  }

  onMouseUpN() {
    if (this.anim_mode && this.mo_state_d) {
      let pick = this.dfx.getHitPosition();
      if (pick && pick.pickedMesh) {
        if (pick.pickedMesh.doortype && pick.pickedMesh.doortype == 'hatch') {
          this.isHatch = pick.pickedMesh;
        }
      }
    }
    this.mo_state_d = 0;
  }

  initialize(avatarArray, camera) {
  }

  /* * 
  * method for keydown event
  * * */
  handleKeyboardDownEvent(event: KeyboardEvent) {
    const key = event.key.toString().toLowerCase();
    if (event.shiftKey && key === 't') {
    }
    if (key === 'f') {
      this.forward();
    }
    if (key === 'b') {
      this.backward();
    }
    if (key === 'l') {
      this.turnLeft();
    }
    if (key === 'r') {
      this.turnRight();
    }
    if (key === 'u') {
      this.lookUp();
    }
    if (key === 'd') {
      this.lookDown();
    }
    if (key === 'h') {
      this.restartAvatar();
    }
  }

  /* * 
  * method for keyup event
  * * */
  handleKeyboardUpEvent(event: KeyboardEvent) {
    const key = event.key.toString().toLowerCase();
    if (event.shiftKey && key === 't') {
    }
    if (key === 'f') {
      this.onMouseUpN();
    }
    if (key === 'b') {
      this.onMouseUpN();
    }
    if (key === 'l') {
      this.onMouseUpN();
    }
    if (key === 'r') {
      this.onMouseUpN();
    }
    if (key === 'u') {
      this.onMouseUpN();
    }
    if (key === 'd') {
      this.onMouseUpN();
    }
  }

  forward() {
    this.onMouseDownN(1);
  }

  backward() {
    this.onMouseDownN(-1);
  }

  turnLeft() {
    let cam = this.cam;
    cam.yaw_deg.value -= 0.1
  }

  turnRight() {
    let cam = this.cam;
    cam.yaw_deg.value += 0.1
  }

  lookDown() {
    this.cam.pitch_deg.value += 0.1
    if (this.cam.pitch_deg.value > 45) {
      this.cam.pitch_deg.value = 45
    }
  }

  lookUp() {
    this.cam.pitch_deg.value -= 0.1
    if (this.cam.pitch_deg.value < -45) {
      this.cam.pitch_deg.value = -45
    }
  }

  restartAvatar() {
    let mesh = this.dfx.getElementMesh(this.follow_target);
    if (mesh) {
      mesh.position = this.ft_start.clone();
    }
    if (this.cam) {
      this.cam.yaw_deg.value = 0.001;
      this.cam.pitch_deg.value = 0.001;
    }
    // this.current_deck="1";
    // this.updateDeckMask("1");
  }

  run() {
  }

  jump() {
  }

  climbUP() {
  }

  climbDown() {
  }

  pick() {
  }

  drop() {
  }

  die() {
  }

  respawn() {
  }

  gotoPosition() {
  }

  increaseSpeed() {
  }

  decreaseSpeed() {
  }

  reset() {
  }

  start() {
  }

  stop() {
  }

  pause() {
  }

  clear() {
  }

  win() {
  }

  lose() {
  }

  warn() {
  }

}
