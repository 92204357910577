import { Component, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { Event } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CommunicationService } from '../services/communication.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

export interface Element {
  name: string;
  icon: any;
  ticon?,
  state: boolean,
  tooltip?
}

const CONTROL_BUTTON_PROPS: Element[] = [
  { name: 'zoomout', icon: 'remove', ticon: 'remove', state: true, tooltip: "Zoom Out" },
  { name: 'zoomin', icon: 'add', ticon: 'add', state: true, tooltip: "Zoom In" },
  { name: '2D', icon: 'dimension', state: false, tooltip: "2D/3D View" },
  { name: 'home', icon: 'home', ticon: 'home', state: true, tooltip: "Home" },
  { name: 'front', icon: 'front', state: false },
  { name: 'top', icon: 'top', state: false },
  { name: 'back', icon: 'back', state: false },
  { name: 'right', icon: 'right', state: false },
  { name: 'left', icon: 'left', state: false },
  { name: 'drag', icon: 'swipe', state: false, ticon: 'do_not_touch' },
  { name: 'reset', icon: 'refresh', state: false, ticon: 'refresh' },
];

@Component({
  selector: 'app-controltools',
  templateUrl: './controltools.component.html',
  styleUrls: ['./controltools.component.scss'],
  providers: [DatePipe]
})

export class ControltoolsComponent implements AfterViewInit {
  displayedColumns: string[] = ['position'];
  arrControlBtnProps = CONTROL_BUTTON_PROPS;
  broadcastSubs: any;

  @Output() controlToolEvent = new EventEmitter<string>();
  @Output() zoomControl = new EventEmitter<string>();

  constructor(private datePipe: DatePipe, private communicationServ: CommunicationService, private cdr: ChangeDetectorRef, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private translate: TranslateService, private language: LanguageService) {

    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
      });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  onMouseDown(row, index) {
    if (row.name === 'zoomin' || row.name === 'zoomout') {
      let zoomPosition = row.name === 'zoomin' ? ']' : '[';
      this.zoomControl.emit(zoomPosition)
    }
  }

  //RAJ-COMMENT: check positionToolEvent, make singne event method and handle it
  buttonClick(row, index) {    
    this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;    
    this.controlToolEvent.next(row);
  }

  /* * * * *
* method for communicate event instance with data to access all components
* * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
}
