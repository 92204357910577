import { Component, Input, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { ConfigService } from '../services/config.service';
import { ControllerService } from "../services/controller.service";

@Component({
  selector: 'app-course-panel',
  templateUrl: './course-panel.component.html',
  styleUrls: ['./course-panel.component.scss']
})
export class CoursePanelComponent implements OnInit {
  coursePanelOpen: boolean = true;
  courseHandleToggleOpen: boolean = true;
  controlPosition: any = 'Right';
  @Input() set courseNode(v: any) {
    this.getSelectedCourse({ index: v })
    console.log("data-main",v);
  }
  @Input() selectedNetwork: any;
  selectedMenu: number = 0;
  childMenu: any = [];
  appNavList: any;
  // appNavList: any =
  //   {
  //     "appName": "Procedures",
  //     "childList": [
  //       {
  //         "appName": "Action & Emergency Procedures",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //         "read": false,
  //         "column": 2,
  //         "refID": 1,
  //         "content": [
  //           { "content": "DCS Alarm Pipe", "read": true },
  //           { "content": "Member Responsibilities", "read": true },
  //           { "content": "Flood Required Actions", "read": true },
  //           { "content": "Man Overboard Actions", "read": true },
  //           { "content": "Casualty Actions", "read": true },
  //           { "content": "HazMat Spill", "read": false },
  //           { "content": "Fuel Spill", "read": false },
  //           { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //           { "content": "Anchor Control", "read": false },
  //           { "content": "Berthing a Ship Out-Board", "read": false },
  //         ]
  //       },
  //       {
  //         "appName": "Hose Procedure for Attack and Backup Team",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //         "read": false,
  //         "column": 1,
  //         "refID": 9,
  //         "content": [
  //           { "content": "Laying out and Charging Hoses", "read": true }
  //         ]
  //       },
  //       {
  //         "appName": "Pumping/Flooding Capabilities",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //         "url": "",
  //         "subkey": "pumpCapable_course",
  //         "read": false,
  //         "column": 1,
  //         "refID": 20,
  //         "content": [
  //           { "content": "Locate eductors, emergency flooding overboard discharges and submersible pumps", "read": true }
  //         ]
  //       },
  //       {
  //         "appName": "Reaction to Missile Emergencies",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //         "read": false,
  //         "column": 1,
  //         "refID": 22,
  //         "content": [
  //           { "content": "Fire Fighting Tour", "read": false }
  //         ]
  //       },
  //       {
  //         "appName": "RadHaz Precautions, Fuel and HazMat Spills",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //         "read": false,
  //         "column": 1,
  //         "refID": 23,
  //         "content": [
  //           { "content": "Fire Fighting Tour", "read": false }
  //         ]
  //       },
  //       {
  //         "appName": "At Sea Emergency Response",
  //         "icon": "priority_high",
  //         "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //         "read": false,
  //         "column": 1,
  //         "refID": 24,
  //         "content": [
  //           { "content": "Fire Fighting Tour", "read": false }
  //         ]
  //       }
  //     ]
  //   };
  // appNavList:any=        {
  //   "appName": "Locations",
  //   "keyName": "location",
  //   "key": "course",
  //   "disabled": false,
  //   "url": "",
  //   "subkey": "location_course",
  //   "childList": [
  //     {
  //       "appName": "Compartments",
  //       "keyName": "comp",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "comp_course",
  //     },
  //     {
  //       "appName": "Stretcher Locations",
  //       "keyName": "strloc",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "strloc_course",
  //     },
  //     {
  //       "appName": "First Aid Lockers",
  //       "keyName": "firstAidLoc",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "firstAid_course",
  //     },
  //     {
  //       "appName": "Escape Hatches",
  //       "keyName": "escHat",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "escHat_course",
  //     },
  //     {
  //       "appName": "Location of chemox",
  //       "keyName": "chemox",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "chemox_course",
  //     },
  //     {
  //       "appName": "First Aid Extinguishers",
  //       "keyName": "firstAidExt",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "firstAidExt_course",
  //     },
  //     {
  //       "appName": "Fire Main System",
  //       "keyName": "fireMain",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "fireMain_course",
  //     },
  //     {
  //       "appName": "Quartzoid sprinkler system",
  //       "keyName": "quartzoid",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "quartzoid_course",
  //     },
  //     {
  //       "appName": "Magazine flood and spray system",
  //       "keyName": "magazine",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "magazine_course",
  //     },
  //     {
  //       "appName": "Fitted AFFF system",
  //       "keyName": "fittAf",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "fittAf_course",
  //     },
  //     {
  //       "appName": "Fitted Halon System",
  //       "keyName": "fittHa",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "fittHa_course",
  //     },
  //     {
  //       "appName": "Galley Fire Protection",
  //       "keyName": "gallery",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "gallery_course",
  //     },
  //     {
  //       "appName": "Twin Agent Unit (TAU)",
  //       "keyName": "twinAgent",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "twinAgent_course",
  //     },
  //     {
  //       "appName": "Fine Water Spray System",
  //       "keyName": "fineWater",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "fineWater_course",
  //     },
  //     {
  //       "appName": "DC Equipment ",
  //       "keyName": "equipment",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "equipment_course",
  //     },
  //     {
  //       "appName": "Shincom, Main Broadcast and PRC",
  //       "keyName": "shincom",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "shincom_course",
  //     },
  //     {
  //       "appName": "Floodable Lockers",
  //       "keyName": "floodable",
  //       "key": "course",
  //       "disabled": false,
  //       "url": "",
  //       "subkey": "floodable_course",
  //     }
  //   ]
  // };
  appSideNavList: any = [];
  // appSideNavList: any = [
  //   {
  //     "appName": "Halifax",
  //     "childList": [
  //       {
  //         "appName": "Procedures",
  //         "childList": [
  //           {
  //             "appName": "Action & Emergency Procedures",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 1,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood Required Actions", "read": true },
  //               { "content": "Man Overboard Actions", "read": true },
  //               { "content": "Casualty Actions", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Hose Procedure for Attack and Backup Team",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 9,
  //             "content": [
  //               { "content": "Laying out and Charging Hoses", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Pumping/Flooding Capabilities",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "url": "",
  //             "subkey": "pumpCapable_course",
  //             "read": false,
  //             "column": 1,
  //             "refID": 20,
  //             "content": [
  //               { "content": "Locate eductors, emergency flooding overboard discharges and submersible pumps", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Reaction to Missile Emergencies",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 22,
  //             "content": [
  //               { "content": "Fire Fighting Tour", "read": false }
  //             ]
  //           },
  //           {
  //             "appName": "RadHaz Precautions, Fuel and HazMat Spills",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 23,
  //             "content": [
  //               { "content": "Fire Fighting Tour", "read": false }
  //             ]
  //           },
  //           {
  //             "appName": "At Sea Emergency Response",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 24,
  //             "content": [
  //               { "content": "Fire Fighting Tour", "read": false }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         "appName": "Locations",
  //         "childList": [
  //           {
  //             "appName": "Compartments",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 2,
  //             "content": [
  //               { "content": "Compartment Location", "read": true },
  //               { "content": "Compartment Hazards", "read": true },
  //               { "content": "Passageways and Alternate Routes", "read": true },
  //               { "content": "Assist Duty Tech Rounds", "read": true },
  //               { "content": "Accompany Magazine Yeoman on Magazine Round", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Stretcher Locations",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 3,
  //             "content": [
  //               { "content": "Find Stretchers on All Decks", "read": true },
  //               { "content": "Simulated Casualty", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "First Aid Lockers, Kits, Bags & Medical Devices",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 4,
  //             "content": [
  //               { "content": "Locate First Aid Lockers/Kits", "read": true },
  //               { "content": "Locate First Aid Bags", "read": true },
  //               { "content": "Locate Water Gel Blankets", "read": true },
  //               { "content": "Locate Casualty Team Locations", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Escape Hatches",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 5,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Location of chemox",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 6,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "First Aid Extinguishers",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 7,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Fire Main System",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 8,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Quartzoid sprinkler system",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 10,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Magazine flood and spray system",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 11,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Machinery space casings, uptakes and engine enclosure dry pipe sprinkler system",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 12,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Fitted AFFF system",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 13,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Fitted Halon System",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 14,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Galley Fire Protection",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 15,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Twin Agent Unit (TAU)",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 16,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Fine Water Spray System",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 17,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "DC Equipment and DC and Fire Fighting Lockers",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 18,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Shincom, Main Broadcast and PRC",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 19,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           },
  //           {
  //             "appName": "Floodable Lockers",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 2,
  //             "refID": 19,
  //             "content": [
  //               { "content": "DCS Alarm Pipe", "read": true },
  //               { "content": "Member Responsibilities", "read": true },
  //               { "content": "Flood", "read": true },
  //               { "content": "Man Overboard", "read": true },
  //               { "content": "Casualty", "read": true },
  //               { "content": "HazMat Spill", "read": false },
  //               { "content": "Fuel Spill", "read": false },
  //               { "content": "Hanger Explosive Vapour Alarm", "read": false },
  //               { "content": "Anchor Control", "read": false },
  //               { "content": "Berthing a Ship Out-Board", "read": false },
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         "appName": "Misc",
  //         "childList": [
  //           {
  //             "appName": "Fire Protection Zones",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 25,
  //             "content": [
  //               { "content": "Use and Demonstrate Knowledge", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "Dry pipes for Machinery casings",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_learning", "icon": "menu_book", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 26,
  //             "content": [
  //               { "content": "Use and Demonstrate Knowledge", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "Heat and Smoke detectors",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 27,
  //             "content": [
  //               { "content": "Ionization (Smoke) Detectors", "read": true },
  //               { "content": "Heat Detectors", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "Man Locked in Fridge alarm",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 28,
  //             "content": [
  //               { "content": "Use and Demonstrate Knowledge", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "NBC and Citadels",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 29,
  //             "content": [
  //               { "content": "Location", "read": true },
  //               { "content": "NBC Filters", "read": true },
  //               { "content": "A/C Plants", "read": true },
  //               { "content": "Fan Coil Units & Booster Chiller Units", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Smoke Control",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 30,
  //             "content": [
  //               { "content": "Use and Demonstrate Knowledge", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "Hangar Explosive Vapour Detector",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 31,
  //             "content": [
  //               { "content": "Use and Demonstrate Knowledge", "read": true },
  //             ]
  //           },
  //           {
  //             "appName": "Smoke Evacuation",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 32,
  //             "content": [
  //               { "content": "Evacuation Spaces", "read": true },
  //               { "content": "De-Smoking", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Nature of Fire",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 33,
  //             "content": [
  //               { "content": "Combustion", "read": true },
  //               { "content": "Sources of Heat and Heat Transfer", "read": true },
  //               { "content": "Classification of fires", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Fire Hazards of Metal",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 34,
  //             "content": [
  //               { "content": "Aluminum", "read": true },
  //               { "content": "Titanium", "read": true },
  //               { "content": "Magnesium", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Damage Control Conditions",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 35,
  //             "content": [
  //               { "content": "3 Damage Control Conditions", "read": true },
  //               { "content": "The Red Zone", "read": true },
  //               { "content": '"May Be Left Open" Tags', "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "Watch Systems",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 36,
  //             "content": [
  //               { "content": "Two Watch System", "read": true },
  //               { "content": "Degree of Readiness", "read": true },
  //               { "content": "States of Preparedness", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "NBC Defence",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 37,
  //             "content": [
  //               { "content": "General", "read": true },
  //               { "content": "Mission Oriented Protective Postures (MOPP)", "read": true },
  //               { "content": "Biological Chemical Warfare (BCW) Survival Rule", "read": true },
  //               { "content": "IA Drill", "read": true },
  //               { "content": "Immediate DecontaminationDrill", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "NBC Conditions",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 38,
  //             "content": [
  //               { "content": "Two Conditions: Bravo & Alpha", "read": true },
  //               { "content": "Chemical/Biological Attack", "read": true },
  //               { "content": "Nuclear Attack", "read": true },
  //               { "content": "Cleansing Post", "read": true },
  //               { "content": "Cleansing Stations", "read": true }
  //             ]
  //           },
  //           {
  //             "appName": "General",
  //             "icon": "priority_high",
  //             "courseList": [{ "type": "digital_twin", "icon": "desktop_windows", "status": false }, { "type": "digital_learning", "icon": "menu_book", "status": false }, { "type": "f2f_learning", "icon": "directions_walk ", "status": false }],
  //             "read": false,
  //             "column": 1,
  //             "refID": 39,
  //             "content": [
  //               { "content": "Emergency Stations", "read": true },
  //               { "content": "Verification Muster", "read": true },
  //               { "content": "Escape Routes", "read": true },
  //               { "content": "Flying Stations", "read": true },
  //               { "content": "Rescue Stations", "read": true },
  //               { "content": "Life Jackets & Protective Masks", "read": true }
  //             ]
  //           },

  //         ]
  //       }
  //     ]
  //   },
  //   // {
  //   //   "appName": "Troubleshooting",
  //   //   "keyName": "troubleshooting",
  //   //   "key": "course",
  //   //   "disabled": false,
  //   //   "url": "",
  //   //   "subkey": "engines_course",
  //   //   "childList": [
  //   //     {
  //   //       "appName": "Procedures",
  //   //       "keyName": "procedures",
  //   //       "key": "course",
  //   //       "disabled": false,
  //   //       "url": "",
  //   //       "subkey": "procedures_course",
  //   //     },
  //   //     {
  //   //       "appName": "Locations",
  //   //       "keyName": "location",
  //   //       "key": "course",
  //   //       "disabled": false,
  //   //       "url": "",
  //   //       "subkey": "location_course",
  //   //     },
  //   //     {
  //   //       "appName": "Misc",
  //   //       "keyName": "misc",
  //   //       "key": "course",
  //   //       "disabled": false,
  //   //       "url": "",
  //   //       "subkey": "misc_course",
  //   //     }
  //   //   ]
  //   // }
  // ];
  broadcastSubs
  userNodeList: any = [];
  nmCompList: any = [];
  selectedNode: any;
  appSideNavMenuList: any = [];
  selectedNodetree: any;
  percentage = 0;
  applicationAnimation: any;
  appCourseAnimation: boolean = false;
  constructor(private ctrlServ: ControllerService, private communicationServ: CommunicationService, private configService: ConfigService,) { }

  ngOnInit(): void {
    // this.updateSelectedCourse();
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "graphicNode") {
          if (data.event === 'parentNodeSelected') {
            console.log("parentNodeSelected", data.data);
            if (this.selectedNodetree != 'dvt' || this.selectedNodetree != 'mechanical') {
              this.courseHandleToggleOpen = true;
              this.selectedNode = data.data;
              this.cardCourseCompleteStatus();
              this.updateSelectedCourse();
            }
          }
        }
        if (data.src == 'network') {
          if (data.event == 'updatedUserNode') {
            console.log("updatedUserNode", data.data)
            this.userNodeList = data.data;;
            this.cardCourseCompleteStatus()
            this.updateSelectedCourse();
          }
          if (data.event == 'userNodeDisabled') {
            // this.courseHandleToggleOpen = false;
          }
        }
        if (data.src === "networkmodel") {
          if (data.event === 'componentList') {
            console.log("componentList", data.data)
            this.nmCompList = data.data;
          }
          if (data.event === 'nodetree') {
            this.selectedNodetree = data.data;
            console.log("selectedNodetree", this.selectedNodetree)
          }
          if (data.event == 'menuList') {
            this.appSideNavList = data.data;
            this.selectedMenu = 0;
            this.appSideNavMenuList = data.data;
            // this.updateSelectedCourse();
            this.getSelectedCourse({ index: this.selectedCourse });
            this.cardCourseCompleteStatus();
            this.updateSelectedCourse();
            console.log("menuList--", this.appSideNavList);
            // this.appSideNavList = data.data;
          }
          if (data.event == 'menuListUpdate') {
            this.appSideNavList = data.data;
            this.selectedMenu = 0;
            this.appSideNavMenuList = data.data;
            this.getSelectedCourse({ index: this.selectedCourse });
            // this.cardCourseCompleteStatus();
            this.updateSelectedCourse();
            console.log("menuList--", this.appSideNavList);
            this.appNavList =  this.appSideNavList[0];
          }
        }
        if (data.src === "detailTag") {
          if (data.event === 'menuActive') {
            let menu = data['data'];
            if (menu) {
              this.menuActive(menu, this.selectedMenu);
            }
          }
        }
        if (data.src == 'main') {
          if (data.event == 'node-tree') {
            this.appCourseAnimation = false;
          }
        }
      })
      // console.log('dataaa', this.appSideNavList);
  }

  menuActive(pageData, i) {
    // this.selectedMenu = pageData.refID;
    // document.getElementById('cardView' + pageData.refID).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    // document.getElementById('cardView' + pageData.refID).style.boxShadow = '0px 0px 10px 0px #4b79ed';
    // document.getElementById('cardView' + pageData.refID).style.border = '1px solid #5b8aff';
    // setTimeout(() => {
    //   document.getElementById('cardView' + pageData.refID).style.boxShadow = '';
    //   document.getElementById('cardView' + pageData.refID).style.border = '';
    // }, 1500);
    this.selectedMenu = i;
    var data: any = [];
    data = this.appSideNavMenuList.filter(e => e.appName.toLowerCase().trim() == pageData.appName.toLowerCase().trim());
    // this.appNavList = pageData;
    this.appNavList = data[0];
    // document.querySelector('#cardView' + pageData.refID).scrollIntoView({ behavior: 'smooth', block: 'start' });


    // if (pageData.childList.length > 0) {
    //   if (this.selectedMenu == pageData.key) {
    //     delete this.selectedMenu;
    //     this.childMenu = [];
    //     return;
    //   }
    //   this.selectedMenu = pageData.key;
    //   this.childMenu = pageData.childList;
    // } else {
    //   this.selectedMenu = pageData.key;
    //   this.openContent(pageData);
    // }
  }

  openContent(e) {
    console.log(e)
  }

  courseToggleOpenHanddle() {
    this.courseHandleToggleOpen = !this.courseHandleToggleOpen;
  }

  getPercentage() {
    var percentage = 0;
    var value = [];
    if (this.appNavList) {
      if (this.appNavList.childList.length > 0) {
        value = this.appNavList.childList.filter(e => e.read == true);
        percentage = Math.trunc((value.length / this.appNavList.childList.length) * 100);
      }
    }
    //  else{
    //   percentage = 0;
    // }
    // this.percentage = percentage;
    return percentage;
    // console.log("getPercentage data", percentage);
    
  }

  selectedCourse: any;
  getSelectedCourse(ele) {
    if (ele) {
      let id = ele['index'].toLowerCase().trim() == 'misc' ? 'Miscellaneous Requirements' : ele['index'];
      this.selectedCourse = ele.index;
      if (this.appSideNavList.length >= 0) {
        let idx = this.appSideNavList.findIndex(e => e.appName.toLowerCase().trim() == id.toLowerCase().trim());
        if (idx > -1) {
          this.appNavList = this.appSideNavList[idx];
          // console.log("menuList--", this.appSideNavList[idx]);
          // console.log("menuList--", this.appSideNavList);
        }
      }
    }
  }

  getProgress(ele, id) {
    if (this.userNodeList["userNode"]) {
      // var key = ele=='lesson'?'lesson_progress':ele=='assemble_part'?'assemble_part_progress':ele=='assemble_part'
      let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == id);
      // console.log(this.userNodeList["userNode"],ele,idx)
      if (idx > -1) {
        var progress = this.userNodeList["userNode"][idx][ele] == 100 ? true : false;
        return progress;
      } else {
        return false;
      }
    } else {
      return false;
    }
    // return true;
  }

  getDependencyStatus(course, i) {
    let obj = course.courseList[i];
    if (obj['type'] == 'dependency') {
      let idx = this.nmCompList.findIndex(e => e.refID == course.refID);
      if (idx > -1) {
        var vertexId: any = Number(this.nmCompList[idx].id);
        if (this.userNodeList["userNode"]) {
          let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == vertexId);
          if (idx > -1) {
            var progress = this.userNodeList["userNode"][idx][course.courseList[i]['type']] == 100 ? true : false;
            return progress;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // isActiveIcon(course, idx, pidx) {
  //   let obj = course.courseList[idx];
  //   if (obj['type'] == 'dependency') {
  //     let childList = this.appNavList['childList'];
  //     const index = childList.findIndex(el => el['read'] == false);
  //     if (index > -1) {
  //       return index === pidx ? false : true;
  //     } else {
  //       return !course.read
  //     }
  //   } else {
  //     let childList = this.appNavList['childList'];
  //     const index = childList.findIndex(el => el['read'] == false);
  //     if (index > -1) {
  //       return index === pidx ? true : false;
  //     } else {
  //       return course.read
  //     }
  //   }
  // }

  /**
   * is active icon 
   */
  disableCard = false;
  isActiveIcon(course, idx, pidx) {
    let obj = course.courseList[idx];
    // this.disableCard = false;
    if (obj['type'] == 'dependency') {
      let childList = this.appNavList['childList'];
      const everyfalse = childList.every(el => el['read'] == false);
      const everyTrue = childList.every(el => el['read'] == true);
      if (everyTrue) {
        this.disableCard = false;
        return false;
      } else if (everyfalse) {
        const index = childList.findIndex(el => el['read'] == false);
        const cindex = childList.findIndex(el => el['refID'] == course['refID']);
        if (pidx) {          
          this.disableCard = index === 0 ? cindex === index ? false : true : true;
        } else {
          this.disableCard = false;
        }
        return index === 0 ? cindex === index ? false : true : true;
      } else {
        const index = childList.findIndex(el => el['read'] == false);
        const cindex = childList.findIndex(el => el['refID'] == course['refID']);
        if (index <= 1) {
          if (pidx) {
            this.disableCard = index <= 1 ? cindex <= index ? false : true : true;           
          } else {
            this.disableCard = false;
          }
          return index <= 1 ? cindex <= index ? false : true : true;
        } else if (index <= 2) {
          if (pidx) {
            this.disableCard = index <= 2 ? cindex <= index ? false : true : true;           
          } else {
            this.disableCard = false;
          }
          return index <= 2 ? cindex <= index ? false : true : true;
        } else if (index <= 3) {
          if (pidx) {
            this.disableCard = index <= 3 ? cindex <= index ? false : true : true;           
          } else {
            this.disableCard = false;
          }
          return index <= 3 ? cindex <= index ? false : true : true;
        }
      }
    }

    else {
      if (pidx) {
      }else{
        this.disableCard = false;
      }
      let childList = this.appNavList['childList'];
      const everyfalse = childList.every(el => el['read'] == false);
      const everyTrue = childList.every(el => el['read'] == true);
      if (everyTrue) {
        return true;
      } else if (everyfalse) {
        const index = childList.findIndex(el => el['read'] == false);
        const cindex = childList.findIndex(el => el['refID'] == course['refID']);
        return index === 0 ? cindex === index ? true : false : false;
      } else {
        const index = childList.findIndex(el => el['read'] == false);
        const cindex = childList.findIndex(el => el['refID'] == course['refID']);
        if (index <= 1) {
          return index <= 1 ? cindex <= index ? true : false : false;
        } else if (index <= 2) {
          return index <= 2 ? cindex <= index ? true : false : false;
        } else if (index <= 3) {
          return index <= 3 ? cindex <= index ? true : false : false;
        }
      }
    }
  }

  getDependencyList() {
    let menu;
    for (let childList of this.appNavList['childList']) {
      if (!childList['read']) {
        menu = childList;
        break;
      }
    }
    return menu;
  }

  courseRead(course, ci) {
    let obj = course.courseList[ci];
    if (obj['type'] == 'dependency') {
      let menu = this.getDependencyList();
      // if (menu) {
      //   this.menuActive(menu, this.selectedMenu);
      // }
      return;
    }
    let idx = this.nmCompList.findIndex(e => e.refID == course.refID);
    if (idx > -1) {
      var data: any = { "vertexId": Number(this.nmCompList[idx].id), "userId": this.configService.userId, "node": this.selectedNetwork };
      data[course.courseList[ci]['type']] = 100;
      this.postUserNode(data);
      if (course.courseList[ci]['type'] == "digital_learning") {
        this.courseHandleToggleOpen = false;
        this.broadcastInfo({ src: 'coursePanel', event: 'courseCard', data: course, index: ci });
      }
    }
  }

  postUserNode(data) {
    if (data.vertexId && data.userId) {
      console.log("postUserNode", data);
      this.broadcastInfo({ src: 'graphicNode', event: 'userSelectNodeUpdate', data: data });
    }
  }

  getStatusCourse(course, i) {
    let idx = this.nmCompList.findIndex(e => e.refID == course.refID);
    if (idx > -1) {
      var vertexId: any = Number(this.nmCompList[idx].id);
      if (this.userNodeList["userNode"]) {
        // var key = ele=='lesson'?'lesson_progress':ele=='assemble_part'?'assemble_part_progress':ele=='assemble_part'
        let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == vertexId);
        // console.log(this.userNodeList["userNode"],ele,idx)
        if (idx > -1) {
          var progress = this.userNodeList["userNode"][idx][course.courseList[i]['type']] == 100 ? true : false;
          return progress;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  completedNodeList = [];
  _userNodeList = {
    "userNode": [
      {
        "_id": "61b346de8353a8003aa3b3cd",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 6,
        "digital_twin": 0,
        "digital_learning": 100,
        "f2f_learning": 0
      },
      {
        "_id": "61b346e68353a8003aa3b3ce",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 7,
        "digital_twin": 0,
        "digital_learning": 100,
        "f2f_learning": 0
      },
      {
        "_id": "61b3473f8353a8003aa3b3cf",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 8,
        "digital_twin": 0,
        "digital_learning": 0,
        "f2f_learning": 100
      },
      {
        "_id": "61b3473f8353a8003aa3b3cf",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 9,
        "digital_twin": 0,
        "digital_learning": 0,
        "f2f_learning": 100
      },
      {
        "_id": "61b3473f8353a8003aa3b3cf",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 10,
        "digital_twin": 0,
        "digital_learning": 0,
        "f2f_learning": 100
      },
      {
        "_id": "61b3473f8353a8003aa3b3cf",
        "userId": "f821d40c-1a6c-4c05-8a6f-518e3cec7a4e",
        "vertexId": 11,
        "digital_twin": 0,
        "digital_learning": 0,
        "f2f_learning": 100
      }
    ]
  }
  cardCourseCompleteStatus() {
    if (this.nmCompList.length > 0) {
      this.appSideNavList.forEach((el, i) => {
        var courseData = el;
        var updateData = [];
        courseData.childList.forEach(element => {
          let idx = this.nmCompList.findIndex(e => e.refID == element.refID);
          if (idx > -1) {
            var vertexId: any = Number(this.nmCompList[idx].id);
            var parentId: any = Number(this.nmCompList[idx].parentID);
            if (this.userNodeList["userNode"]) {
              let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == vertexId);
              if (idx > -1) {
                var status = true;
                element.courseList.forEach(ele => {
                  if (ele['type'] != 'dependency') {
                    if (status) {
                      status = this.userNodeList["userNode"][idx][ele['type']] == 100 ? true : false;
                    }
                  }
                });
                element['read'] = status;
                if (element['content'].length > 0) {
                  element['content'].forEach((ele, i) => {
                    element['content'][i]['read'] = true;
                  });
                }
                updateData.push(element);
              } else {
                updateData.push(element);
              }
              if (status) {
                const found = this.completedNodeList.some(el => el['id'] === vertexId);
                if (!found) {
                  let obj = { id: vertexId, profile: '#10c516' };
                  this.completedNodeList.push(obj);

                  const checkParentNodes = this.nmCompList.filter(el => el.parentID === parentId);
                  const userNodes = this.userNodeList["userNode"].map(el => el.vertexId);
                  const found = checkParentNodes.every(r => userNodes.includes(r.id));

                  if (found) {
                    const foundPrent = this.completedNodeList.some(el => el['id'] === parentId);
                    if (!foundPrent) {
                      let obj = { id: parentId, profile: '#10c516' };
                      this.completedNodeList.push(obj);
                    }
                  }

                }
              }
            }
          }
        });
        // console.log('updateData', updateData)
        if (updateData) {
          this.appSideNavList[i]['childList'] = updateData;
        }
        
        // console.log("menuList--", this.appSideNavList[i]);
      });
      this.getSelectedCourse(this.selectedNode);
      // if (this.appCourseAnimation == false) {
       this.updateNodeColor();
      // }
    }
  }

  updateNodeColor() {
    // if (this.appCourseAnimation == false) {
      this.broadcastInfo({ src: 'coursePanel', event: 'updateNodeColor', node: this.completedNodeList });
    // }
  }

  updateSelectedCourse() {
    this.broadcastInfo({ src: 'coursePanel', event: 'updateSelectedCourse', data: this.appSideNavList, selectedData: this.appNavList });
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  getCardwidth(e) {
    let rawElem = document.getElementById(e);
    let width = 0;
    if (rawElem != null) {
      width = Math.round(rawElem.offsetWidth / 1.5);
    }

    return width + 'px';
  }
  applicationUrl(e){
    if (e == 'idg') {
      this.applicationAnimation = true;
      this.appCourseAnimation = true;
      this.broadcastInfo({ src: 'coursePanel', event: 'updateIdgAnimationData', data: true, key: 'idg' });
    }
    if (e == 'idg-animation') {
      this.appCourseAnimation = true;
      this.broadcastInfo({ src: 'coursePanel', event: 'updateIdgAnimationData', data: true, key: 'idg-animation' });
    }
    // if (e == 'idg') {
    //   this.applicationAnimation = true;
    //   this.configService.appCourseAnimation = true;
    //   // this.broadcastInfo({ src: 'coursePanel', event: 'updateIdgAnimationData', data: true });
    //   // setTimeout(() => {
    //   //   this.broadcastInfo({ src: 'coursePanel', event: 'updateIdgAnimation', data: true });
    //   // }, 5000);
    // }
  }
}
