import * as uuid from 'uuid';

export class DemoAvatar {
  datauxview: any;
  dfx: any;
  avatar: any;
  communicationServ: any;
  broadcastSubs: any;

  constructor(datauxview, _communicationServ = null) {
    if (!this.datauxview) {
      this.datauxview = datauxview;
      this.communicationServ = _communicationServ;
      this.dfx = this.datauxview.getDatascape();
      this.serviceInit();
    }
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * 
  * method for communication service receive instance
  * * */
  serviceInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
      })
  }

  /* * 
   * method for avatar creation
   * * */
  async createAvatar(type, model = 'avatar', startpos = { x: 0, y: 0, z: 0 }, parent = null) {
    if (type === '_dummy_') {
      this.avatar = this.createDummyObject('cube', startpos, null);
    } else if (type.includes('.glb') || type.includes('.gltf')) {
      this.avatar = await this.loadObject(type, model, startpos, null);
    } else if (type['_isMesh']) {
      this.avatar = this.loadMesh(type, startpos = { x: 0, y: 0, z: 0 }, parent = null);
    }
    return new Promise(resolve => {
      resolve(this.avatar);
    })
  }

  /* * 
   * create dummy object
   * * */
  createDummyObject(shape, startpos, parent = null) {
    var id = 'dummy_' + uuid.v4();
    var elem = this.datauxview.getElementId(id);
    if (!elem) {
      var size = 0.1;
      let profiles = { transparent: { material: 'transparent' }, regular: { material: 'see_me_red' } };
      let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: startpos.x, y: startpos.y, z: startpos.z }, size: size } };
      this.datauxview.addElement(shape, id, profiles, settings);
      elem = this.datauxview.getElementId(id);
    }
    return elem;
  }

  /* *
   * load object from path
   * * */
  loadObject(path, model, startpos, parent = null) {
    let settings = { "path": path };
    let ship_objects = {
      "shapes": [
        {
          "path1": {
            "model": model,
            "material": "see_me_red",
            "size": 0.1
          }
        }
      ],
      "objects": [
        {
          "type": "static",
          "parent": "",
          "name": "ehfx_path_1",
          "model": "path1",
          "place": {
            "pos": {
              "x": 0,
              "y": 0.3,
              "z": 0
            },
            "rot": {
              "x": 0,
              "y": 0,
              "z": 0
            }
          }
        }
      ]
    }
    return new Promise((resolve, reject) => {
      this.dfx.addDynamicShapes(settings).then(() => {
        let shapes = ship_objects['shapes'];
        let position = { x: startpos.x, y: startpos.y, z: startpos.z };
        ship_objects['objects'][0]['place']['pos'] = position;
        let objects = ship_objects['objects'];
        this.datauxview._createModels(shapes, (boo) => {
          this.datauxview.addElements(objects, (elements) => {
            let name = elements[0].name;
            let elem = this.datauxview.getElementId(name);
            resolve(elem);
          }, (id, pointer) => { })
        }, (id, pointer) => { })
      });
    });
  }

  /* * 
   * load object from mesh
   * * */
  loadMesh(mesh, startpos, parent = null) {
    if (!mesh) return;
    mesh.position = startpos;
    return mesh;
  }

}
