import { rejects } from 'assert';
import { resolve } from 'dns';
import { Avatar } from '../services/avatar.service';
import { SmokeEffect } from '../services/effects/smoke.service';
import * as uuid from 'uuid';

declare var BABYLON;
export class GameService {
  datauxview: any;
  dfx: any;
  avatar: any;
  communicationServ: any;
  broadcastSubs: any;
  khaConfig
  scene
  mousestate = "up"
  message="";

  constructor(datauxview, _communicationServ = null, _khaConfig) {
    if (!this.datauxview) {
      this.datauxview = datauxview;
      this.communicationServ = _communicationServ;
      this.khaConfig = _khaConfig;
      this.dfx = this.datauxview.getDatascape();
      this.scene = this.dfx.getCamera().getScene();
      this.serviceInit();
      this.processHFX_slms();

      window.addEventListener('keydown', (event) => {
        this.handleKeyboardDownEvent(event);
      });

      window.addEventListener('keyup', (event) => {
        this.handleKeyboardUpEvent(event);
      });

      window.addEventListener('pointerdown', (event) => {
        this.mousestate = "down";
        if (event.target['nodeName'] === 'CANVAS') {
          this.onMouseDownN();
        }
      });

      window.addEventListener("pointerup", (event) => {
        if (this.mousestate === 'down') {
          if ((<HTMLInputElement>event.target).id == "renderCanvas") {
            if (event.which === 1 || event.button === 0) {
              setTimeout(() => {
                this.canvasPickedObject(event);
              }, 201)
            }
          }
        }
        this.onMouseUpN();
        this.mousestate = "up";
      });

      window.addEventListener("pointermove", (event) => {
        this.onMouseMoveN();
      });

    }
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * 
  * method for communication service receive instance
  * * */
  serviceInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if(data.src === 'gameServ'){
          if(data.event === 'user-role-assinged'){
            /** on user role assigned */
            this.message="Role assigned!"
            if(this.user_role==="Watcher"){
              this.game_paused=false;
              this.startPathAnimDemo();
            }else{
              this.startPathAnimDemo();
            }              
          }
          if(data.event === 'fire-pipe'){
            
            if(this.user_role!=="Watcher"){  
              this.message="PIPE:: "+ data['data'];            
              this.game_paused=false;
              this.startPathAnimDemo();
            }
          }
          if(data.event === 'game-complete'){          
              this.game_state="done"
              this.message="GAME:: "+ data['data'];
          }
          if(data.event === 'fire-putoff'){          
            this.putoffFire()
          }
          if(data.event === 'pipe'){
            this.pipes.push(data['data']);
          }
        }

      })
  }

  /**
   * KYS GAME MODE - START
   */
  aId
  cam
  cray
  gray
  fray
  last_valid_pos
  follow_target
  ft_start
  cray_bck
  fray_bck
  current_deck = "1";
  game_state = ""
  shipCompartment
  ship_doors = [];
  ship_hatches = [];
  game_paused=false;
  demo2_starts={
    "watcher":[0.0486,13.9747,-26.57],
    "ood":[-3.07,10,-102.0],
    "dpo":[-3.69,10,-86.51],
    "guest":[0.0486,13.9747,-26.57]
  }

  /**
   * initialization for game mode
   */
  initGameMode(obj=null) {
    if(obj&&obj.gameid){
      this.gameID=obj.gameid;
    }
    this.ft_start = this.dfx.vector3([0.0572, 13.9747, -125.3028]);
    if(this.gameID===2){
      this.message="Wait for others to join!"
      this.user_id='user_'+uuid.v4();
      this.game_paused=true;
      this.initSocket();
    }
    this.startPathAnimDemo();
  }

  processHFX_slms() {
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    hfx.scaling.z = -1;
    this.processShipMaterial();
    hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {
      // let mat = mesh.material ? mesh.material.name : "";
      // mesh.mid = mat;
      if (this.anim_mode) {
        mesh.layerMask = 0x10000000;
      }
      if (mesh.name.toLowerCase().includes('door') || mesh.name.toLowerCase().includes('hatch')) {
        let t = this.setDoorPivot(mesh);
        if (t === 'door') {
          this.ship_doors.push(mesh);
        } else {
          this.ship_hatches.push(mesh);
        }
      }
    })
  }

  processShipMaterial() {
    this.scene.materials.forEach((mat) => {
      if (mat.getClassName().includes("PBR")) {
        mat.specularIntensity = 0;
        mat.metallic = 0;
        mat.roughness = 1;
        mat.ambientColor = this.dfx.color3([1, 1, 1]);
      }
    })
  }

  /**
  * code to initialize the 
  */
   animStartState:any=false;
  async _startPathAnimDemo() {
    //this.openAllShipDoors();
    //this.openAllShipHatches();
    /* let avt = this.ctrlServ.getChildMeshEx('ehfx_path_1', 'avatar');
    avt.isPickable=false;
    if (this.ft_start) {
      avt.position = this.ft_start.clone();
    } */
    setTimeout(()=>{
      if(this.gameID===2){
        this.message="FIRE STATUS: 100%";
      }
      
    },2000)
    
    this.game_state = "ontask";
    let apos = this.ft_start; //avt.absolutePosition;

    if (!this.follow_target) {
      this.cam = this.dfx.getCamera();
      this.ft_start = apos.clone();
      let dummy = await new Avatar(this.datauxview, this.communicationServ).createAvatar('_dummy_', 'avatar', apos, 1, null);
      let avt2 = await new Avatar(this.datauxview, this.communicationServ).createAvatar('_dummy_', 'avatar', apos, 0.5, 'red');
      let mesh = this.dfx.getElementMesh(dummy);
      let mesh2 = this.dfx.getElementMesh(avt2);
      mesh.layerMask = 0x10000000;
      mesh2.layerMask = 0x10000000;
      mesh.isPickable = false;
      mesh2.isPickable = false;
      /**
       * apply Collision Detection
       * add below code to seperate method 
       */
      this.cray = this.dfx.attachRay({ handle: dummy, position: [0, 0, 0.6], direction: [0, 0, 1], length: 0.4, showray: !true });
      this.gray = this.dfx.attachRay({ handle: dummy, position: [0, -0.6, 0], direction: [0, -1, 0], length: 3, showray: !true });
      this.fray = this.dfx.attachRay({ handle: dummy, position: [0, -1.0, 0.75], direction: [0, 0, 1], length: 0.25, showray: !true });
      // collision detection for backward movement
      this.cray_bck = this.dfx.attachRay({ handle: dummy, position: [0, 0, -0.6], direction: [0, 0, -1], length: 0.4, showray: !true });
      this.fray_bck = this.dfx.attachRay({ handle: dummy, position: [0, -1.0, -0.75], direction: [0, 0, -1], length: 0.25, showray: !true });

      /* mesh.checkCollisions=true;
       mesh.ellipsoid = new BABYLON.Vector3(1, 1.5, 1);
       mesh.ellipsoidOffset = new BABYLON.Vector3(0, 1.5, 0);  */
      this.follow_target = dummy;
      this.dfx.setAsParent(dummy, [avt2]);
      this.dfx.modify(avt2, { geometry: { position: { x: 0, y: 20, z: 0 } } });
      //mesh.setParent(avt);
    }
    this.applyLayerMask_Ship();
    this.cam.fov = 25 * this.deg_rad;
    let mesh = this.dfx.getElementMesh(this.follow_target);


    //this.moveCameraTo(null,{target:apos,distance:10,yaw:0.001,pitch:0.001});

    //this.prepareVenueForAnim();
    this.attachPIPCamera();
    this.updateDeckMask("1");
    if (this.esmoke) {
      this.esmoke.setLayerMask()
    }
    let cam = this.cam;
    cam.targetX.value = apos.x;
    cam.targetY.value = apos.y;
    cam.targetZ.value = apos.z;
    cam.distance.value = 0.01;
    if(this.gameID!=2){
    cam.yaw_deg.value = 0.001;
    cam.pitch_deg.value = 0.001;
    }
    cam.minZ = 0.01;
    let ds = (5 / 60);
    let wait = 0
    let anim = () => {
      if(this.game_paused){
        this.aId = requestAnimationFrame(anim);
        return;
      }
      let stop = false;
      if (wait) {
        wait--
        if (wait < 0) {
          wait = 0;
        }
        this.aId = requestAnimationFrame(anim);
        return;
      }
      if (this.game_state != 'ontask') {
        this.loadNextTask();
        this.aId = requestAnimationFrame(anim);
        return
      }
      if (this.mo_state_d) {
        mesh.rotation.y = (cam.yaw_deg.value) * this.deg_rad;
        let yang = mesh.rotation.y;
        let _ds = ds * this.doubleClick;
        let dz = _ds * Math.cos(yang) * this.mo_state_d;
        let dx = _ds * Math.sin(yang) * this.mo_state_d;
        let ldz = Math.cos(yang + Math.PI) * this.mo_state_d;
        let ldx = Math.sin(yang + Math.PI) * this.mo_state_d;
        let gpick = this.dfx.pickWithRay({ ray: this.gray, predicate: null, fastcheck: !true });
        if (gpick && gpick.hit) {
          let name = gpick.pickedMesh.name;
          let y = gpick.pickedPoint.y
          let _y = y + 1.5
          mesh.position.y = _y;
          let dc = this.getPlayerCurrentDeck(name);
          if (dc != this.current_deck) {
            this.current_deck = dc;
            this.updateDeckMask(dc);
          }
        } else {
          mesh.position = this.last_valid_pos.clone();
          stop = true;
          wait = 30;
        }
        let cray = this.mo_state_d === -1 ? this.cray_bck : this.cray;
        let fray = this.mo_state_d === -1 ? this.fray_bck : this.fray;
        let pick = this.dfx.pickWithRay({ ray: cray, predicate: null, fastcheck: !true });
        let fpick = this.dfx.pickWithRay({ ray: fray, predicate: null, fastcheck: !true });
        if (pick && pick.hit) {
          let name = pick.pickedMesh.name.toLowerCase();
          if (name.includes("door") || name.includes("hatch")) {
          } else {
            stop = true;
          }
        }
        if (!stop) {
          if (fpick && fpick.hit) {
            let name = fpick.pickedMesh.name.toLowerCase();
            if (name.includes("door") || name.includes("hatch")) {
            } else {
              stop = true;
            }
          }
        }

        if (!stop) {
          let lp = mesh.position.clone();
          lp.x += ldx;
          lp.z += ldz;
          this.last_valid_pos = lp;
          mesh.position.x += dx;
          mesh.position.z += dz;
        }

        //mesh.moveWithCollisions(new BABYLON.Vector3(dx, 0, dz));   
      } else {
        if (this.isHatch) {
          let dv = this.isHatch.pcenter.subtract(mesh.position)
          let d = dv.length();
          if (d < 3.5) {
            mesh.position.x = this.isHatch.pcenter.x;
            mesh.position.z = this.isHatch.pcenter.z;
            mesh.position.y = this.isHatch.pcenter.y + (1 * Math.sign(dv.y));
            stop = true;
            this.isHatch = false;
            //return;
            console.log("CLIMB UP/DOWN --- HATCH", Math.sign(dv.y))
            let dc = this.getPlayerCurrentDeck();
            if (dc != this.current_deck) {
              this.current_deck = dc;
              this.updateDeckMask(dc);
            }
          }
        } else if (this.isTarget) {
          wait = 100;
        }
      }
      let apos = mesh.position;
      cam.targetX.value = apos.x;
      cam.targetY.value = apos.y;
      cam.targetZ.value = apos.z;
      cam.distance.value = 0.01;
      this.updatePIPCamera();
      this.aId = requestAnimationFrame(anim);
    }
    setTimeout(() => {
      /* this.dfx.attachCameraToTarget(this.follow_target, { radius: 0.00001, heightOffset: 0.0000001, rotationOffset: 0.00001, cameraAcceleration: 0.05 });
      this.scene._activeCamera.heightOffset = 0;
      this.scene._activeCamera.rotationOffset = 0;
      this.scene._activeCamera.minZ = 0.1
      setTimeout(() => {
        this.scene.animationGroups.forEach((anim) => {
          anim.start(false, 0.25)
        })
      }, 1000) */

      this.aId = requestAnimationFrame(anim);
      this.animStartState=false;
    }, 1500)
  }

  mo_state_d = 0;
  deg_rad = Math.PI / 180;
  isHatch;
  isTarget
  doubleClick = 0;
  dc_timer
  sw_timer
  lm_default = 0x0FFFFFFF

  onMouseDownN(d = 1) {
    this.swapActiveCams();
    this.sw_timer = setTimeout(() => {
      this.swapActiveCams();
    }, 200)
    this.isHatch = null;
    this.isTarget = null;
    this.info_detail = "";
    clearTimeout(this.dc_timer);
    this.doubleClick += 1;
    this.mo_state_d = this.info_mode ? 0 : d;
  }
  onMouseMoveN() {
    if (!this.mo_state_d) {
      return;
    }
  }
  onMouseUpN() {
    this.dc_timer = setTimeout(() => {
      this.doubleClick = 0;
    }, 200)
    if (this.anim_mode && this.mo_state_d) {
      let pick = this.dfx.getHitPosition();
      if (pick && pick.pickedMesh) {
        let name = pick.pickedMesh.name;
        this.checkIfTaskTarget(name);
        if (pick.pickedMesh.doortype && pick.pickedMesh.doortype == 'hatch') {
          this.isHatch = pick.pickedMesh;
        }
      }
    }
    this.mo_state_d = 0;
  }

  /* * 
  * method for keydown event
  * * */
  handleKeyboardDownEvent(event: KeyboardEvent) {
    const key = event.key.toString().toLowerCase();
    if (event.shiftKey && key === 't') {
    }
    if (key === 'f') {
      this.forward();
    }
    if (key === 'b') {
      this.backward();
    }
    if (key === 'l') {
      this.turnLeft();
    }
    if (key === 'r') {
      this.turnRight();
    }
    if (key === 'u') {
      this.lookUp();
    }
    if (key === 'd') {
      this.lookDown();
    }
    if (key === 'h') {
      this.restartAvatar();
    }
  }

  /* * 
  * method for keyup event
  * * */
  handleKeyboardUpEvent(event: KeyboardEvent) {
    const key = event.key.toString().toLowerCase();
    if (event.shiftKey && key === 't') {
    }
    if (key === 'f') {
      this.onMouseUpN();
    }
    if (key === 'b') {
      this.onMouseUpN();
    }
    if (key === 'l') {
      this.onMouseUpN();
    }
    if (key === 'r') {
      this.onMouseUpN();
    }
    if (key === 'u') {
      this.onMouseUpN();
    }
    if (key === 'd') {
      this.onMouseUpN();
    }
  }

  forward() {
    this.onMouseDownN(1);
  }
  backward() {
    this.onMouseDownN(-1);
  }
  nav_speed = 6;
  turnLeft() {
    let cam = this.cam;
    cam.yaw_deg.value -= 0.1 * this.nav_speed
  }
  turnRight() {
    let cam = this.cam;
    cam.yaw_deg.value += 0.1 * this.nav_speed
  }
  lookDown() {
    this.cam.pitch_deg.value += 0.1 * this.nav_speed
    if (this.cam.pitch_deg.value > 45) {
      this.cam.pitch_deg.value = 45
    }
  }
  lookUp() {
    this.cam.pitch_deg.value -= 0.1 * this.nav_speed
    if (this.cam.pitch_deg.value < -45) {
      this.cam.pitch_deg.value = -45
    }
  }
  restartAvatar() {
    let mesh = this.dfx.getElementMesh(this.follow_target);
    if (mesh) {
      mesh.position = this.ft_start.clone();
    }
    if (this.cam) {
      this.cam.yaw_deg.value = 0.001;
      this.cam.pitch_deg.value = 0.001;
    }
    this.current_deck = "1";
    this.updateDeckMask("1");
  }
  respawnAvatar() {
    let mesh = this.dfx.getElementMesh(this.follow_target);
    mesh.position = this.last_valid_pos.clone();
  }
  getPlayerCurrentDeck(name = null) {
    let dc = null
    if (!name) {
      let gpick = this.dfx.pickWithRay({ ray: this.gray, predicate: null, fastcheck: !true });
      if (gpick && gpick.hit) {
        name = gpick.pickedMesh.name;
      }
    }
    if (name) {
      let arr = name.split(".");
      let sdc = arr[arr.length - 2];
      if (sdc && sdc.includes('deck_')) {
        dc = sdc.split("deck_")[1]
      }
    }
    return dc
  }
  loadNextTask(reset = true) {
    if (this.game_state === 'done') {
      this.khaConfig.removeCompartments();
      return
    }
    this.game_state = "ontask";
    this.task_index++;
    /* if(this.task_index>=this.taskObj.length){
      this.game_state="done";
    } */
    let ctask = Object.assign({}, this.current_task);
    this.current_task = this.taskObj[this.task_index];
    let c = this.current_task.compartment;
    this.shipCompartment = { code: c, name: this.current_task.task };

    if (ctask && ctask.compartment && ctask.compartment != this.current_task.compartment) {
      //let ct = ctask.compartment;
      this.khaConfig.removeCompartments();
      this.khaConfig.loadCompartment(c, (oarr) => {
        let obj = oarr[0];
        let el = this.datauxview.getElementId(obj.name);
        let mesh = this.dfx.getElementMesh(el).getChildren()[0];
        if (mesh) {
          let meshes = mesh.getChildren();
          meshes.forEach((_m) => {
            _m.layerMask = 0x10000000;
          })
        }
      }, false)
    }
  }
  pipCam
  createPIPCamera() {
    let pipCamera /* = new BABYLON.FreeCamera("pipCamera", new BABYLON.Vector3(0,50,0), this.scene);
    pipCamera.setTarget(BABYLON.Vector3.Zero()); */
    pipCamera = new BABYLON.TransitionCamera(
      "pipCamera", this.scene,
      0.001, 90,
      this.cam.minDistance, this.cam.maxDistance,
      1500, this.cam.GetTargetPos(),
      this.cam.fov, 0, 0
    );
    let engine = this.scene.getEngine();
    let ar = engine.getAspectRatio(this.cam);
    let pipW = (ar < 1) ? 0.3 : 0.3 * (1 / ar);
    let pipH = (ar < 1) ? 0.3 * ar : 0.3;
    let pipX = 0;//1 - pipW;
    let pipY = 1 - pipH;
    pipCamera.viewport = new BABYLON.Viewport(pipX, pipY, pipW, pipH);

    pipCamera.layerMask = 0x10000000;
    this.pipCam = pipCamera;
    this.scene.activeCameras.push(this.cam);
    this.scene.activeCameras.push(pipCamera);
  }
  attachPIPCamera() {
    if (this.pipCam) {
      this.scene.activeCameras.push(this.cam);
      this.scene.activeCameras.push(this.pipCam);
      this.updatePIPCamera();
    } else {
      this.createPIPCamera();
    }
  }
  removePIPCamera() {
    //this.scene.activeCameras.pop();
    this.scene.activeCameras = [];
    this.scene.activeCamera = this.cam;
  }
  updatePIPCamera() {
    if (!this.pipCam) {
      return;
    }
    this.pipCam.targetX.value = this.cam.targetX.value;
    //this.pipCam.targetY.value = this.cam.targetY.value;
    this.pipCam.targetZ.value = this.cam.targetZ.value;
  }
  swapActiveCams() {
    if (!this.anim_mode) {
      return
    }
    if (!this.scene.activeCameras.length) {
      return
    }
    let a = this.scene.activeCameras[0];
    this.scene.activeCameras[0] = this.scene.activeCameras[1];
    this.scene.activeCameras[1] = a;
  }
  updateDeckMask(dc, m = null) {
    this.khaConfig.applyLayermask_Decks(dc, m);
  }
  anim_mode = false;
  info_mode = false;
  info_detail = "";
  startPathAnimDemo() {
    //this.getTask();
    this.anim_mode = true;
    if(this.gameID===2&&this.game_paused){
      this.addProblemForTask(() => {});
      return
    }
    
    let c = this.current_task.compartment;
    this.shipCompartment = { code: c, name: this.current_task.task }
    if (this.khaConfig.compartments_store[c]) {
      this.khaConfig.showCompartments(c);
      this.khaConfig.showHideCompartmentWall([c], false);      
      this.addProblemForTask(() => this._startPathAnimDemo());
    } else {
      this.khaConfig.clear_compartments = false;
      this.khaConfig.loadCompartment(c, (oarr) => {
        let obj = oarr[0];
        let el = this.datauxview.getElementId(obj.name);
        let mesh = this.dfx.getElementMesh(el).getChildren()[0];
        if (mesh) {
          let meshes = mesh.getChildren();
          meshes.forEach((_m) => {
            _m.layerMask = 0x10000000;
          })
          /* let r=this.rand(0,meshes.length-1);
          let cp=meshes[r];
          let _name=this.ctrlServ.getMeshName(cp.name);
          let name=this.getCompName(_name);
          this.current_task.component=name;
          this.shipCompartment={code:c,name:name} */
        }        
        this.addProblemForTask(() => this._startPathAnimDemo());
      }, false)
    }
  }
  stopPathAnimDemo() {
    this.anim_mode = false;
    //this.dfx.detachCameraFromTarget();  
    cancelAnimationFrame(this.aId);
    this.restartAvatar();
    if(this.cam){
      this.cam.fov = 35 * this.deg_rad;
    }
    
    this.khaConfig.clear_compartments = true;
    this.khaConfig.hideAllCompartments();
    this.applyLayerMask_Ship(this.lm_default);
    if(this.cam){
      this.cam.layerMask = this.lm_default;
    }
    
    /* this.scene.animationGroups.forEach((anim) => {
      anim.reset();
      anim.stop();
    })
    this.scene.stopAllAnimations(); */
    this.removePIPCamera();
    this.resetVenue();
    // this.onChangeCameraPosition('home');
    this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'home', sub: null });
    this.current_deck = null;
    this.current_task = { compartment: null, component: null, task: null, hint: '' }
    this.task_index = 0;
    this.info_detail = "";
    this.shipCompartment = null;
    this.score = 0;
    this.attempt = 1;
    this.sel_ext_class = "";
    this.ext_tested = false;
    this.inventory_on = 0;
    this.inventory_state = [false, false,false];
    this.inventory_view_state = [false, false,false];
    this.inventory_view_state = [];
    this.class_select_on = false;
    this.class_select_state = [false, false, false];
    this.game_state = ""
    this.updateDeckMask("1", this.lm_default);
  }
  setDoorPivot(m) {
    let bb = m.getBoundingInfo().boundingBox;
    let v = m.getBoundingInfo().boundingSphere.center.clone();
    m.pcenter = m.getBoundingInfo().boundingSphere.centerWorld.clone();
    let dx = bb.maximum.x - bb.minimum.x;
    let dz = bb.maximum.z - bb.minimum.z;
    let dy = bb.maximum.y - bb.minimum.y;
    let type = 'door';
    if (dy < 0.3) {
      type = 'hatch';
    }
    let s = type == 'door' ? 1 : -1
    if (Math.abs(dx) > Math.abs(dz)) {
      v.x = v.x + (s * dx / 2)
    } else {
      v.z = v.z + (s * dz / 2)
    }
    m.rotationQuaternion = null;
    m.doortype = type;

    m.setPivotPoint(v);
    return type;
  }
  openAllShipDoors() {
    this.ship_doors.forEach((door) => {
      door.rotation.y = Math.PI / 2
    })
  }
  openAllShipHatches() {
    this.ship_hatches.forEach((door) => {
      door.rotation.x = -Math.PI / 2
    })
  }
  getCompName(n) {
    let s = n.split("+");
    return s[1] ? s[1] : s[0];
  }
  current_task = { compartment: null, component: null, task: null, hint: '' }
  taskObj = null
  task_index = 0;
  tourTask() {
    return [
      { compartment: '1DZ0', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Step into Lobby in front of CCR door' }
      , { compartment: '1DB', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Go through the CCR and CER' }
      , { compartment: '01DZ2', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Go up the Ladder to 01 Deck Bridge Flats' }
      , { compartment: '01DA', component: 'Shincom', task: 'SHINCOM and Sound Powered Terminals', hint: 'Enter the Bridge' }
      , { compartment: '2HZ0', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Walk Aft to Tech Office Flats' }
      , { compartment: '2CZ', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Walk Forward up the Stbd side to the 3 Mess lobby' }
      , { compartment: '2GZ1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Enter the AFFF Compartment' }
      , { compartment: '2GZ1', component: 'AFFF_-_TAU_-_Range_Guard', task: 'AFFF System', hint: 'Enter the AFFF Compartment' }
      , { compartment: '2KA2', component: 'CO2_and_Halon', task: 'Pull station', hint: 'Walk Aft to the Flats outside the after SIS' }
      , { compartment: '2KA2', component: 'Firemain', task: 'Firemain', hint: 'Walk Aft to the Flats outside the after SIS' }
      , { compartment: '2KZ1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
      , { compartment: '2KB1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
      , { compartment: '2KZ3', component: 'AFFF_-_TAU_-_Range_Guard', task: 'TAU System', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
      , { compartment: '2LA0', component: 'Firemain', task: 'Firemain', hint: 'Return to After Section Base Flats' }
      , { compartment: '2LY0', component: 'Helicopter_Hauldown_and_Status_Indicating', task: 'RAST', hint: 'Walk Aft to the Quarterdeck Lobby' }
      , { compartment: '2MZ2', component: 'AFFF_-_TAU_-_Range_Guard', task: 'TAU unit', hint: 'Walk Aft to the Quarterdeck Lobby' }
      , { compartment: 'ZK-M', component: 'Shincom', task: 'Quarterdeck', hint: 'Go up the ladder to Quarterdeck' }
      , { compartment: '1JZ2', component: 'Shincom', task: 'SHINCOM Terminal', hint: 'Walk Forward to Hangar Face' }
      , { compartment: '1JA2', component: 'AFFF_-_TAU_-_Range_Guard', task: 'AFFF Hydrants', hint: 'Step into the Port Hangar Lobby' }
      , { compartment: '1JA1', component: 'Magazine_Sprinkling_and_Fine_Water_Spray', task: 'CIWS Mag Spray Valves', hint: 'Cross Over to the Stbd Hangar Lobby' }
    ]
  }
  fireFightTask() {
    return [
      { compartment: '2LA0', component: 'Portable_Firefighting', task: 'Aft DC LOBBY', hint: 'Pick fire fighting equipments to put out "Class B" fire' },
      { compartment: '2MA0', component: 'Ladders', task: 'Goto Quarterdeck', hint: 'Click the ladder and then hatch to climb up' },
      { compartment: 'ZK-M', component: 'Hull_Equipment', task: 'Attack fire', hint: 'Click over object where you see smoke' }
    ]
  }
  taskID = "";
  gameID=1
  getTask(t = "tour") {
    if (this.taskID != t) {
      this.taskID = t;
      if (t == 'tour') {
        this.taskObj = this.tourTask();
      }
      if (t == 'demo2') {
        this.taskObj = this.fireFightTask();
      }
      /* this.taskObj = [
        {compartment:'5E',component:'motor_driven_fire',task:'Motor driven pump',hint:'FAMR (stbd side after corner)'},
        {compartment:'5H',component:'motor_driven_fire',task:'Motor driven pump',hint:'after AAMR (port side fwd corner)'},
        {compartment:'4LA0',component:'diesel_driven_fire',task:'Diesel driven pump',hint:'#3 general stores'}
      ] */
      /* this.taskObj = [
        //First_Aid_Cabinet_and_stowage
        AFFF_-_TAU_-_Range_Guard
        {compartment:'1JA0',component:'First_Aid_Cabinet_and_stowage',task:'First Aid Locker',hint:'Hangar'}
      ] */
      /* this.taskObj = [
        { compartment: '1DZ0', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Step into Lobby in front of CCR door' }
        , { compartment: '1DB', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Go through the CCR and CER' }
        , { compartment: '01DZ2', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Go up the Ladder to 01 Deck Bridge Flats' }
        , { compartment: '01DA', component: 'Shincom', task: 'SHINCOM and Sound Powered Terminals', hint: 'Enter the Bridge' }
        , { compartment: '2HZ0', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Walk Aft to Tech Office Flats' }
        , { compartment: '2CZ', component: 'CO2_and_Halon', task: 'Halon bottles/pull station', hint: 'Walk Forward up the Stbd side to the 3 Mess lobby' }
        , { compartment: '2GZ1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Enter the AFFF Compartment' }
        , { compartment: '2GZ1', component: 'AFFF_-_TAU_-_Range_Guard', task: 'AFFF System', hint: 'Enter the AFFF Compartment' }
        , { compartment: '2KA2', component: 'CO2_and_Halon', task: 'Pull station', hint: 'Walk Aft to the Flats outside the after SIS' }
        , { compartment: '2KA2', component: 'Firemain', task: 'Firemain', hint: 'Walk Aft to the Flats outside the after SIS' }
        , { compartment: '2KZ1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
        , { compartment: '2KB1', component: 'CO2_and_Halon', task: 'Halon bottles', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
        , { compartment: '2KZ3', component: 'AFFF_-_TAU_-_Range_Guard', task: 'TAU System', hint: 'Walk Aft to After Section Base Flats, then forward up the stbd side to the LSO Lobby' }
        , { compartment: '2LA0', component: 'Firemain', task: 'Firemain', hint: 'Return to After Section Base Flats' }
        , { compartment: '2LY0', component: 'Helicopter_Hauldown_and_Status_Indicating', task: 'RAST', hint: 'Walk Aft to the Quarterdeck Lobby' }
        , { compartment: '2MZ2', component: 'AFFF_-_TAU_-_Range_Guard', task: 'TAU unit', hint: 'Walk Aft to the Quarterdeck Lobby' }
        , { compartment: '2MA0', component: 'Hatches', task: 'Quarterdeck', hint: 'Go up the ladder to Quarterdeck' }
        , { compartment: '1JZ2', component: 'Shincom', task: 'SHINCOM Terminal', hint: 'Walk Forward to Hangar Face' }
        , { compartment: '1JA2', component: 'AFFF_-_TAU_-_Range_Guard', task: 'AFFF Hydrants', hint: 'Step into the Port Hangar Lobby' }
        , { compartment: '1JA1', component: 'Magazine_Sprinkling_and_Fine_Water_Spray', task: 'CIWS Mag Spray Valves', hint: 'Cross Over to the Stbd Hangar Lobby' }
      ] */
    }
    this.current_task = this.taskObj[this.task_index];
  }
  checkIfTaskTarget(name) {
    let t = this.getMeshName(name);
    let cmp = t.includes(this.current_task.compartment)
    t = this.getCompName(t);
    this.info_detail = t;
    if (cmp && t.includes(this.current_task.component)) {
      this.isTarget = true;
    }
    if (this.isTarget) {
      this.isTarget = null;
      let task_state = 1


      if (this.task_index == this.taskObj.length - 1) {
        if (this.taskID === "demo2") {
          if (this.task_complete.includes(this.sel_ext_class)) {
            if (this.attempt === 1) {
              this.score += this.score_inc;
            }
            if(this.taskID === 'demo2' && this.gameID==2){
              if(this.game_state != "done"){
                this.game_state = "done";
                this.info_detail = "STEP " + (this.task_index + 1) + " OF " + this.taskObj.length + " COMPLETED!";
                this.sendToAllPlayers({event:"fire-putoff"});
                /* this.esmoke.putoffStep((msg,p)=>{
                  this.message="FIRE STATUS: "+p
                  if(msg==='done'){
                    this.sendToAllPlayers({event:"game-complete"});
                  }
                }); */
              }
            }else{
              this.game_state = "done";
              this.info_detail = "TASK COMPLETED!";
              if (this.taskID === 'demo2') {
                this.esmoke.putoff();
              }
            }
            

          } else {
            this.attempt++;
            this.info_detail = "WRONG EXTINGUISHER SELECTED FOR 'CLASS B' FIRE";
          }
        } else {
          if (this.attempt === 1) {
            this.score += this.score_inc;
          }
          this.game_state = "done";
          this.info_detail = "TASK COMPLETED!";
          if (this.taskID === 'demo2') {
            this.esmoke.putoff();
          }
        }

      } else {
        this.game_state = "complete";
        this.info_detail = "STEP " + (this.task_index + 1) + " OF " + this.taskObj.length + " COMPLETED!";
        this.score += this.score_inc;
        if (this.taskID === "demo2") {
          if (this.task_index === 0) {
            this.inventory_on = 1;
            this.inventory_view_state[0]=true;
            this.inventory_view_state[1]=true;
          }
        }
        if (this.taskID === "demo2") {
          if (this.task_index === 1) {
            this.info_detail = "click"
          }
        }
      }
    }
  }

  rand(min, max) {
    return (Math.floor(Math.pow(10, 14) * Math.random() * Math.random()) % (max - min + 1)) + min;
  }

  applyLayerMask_Ship(m = 0x10000000) {
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {
      mesh.layerMask = m;
    })
    if(this.cam){
      if (m === this.lm_default) {
        this.cam.layerMask = m;
      } else {
        this.cam.layerMask = 0x30000000
      }
    }

  }

  venue_state: any = {};
  prepareVenueForAnim() {
    this.venue_state = { filter_deck: this.khaConfig.filter_deck, ods: this.khaConfig.otherDecks }
    this.khaConfig.filter_deck = ['02','01','1','M','2','3','4','5'];
    this.khaConfig.otherDecks = 'solid';
    this.khaConfig.applyDisplayRuleFor_Deck(true);
  }
  resetVenue() {
    this.khaConfig.filter_deck = this.venue_state.filter_deck;
    this.khaConfig.otherDecks = this.venue_state.ods;
    this.khaConfig.applyDisplayRuleFor_Deck(true);
  }

  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    if (arr[arr.length - 1].indexOf('00') === 0) {
      return arr[arr.length - 2] + "." + arr[arr.length - 1]
    }
    return arr[arr.length - 1];
  }

  canvasPickedObject(e) {
    if (this.anim_mode && this.info_mode) {
      this.swapActiveCams();
    }
    const hit = this.dfx.getHitPosition();
    let mesh = hit.pickedMesh;
    if (mesh != null && this.doubleClick === 0) {
      let str = mesh.name;
      let narr = str.split(".");
      let nstr = narr[narr.length - 1];
      let a = nstr.split("+")
      let name = a[1] ? (a[1] + " (" + (a[0].split("_")[1]) + ")") : a[0];
      console.log("nstr ", nstr);
      this.khaConfig.selectedTagMesh = nstr;
      delete this.selectedKhaSystems;
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
      }
      this.getSelectedKhaSystemsLabel(mesh, a);
      this.khaConfig.createMatObject('cube', hit, name);
    } else {
      this.selectedKhaSystems = null
      this.khaConfig.showMatObjectTag(mesh, false);
    }
    if (this.anim_mode && this.info_mode) {
      this.swapActiveCams()
    }
  }

  selectedKhaSystems: any;
  materialmap: any = {}
  getSelectedKhaSystemsLabel(e, a) {
    this.selectedKhaSystems = { color: "#00000000" };
    let code = a[1] ? ((a[0].split("_")[1])) : "--";
    let desc = a[1] ? (a[1]) : a[0];
    let mat = e.mid;
    this.selectedKhaSystems.id = e['id'];
    this.selectedKhaSystems.code = code;
    this.selectedKhaSystems.desc = desc;

    if (this.materialmap[mat]) {
      this.selectedKhaSystems.color = this.materialmap[mat]
    }
    // if (this.statusMode) {
    //   let colors = { "red": "#ff0000", "yellow": "#ffff00", "transparent": "#ababb2" };
    //   let statusColor = this.getStatusColorCode(e['id']);
    //   if (statusColor) {
    //     this.selectedStatus = statusColor;
    //     let color = colors[statusColor];
    //     this.materialmap[mat] = color;
    //     this.selectedKhaSystems.color = color;
    //   }
    // } else {
    if (mat && !this.materialmap[mat]) {
      let m = this.scene.getMaterialByName(mat);
      let c = m.albedoColor;
      let r = Math.round(c.r * 255);
      let g = Math.round(c.g * 255);
      let b = Math.round(c.b * 255);
      let hex = this.rgbToHex(r, g, b);
      this.materialmap[mat] = hex;
      this.selectedKhaSystems.color = hex;
    }
    // }
  }

  private componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  getStatusColorCode(id) {
    let material;
    for (let mat of Object.keys(this.khaConfig.systemSubComps)) {
      let comps = this.khaConfig.systemSubComps[mat];
      const found = comps.some(m => m.id === id);
      if (found) {
        material = mat;
        break;
      }
    }
    return material;
  }

  /**
   * KYS GAME MODE - END
   */

  /* * *
  * controls keys for game mode start
  * * */
  lookupaid
  lookdownaid
  turnleftaid
  turnrightaid
  startLookUp() {
    let anim = () => {
      this.lookUp();
      this.lookupaid = requestAnimationFrame(anim);
    }
    this.lookupaid = requestAnimationFrame(anim);
  }

  stopLookUp() {
    cancelAnimationFrame(this.lookupaid);
  }

  startLookDown() {
    let anim = () => {
      this.lookDown();
      this.lookdownaid = requestAnimationFrame(anim);
    }
    this.lookdownaid = requestAnimationFrame(anim);
  }

  stopLookDown() {
    cancelAnimationFrame(this.lookdownaid);
  }

  startTurnLeft() {
    let anim = () => {
      this.turnLeft();
      this.turnleftaid = requestAnimationFrame(anim);
    }
    this.turnleftaid = requestAnimationFrame(anim);
  }

  stopTurnLeft() {
    cancelAnimationFrame(this.turnleftaid);
  }

  startTurnRight() {
    let anim = () => {
      this.turnRight();
      this.turnrightaid = requestAnimationFrame(anim);
    }
    this.turnrightaid = requestAnimationFrame(anim);
  }

  stopTurnRight() {
    cancelAnimationFrame(this.turnrightaid);
  }
  /* * *
  * controls keys for game mode end
  * * */


  run() {
  }

  jump() {
  }

  climbUP() {
  }

  climbDown() {
  }

  pick() {
  }

  drop() {
  }

  die() {
  }

  respawn() {
  }

  gotoPosition() {
  }

  increaseSpeed() {
  }

  decreaseSpeed() {
  }

  reset() {
  }

  start() {
  }

  stop() {
  }

  pause() {
  }

  clear() {
  }

  win() {
  }

  lose() {
  }

  warn() {
  }
  /**
   * tasks
   */
  esmoke
  addSmoke(loc=[-2.6, 11.65, -3.11],cbck=null) {
    this.esmoke = new SmokeEffect(loc, this.scene);
    this.esmoke.addSmokeEffect(()=>{
      if(cbck){
        cbck()
      }
    })
  }
  addProblemForTask(cbck) {
    this.prepareVenueForAnim();
    if (this.taskID == 'tour') {
      cbck();
      return
    }    
    if(this.gameID===2){      
      if(this.game_state === ''){
        this.game_state = "intro";
        return                     
      }
      if(this.game_state==='intro'&&this.user_role){
        this.ft_start=this.dfx.vector3(this.demo2_starts[this.user_role.toLowerCase()]);   
        let yaw=0.001
        if(this.user_role!='Watcher'){
          yaw=90
        }     
        let view = { "target": { "x": this.ft_start.x, "y": this.ft_start.y, "z": this.ft_start.z }, "distance": 0.01, "yaw": yaw, "pitch": 0.001 }
        this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'custom', data: view });       
        
      }
      if(this.game_state==='intro'&&!this.game_paused){ 
        if(this.user_role==='Watcher'){  
        this.inventory_on = 1;
        this.inventory_view_state[2]=true;
        }
        this.addSmoke(undefined, ()=>{
          let c = this.current_task.compartment;
          this.shipCompartment = { code: c, name: this.current_task.task }
          cbck() 
        });      
                 
      }
      return;
    }
    this.addSmoke();
    this.game_state = "intro";
    this.shipCompartment = { code: "FIRE, FIRE", name: "FIRE in the Quarterdeck!" }
    let view = { "target": { "x": this.esmoke.pemit.position.x, "y": this.esmoke.pemit.position.y, "z": this.esmoke.pemit.position.z }, "distance": 30 }

    this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'custom', data: view });
    setTimeout(() => {
      let view = { "target": { "x": this.ft_start.x, "y": this.ft_start.y, "z": this.ft_start.z }, "distance": 0.01, "yaw": 0.001, "pitch": 0.001 }
      this.broadcastInfo({ src: 'gameServ', event: 'onChangeCameraPosition', key: 'custom', data: view });
      setTimeout(() => {
        let c = this.current_task.compartment;
        this.shipCompartment = { code: c, name: this.current_task.task }
        cbck()
      }, 2000)
    }, 10000)

  }

  onInventory(id) {
    if (id == 1) {
      if (!this.inventory_state[0]) {
        this.inventory_state[0] = true;
        this.score += this.score_inc;
      }
    }
    if (id == 2) {
      this.class_select_on = !this.class_select_on
      //this.inventory_state[1]=true;
      if (this.sel_ext_class && !this.ext_tested) {
        //this.inventory_state[1]=true;
        this.testFExt();
      }
    }
    if(id===3){
      if (!this.inventory_state[2]) {
        this.inventory_state[2] = true;
        this.score += this.score_inc;
        this.sendToAllPlayers(this.sendToAllPlayers({event:'fire-pipe',userid:this.user_id,message:"FIRE, Fire, FIRE in the Quarterdeck!"}))
      }
    }
  }
  score = 0
  task_complete = ["B", "C"];
  attempt = 1;
  sel_ext_class = "";
  ext_tested = false;
  score_inc = 5;
  inventory_on = 0;
  inventory_state = [false, false,false];
  inventory_view_state = [];
  class_select_on = false;
  class_select_state = [false, false, false];
  onClassSelect(id) {
    let arr = ["A", "B", "C"];
    this.sel_ext_class = arr[id - 1];
    this.class_select_state = [false, false, false];
    this.class_select_state[id - 1] = true;
  }
  testFExt() {
    this.ext_tested = !false;
    this.inventory_state[1] = true;
    this.score += this.score_inc;
  }
  putoffFire(){
    this.esmoke.putoffStep((msg,p)=>{
      this.message="FIRE STATUS: "+p
      if(msg==='done'){
        this.sendToAllPlayers({event:"game-complete"});
      }
    });
  }
  /**
   * multi-player
   * socket communication
   */
  socket
  pipes=[]
  userroles=["OOD","DPO","Watcher"]
  initSocket(){
    this.socket=this.communicationServ.getSocketInstance();
    this.socket.on('connect', (data)=>{
      this.onConnect(data);

    });
    this.socket.on('receiveMsg', (data)=>{
      this.onReceiveMsg(data)
    });
    this.socket.on('disconnect', (data)=>{
      this.onDisconnect(data);
    });
    this.socket.on('connect_error', (data)=>{
      this.onError(data);
    });
    this.socket.on('reconnect_error', (data)=>{
      this.onError(data);
    });
  }
  onConnect(evt) {
    this.connectInfo[this.user_id]=Date.now();
    this.sendToAllPlayers({event:'handshake',userid:this.user_id})
    this.writeToScreen("CONNECTED",evt);
  }

  onDisconnect(evt) {
    this.writeToScreen("DISCONNECTED");
  }

  user_id
  user_role
  players_total=3;
  mp=1;
  es=true;
  qrole
  wfr=false;
  ping='';
  connectInfo={};
  onReceiveMsg(data) {
    if(!data){
      return
    }
    let event=data.event;
    let userid=data.userid;  
    let userrole = data.userrole;  
      if(event === 'handshake'){
        if(this.user_id!==userid){          
          this.connectInfo[userid]=this.userroles[this.mp]||'guest';
          this.mp++;
          if(this.mp === this.players_total){

            this.user_role=this.userroles[0];
            this.sendToAllPlayers({event:'assign-role',userid:this.user_id,info:this.connectInfo})
            this.broadcastInfo({ src: 'gameServ', event: 'user-role-assinged', key: '', sub: null })
          } else if(this.mp > this.players_total){
            this.sendToAllPlayers({event:'assign-role',userid:this.user_id,info:this.connectInfo})
          }
        }
      }
      if(event === 'get-role'){
        if(this.user_id!==userid){
          this.ping=event;
          this.sendToAllPlayers({event:'on-get-role',userid:this.user_id,userrole:this.user_role})
        }
      }
      if(event === 'fire-pipe'){  
        if(this.user_id!==userid){      
          this.ping=event;
          this.broadcastInfo({ src: 'gameServ', event: 'fire-pipe', data:data.message })   
        }   
      }
      if(event === 'game-complete'){
        this.ping=event;
          this.broadcastInfo({ src: 'gameServ', event: 'game-complete', data:"TASK COMPLETED!" })  
      }
      if(event === 'fire-putoff'){
        this.ping=event;
          this.broadcastInfo({ src: 'gameServ', event: 'fire-putoff', data:"" })  
      }
      if(event === 'assign-role'){
        if((this.user_id!==userid)&&!this.user_role){          
          this.user_role = data.info[this.user_id];
          this.broadcastInfo({ src: 'gameServ', event: 'user-role-assinged', key: '', sub: null }) 
        }
      }
      if(event === 'on-get-role'){
        if(this.user_id!==userid){
         /*  this.ping=event;
          this.mp++;
          if(data.userrole === this.qrole){
            this.es=false;
          }
          if(this.mp === this.players_total){
            if(this.es){
              this.user_role = this.qrole;
              this.broadcastInfo({ src: 'gameServ', event: 'user-role-assinged', key: '', sub: null })
            }
          } */
        }
      }
    
  }

  onError(message) {
    this.writeToScreen(message);
  }

  sendToAllPlayers(message) {
    this.socket.emit('ackCmd', message);
  }

  writeToScreen(...message) {
    console.log(...message);
  }

}
