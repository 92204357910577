import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { ControllerService } from '../services/controller.service';

@Component({
  selector: 'app-quizpanel',
  templateUrl: './quizpanel.component.html',
  styleUrls: ['./quizpanel.component.scss']
})
export class QuizpanelComponent implements OnInit {
  @Input() shipComps: any = [];
  componentName: any = 'Panel';
  showAnswerStatus: any = 'default';
  hide_wrong_choice: boolean = false;
  broadcastSubs
  current = 0;
  prev = -1;
  answerStatus = false;
  quizCorrectPopup: boolean = false;
  quizWrongPopup: boolean = false;
  wrongAnswerMessage: any = "";
  quizList: any = [
    { question: '', answer: '', answerStatus: false, position: { x: 0, y: 0, z: 0 } },
    { question: '', answer: '', answerStatus: false, position: { x: 0, y: 0, z: 0 } },
    { question: '', answer: '', answerStatus: false, position: { x: 0, y: 0, z: 0 } },
    { question: '', answer: '', answerStatus: false, position: { x: 0, y: 0, z: 0 } },
    { question: '', answer: '', answerStatus: false, position: { x: 0, y: 0, z: 0 } }
  ]

  constructor(private ctrlServ: ControllerService, private communicationServ: CommunicationService) { }

  ngOnInit(): void {
    this.generateQuestion();
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'validate-answer') {
            this.onMouseEnter();
          }
        }
      })
  }

  generateQuestion() {
    let shipComps = this.shipComps.map(el => el);
    this.quizList.forEach(quiz => {
      let comp = shipComps[Math.floor(Math.random() * shipComps.length)];
      quiz['question'] = comp;
      let idx = shipComps.findIndex(el => el === comp);
      if (idx > -1) {
        shipComps.splice(idx, 1);
      }
    });
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  onMouseEnter() {
    if (!this.ctrlServ.dragedComponentName) return;
    if (this.quizList[this.current]['question'].split(" ").join("") === this.ctrlServ.dragedComponentName.split(" ").join("")) {
      this.showAnswerStatus = 'correct';
      this.quizList[this.current]['answerStatus'] = true;
      this.quizList[this.current]['answer'] = this.ctrlServ.dragedComponentName;
      this.quizList[this.current]['position'] = JSON.parse(JSON.stringify(this.ctrlServ.dragedComponentPos));
      this.answerStatus = true;
      setTimeout(() => this.showCorrectAnsPopup(), 100);

      // setTimeout(() => {
      //   this.ctrlServ.hideElement(this.componentName);
      // }, 500)

    } else {
      this.showAnswerStatus = 'wrong';
      this.quizList[this.current]['answerStatus'] = false;
      this.quizList[this.current]['answer'] = this.ctrlServ.dragedComponentName;
      this.quizList[this.current]['position'] = JSON.parse(JSON.stringify(this.ctrlServ.dragedComponentPos));
      this.answerStatus = true;
      setTimeout(() => this.showWrongAnsPopup(), 100);

      // if (this.hide_wrong_choice) {
      //   setTimeout(() => {
      //     this.ctrlServ.hideElement(this.ctrlServ.dragedComponentName);
      //   }, 500)
      // } else {
      //   // this.broadcastInfo({ src: 'main', event: 'reset-draged-component', data: this.ctrlServ.dragedComponentName, sub: null });
      // }
    }
  }

  updateQuizData() {
    this.broadcastInfo({ src: 'main', event: 'update-quiz-data', data: this.quizList });
    this.broadcastInfo({ src: 'main', event: 'show-quiz-panel', data: "quiz", key: true });
    this.broadcastInfo({ src: 'main', event: 'stop-drag-component', data: "" });
  }

  showCorrectAnsPopup() {
    this.quizCorrectPopup = true;
    if (this.quizList.length - 1 > this.current) {
      setTimeout(() => {
        this.quizCorrectPopup = false;
        this.onNext();
      }, 2000);
    } else {
      setTimeout(() => {
        this.quizCorrectPopup = false;
        this.updateQuizData()
      }, 2000);
    }
  }

  showWrongAnsPopup() {
    this.wrongAnswerMessage = this.quizList[this.current]['question'];
    this.quizWrongPopup = true;
    if (this.quizList.length - 1 > this.current) {
      setTimeout(() => {
        this.quizWrongPopup = false;
        this.onNext();
      }, 2000);
    } else {
      setTimeout(() => {
        this.quizWrongPopup = false;
        this.updateQuizData();
      }, 2000);
    }
  }

  onAnswerStatus() {
    if (this.quizList[this.current]['answer']) {
      if (this.quizList[this.current]['answerStatus']) {
        this.showAnswerStatus = 'correct';
        this.broadcastInfo({ src: 'main', event: 'update-draged-comp-position', data: this.quizList[this.current]['answer'], pos: this.quizList[this.current]['position'] });
      } else {
        this.showAnswerStatus = 'wrong';
        this.broadcastInfo({ src: 'main', event: 'update-draged-comp-position', data: this.quizList[this.current]['answer'], pos: this.quizList[this.current]['position'] });
      }
      this.answerStatus = true;
    } else {
      this.showAnswerStatus = 'default';
      this.answerStatus = false;
      this.broadcastInfo({ src: 'main', event: 'reset-components', data: '', sub: null });
    }
  }
  onPrev() {
    this.prev = this.current--;
    this.onAnswerStatus();
  }
  onNext() {
    this.prev = this.current++;
    this.onAnswerStatus();
  }
  getWidth(e) {
    let elem = document.getElementById(e);
    return elem.offsetWidth + 30 + 'px';
  }
  isLeftTransition(idx: number): boolean {
    return this.current === idx ? this.prev > this.current : this.prev < this.current;
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    if (event.target.className == 'dropElement') {
      console.log(event)
    }
  }
}
