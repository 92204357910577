import { Component, OnInit, EventEmitter, Output, ViewChild, Input, } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { NSCModel } from "../services/graphFDL/nsc";
import { ConfigService } from '../services/config.service';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-detailpanel',
  templateUrl: './detailpanel.component.html',
  styleUrls: ['./detailpanel.component.scss']
})
export class DetailpanelComponent implements OnInit {
  mode: ProgressSpinnerMode = 'determinate';
  nmCompMiniList: any = [];
  ern = '';
  nmCompList: any = [];
  broadcastSubs: any;
  nmMinWidth: any = '425px'; //Math.round(window.innerWidth / 4.5) + 'px';
  nmMaxWidth: any = Math.round(window.innerWidth / 3.1) + 'px';
  nmCardWidth: any = Math.round(window.innerWidth / 4.5) + 'px';
  ernCardHeight: any = 200;
  selectedKha = 'buoyancy';
  selectedNodetree: any;
  userNodeList: any = [];
  currentNavList = {};

  @Input() selectedNetwork: any;
  // @Output() openDialog: EventEmitter<any> = new EventEmitter<any>();
  // @Input() set nmCompMiniDataList(v: any) {
  //   console.log("nmCompMiniDataList",v);
  //   if (v) {
  //   }
  // }
  @Input() set ernValue(v: any) {
    console.log("ernValueData", v);
    if (v) {
      this.ern = v;
    }
  }
  @Input() appMode: any;
  nscmodel
  courseLists: any = [];
  selectedCourseData: any;
  courseDataLists: any = [];
  constructor(/* private nscmodel: NSCModel, */private domSanitizer: DomSanitizer, private configService: ConfigService, private communicationServ: CommunicationService) {
    this.nscmodel = new NSCModel(this.configService, this.communicationServ, this.ern);
  }
  /* * * * *
    * method for communicate event instance with data to access all components
    * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "networkmodel") {
          if (data.event === 'nodetree') {
            this.selectedNodetree = data.data;
            console.log("selectedNodetree", this.selectedNodetree)
          }
          if (data.event === 'nmCompMiniAdd') {
            this.nmCompMiniList = data.miniData;
          }
          if (data.event === 'componentList') {
            console.log("componentList", data.data)
            this.nmCompList = data.data;
          }
          if (data.event === 'componentDataList') {
            // console.log("componentList", data.data)
            if (this.selectedNodetree == 'dvt') {
              this.courseDataLists = data.data;
              this.courseNode();
            }
            if (this.selectedNodetree == 'mechanical') {
              this.courseDataLists = data.data;
              this.courseNode();
            }
          }
        }
        if (data.src == 'network') {
          if (data.event == 'updatedUserNode') {
            console.log("updatedUserNode", data.data)
            this.userNodeList = data.data;;
          }
        }
        if (data.src == 'sidepanel') {
          if (this.appMode == 'idg') {
            if (data.event === 'selectedindex') {
              this.selectedNode(data);
            }
            if (data.event === "nmCompMiniAdd") {
              this.nmCompMaxMiniAdd(data.data)
            }
          }
        }
        if (data.src == 'coursePanel') {
          if (data.event == 'courseCard') {
            this.onLoadLink(data.data, data.index)
          }
          if (data.event == "updateSelectedCourse") {
            this.courseLists = data.data;
            this.selectedCourseData = data.selectedData;
          }
        }
        if (data.src == 'graphicNode') {
          if (data.event == "nodeclicked") {
            let obj = data['data'];
            if (obj) {
              this.getClickedNodeData(obj);
            }
          }
        }
      })
  }
  nmPopupModel(e) {
    let idx = this.nmCompList.findIndex(el => el.refID == e.refID);
    if (idx > -1) {
      e = this.nmCompList[idx];
    }
    if (this.appMode == 'idg') {
      this.nmPopupMaxModel(e)
    } else {
      this.broadcastInfo({ src: 'detailpanel', event: 'nmPopupModel', data: e });
    }

  }
  nmCompClose(e) {
    let idx = this.nmCompList.findIndex(el => el.refID == e.refID);
    if (idx > -1) {
      e = this.nmCompList[idx];
    }
    if (this.appMode == 'idg') {
      this.nmCompMaxClose(e)
    } else {
      this.broadcastInfo({ src: 'detailpanel', event: 'nmCompClose', data: e });
    }
  }
  calculateHeight(item, ifr: any, pos: any) {
    if (ifr) {
      let elem = document.getElementById('iframeNode' + item.id);
      if (elem) {
        if (pos == 'height') {
          return elem.offsetHeight / 1.1;
        }
        if (pos == 'width') {
          // return elem.offsetWidth/1.1;
        }
      }


    } else {
      if (item['btext']) {
        let quizNode = ['TP 005.01.03.003', 'TP 005.01.03.002'];
        if (quizNode.includes(item['btext'].trim())) {
          this.ernCardHeight = 220;
          return true;
        } else {
          return false;
        }
      }
    }
  }
  onLoadGroupSysComp() {
    this.broadcastInfo({ src: 'detailpanel', event: 'onLoadGroupSysComp', data: '' });
  }

  getCurrentNavList(refID) {
    this.selectedRefID = refID;
    this.currentNavList = {};
    for (let navList of this.courseLists) {
      for (let childList of navList.childList) {
        if (childList['refID'] == refID) {
          this.currentNavList = navList;
          break;
        }
      }
      if (Object.keys(this.currentNavList).length > 1) break;
    }
  }

  getClickedNodeData(e) {
    this.getCurrentNavList(e.refID);
    const index = this.nmCompList.findIndex(el => el.id == e.parentID);
    if (index > -1) {
      this.selectedParentNode = this.nmCompList[index];
    }
  }

  selectedParentNode = "";
  selectedRefID = "";
  nmCompMiniAdd(e) {
    this.getCurrentNavList(e.refID);
    let idx = this.nmCompList.findIndex(el => el.refID == e.refID);
    if (idx > -1) {
      e = this.nmCompList[idx];
    }

    const index = this.nmCompList.findIndex(el => el.id == e.parentID);
    if (index > -1) {
      this.selectedParentNode = this.nmCompList[index];
    }

    if (this.appMode == 'idg') {
      this.nmCompMaxMiniAdd(e)
    } else {
      this.broadcastInfo({ src: 'detailpanel', event: 'nmCompMiniAdd', data: e });
    }
  }
  loadPdfViewer(e) {
    this.broadcastInfo({ src: 'detailpanel', event: 'loadPdfViewer', data: e });
  }
  openDoc() {
    // this.broadcastInfo({ src: 'detailpanel', event: 'nmPopupModel', data: '' });
  }
  openERNDoc(e) {
    this.broadcastInfo({ src: 'detailpanel', event: 'openERNDoc', data: e });
  }
  _onLoadERNTree(e) {
    this.broadcastInfo({ src: 'detailpanel', event: 'onLoadERNTree', data: e });
  }
  onLoadSys(e) {
    this.broadcastInfo({ src: 'detailpanel', event: 'onLoadSys', data: e });
  }
  getElement(data) {
    if (data != undefined) {
      // var elementList;
      // if(data.includes('<mark>')){
      //   elementList.push(data)
      // }else{
      var elementList = data.split(" / ");
      // }

      if (elementList.length > 0) {
        let idName = 'cardContent';
        var ele = '<ul id="' + idName + '">';
        elementList.forEach(element => {
          var str = element.split(/ +/)[0] == '' ? element.split(/ +/)[1] : element.split(/ +/)[0];
          var matches = str.match(/(\d+)/);
          var style = "";
          if (matches != null) {
            style = "indent"
          }
          if (ele != undefined) {
            ele += '<li id="' + style + '"><p>' + element + '<p></li>';
          } else {
            ele = '<li id="' + style + '"><p>' + element + '<p></li>';
          }
        });
        ele += '</ul>'
        // console.log(ele);
        if (ele != undefined) {
          return ele;
          // return this.html_sanitizer.bypassSecurityTrustHtml(ele);
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getExtenSion(filename) {
    return filename.split('.').pop();
  }
  ngOnDestroy() {
    this.broadcastSubs.unsubscribe();
  }

  /* *
   * on Load Mian view
   */
  onLoadComponent() {
    this.broadcastInfo({ src: 'main', event: 'changeview', data: "idg", key: '' });
  }

  openQuiz(item, key) {
    if (key == 'assemble-part') {
      var data: any = { "vertexId": Number(item.id), "userId": this.configService.userId, "assemble_part_progress": 100 };
    } else {
      data = { "vertexId": Number(item.id), "userId": this.configService.userId, "part_identification_progress": 100 };
    }
    this.postUserNode(data);
    this.broadcastInfo({ src: 'detailtag', event: 'open-quiz-window', data: key, key: '' });
  }
  iframeNodeList: any = [];
  onLoadLink(ele, i) {
    console.log('onLoadLink', ele);
    // var data:any={"vertexId":Number(ele.id),"userId":this.configService.userId,"lesson_progress":100};
    // this.postUserNode(data);
    // if (this.iframeNodeList.length > 0) {
    //   let idx = this.iframeNodeList.findIndex(e => e.refID == ele.refID);
    //   if (idx == -1) {
    //     this.iframeNodeList.push({ appName: ele.appName, refID: ele.refID, url: ele['courseList'][i]['url'] })
    //   }
    // } else {
    //   this.iframeNodeList.push({ appName: ele.appName, refID: ele.refID, url: ele['courseList'][i]['url'] })
    // }
    // this.broadcastInfo({ src: 'detailtag', event: 'iframe', data: "idg", key: '' });
    let w = window.innerWidth / 1.3;
    let h = window.innerHeight / 1.3;
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    window.open(ele['courseList'][i]['url'], "child", "width=" + w + ",height=" + h + ",left=" + left + ",top=" + top);
  }
  getUrl(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }
  iframeCardClose(ele) {
    if (this.iframeNodeList.length > 0) {
      let idx = this.iframeNodeList.findIndex(e => e.refID == ele.refID);
      if (idx > -1) {
        this.iframeNodeList.splice(idx, 1)
      }
    }
  }
  getProgress(ele, id) {
    if (this.userNodeList["userNode"]) {
      // var key = ele=='lesson'?'lesson_progress':ele=='assemble_part'?'assemble_part_progress':ele=='assemble_part'
      let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == id);
      // console.log(this.userNodeList["userNode"],ele,idx)
      if (idx > -1) {
        var progress = this.userNodeList["userNode"][idx][ele] == 100 ? true : false;
        return progress;
      } else {
        return false;
      }
    } else {
      return false;
    }
    // return true;
  }
  /* * *
* selected node to highligh
* * */
  lastSelectedNode: any = '';
  selectedNode(data) {
    let flag = data['key'];
    if (flag) {
      if (this.lastSelectedNode) {
        if (document.getElementById(this.ern + "nmMiniAdd_" + this.lastSelectedNode)) {
          if (document.getElementById(this.ern + "nmMaxLabel_" + this.lastSelectedNode).style.display != 'none') {
            document.getElementById(this.ern + "nmMiniAdd_" + this.lastSelectedNode).click();
          }
        }
      }
      var state = this.checkMiniList(data);
      if (!state) {
        this.lastSelectedNode = data['id'];
        return;
      }
      // this.nscmodel.showConnector = false;
      // if (this.arrControlBtnProps[9].state) {
      // this.nscmodel.showConnector = true;
      // // }
      this.showTag(data['id']);
      this.lastSelectedNode = data['id'];
      // this.broadcastInfo({ src: 'graphicNode', event: 'nodeSelected', id: data['id'], key: true });
    } else {
      this.nmCompMaxClose(data);
    }
  }

  /* * *
* method for show node tag
* * */
  showTag(el) {
    let tagElem;
    try {
      var miniElem = document.getElementById(this.ern + "nmMiniLabel_" + el);
      // if (def) {
      //   this.focusElement(el);
      // }
      // this.hightlightNode(true, el);
      if (!tagElem) {
        tagElem = document.getElementById(this.ern + "nmMaxLabel_" + el);
      }

      let ldom: any = tagElem;
      if (ldom != null) {
        ldom.style.display = "inline-block";
        if (miniElem) {
          ldom.style.display = "none";
        }
      }
      // } else {
      //   if (ldom != null) {
      //     ldom.style.display = "none";
      //   }
      // }
    } catch (e) {
      console.log(e)
    }
  };


  checkMiniList(data) {
    var state = false;
    if (this.nmCompMiniList.length > 0) {
      let idx = this.nmCompMiniList.findIndex(e => e.id == data.id);
      if (idx > -1) {
        let indx = this.nmCompList.findIndex(e => e.id == data.id);
        // this.nmPopupModel(this.nmCompList[indx])
        // let id = 'vert_' + data['id'];
        // let el = this.datauxview._allElements[id];
        // this.hightlightNode(false, el);
        // if (this.maxList.length > 0) {
        //   let id = 'vert_' + this.maxList[0]['id'];
        //   let el = this.datauxview._allElements[id];
        //   this.hightlightNode(false, el);
        //   this.maxList = [];
        // }
        // this.hiddenNodeLevels.forEach(level => {
        //   this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
        // });
      } else {
        state = true;
      }
    } else {
      state = true;
    }
    return state;
  }

  nmCompMaxClose(ele) {
    var ern = '';
    // if (this.appModeDtails == 'idg') {
    let idx = this.nmCompMiniList.findIndex(e => e.id == ele.id);
    if (idx > -1) {
      this.nmCompMiniList.splice(idx, 1);
      this.broadcastInfo({ src: 'sidepanel', event: 'nmCompMiniAdd', miniData: this.nmCompMiniList, maxData: [] });
    } else {
      let elem = document.getElementById(ern + "nmMaxLabel_" + ele['id']);
      if (elem != null) {
        elem.style.display = 'none';
      }
    }
    // }

  }
  maxList = [];
  nmPopupMaxModel(ele) {

    let idx = this.nmCompMiniList.findIndex(e => e.id == ele.id);
    if (idx > -1) {
      this.nmCompMiniList.splice(idx, 1);
      let elem = document.getElementById(this.ern + "nmMaxLabel_" + ele['id']);
      if (elem != null) {
        elem.style.display = 'block';
      }

      if (this.maxList.length > 0) {
        // this.nmCompMiniAdd(this.maxList[0])
        let e = document.getElementById(this.ern + "nmMaxLabel_" + this.maxList[0]['id']);
        if (e != null) {
          e.style.display = 'none';
        }
        let id = this.nmCompList.findIndex(e => e.id == this.maxList[0]['id']);
        this.nmCompMiniList.push(this.nmCompList[id]);
        //   let idx = this.maxList.findIndex(e => e.id == ele.id);
        //   if (idx == -1) {
        //     this.maxList.push(ele);
        //     // this.nscmodel.showNode(data.id);
        // let eleId = 'vert_' + this.maxList[0].id;
        // let elId = this.datauxview._allElements[eleId];
        this.maxList = [];
      }
      // } else {
      this.maxList.push(ele);

    }
  }

  nmCompMaxMiniAdd(e) {
    if (e) {
      let elem = document.getElementById(this.ern + "nmMaxLabel_" + e['id']);
      if (elem != null) {
        elem.style.display = 'none';
      }
      // this.nmCompMiniList=[];
      let idx = this.nmCompMiniList.findIndex(ele => ele.id == e.id);
      if (idx == -1) {
        this.nmCompMiniList.push(e);
        let indx = this.maxList.findIndex(ele => ele.id == e.id);
        if (indx > -1) {
          let eleId = 'vert_' + this.maxList[indx].id;
          // let elId = this.datauxview._allElements[eleId];
          // this.hightlightNode(false, elId);
          this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: this.maxList[indx].id, key: false });
          this.broadcastInfo({ src: 'networkmodel', event: 'nmCompMiniAdd', miniData: this.nmCompMiniList, maxData: this.maxList });
          this.broadcastInfo({ src: 'networkmodel', event: 'nmCompMiniAddDetails', miniData: this.nmCompMiniList, maxData: this.maxList });
          this.maxList.splice(indx, 1);
          // this.controlNodes({ key: true, id: e.id });
        }
        // this.hiddenNodeLevels.forEach(level => {
        //   this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
        // });
      }
    }

  }
  getBGColor(el) {
    if (this.nmCompList.length > 0) {
      let idx = this.nmCompList.findIndex(e => e.refID == el.refID);
      if (idx > -1) {
        return this.nmCompList[idx]['color'];
      }
    }
    // console.log("getBGColor",e)
  }

  getDependencyStatus(course, idx) {
    let obj = course.courseList[idx];
    if (obj['type'] == 'dependency') {
      return course['content'].every(el => el.read === true);
    } else {
      return false;
    }
  }

  /**
  * is active icon 
  */
  disableCard = false;
  isActiveIcon(course, idx, pidx) {
    if (Object.keys(this.currentNavList).length === 0) return;
    if (this.selectedRefID == course.refID) {
      let obj = course.courseList[idx];

      if (obj['type'] == 'dependency') {
        let childList = this.currentNavList['childList'];
        const everyfalse = childList.every(el => el['read'] == false);
        const everyTrue = childList.every(el => el['read'] == true);
        if (everyTrue) {
          this.disableCard = false;
          return false;
        } else if (everyfalse) {
          const index = childList.findIndex(el => el['read'] == false);
          const cindex = childList.findIndex(el => el['refID'] == course['refID']);
          this.disableCard = index === 0 ? cindex === index ? false : true : true;
          return index === 0 ? cindex === index ? false : true : true;
        } else {
          const index = childList.findIndex(el => el['read'] == false);
          const cindex = childList.findIndex(el => el['refID'] == course['refID']);
          if (index <= 1) {
            this.disableCard = index <= 1 ? cindex <= index ? false : true : true;
            return index <= 1 ? cindex <= index ? false : true : true;
          } else if (index <= 2) {
            this.disableCard = index <= 2 ? cindex <= index ? false : true : true;
            return index <= 2 ? cindex <= index ? false : true : true;
          } else if (index <= 3) {
            this.disableCard = index <= 3 ? cindex <= index ? false : true : true;
            return index <= 3 ? cindex <= index ? false : true : true;
          }
        }
      }

      else {
        let childList = this.currentNavList['childList'];
        const everyfalse = childList.every(el => el['read'] == false);
        const everyTrue = childList.every(el => el['read'] == true);
        if (everyTrue) {
          return true;
        } else if (everyfalse) {
          const index = childList.findIndex(el => el['read'] == false);
          const cindex = childList.findIndex(el => el['refID'] == course['refID']);
          return index === 0 ? cindex === index ? true : false : false;
        } else {
          const index = childList.findIndex(el => el['read'] == false);
          const cindex = childList.findIndex(el => el['refID'] == course['refID']);
          if (index <= 1) {
            return index <= 1 ? cindex <= index ? true : false : false;
          } else if (index <= 2) {
            return index <= 2 ? cindex <= index ? true : false : false;
          } else if (index <= 3) {
            return index <= 3 ? cindex <= index ? true : false : false;
          }
        }
      }

    }
  }

  getDependencyList(pidx) {
    let menu;
    if (Object.keys(this.currentNavList).length === 0) return;
    for (let childList of this.currentNavList['childList']) {
      if (!childList['read']) {
        menu = childList;
        break;
      }
    }
    return menu;
  }

  courseRead(course, ci, pIdx) {
    let obj = course.courseList[ci];
    if (obj['type'] == 'dependency') {
      let menu = this.getDependencyList(pIdx);
      if (menu) {
        this.broadcastInfo({ src: 'graphicNode', event: 'parentNodeSelected', data: this.selectedParentNode });
        setTimeout(() => {
          this.broadcastInfo({ src: 'detailTag', event: 'menuActive', data: menu });
        });
      }
      return
    }

    let idx = this.nmCompList.findIndex(e => e.refID == course.refID);
    if (idx > -1) {
      var data: any = { "vertexId": Number(this.nmCompList[idx].id), "userId": this.configService.userId, "node": this.selectedNetwork };
      data[course.courseList[ci]['type']] = 100;
      this.postUserNode(data);
      if (course.courseList[ci]['type'] == "digital_learning") {
        this.broadcastInfo({ src: 'coursePanel', event: 'courseCard', data: course });
      }
    }
  }

  postUserNode(data) {
    if (data.vertexId && data.userId) {
      console.log("postUserNode", data);
      this.broadcastInfo({ src: 'graphicNode', event: 'userSelectNodeUpdate', data: data });
    }
  }

  getStatusCourse(course, i) {
    let idx = this.nmCompList.findIndex(e => e.refID == course.refID);
    if (idx > -1) {
      var vertexId: any = Number(this.nmCompList[idx].id);
      if (this.userNodeList["userNode"]) {
        // var key = ele=='lesson'?'lesson_progress':ele=='assemble_part'?'assemble_part_progress':ele=='assemble_part'
        let idx = this.userNodeList["userNode"].findIndex(e => e.vertexId == vertexId);
        // console.log(this.userNodeList["userNode"],ele,idx)
        if (idx > -1) {
          var progress = this.userNodeList["userNode"][idx][course.courseList[i]['type']] == 100 ? true : false;
          return progress;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  
  courseNode(){
    var courseDataLists = [];
    this.courseDataLists.forEach(el => {
      if (el.level != '0' && el.level != '1' && el.level != '2' && el.level != '3') {
        courseDataLists.push(el)
      }
    });
    this.courseDataLists = courseDataLists;
    console.log("element data:-", this.courseDataLists);
  }
}
