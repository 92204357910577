import { Component, ViewChild, AfterViewInit, HostListener, Inject, } from '@angular/core';
import { DatePipe } from '@angular/common';

// service
import { ConfigService } from '../services/config.service';
import { CommunicationService } from '../services/communication.service';
import { ControllerService } from '../services/controller.service';
import { TagService } from '../services/tag.service';

declare var BABYLON;
@Component({
  selector: 'app-navyapp',
  templateUrl: './navyapp.component.html',
  styleUrls: ['./navyapp.component.scss'],
  providers: [DatePipe]
})

export class NavyappComponent implements AfterViewInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;

  dfx: any;
  projectConfig: any;
  process_txt = "Processing...";
  pcsDemos = [];
  selectedDemo = this.pcsDemos[0] || "";
  // showLoading: boolean = false;
  showBlockLoading: boolean = false;
  broadcastSubs
  controlPanelState: boolean;
  allElements = [];
  cam_mode: any;

  constructor(private datePipe: DatePipe, private tagServ: TagService, private configService: ConfigService, private ctrlServ: ControllerService, private communicationServ: CommunicationService) {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
      })
    window['scope'] = this;
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * * *
  * method for project config json based on load the project
  * initilaze the objects form settings.json
  * * * * * */
  ngAfterViewInit(): void {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.projectConfig = project_config;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings', 'json', (data, settingstatus) => {
          var setting_status = settingstatus;
          if (!setting_status) {
          } else {
            this.initObjects();
          }
        });
      }, (err) => { console.log(err) });
    }, (err) => { alert("License not found!") })

    let canvas = document.getElementById('canvasElem').children[0].children[0];
    canvas.addEventListener('pointerdown', (evt) => {
    })
    canvas.addEventListener('pointerup', (evt) => {
    })
  }

  /* * * * *
  * init onjects
  * loading all the static objects from objects.json
  * * * * * */
  imodel = null;
  initObjects() {
    // load all the assets to be used information from socket
    this.datauxview.loadAssets('objects', 'json', (objectstatus) => {
      if (objectstatus) {
        // load all the object settings from settings json file
        this.datauxview.renderAssets('objects', 'json', (objectstatus) => {
          if (objectstatus) {
            console.log('objects', objectstatus);
            this.dfx = this.datauxview.getDatascape();
            this.allElements = this.datauxview.getElementsid();
            this.ctrlServ.init(this.datauxview);
            this.tagServ.init(this.datauxview);
            this.addEnv();

            this.processModel();
            /* this.imodel = this.datauxview.getElementId("imodel");
            this.getSysDefalutPosition(this.getMesh('imodel')); */

          }
        }, (id, pointer) => this.animate(id, pointer));
      }
    });
  }

  /* * * *
  * Zoom event to zoom in / out the venue
  * * * */
  isMouseDown = false;
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }

  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }

  @HostListener('window:message', ['$event'])
  onmessage(e) {
  }

  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.stopZoom();
    }
  }

  @HostListener('document:pointerdown', ['$event'])
  handleMouseDownEvent(event: MouseEvent) {
    if ((<HTMLInputElement>event.target).id == "renderCanvas") {
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardUPEvent(event: KeyboardEvent) {
    if (event.key) {
      const key = event.key.toString().toLowerCase();
      if (event.shiftKey && key === 't') {
        this.controlPanelState = !this.controlPanelState;
      }
    }
  }

  /* * * * *
  * Camera Position event to move the camera to different view / angle
  * * * * * */
  onChangeCameraPosition(camView) {
    console.log("camView ", camView);
    this.datauxview.Camera(camView);
  }

  /* * * * *
  * Control Tool
  * * * * * */
  controlToolEvent(control) {
    if (control.name === 'home') {
      this.changeCamView(control.name);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.icon === 'dimension') {
      control.name = control.state ? '2D' : '3D';
      this.onChangeCameraMode(control.name);
    } else if (control.name === 'left') {
      this.changeCamView(control.name);
    } else if (control.name === 'right') {
      this.changeCamView(control.name);
    } else if (control.name === 'back') {
      this.changeCamView(control.name);
    } else if (control.name === 'top') {
      this.changeCamView(control.name);
    } else if (control.name === 'front') {
      this.changeCamView(control.name);
    } else if (control.name === 'drag') {
      if (control.state) {
        this.dragMode = true;
        this.enableDrag();
      } else {
        this.dragMode = false;
        this.removeDragAll();
      }
    } else if (control.name === 'reset') {
      this.clearDrag();
      if (this.dragMode) {
        this.enableDrag();
      }
    }
  }

  camviews = {
    "home": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 300, "pitch": 27.85 },
    "2D": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 270 },
    "front": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 270, "pitch": 0.001 },
    "top": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 270, "pitch": 89.9 },
    "back": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 89.5, "pitch": 0.001 },
    "right": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 179.5, "pitch": 0.001 },
    "left": { "target": { "x": 0.3, "y": 0.86, "z": -0.3 }, "distance": 10, "yaw": 0.001, "pitch": 0.001 },
  }

  changeCamView(v, vobj = false) {
    let view = vobj ? v : this.camviews[v];
    this.moveCameraTo(null, view)
  }

  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let camera = dfx.getCamera();
    let _view = view;
    if (!view) {
      let dr = this.getElemRadius(el);
      let target = this.getElemCenter(el);
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(20, ds);
      _view = { distance: ds, target }
    }
    dfx.moveCamera(_view);
  }
  getElemCenter(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let target;
    let mesh = dfx.getElementMesh(el);
    // if(this.extras.includes(id)){
    // target=this.getAverageCenter(mesh);
    // }else{
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    target = { x: c.x, y: c.y, z: c.z }
    // }
    return target;
  }

  getElemRadius(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let mesh = dfx.getElementMesh(el);
    let c = id ? this.getMaxRadius(mesh) : mesh.getBoundingInfo().boundingSphere.radiusWorld;
    return c;
  }

  getMaxRadius(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = Number.MIN_VALUE
    meshes.forEach((mesh) => {
      if (mesh.isEnabled() && mesh.isVisible && mesh.material.alpha) {
        let pt = mesh.getBoundingInfo().boundingSphere.radiusWorld;
        c = Math.max(c, pt)
      }
    });
    return c;
  }


  /* * * * *
  * change cameta mode
  * * * * * */
  onChangeCameraMode(camView) {
    this.datauxview.venue_orientation = 0.35;
    this.datauxview.yaw_2d = 45;
    this.datauxview.yaw_3d = 43.4;
    this.datauxview.pitch_3d = 27.9;

    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.changeCamView('top');
    }
    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      setTimeout(() => {
        this.changeCamView('home');
      }, 1300);
    }
  }

  selectedElement = {
    "id": "none",
    "geometry": {
      "position": { "x": 0, "y": 0, "z": 0 },
      "size": 0,
      "orientation": { "x": 0, "y": 0, "z": 0 },
    }
  };

  /* * * *
  * Get the handle to the element selected from list
  * * * */
  onGetElementProperties(key) {
    var elem = this.datauxview.getObjectProperties(key);
    this.selectedElement = elem;
    console.log("this.selectedElement :", this.selectedElement);
  }

  /* * * * *
  * Change the position / coordinates on the selected object
  * * * * * */
  onChangeObjectPosition(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": objProp.position,
        "rot": { "x": null, "y": null, "z": null }
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * Change the size on the selected object
  * * * * * */
  onChangeObjectSize(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": { "x": null, "y": null, "z": null },
        "rot": { "x": null, "y": null, "z": null },
        "size": objProp.size
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * change object Orientation
  * * * * * */
  onChangeObjectOrientation(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": { "x": null, "y": null, "z": null },
        "rot": { "x": null, "y": objProp.orientation, "z": null },
        "size": null
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * trigger Event
  * * * * * */
  ontrigger(keycode) {
    this.datauxview.triggerKey(keycode);
  }

  /* * * * *
  * Animate Event
  * * * * * */
  animate(id, pointer) {
    if (pointer == 'pickLeft') {
    }
  }

  settingPanelState(e) {
    this.controlPanelState = e;
  }
  /**
   * sky effect
   */
  sky
  land_mtl
  addEnv() {
    this.setVenueLightIntensity(3);
    //this.sky = this.dfx.addSky('sky', { size: 30000, rayleigh: 1, inclination: 0.1 });
    let m = this.datauxview.getElementId("land");
    let mesh = this.dfx.getElementMesh(m);
    let glass_mtl = this.scene.getMaterialByID("glass_mtl");
    let glass_mtl2 = this.dfx.getStandardMaterial("glass_mtl2");
    let tex = glass_mtl.diffuseTexture;//dfx.getTexture("/assets/rov/assets/base.png");
    glass_mtl2.diffuseColor.r = 0.1;
    glass_mtl2.diffuseColor.g = 0.1;
    glass_mtl2.diffuseColor.b = 0.1;
    glass_mtl2.opacityTexture = tex;
    glass_mtl2.opacityTexture.getAlphaFromRGB = true;
    mesh.material = glass_mtl2;
    this.land_mtl = glass_mtl2;
  }
  /**
   * Environment settings
   */
  scene
  setVenueLightIntensity(i = 4) {
    this.scene = this.dfx.getCamera().getScene();
    let lights = this.scene.lights;
    lights.forEach((light) => {
      light.intensity = i;
    })
  }
  applylandMtl() {
    let dfx = this.datauxview.getDatascape();
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    mesh.material = this.land_mtl;
  }
  showHideLand(boo) {
    let dfx = this.datauxview.getDatascape();
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    mesh.isVisible = boo;
  }
  processModel() {
    let cam = this.dfx.getCamera();
    let scene = cam.getScene();
    scene.materials.forEach((m) => {
      if (m.metallic) {
        m.metallic = 0.05;
        m.roughness = 0.01;
      }
    })
  }
  /**
   * Drag Feature
   */
  dragMode = false;
  shipDragobj: any = {};
  isDrag = false;
  rightclick = false;
  enableElementDrag(child, children = true) {

    if (!child) {
      return
    }
    let mesh = this.dfx.getElementMesh(child);
    if (!mesh) {
      return
    }
    let meshes = children ? mesh.getChildren() : [mesh];
    meshes.forEach((m) => {
      let mname = this.getMeshName(m.name)
      let b = this.dfx.getDragBehavior([0, 1, 0]);
      let parent = null;
      b.useObjectOrienationForDragging = false;
      b.updateDragPlane = false;
      m.addBehavior(b);
      b.onDragObservable.add((event) => {
        this.isDrag = true;

        if (this.rightclick) {
          b.releaseDrag();
          this.isDrag = false;
        }
      })
      let dragStartPos = {}
      b.onDragStartObservable.add((event) => {
        /* let pos = m.position;
        dragStartPos = JSON.parse(JSON.stringify(pos)) */

      })
      b.onDragEndObservable.add((event) => {
        /* let endPos = m.position;
        let x = this.getDifference(dragStartPos['x'], endPos.x);
        let y = this.getDifference(dragStartPos['y'], endPos.y);
        let z = this.getDifference(dragStartPos['z'], endPos.z);
        if (x <= 0 || y <= 0 || z <= 0) {
          this.moveCameraTo(null, this.selectedHitPosition);
        } */

      })
      this.shipDragobj[mname] = [m, b];
    })
  }
  enableDrag() {
    this.enableElementDrag(this.imodel)
  }
  removeDragAll() {
    //this.dragMode=false;
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      if (this.shipDragobj[key]) {
        this.shipDragobj[key][0].removeBehavior(this.shipDragobj[key][1]);
        this.shipDragobj[key][1] = null;
      }
    });
  }
  resetDragElements() {
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      let m = this.shipDragobj[key];
      let pos = this.shipDefPosition[key];
      if (m && pos) {
        m[0].position.x = Number(pos.x.toFixed(4));
        m[0].position.y = Number(pos.y.toFixed(4));
        m[0].position.z = Number(pos.z.toFixed(4));
      }
    });
  }
  clearDrag(boo = true) {
    this.removeDragAll();
    if (boo) {
      this.resetDragElements();
    }
    this.shipDragobj = {};
  }
  shipDefPosition: any = {};
  getSysDefalutPosition(mesh) {
    this.shipDefPosition = {};
    let meshes = mesh.getChildren();
    meshes.forEach((m) => {
      if (m.rotationQuaternion) {
        let _rot = m.rotationQuaternion.toEulerAngles();
        let s = m.scaling;
        m.rotationQuaternion = null;
        m.rotation.x = _rot.x;
        m.rotation.y = _rot.y;
        m.rotation.z = _rot.z;
        m.scaling = s;
      }
      let mname = this.getMeshName(m.name);
      this.shipDefPosition[mname] = { x: m.position.x, y: m.position.y, z: m.position.z };

    });
    //this.shipDefPosition = JSON.parse(JSON.stringify(obj));
  }
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    return arr[arr.length - 1];
  }
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  getChildMesh(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => e.name.includes("." + cid));
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }
  getChildMeshEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => this.getMeshName(e) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }

}

