import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, PopupComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { DatauxviewModule } from 'datauxview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { from } from 'rxjs';

//components
import { NavyappComponent } from './navyapp/navyapp.component'
import { HeaderComponent } from './header/header.component';
import { ControltoolsComponent } from './controltools/controltools.component';
import { SidepanelComponent } from './sidepanel/sidepanel.component';
import { TagComponent } from './tag/tag.component';
import { StaterApp } from './staterapp/staterapp.component'
import { PositiontoolsComponent } from './positiontools/positiontools.component';
import { NetworkModelComponent } from './network-model/network-model.component';
import { DetailpanelComponent } from './detailpanel/detailpanel.component';
import { QuizpanelComponent } from './quizpanel/quizpanel.component';
import { SlmsSidepanelComponent } from './slms-sidepanel/slms-sidepanel.component';
import { ShipDetailComponent } from './ship-detail/ship-detail.component';
import { InexxtComponent } from './inexxt/inexxt.component';
import { RHIB } from './rhib/rhib.component';

//providers
import { ConfigService } from './services/config.service';
import { ControllerService } from './services/controller.service';
import { CommunicationService } from './services/communication.service';
import { TagService } from './services/tag.service';
import { customInterceptor } from './services/http.interceptor';
import { AnimatorService } from './services/animator.service';
import { KHASystemService } from './services/systems/kha-systems.service';

// mat components
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { CoursePanelComponent } from './course-panel/course-panel.component';
import { GameViewComponent } from './game-view/game-view.component';
import { LoaderScreenComponent } from './loader-screen/loader-screen.component';
import { RadialSlider } from './components/radial-slider/radial-slider.component'
import { SpeedSlider } from './components/speed-slider/speed-slider.component'
import { AltiSlider } from './components/alti-slider/alti-slider.component'
import { LineSlider } from './components/line-slider/line-slider.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import { CommentsComponent } from './comments/comments.component';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRippleModule} from '@angular/material/core';

import { ColorPickerModule } from 'ngx-color-picker';
import { IengineComponent } from './iengine/iengine.component';
import {PdfViewerSearchComponent} from './pdf-viewer-search/pdf-viewer-search.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProgressPanelComponent } from './progress-panel/progress-panel.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ControltoolsComponent,
    SidepanelComponent,
    PositiontoolsComponent,
    NetworkModelComponent,
    NavyappComponent, StaterApp,
    TagComponent,
    DetailpanelComponent,
    QuizpanelComponent,
    SlmsSidepanelComponent,
    ShipDetailComponent,
    InexxtComponent,
    RHIB,
    CoursePanelComponent,
    GameViewComponent,
    LoaderScreenComponent,
    RadialSlider,
    SpeedSlider,
    AltiSlider,
    LineSlider,
    PopupComponent,
    CommentsComponent,
    IengineComponent,
    PdfViewerSearchComponent,
    ProgressPanelComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DatauxviewModule,
    ReactiveFormsModule,
    PdfViewerModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatRadioModule,
    MatListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ColorPickerModule,
    MatTableModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,

  ],
  entryComponents: [
    PopupComponent,
    CoursePanelComponent    
  ],
  exports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    MatTooltipModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
  ],
  providers: [
    ConfigService,
    ControllerService,
    CommunicationService,
    TagService,
    KHASystemService,
    AnimatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
