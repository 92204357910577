import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ShapeCreatorService } from '../services/shape-creator.service';
import { ConfigService } from '../services/config.service';
import { AnimatorService } from '../services/animator.service';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from "../services/controller.service";
import { object_path, object_geom } from "../Objects";

declare var BABYLON;
declare var window;
var debug = false;
var _console = {
  "log": (...args) => {
    if (debug) {
      window.console.log(...args)
    }
  }
}

@Component({
  selector: 'app-inexxt',
  templateUrl: './inexxt.component.html',
  styleUrls: ['./inexxt.component.scss']
})

export class InexxtComponent implements OnInit, AfterViewInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;
  @ViewChild('positiontoolcomponent', { static: true }) public positiontoolcomponent: any;

  shapeCreatorService: ShapeCreatorService;
  process_txt = "Processing...";
  controlsPosition: any = 'Right';
  pcsDemos = [];
  selectedDemo = this.pcsDemos[0] || "";
  isAppLoaded: boolean = false;
  showLoading = true;
  showTransLoading = false
  showExternalTools = false;
  broadcastSubs
  positiontool = false;
  socketcontroler = false;
  controlPanelState: boolean = false;
  showSSPanel: boolean = true;
  showDrawerPanel: boolean = true;
  disableTrans: boolean = false;
  transMode: boolean = true;
  focus_title: string = "";
  focus_col: string = "#fff";
  doreset = false;

  shipObjects: any = [
    { name: 'iNeXXT campus', key: 'inexxt' },
    { name: 'IDG', key: 'idg' }
  ];

  @Input() activeModel: any = 'inexxt';

  constructor(private ctrlServ: ControllerService, private configService: ConfigService, private animatorService: AnimatorService, private communicationServ: CommunicationService) {
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.broadcastInfo({ src: 'loader', event: 'loaderState', data: {state: true,process_txt:"Processing...",selectedDemo:this.pcsDemos[0] || "",event:'showBlockLoading'} });
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "controls") {
        }
        if (data.src === "main") {

        }
        if (data.src === "sidepanel") {
          if (data.event === "inexxt-building-trans-toggle") {
            this.inexxtBuildingTransToggle(data.key);
          }
          if (data.event === "inexxt-controlChange") {
            let obj = data['data'];
            this.onControlChange(obj)
          }
          if (data.event === "rhib-controlChange") {
            let obj = data['data'];
            this.onControlChange(obj)
          }
        }
      })
  }

  controlChangeObj
  onControlChange(data) {
    let key = data.name;
    let f = "";
    if (this.controlChangeObj) {
      f = this.controlChangeObj;
    }
    this.controlChangeObj = data.name;
    let state = data.state;
    let event = data.event;
    if (event === 'showhide') {
      // this.showHideElem(key, state);
      // this.showHideCompTargets(!this.multipleTagStatus);
      // this.reapplyDrag();
    } else if (event === 'showhidecomp') {
      if (state) {
        let solidtrans = data.solidtrans;
        if (!solidtrans) {
          this.ctrlServ.transparentElement(key);
        } else {
          this.ctrlServ.showElement(key);
        }
      } else {
        this.ctrlServ.hideElement(key);
      }
    } else if (event === 'locate') {
      let compKey = data.key;
      let compId = data.id;
      this.shipChildCompsCntls = data.children;
      if (!state) {
        this.closeBack(this.shipCompsCntls['Components'][0], 'Components');

        this.resetElemState();
        this.stopEnvAnim(true);
        // this.showHideCompTargets(!this.multipleTagStatus);
        if (!this.globalPause) {
          this.compAnimStatus = 'playing';
          setTimeout(() => {
            this.animatorService.pauseAllAnim(false, true);
            this.animatorService.triggerPausedAnim(key);

            this.animatorService.showHideProps(true, key);
            this.reapplyDrag();
          }, 500)
        } else {
          //this.animatorService.triggerPausedAnim(key);
          this.animatorService.showHideProps(true, key);
        }
      } else {
        this.animatorService.showHideProps(!true);
        if (!this.globalPause) {
          this.animatorService.pauseAllAnim(true, true);
          this.compAnimStatus = 'paused';
        }
        this.animatorService.stopAnim(key, true, true);
        // this.showHideCompTargets(false);
        setTimeout(() => {
          this.focusElem(key, state);
          let passValue = { 'key': compKey, 'id': compId };
          this.onLocCtl(passValue, key);
        }, 100)
      }
      this.cameraHideShow(!this.cameraTargetOff);
      this.sensorHideShow(!this.sensorTargetOff);
    } else if (event === 'solidtrans') {
      this.solidTransElem(key, state);
    } else if (event === 'showhidetrans') {
      this.showHideTransElem(state);
      this.showHideCompTargets(!this.multipleTagStatus);
      this.sensorHideShow(!this.sensorTargetOff);
    }
  }

  dragMode = false;
  operationMode = false;
  onChangeCameraPosition(camView) {
    this.viewObjectMode = camView;
    if (camView == 'drag') {
      this.dragMode = !this.dragMode;
      if (!this.dragMode) {
        this.removeDrag("null", true);
      } else {
        this.removeDrag("null", true);
        this.reapplyDrag();
      }
      return
    }
    if (camView == 'drag_reset') {
      this.resetChildren();
      this.onChangeCameraPosition('home');
      return
    }
    if (camView === 'water') {
      // this.toggleOcean();
      return
    }
    if ((camView == 'left' || camView == 'right' || camView == 'back' || camView == 'back' || camView == 'top' || camView == 'front')) {
      this.changeCamView(camView);
      return;
    }
    if (camView === 'home') {
      this.changeCamView('home', false);
    }
    if (this.cam_mode == '2D' && (camView == 'left' || camView == 'right' || camView == 'back')) {
      camView = 'ortho' + camView
    } else {
    }
  }

  cam_mode = '3D'
  onChangeCameraMode(camView) {
    this.cam_mode = camView;
    this.datauxview.CameraMode(camView);
    if (camView == '3D') {
      setTimeout(() => {
        this.changeCamView('home', false);
      }, 1500);
    }
  }

  /**
  * sample code to load the application venue using different data source
  */
  ngAfterViewInit(): void {
    // load all the canvas settings from settings json file     
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings-inexxt', 'json', (data, settingstatus) => {
          var setting_status = settingstatus;
          this.datauxview.yaw_3d = this.datauxview.yaw_2d = 270;
          this.datauxview.pitch_2d = 15
          this.showLoading = true;
          this.broadcastInfo({ src: 'loader', event: 'loaderState', data: {state:true,process_txt:"Processing...",selectedDemo:this.pcsDemos[0] || "",event:'showBlockLoading'} });
          if (!setting_status) {
            setting_status = this.attachDynamicObject(data, (settingstatus) => {
              if (settingstatus) {
                this.initObjects();
              }
            });
          } else {
            this.initObjects();
          }
        });
      }, (err) => { _console.log(err) });
    }, (err) => { alert("License not found!") });
  }

  track_label_tex = {
    "meshes": [{
      "position": [0, 0, 0],
      "rotation": [0, 0, 0],
      "scaling": [1, 1, 1],
      "isVisible": true,
      "isEnabled": true,
      "checkCollisions": false,
      "alphaIndex": 201,
      "billboardMode": 0,
      "receiveShadows": false,
      "name": "track",
      "id": "track",
      "positions": [],
      "normals": [],
      "uvs": [],
      "indices": []
    }]
  };

  attachDynamicObject(data, callback) {
    var _this = this;
    const TERMINAL_ORIGIN = [-97.35, 33];
    this.shapeCreatorService = new ShapeCreatorService(TERMINAL_ORIGIN[0], TERMINAL_ORIGIN[1]);
    var trackWidth_met = 4;
    let trackgeom = _this.shapeCreatorService.MakeColourRect(1000, trackWidth_met, 32.0 / 1024.0, 1 - (828.0 / 1024.0));
    let _track = JSON.parse(JSON.stringify(_this.track_label_tex));
    let track = _track.meshes[0];
    track.name = track.name;
    track.id = track.name;
    track.positions = trackgeom.positions;
    track.normals = trackgeom.normals;
    track.uvs = trackgeom.uvs;
    track.indices = trackgeom.indices;
    data.shapes[track.name] = 'data:' + JSON.stringify(_track);
    return _this.datauxview.attachDynamicObject(data, callback);
  }

  land_mtl
  scene
  setVenueLightIntensity(i = 4) {
    let lights = this.scene.lights;
    lights.forEach((light) => {
      light.intensity = i;
    })
  }
  isSubSys(c) {
    let info = this.getCtrlData(c);
    return info.isSub;
  }
  getCtrlData(val, prop = 'id', action = 'eq') {
    let data = null;
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        if (action === 'eq') {
          if (c[prop] == val) {
            data = c;
          }
        }
        if (action === 'contains') {
          if (c[prop].includes(val)) {
            data = c;
          }
        }
      })
    }
    return data;
  }

  compTargets = []
  sensors = []
  cameras = [];
  shipCameras = []
  compDefPos = {};
  shipComps = [];
  shipChildCompsCntls = [];
  shipCompsCntls = {};
  bckupShip_comp_ctl_list: any = [];

  setShipCompsCntls() {
    this.shipCompsCntls["Components"] = [
      { "name": "2ND FLOOR", "id": "2ND FLOOR", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "GROUND FLOOR", "id": "GROUND FLOO", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "OTHERS", "id": "OTHERS", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "Planting", "id": "Planting", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "T/0 ROOF-X SPACE", "id": "T/0 ROOF-X SPACE", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "U/S JOIST MULTIP ROOM", "id": "U/S JOIST MULTIP ROOM", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "U/S JOIST-1ST FLOOR", "id": "U/S JOIST-1ST FLOOR", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }, { "name": "U/S ROOF-2ND FLOOR", "id": "U/S ROOF-2ND FLOOR", "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false }
    ]
  }

  cameraGroup = [];
  children = {};
  dbobj = {};
  shipElems;
  deconstructModel() {
    let dfx = this.datauxview.getDatascape();
    let pmesh = this.ctrlServ.getMesh('sina_crn');
    let idg = this.ctrlServ.getMesh('imodel');
    idg.rotationQuaternion = null;
    pmesh.scaling.z = -1
    let ship = this.datauxview.getElementId('sina_crn_building');
    let mesh = dfx.getElementMesh(ship);

    mesh.getChildren().forEach((m) => {
      if (!m.isPickable) {
        m.isPickable = true;
      }
      let mid = this.ctrlServ.getMeshID(m);
      this.shipComps.push(mid);
      let _rot = m.rotationQuaternion ? m.rotationQuaternion.toEulerAngles() : m.rotation;
      let _ce = this.getParentElemBound(m);
      this.compDefPos[mid] = { bound: _ce, color: m.material.albedoColor, position: { x: m.position.x, y: m.position.y, z: m.position.z }, rot: { x: _rot.x, y: _rot.y, z: _rot.z }, qrot: m.rotationQuaternion, scale: m.scaling }
      m.isVisible = false;
      m.setEnabled(false);
    })

    let children = [];
    this.shipComps.forEach((c) => {
      let comp = this.datauxview.getElementId(c);
      let geometry = this.compDefPos[c];
      dfx.modify(comp, { geometry: { position: geometry.position } })
      children.push(comp);
      let pos = { x: geometry.position.x, y: geometry.position.y, z: geometry.position.z };
      this.children[c] = { pos }
      let hmesh = dfx.getElementMesh(comp);
      if (!this.dbobj[c]) {
        this.dbobj[c] = [, hmesh];
      }
      hmesh.rotationQuaternion = null;
      hmesh.rotation.x = geometry.rot.x;
      hmesh.rotation.y = geometry.rot.y;
      hmesh.rotation.z = geometry.rot.z;
      hmesh.scaling = geometry.scale;
      dfx.setElementTransition(comp, 0);
    })
    dfx.setAsParent(ship, children);
    this.shipElems = [].concat(this.sensors, this.cameras, this.shipComps);
    mesh.isVisible = false;
    this.applyParentChild();
  }

  applyParentChild() {
    let dfx = this.datauxview.getDatascape();
    this.shipComps.forEach((key) => {
      let parent = this.dbobj[key][1];
      let info = this.getCtrlData(key)
      if (info.isSub && info.children.length) {
        let c = info.children;
        c.forEach((cid) => {
          let _child = this.dbobj[cid][1];
          _child.setParent(parent)
        })
      }
    })
  }

  getParentElemBound(mesh) {
    let meshes = mesh.getChildMeshes();
    let c = this.getElemCenter(null, mesh);
    let r = this.getElemRadius(null, mesh);
    let l = 0
    /* if (meshes.length) {
      let cx = 0;
      let cy = 0;
      let cz = 0;
      meshes.forEach((m) => {
        if (!m.getChildren().length) {
          l++;
          let ce = this.getElemCenter(null, m);
          let _r = this.getElemRadius(null, m);
          r = Math.max(r, _r);
          cx += ce.x;
          cy += ce.y;
          cz += ce.z;

        }
      })
      cx = cx / l
      cy = cy / l
      cz = cz / l
      c = { x: cx, y: cy, z: cz }
    } */
    return [c, r];
  }

  addEnv() {
    this.setVenueLightIntensity(2.25);
    //this.sky = this.dfx.addSky('sky', { size: 30000, rayleigh: 1, inclination: 0.1 });
    let m = this.datauxview.getElementId("land");
    let mesh = this.dfx.getElementMesh(m);
    let glass_mtl = this.scene.getMaterialByID("glass_mtl");
    let glass_mtl2 = this.dfx.getStandardMaterial("glass_mtl2");
    let tex = glass_mtl.diffuseTexture;//dfx.getTexture("/assets/rov/assets/base.png");
    glass_mtl2.diffuseColor.r = 0.15;
    glass_mtl2.diffuseColor.g = 0.15;
    glass_mtl2.diffuseColor.b = 0.15;
    glass_mtl2.opacityTexture = tex;
    glass_mtl2.opacityTexture.getAlphaFromRGB = true;
    mesh.material = glass_mtl2;
    this.land_mtl = glass_mtl2;
    mesh.isPickable = false;
  }

  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      this.mousestate = 'down';
    })
    canvas.addEventListener("pointerup", (event) => {
      this.mousestate = 'up';
    })
    canvas.addEventListener("pointermove", (event) => {
      this.mousestate = 'up';
      // this.mousepos = [event.clientX, event.clientY]
    })
  }

  removeLoader() {
    let ldiv = document.getElementById("babylonjsLoadingDiv");
    if (ldiv) {
      ldiv.remove();
    }
  }

  /* * *
  * method for init objects
  * * */
  dfx
  initObjects() {
    window.scope = this;
    // load all the assets to be used information from socket
    this.datauxview.loadAssets('objects-inexxt', 'json', (objectstatus) => {
      if (objectstatus) {
        this.datauxview.renderAssets('objects-inexxt', 'json', (objectstatus) => {
          if (objectstatus) {
            this.dfx = this.datauxview.getDatascape();
            this.scene = this.dfx.getCamera().getScene();
            this.ctrlServ.init(this.datauxview);
            this.removeLoader();
            this.addEnv();
            this.setShipCompsCntls();
            this.changeCamView('home');
           
            this.loadBuildings(() => {
              this.deconstructModel();
              this.showTransLoading = false;
              setTimeout(() => {
                this.showLoading = false;
                this.broadcastInfo({ src: 'loader', event: 'loaderState', data: {state:false,process_txt:"Processing...",selectedDemo:this.pcsDemos[0] || "",event:'showBlockLoading'} });
                this.showTransLoading = true;
              },1500)
            });
            this.initCanvas();

            setTimeout(() => {
              this.showExternalTools = true;
            }, 1000);
          }
        }, () => { 
          this.showLoading = false;
          this.broadcastInfo({ src: 'loader', event: 'loaderState', data: {state:false,process_txt:"Processing...",selectedDemo:this.pcsDemos[0] || "",event:'showBlockLoading'} });
        })
      }
    });
  }

  /* * *
  * method for load dynamic objects
  * * */
  allElements: any = [];
  loadBuildings(cbck) {
    let config = "inexxt";
    let def = {};
    for (let m in object_path[config]) {
      def[m] = object_path[config][m];
    };
    this.dfx.addDynamicShapes(def).then(() => {
      let shapes = object_geom[config]['shapes'];
      let objects = object_geom[config]['objects'];
      this.datauxview._createModels(shapes, (boo) => {
        this.datauxview.addElements(objects, (_Objects) => {
          if (cbck) {
            this.allElements = _Objects;
            cbck();
          }
        }, (id, pointer) => { })
      }, (id, pointer) => { })
    });
  }

  /**
  * Camera Position event to move the camera to different view / angle
  */
  viewMode = 'solid';
  viewObjectMode = 'solid';
  camviews = {
    "home": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": 160, "pitch": 27.85 },
    "top": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": 190, "pitch": 89.9 },
    "left": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": -70, "pitch": 27.85 },
    "right": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": 100, "pitch": 27.85 },
    "back": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": 20, "pitch": 27.85 },
    "front": { "target": { "x": 35.4138, "y": 3, "z": -30.8376 }, "distance": 560, "yaw": 190, "pitch": 27.85 },
    "idg-focus": { "target": { "x": 10.64960, "y": 3, "z": -25.0223 }, "distance": 150, "yaw": 125, "pitch": 27.85 },
  }

  changeCamView(v, vobj = false) {
    let view;
    view = vobj ? v : this.camviews[v];
    this.moveCameraTo(null, view)
  }

  getElemCenter(el, _mesh = null) {
    let dfx = this.datauxview.getDatascape();
    let mesh = _mesh || dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    let target = { x: c.x, y: c.y, z: c.z }
    return target;
  }

  getElemRadius(el, _mesh = null) {
    let dfx = this.datauxview.getDatascape();
    let mesh = _mesh || dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.radius;
    return c;
  }

  dradius = 2.63 * 10;
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let camera = dfx.getCamera();
    let _view = view;
    if (!view) {
      let dr = this.getElemRadius(el);
      let target = this.getElemCenter(el);
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(3, ds);
      _view = { distance: ds, target }
    }
    dfx.moveCamera(_view);
    setTimeout(() => {
      // if (this.dragMode)
      //   this.reapplyDrag()
    }, 1000)
  }

  showHideTransElem(state) {
    if (state) {
      this.hideTransparentElem()
    } else {
      this.showTransparentElem();
    }
  }

  hideTransparentElem() {
    this.transMode = false;
    let dfx = this.getDatascape();
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((info) => {
        if (info.opacity === 'transparent' || !info.selected) {
          // if (this.multipleTagStatus) {
          //   _console.log("multipleTagStatus", info)
          //   var elem = this.datauxview.getElementId(info.id)
          //   this.removeObserver(elem);
          //   let pop = document.getElementById('tooltip_' + info.id);
          //   pop.style.display = "none";
          //   //this.addObserver(elem);
          //   //this.selectedelem = elem;
          // } else {
          //   if (this.selectedelem) {
          //     this.removeObserver(elem);
          //     let pop = document.getElementById('tooltip_' + info.id);
          //     pop.style.display = "none";
          //   }
          // }

          this.showHideElem(info.id, false);
          info.visible = false;

          if (info && info.sensors) {
            info.sensors.forEach((sensor) => {
              let profile = 'invisible';
              let elem = this.datauxview.getElementId(sensor);
              /* let isSensor=this.sensors.includes(sensor);
              let isCamera = this.cameras.includes(sensor);
              if(isSensor&&this.sensorTargetOff){
                profile='invisible'
              }
              if(isCamera&&this.cameraTargetOff){
                profile='invisible'
              } */
              if (elem) {
                dfx.modify(elem, { profile });
              }
            })
          }
        }
      })
    }
  }
  showTransparentElem() {
    this.transMode = true;
    let dfx = this.getDatascape();
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((info) => {

        if (info.opacity === 'transparent' || !info.selected) {
          info.opacity = 'transparent';
          this.showHideElem(info.id, true);
          info.visible = true;

          if (info && info.sensors) {
            info.sensors.forEach((sensor) => {
              let profile = 'regular';
              let elem = this.datauxview.getElementId(sensor);
              let isSensorT = this.sensors.includes(sensor) && sensor.includes('target');
              let isCameraT = this.cameras.includes(sensor) && sensor.includes('target');
              if (isSensorT && this.sensorTargetOff) {
                profile = 'invisible'
              }
              if (isCameraT && this.cameraTargetOff) {
                profile = 'invisible'
              }
              if (elem) {
                dfx.modify(elem, { profile });
              }
            })
          }
        }
      })
    }
  }
  showHideElem(id, state) {
    let dfx = this.getDatascape();
    let elem = this.datauxview.getElementId(id);
    let info = this.getCtrlData(id);
    let profile = info ? info.profile || 'regular' : 'regular';
    if (!info.isSub) {
      return
    }
    if (this.viewObjectMode === 'texture') {
      profile = 'regular'
    }
    if (!state) {
      profile = 'invisible';
    } else {
      if (info.opacity === 'transparent') {
        profile = "transparent"
      }
    }
    info.visible = state;
    dfx.modify(elem, { profile });
    if (info.children.length && profile !== 'invisible') {
      // this.applyProfileToChildren(info);
    }
    /* if (info && info.id.includes("_arm")) {
      this.linkboatProfile(dfx, info.id, profile);
    } */
  }
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  triggerCompAnim(key) {
    this.animatorService.onClick(null, key);
  }
  rotateComp(id, obj) {
    let mesh = this.dbobj[id][1];
    let r0 = this.compDefPos[id].rot[obj['axis']];
    let r1 = obj['value'];

    let rad = this.deg_toRad(r1);
    let val = r0 + rad;
    mesh.rotation[obj['axis']] = val;
  }
  deg_toRad(deg) {
    return deg * Math.PI / 180;
  }
  rad_toDeg(rad) {
    return rad * 180 / Math.PI;
  }
  globalPause = false;
  triggerDemoAnim(obj) {
    let key = obj.name;
    let id = key;
    let state = obj.state;
    /* if(key==="Box060"){
      if(state=='rotate'){
        this.rotateComp(key,{axis:'z',value:obj.data.val})
      }
    } */
    if (state == 'rotate') {
      this.rotateComp(key, { axis: obj.data.axis || 'y', value: obj.data.val * obj.data.dir })
    }
    if (state == 'rpm') {
      this["rpm_" + obj.data.id] = obj.data.val;
    }
    if (key === "stop") {
      this.animatorService.stopAllAnim();
    }
    if (key === "pause") {
      this.globalPause = state;
      this.startAnimation();
      this.animatorService.pauseAllAnim(state);
    }
  }

  animStatus: string = "stopped";
  pauseAnim = false;
  compAnimStatus: string = "playing"
  startAnimation() {
    /* if (this.animStatus === 'playing') {
      this.pauseAnim = true;
      this.animStatus = "paused";
    } else if (this.animStatus === 'paused') {
      this.pauseAnim = false;
      this.animStatus = "playing";
    } else {
      this.animStatus = "playing";
      this.rotateRotors();
      //this.startSmartSRadar()
    } */
    if (this.compAnimStatus === 'playing') {
      this.compAnimStatus = "paused";

    } else {
      this.compAnimStatus = 'playing';
    }
  }

  solidTransElem(id, solid) {
    let dfx = this.getDatascape();
    let elem = this.datauxview.getElementId(id);
    let info = this.getCtrlData(id);
    let profile = info ? info.profile || 'regular' : 'regular';
    info.opacity = 'solid';
    info.checked = !false;
    if (this.viewObjectMode === 'texture') {
      profile = 'regular'
    }
    if (!solid) {
      if (profile !== 'regular') {
        profile = "transparent"
      } else {
        profile = 'transparent';
      }
      if (!this.transMode) {
        profile = 'invisible';
      }
      info.opacity = 'transparent'
      info.checked = false;
    }
    dfx.modify(elem, { profile });
    if (info.children.length && profile !== 'invisible') {
      // this.applyProfileToChildren(info);
    }
    if (info && info.sensors) {
      info.sensors.forEach((sensor) => {
        let _profile = profile === 'invisible' ? profile : 'regular';
        let elem = this.datauxview.getElementId(sensor);
        let isSensorT = this.sensors.includes(sensor) && sensor.includes('target');
        let isCameraT = this.cameras.includes(sensor) && sensor.includes('target');
        if (isSensorT && this.sensorTargetOff) {
          _profile = 'invisible'
        }
        if (isCameraT && this.cameraTargetOff) {
          _profile = 'invisible'
        }
        if (elem) {
          dfx.modify(elem, { profile: _profile });
        }
      })
    }
  }

  /**
  * method for get center position of child mesh
  */
  getAverageCenter(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = [0, 0, 0]
    meshes.forEach((mesh) => {
      let pt = mesh.getBoundingInfo().boundingSphere.centerWorld
      c[0] += pt.x; c[1] += pt.y; c[2] += pt.z;
    });
    return { x: c[0] / l, y: c[1] / l, z: c[2] / l }
  }

  focusMode = false;
  focusElem(id, state) {
    let camera = this.dfx.getCamera();
    let s = state ? 'hide' : 'solid';
    this.resetElemState(s, id);
    let elem = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(elem);
    this.animEnv();
    if (!this.lastCamState) {
      this.lastCamState = this.getCamState();
    }
    let p = this.compDefPos[id];
    let _view = null;
    if (p && p.bound) {
      let dr = p.bound[1];
      let target = p.bound[0];
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(3, ds);
      _view = { distance: ds, target: this.getAverageCenter(mesh) }
    }
    if (_view) {
      this.moveCameraTo(null, _view);
    } else {
      this.moveCameraTo(elem);
    }
  }

  focusChildElem(id) {
    let camera = this.dfx.getCamera();
    let elem = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(elem);
    if (!this.lastCamState) {
      this.lastCamState = this.getCamState();
    }
    let p = this.compDefPos[id];
    let _view = null;
    if (p && p.bound) {
      let dr = p.bound[1];
      let target = p.bound[0];
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(3, ds);
      _view = { distance: ds, target: this.getAverageCenter(mesh) }
    }
    if (_view) {
      this.moveCameraTo(null, _view);
    } else {
      this.moveCameraTo(elem);
    }
  }
  getFocusElem() {
    let foc_elem = null;
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        if (c.focus) {
          foc_elem = c.id;
        }
      })
    }
    return foc_elem;
  }
  checkForFocusElem() {
    let foc_elem = this.getFocusElem();
    if (foc_elem) {
      this.focusElem(foc_elem, 'solid');
    }
  }

  getChildElems(key) {
    let shipElems = [];
    if (!this.objDrillDownDataPath) return shipElems;
    let shipCompCtls = this.objDrillDownDataPath[key];
    let comp = shipCompCtls.find(el => el.id === key);
    if (comp && comp.children.length) {
      shipElems = comp.children.map(el => el.id);
    }
    return shipElems;
  }

  componentLevel = [];
  componentDef = {};
  resetElemState(state = 'solid', exp = null) {
    let shipElems = this.shipElems;
    let dfx = this.getDatascape();
    if (state == 'solid') {
      this.componentLevel.pop();
      shipElems = this.shipElems;
      if (!this.componentLevel.length) {
        this.disableTrans = false;
        this.focusMode = false;
      } else {
        let key = this.componentLevel[this.componentLevel.length - 1];
        this.focus_title = this.componentDef[key]['name'];
        this.focus_col = this.componentDef[key]['color'];
        this.focusChildElem(key);
      }
    } else {
      this.focusMode = true;
      this.disableTrans = true;
      this.componentLevel.push(exp);
      if (this.componentLevel.length >= 2) {
        let len = this.componentLevel.length;
        let key = this.componentLevel[len - 2];
        shipElems = this.getChildElems(key);
      }
    }
    this.shipElems.forEach((c) => {
      if (!this.isSubSys(c)) {
        return
      }
      let elem = this.datauxview.getElementId(c);
      let info = this.getCtrlData(c);
      let profile = info ? info.profile || 'regular' : 'regular';
      if (this.viewObjectMode === 'texture') {
        profile = 'regular'
      }
      if (state != 'solid') {
        profile = 'invisible'
      } else {
        if (info) {
          if (info.opacity === 'transparent' && info.checked === false) {
            if (profile !== 'regular') {
              profile = "transparent"
            } else {
              profile = 'transparent';
            }
            if (!this.transMode) {
              profile = 'invisible';
            }
          } else {
            info.opacity = 'solid'
          }
        }
      }
      dfx.modify(elem, { profile: "regular" });
      dfx.modify(elem, { profile });
      if (info.children.length && profile !== 'invisible') {
        // this.applyProfileToChildren(info);
      }
      //this.applyLightConeProfile()
      let f = this.getFocusElem();
      if (f) {
        let i = this.getCtrlData(f);
        i.focus = false;
      }
      if (info && info.sensors) {
        info.sensors.forEach((sensor) => {
          let _profile = profile === 'invisible' ? profile : 'regular';
          let elem = this.datauxview.getElementId(sensor);
          let isSensorT = this.sensors.includes(sensor) && sensor.includes('target');
          let isCameraT = this.cameras.includes(sensor) && sensor.includes('target');
          if (isSensorT && this.sensorTargetOff) {
            _profile = 'invisible'
          }
          if (isCameraT && this.cameraTargetOff) {
            _profile = 'invisible'
          }
          if (elem) {
            dfx.modify(elem, { profile: _profile });
          }

        })
      }
    })
    if (exp) {
      let elem = this.datauxview.getElementId(exp);
      let info = this.getCtrlData(exp);
      let profile = info ? info.profile || 'regular' : 'regular';
      this.focus_title = "";
      this.focus_col = "#fff";
      if (this.viewObjectMode === 'texture') {
        profile = 'regular'
      }
      if (info) {
        this.focus_title = info.name;
        this.focus_col = info.color;
        this.componentDef[this.focus_title] = { name: this.focus_title, color: this.focus_col };
        info.focus = true;
        /* if (profile !== 'regular') {
          profile = info.profile + '_transparent'
        } else {
          profile = 'transparent';
        }
        info.opacity = 'transparent' */
      }
      dfx.modify(elem, { profile });
      if (info.children.length && profile !== 'invisible') {
        // this.applyProfileToChildren(info);
      }
      if (info && info.sensors) {
        info.sensors.forEach((sensor) => {
          let profile = 'regular';
          let elem = this.datauxview.getElementId(sensor);

          if (elem) {
            dfx.modify(elem, { profile });
          }
        })
      }
    } else {
      if (this.lastCamState) {
        this.moveCameraTo(null, this.lastCamState);
        this.lastCamState = null;
      }
    }
  }
  applyProfileToChildren(pinfo) {
    let dfx = this.datauxview.getDatascape();
    pinfo.children.forEach((c) => {
      let comp = this.datauxview.getElementId(c);
      let info = this.getCtrlData(c);
      let profile = 'regular';
      if (info && info.color) {
        profile = info.profile || this.addNewProfileColor(info.color);
        info.profile = profile;
      }
      if (this.viewObjectMode === "texture") {
        profile = "regular";
      }
      if (info && pinfo.opacity == 'transparent') {
        if (pinfo.profile != 'invisible') {

          if (pinfo.profile != 'regular') {
            profile = 'transparent'
          } else {
            profile = 'transparent';
          }
        }
      }
      dfx.modify(comp, { profile: 'regular' })
      dfx.modify(comp, { profile })
    })
  }
  addNewProfileColor(col, addTex = true, prefix = "", model = null, trans = 0.3, bfc = true) {
    if (!col) {
      return;
    }
    let pcol = this.color_map[col];
    if (!pcol) {
      let dfx = this.getDatascape();
      let obj = { "id": "dfx_col1", "diffuse": [1, 1, 1], "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "backFaceCulling": bfc }
      let obj_transp = { "id": "dfx_col2_transparent", "diffuse": [1, 1, 1], "alpha": trans, "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "backFaceCulling": bfc }
      obj.id = prefix + "dfx_" + col;
      obj.diffuse = this.getHexToRGB(col);
      obj.ambient = [obj.diffuse[0] / 4, obj.diffuse[1] / 4, obj.diffuse[2] / 4]
      if (addTex) {
        obj['diffuseTexture'] = { "name": this.getTexture(8, 8, col) }
      } else {

      }
      //obj.ambient = obj.diffuse;
      let mat = [obj];
      dfx.addNewMaterials(mat);
      obj_transp.id = prefix + "dfx_" + col + "_transparent";
      obj_transp.diffuse = obj.diffuse;
      obj_transp.ambient = obj.ambient
      if (addTex) {
        obj_transp['diffuseTexture'] = obj['diffuseTexture']
      } else {

      }
      //obj_transp.ambient = obj.diffuse;
      obj_transp.alpha = trans;
      let mat2 = [obj_transp];
      dfx.addNewMaterials(mat2);

      this.addNewProfileToModels(prefix + "dfx_" + col, model);
      this.color_map[col] = prefix + "dfx_" + col
    } else {
      if (model) {
        this.addNewProfileToModels(this.color_map[col], model);
      }
    }
    return this.color_map[col];
  }
  texCAN
  createContext(width, height) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
  getTexture(w = 8, h = 8, col = "#ffffff") {
    if (!this.texCAN) {
      this.texCAN = this.createContext(w, h)
    }
    let ctx = this.texCAN.getContext("2d");
    ctx.clearRect(0, 0, w, h);
    ctx.beginPath();
    ctx.rect(0, 0, w, h);
    ctx.fillStyle = col;
    ctx.fill();
    let png = this.texCAN.toDataURL();
    return png;
  }
  addNewProfileToModels(prf, model = null) {
    let dfx = this.getDatascape();
    let prfs = { profiles: {} }
    let _p = {
      material: "",
      visible: true,
      selectable: true,
      transition: 0
    };
    let p = this.clone(_p);
    p.material = prf;
    prfs.profiles[prf] = p;
    p = this.clone(_p);
    p.material = prf + "_transparent";
    prfs.profiles[prf + "_transparent"] = p;
    if (model) {
      dfx.addProfiles(model, prfs.profiles);
    } else {
      this.shipComps.forEach((c) => {
        let model = this.datauxview.getElementId(c);
        if (model) {
          dfx.addProfiles(model, prfs.profiles);
        }
      })

      this.sensors.forEach((c) => {
        let model = this.datauxview.getElementId(c);
        if (model) {
          dfx.addProfiles(model, prfs.profiles);
        }
      })
      if (this.targetmodel) {
        dfx.addProfiles(this.targetmodel, prfs.profiles);
      }
    }
  }
  targetmodel
  comp_target_arr = {};
  addTargetModel() {
    let dfx = this.datauxview.getDatascape();
    let opt = {
      shape: 'cameratarget',
      id: 'm_cameratarget',
      size: 1,
      profiles: {
        regular: {
          material: 'see_me_white',
          transition: 0
        },
        target: {
          material: 'target',
          transition: 0
        },
        stop: {
          material: 'stop',
          transition: 0
        }
      }
    }

    this.targetmodel = dfx.model(opt)
  }
  heli_targetmodel
  addHeliTargetModel() {
    let dfx = this.datauxview.getDatascape();
    let opt = {
      shape: 'cube',
      id: 'm_cube',
      size: 1,
      profiles: {
        regular: {
          material: 'see_me_white',
          transition: 0
        },
        target: {
          material: 'target_heli',
          transition: 0
        },
        stop: {
          material: 'stop_heli',
          transition: 0
        }
      }
    }

    this.heli_targetmodel = dfx.model(opt)
  }
  changeTargetProfile(targ, boo = false) {
    let dfx = this.datauxview.getDatascape();
    let profile = boo ? 'target' : 'stop';
    dfx.modify(targ, { profile })
  }
  getElementMaxY(el) {
    let dfx = this.datauxview.getDatascape();
    let mesh = dfx.getElementMesh(el);
    let size = mesh.getBoundingInfo().boundingBox.maximumWorld.y;
    return size;
  }
  createCompTarget(key, elem) {
    let dfx = this.datauxview.getDatascape();
    let info = this.getCtrlData(key);
    let profile = info.profile;
    let id = 'comp_target_' + key + "";
    let center = this.getElemCenter(elem);
    let dr = this.getElementMaxY(elem) + 1;//this.getElemRadius(elem) * 0.75
    let pos: any = { x: center.x, y: dr, z: center.z };
    if (!this.targetmodel) {
      this.addTargetModel();
    }
    let orientation = { x: 0, y: 0, z: 0 }
    if (key === 'CH148') {
      pos = { x: -0.5, y: 5.5, z: 60.8 }
      orientation = { x: 90, y: 180, z: 0 }
      if (!this.heli_targetmodel) {
        this.addHeliTargetModel();
        this.addNewProfileColor(info.color, true, "", this.heli_targetmodel)
      }
    }
    this.comp_target_arr[key] = []
    let p
    this.comp_target_arr[key][0] = p = dfx.add({
      id,
      model: key === 'CH148' ? this.heli_targetmodel : this.targetmodel,

      geometry: { position: { x: pos.x, y: pos.y, z: pos.z }, orientation, size: 0.5 }
    });
    let child1 = this.comp_target_arr[key][1] = dfx.add({
      id: id + "_c_1",
      model: key === 'CH148' ? this.heli_targetmodel : this.targetmodel,
      profile: 'target',
      geometry: { position: { x: 0, y: 0, z: -0.1 }, size: 1 }
    });
    if (key === "CH148") {
      dfx.setElementScale(p, [4.8, 4.8 / 3, 0.1])
    }
    dfx.modify(p, { profile })
    //dfx.modify(child1, { profile: "regular" })

    dfx.setAsParent(p, [child1]);
    if (key !== "CH148") {
      dfx.getElementMesh(p).billboardMode = 7;
    }
  }

  /**
   * dynamic color Profile
   * TO DO - create it as service and add it to datauxview
   */
  color_map = {};
  getHexToRGB(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
  }

  /**
  * Sensor Hide/ Show
  */
  sensorTargetOff = false;
  cameraTargetOff = false;
  compTargetOff = false;
  multipleTagStatus: boolean = false;
  targetHideShow(e) {
    this.compTargetOff = !e;
    this.animatorService.setTagStatus('compTargetOff', this.compTargetOff);
    if (!this.multipleTagStatus) {
      if (this.disableTrans) {
        this.showHideCompTargets(false);
      } else {
        this.showHideCompTargets(e);
      }
    }
  }

  isCompVisible(c) {
    let comp = this.datauxview.getElementId(c);
    let dfx = this.getDatascape();
    let props = dfx.props(comp);
    let vis = props.profile.visible;
    return vis;
  }

  showHideCompTargets(_boo, key = 'all') {
    let boo = _boo;
    if (_boo && this.compTargetOff) {
      boo = false;
    }
    this.compTargets.forEach((c) => {
      if (key === 'all' || c === key) {
        let arr = this.comp_target_arr[c];
        if (arr && arr.length) {
          let el = arr[0];
          let info = this.getCtrlData(c);
          let elm = this.getDatascape().getElementMesh(el);
          let cboo = this.isCompVisible(c) && info.selected;
          elm.isVisible = boo ? cboo : boo;
          elm.getChildMeshes().forEach((m) => {
            m.isVisible = elm.isVisible;
          })
          if (elm.isVisible) {
            this.changeTargetProfile(arr[1], !info.playing)
          }

        }
      }
    })
  }

  /***
   * obj pick show tag
   */
  selectedSensorItem: any = [];
  selectedCameraItem: any = [];
  shipSmartSensor = [];
  tagStatus: any = false;
  shipCompFocus(id, action) {
    let cat = Object.keys(this.shipCompsCntls);
    cat.forEach(element => {
      this.shipCompsCntls[element].forEach((elem, i) => {
        // _console.log("element", elem);
        if (action == 'pickElem') {
          if (elem.id == id) {
            this.shipCompsCntls[element][i].focus = this.tagStatus;
          }
        }
        if (action == 'multiTag') {
          this.shipCompsCntls[element][i].focus = false;
        }
      });
    });
  }

  /***
  * shipcomponent status check
  */
  shipCompFocusState: any = false;
  shipCompFocusElem: any = '';
  shipCompFocusStatus() {
    this.shipCompFocusState = false;
    this.shipCompFocusElem = '';
    let cat = Object.keys(this.shipCompsCntls);
    cat.forEach(element => {
      let idx = this.shipCompsCntls[element].findIndex(e => e.focus == true);
      if (idx > -1) {
        this.shipCompFocusState = true;
        _console.log("shipCompFocusStatusr", this.shipCompFocusState);
        this.shipCompFocusElem = this.shipCompsCntls[element][idx];
      }
    })
    _console.log("shipCompFocusStatus", this.shipCompFocusState)
  }

  sensorHideShow(e, hideSens = false) {
    // _console.log(e);
    // let allObject = this.datauxview.allObjects();
    // let allObjKeys = Object.keys(allObject);
    this.sensorTargetOff = !e;
    this.shipSmartSensor.forEach((element, i) => {
      var object: any = element.target.split('_');
      let parent_data = null;
      let eboo = e;
      if (object[0] == 'sensor') {
        let datascape = this.datauxview.getDatascape();
        let target = this.datauxview.getElementId(element.target);
        let sensor = this.datauxview.getElementId(element.id);
        let domodify = true;
        if (this.disableTrans) {
          let props = datascape.props(sensor);
          domodify = props.profile.visible;
          this.shipCompFocusStatus();
          // if (!this.shipCompFocusState) {
          //   domodify =
          // }
          let idx = this.shipCompFocusElem.sensors.findIndex(e => e == element.id)
          if (idx > -1) {
            domodify = true;
          }
        } else {
          parent_data = this.getCtrlData(element.id, 'sensors', 'contains');
          if (parent_data) {
            let parent = this.datauxview.getElementId(parent_data.id);
            let props_p = datascape.props(parent);
            domodify = props_p.profile.visible;
          }
        }
        if (domodify) {
          if (parent_data) {
            if (!parent_data.selected) {
              eboo = false;
            }
          }
          if (eboo) {
            datascape.modify(target, { profile: 'regular' })
            datascape.modify(sensor, { profile: 'regular' })
          } else {
            datascape.modify(target, { profile: 'invisible' })
            datascape.modify(sensor, { profile: 'invisible' })
          }
          if (hideSens || eboo) {
            if (eboo) {
              datascape.modify(sensor, { profile: 'regular' })
            } else {
              datascape.modify(sensor, { profile: 'invisible' })
            }
          }
        }
      }
    });
  }

  /**
  * Camera Hide/ Show
  */
  cameraHideShow(e, hidecam = false) {
    // _console.log(e);
    // let allObject = this.datauxview.allObjects();
    // let allObjKeys = Object.keys(allObject);
    this.cameraTargetOff = !e;
    this.shipCameras.forEach((element, i) => {
      var object: any = element.target.split('_');
      if (object[0] == 'camera') {
        let datascape = this.datauxview.getDatascape();
        let target = this.datauxview.getElementId(element.target);
        let camera = this.datauxview.getElementId(element.id);
        let domodify = true;
        if (this.disableTrans) {
          let props = datascape.props(camera);
          domodify = props.profile.visible;
        } else {
          let parent_data = this.getCtrlData(element.id, 'sensors', 'contains');
          if (parent_data) {
            let parent = this.datauxview.getElementId(parent_data.id);
            let props_p = datascape.props(parent);
            domodify = props_p.profile.visible;
          }
        }
        if (domodify) {
          if (e) {
            datascape.modify(target, { profile: 'regular' })
          } else {
            datascape.modify(target, { profile: 'invisible' })
          }
          if (hidecam || e) {

            // _console.log("camera", element, e);
            if (e) {
              datascape.modify(camera, { profile: 'regular' })
            } else {
              datascape.modify(camera, { profile: 'invisible' })
            }
          }
        }
      }
      // if (object[1] == 'RIM162ESSM1') {
      //   let datascape = this.datauxview.getDatascape();
      //   let camera = this.datauxview.getElementId(element);
      //   // _console.log("camera", element, e);
      //   if (e) {
      //     datascape.modify(camera, { profile: 'regular' })
      //   } else {
      //     datascape.modify(camera, { profile: 'invisible' })
      //   }
      // }
    });
  }

  showHideLand(boo) {
    let dfx = this.datauxview.getDatascape();
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    mesh.isVisible = boo;
  }
  _alreadyinframe: any;
  env_black = { r: 0.85, g: 0.85, b: 0.85, a: 1 };
  env_sky = { r: 0.65, g: 0.65, b: 0.65, a: 1 };
  env_black_a = { r: 0.85, g: 0.85, b: 0.85, a: 1 };
  envID
  animEnv() {
    let m = this.env_sky.r - this.env_black.r;
    let time = 100;
    let dm = m / time;
    let r = this.env_black_a.r;
    let g = this.env_black_a.g;
    let b = this.env_black_a.b;
    let datascape = this.datauxview.getDatascape();
    let scene = datascape.getCamera().getScene();
    let land = this.datauxview.getElementId('land');
    if (this.envID) {
      //this.stopEnvAnim();
      return;
    }
    //datascape.modify(land, { profile: 'invisible' })
    this.showHideLand(!true);
    this.envID = setInterval(() => {
      r = r + dm;
      g = g + dm;
      b = b + dm;
      scene.clearColor.r = r;
      scene.clearColor.g = g;
      scene.clearColor.b = b;
      this.env_black_a = { r, g, b, a: 1 };
      if (r >= this.env_sky.r) {
        this.stopEnvAnim();
      }
    }, 1)
  }
  stopEnvAnim(reset = false) {
    let datascape = this.datauxview.getDatascape();
    let scene = datascape.getCamera().getScene();
    let land = this.datauxview.getElementId('land');
    clearInterval(this.envID);
    this.envID = null;
    if (reset) {
      scene.clearColor.r = this.env_black.r;
      scene.clearColor.g = this.env_black.g;
      scene.clearColor.b = this.env_black.b;
      this.env_black_a = this.clone(this.env_black);
      // datascape.modify(land, { profile: 'regular' })
      this.showHideLand(true);
    }
  }
  getDatascape() {
    return this.datauxview.getDatascape();
  }
  lastCamState = null;
  getCamState() {
    let dfx = this.getDatascape();
    let cam = dfx.getCamera();
    let distance = cam.distance.value;
    let target = { x: cam.targetX.value, y: cam.targetY.value, z: cam.targetZ.value };
    let yaw = cam.yaw_deg.value;
    let pitch = cam.pitch_deg.value;
    let view = { distance, target, yaw, pitch };
    return view;
  }
  reapplyDrag() {
    if (!this.dragMode) {
      this.removeDrag("null", true);
      return;
    }
    let dfx = this.datauxview.getDatascape();
    this.shipComps.forEach((key) => {
      let child = this.dbobj[key][1];
      if (!child.isEnabled()) {
        return;
      }
      this.removeDrag(key);
      child.isPickable = !false;
      let b = dfx.getDragBehavior([0, 0, 1])
      b.useObjectOrientationForDragging = false;
      child.addBehavior(b);
      this.dbobj[key][0] = b;
    })
  }
  removeDrag(m, except = false) {
    if (m && except) {
      let keys = Object.keys(this.dbobj);
      keys.forEach((key) => {
        if (key != m) {
          if (this.dbobj[key][0])
            this.dbobj[key][1].removeBehavior(this.dbobj[key][0]);
          this.dbobj[key][0] = null;
        }
      })
    } else if (m) {
      if (this.dbobj[m] && this.dbobj[m][0]) {
        this.dbobj[m][1].removeBehavior(this.dbobj[m][0]);
        this.dbobj[m][0] = null;
      }
    }
  }
  resetChildren() {
    let keys = Object.keys(this.dbobj);
    keys.forEach((key) => {
      this.resetChild(key);
    })
  }
  resetChild(m) {
    let obj = this.dbobj[m]
    if (obj) {
      let p = this.children[m].pos;
      let mesh = obj[1];
      mesh.position.x = p.x;
      mesh.position.y = p.y;
      mesh.position.z = p.z;
    }
  }

  objDrag: boolean = false;
  altKey: boolean = false;
  targetClicked: boolean = false;
  dragChange(e) {
    _console.log("drag", e);
  }

  /**
  * creating a key event to show/hide positioning tool
  */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    this.altKey = event.altKey;
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) {
    const key = event.key.toString().toLowerCase();
    this.altKey = false;
    if (event.shiftKey && key === 't') {
      this.controlPanelState = true;
    }
    if (((key === 'v') && event.shiftKey === true)) {
      this.positiontool = !this.positiontool;
    } else if (((key === 's') && event.shiftKey === true)) {
      /* if (!this.socketcontroler) {
        this.positiontoolcomponent.opencontant((result) => {
          this.socketcontroler = result;
        });
      } else {
        this.positiontoolcomponent.closecontant();
        this.socketcontroler = false;
      } */
    }
  }

  /**
  * Zoom event to zoom in / out the venue
  */
  isMouseDown
  mousestate = "up"
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }
  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }
  @HostListener('document:mousepress', ['$event'])
  handleMouseDownEvent(event: MouseEvent) {
    this.mousestate = "down";
    //  if (this.onHoverElem) {
    //    this.offHover(this.onHoverElem);
    //  }
  }
  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.targetClicked = true;
      this.stopZoom();
    }
    this.isMouseDown = !true;
    this.mousestate = "up";
    //  if (this.onHoverElem) {
    //    this.offHover(this.onHoverElem);
    //  }   
    setTimeout(() => {
      this.targetClicked = !true;
    }, 750)
  }

  /**
   * inexxt building show/hide   
   */
  inexxtBuildingTransToggle(boo) {
    this.shipCompsCntls['Components'].forEach((info) => {
      let name = info.id;
      if (boo) {
        let id = name.split(" ").join("").toLowerCase();
        if (id === 'groundfloo') {
          info.visible = true;
          info.selected = false;
          this.ctrlServ.transparentElement(name);
        } else if (id === 'planting') {
          info.visible = true;
          info.selected = true;
          this.ctrlServ.showElement(name);
        } else {
          info.visible = false;
          info.selected = true;
          this.ctrlServ.hideElement(name);
        }
      } else {
        info.visible = true;
        info.selected = true;
        this.ctrlServ.showElement(name);
      }
    })
    if (boo) {
      this.changeCamView('idg-focus');
    } else {
      this.changeCamView('home');
    }
    this.broadcastInfo({ src: 'inexxtmodel', event: 'update-shipcompscntls', data: this.shipCompsCntls });
    this.broadcastInfo({ src: 'main', event: 'doreset', data: '' });
  }

  closeSubSystemView() {
    // this.processToAnimate(this.tagElement.id,false)
    this.focusMode = false;
    let f = this.getFocusElem()
    this.onControlChange({ event: 'locate', name: f, state: false });
  }

  /**
  * code to fetch shapes and objects def for objects.json 
  *  and components def
  */
  getObjDef() {
    let scope = this;
    var el = scope.datauxview.getElementId('sina_crn_building');
    var mesh = scope.dfx.getElementMesh(el);
    var meshes = mesh.getChildren();
    let shapes = [];
    let objects = []
    meshes.forEach((m) => {
      let mname = scope.ctrlServ.getMeshName(m.name);
      let obj = {}
      obj["dfx-" + mname] = { "model": mname, "material": "custom-included", "size": 1 }
      shapes.push(obj);
      let prop = { "type": "static", "parent": "", "name": mname, "model": "dfx-" + mname, "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } }
      objects.push(prop)
    })
    let controls = [];
    objects.forEach((m) => {
      let o = {
        "name": m.name, "id": m.name, "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false
      }
      controls.push(o)
    })
    return { shapes, objects, controls }
  }
  getObjDefJSON() {
    let def = this.getObjDef();
    console.log("------- SHAPES -------");
    console.log(JSON.stringify(def.shapes))
    console.log("------- OBJECTS -------");
    console.log(JSON.stringify(def.objects));
    console.log("------- CONTROLS -------");
    console.log(JSON.stringify(def.controls));
  }


  resetShipCompCtls() {
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        c.focus = false;
        c.opacity = 'solid';
        c.selected = true;
        c.visible = true;
      })
    }
  }

  objDrillDownDataPath: any = [];
  onLocCtl(event, key) {
    let info;
    let objData = this.shipCompsCntls[event.key];
    let IndexData = objData.findIndex(data => data.id == event.id);
    if (IndexData != -1) {
      info = this.shipCompsCntls[event.key][IndexData]
      this.shipCompsCntls[event.key] = info['children']
      this.generateParentId(info['children'], event.id);
      this.objDrillDownDataPath[event.id] = objData;
    }
    this.resetShipCompCtls();
    this.bckupShip_comp_ctl_list = JSON.stringify(this.shipCompsCntls)
  }

  generateParentId(children, parentId) {
    children.forEach(element => {
      element['parentId'] = parentId;
    });
  }

  closeBack(comp, key) {
    if (this.objDrillDownDataPath[comp.parentId] != undefined) {
      this.shipCompsCntls[key] = this.objDrillDownDataPath[comp.parentId];
      this.resetShipCompCtls();
      this.broadcastInfo({ src: 'starterapp', event: 'drill-down', data: this.shipCompsCntls, key: "" });
    }
  }


}
