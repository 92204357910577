
import { GraphBuilder } from "./builder";


declare var BABYLON;

export class NSCModel {
  dfx;
  datauxview;
  scene
  isRightClick: any = false;
  hideUnselectedState: boolean = false;
  hideSubBranchState: boolean = false;
  showPathState: boolean = false;
  current_vid = null;
  networkData: any = [];
  showConnector = false;
  transparentNodes: any = [];
  nodeList: any;
  configService;
  broadcastService;
  ern = ""
  resetLabel = false;
  broadcastSubs
  completedNodeList = [];

  constructor(_configService, _broadcastService, _ern = null) {
    this.configService = _configService;
    this.broadcastService = _broadcastService;
    if (_ern) {
      this.ern = _ern;
    }
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      if (event.which == 1) {
        this.rightclick = false;
      }
      this.isRightClick = false;
      if (event.which == 3) {
        this.isRightClick = true;
      }
    })
    canvas.addEventListener("pointerup", (event) => {
      if (event.which == 1) {
        this.rightclick = true;
      }
    })

    this.broadcastSubs = this.broadcastService.getInstance()
    .subscribe((data: any) => {
      if (data.src == 'coursePanel') {
          if (data.event == 'updateNodeColor') {
            if (data['node']) {
              this.completedNodeList = data['node'];              
            }
          }
        }
    })
  }
  repfact = 1;
  phy = null;
  slen = 20;
  alist = [];
  arr;
  stiff = 200;
  damp = 5;
  slen2 = 10;
  forcemap = {};
  gbbuild;
  gbspec
  animID
  game_engine;

  showCompsAsNetwork(cb) {
    // this.loadNetworkData((data) => {
    this.gbspec = this.networkData;
    let gb = new GraphBuilder(this.gbspec, this.datauxview, this.broadcastService);
    gb.build();
    this.gbbuild = gb;
    cb();
    // })
  }

  // loadNetworkData(cb) {
  //   this.configService.loadFile('../../../assets/scs/data/network.json').then((info) => {
  //     console.log(info);
  //     cb(info)
  //   })
  // }

  renderNetworkModel() {
    let scope = this;
    let scene = this.scene;
    if (!scope.gbbuild) {
      scope.showCompsAsNetwork(() => {
        scope.startNetworkAnim();
      });
    }
    scene.lights.forEach((light) => {
      light.intensity = 1
    })
    //this.moveCameraTo(null, { target: { x: -4.2, y: -61.0, z: -11.6 }, distance: 235 })
  }
  view_dpr
  startNetworkBuild() {
    let scope = this;
    let dfx = this.dfx
    let obj = scope.gbbuild.graph.vertices;
    let keys = Object.keys(obj);
    keys.forEach((i) => {
      let v = obj[i].vid;
      this.addCone(v);
    })
    this.updateConeGeom();
    this.hideLevelZero();
    // this.transparentRectangular(true);
    this.gbbuild.graph.updateLayout();
    this.gbbuild.graph.graphicsManager.ern = this.ern;
    cancelAnimationFrame(this.animID);
    let anim = (timestamp) => {
      if (this.view_dpr != window.devicePixelRatio) {
        this.view_dpr = window.devicePixelRatio;
        if (this.game_engine) {
          this.game_engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
        }
      }
      this.updateNodeLabel();
      this.gbbuild.graph.graphicsManager.updateTag();
      this.animID = window.requestAnimationFrame(anim);
    }
    this.animID = window.requestAnimationFrame(anim);
  }
  /**
   * method rectangle nodes transparent
   */
  transparentRectangular(boo) {
    this.networkData['vertices'].forEach(node => {
      if (node['shape'].toLowerCase() === 'rectangle') {
        let vid = node['id'];
        let vtx = this.gbbuild.graph.getVertex(vid);
        let mesh_n = vtx.mesh;
        let props = this.dfx.props(mesh_n);
        let prf = props.tag.default_prf || 'regular';
        let profile;
        if (boo) {
          profile = prf + '_transparent';
        } else {
          profile = prf
        }
        this.dfx.modify(mesh_n, { profile })
      }
    })
  }
  /**
   * method selected chart model hide/show
   */
  transparentChartModel(boo) {
    this.transparentNodes.forEach(vid => {
      let vtx = this.gbbuild.graph.getVertex(vid);
      let mesh_n = vtx.mesh;
      let props = this.dfx.props(mesh_n);
      let prf = props.tag.default_prf || 'regular';
      let profile;
      if (boo) {
        profile = 'transparent';
      } else {
        profile = prf
      }
      this.dfx.modify(mesh_n, { profile })
    })
  }
  hideLevelZero() {
    let m = this.getDFXElem(1);
    let e = this.gbbuild.graph.getEdge(1).mesh;
    let vm = this.dfx.getElementMesh(m);
    e.setEnabled(false);
    vm.setEnabled(false);
  }
  /**
   * method for is rectangle node/not
   */
  isRectangleNode(vid) {
    let networkData = this.networkData['vertices'];
    const found = networkData.some(node => {
      if (node['id'] === vid) {
        return node['shape'].toLowerCase() === 'rectangle';
      }
    })
    return found;
  }
  /**
   * cone tree model -start
   */
  coneObjs = [];
  coneNode = {};
  defNodeR = 10;
  minCR = 16;
  coneProps = { dheight: 27, dang: 0, dradius: this.defNodeR, minC: 2 * Math.PI * this.minCR };
  updateNodeLabel() {
    let dfx = this.datauxview.getDatascape();
    let cam = dfx.getCamera()
    let obj = this.gbbuild.graph.vertices;
    let keys = Object.keys(obj);
    let lab_parent = document.querySelector("#" + this.ern + "nodes_label");
    if (!lab_parent) {
      return;
    }
    if (this.showConnector) {
      this.renderConnector();
    } else {
      let conn: any = this.getConnectorNode();
      if (conn != null) {
        conn.style.display = "none";
      }
    }
    for (var i = 1; i < keys.length; i++) {
      let id1 = parseInt(keys[i]);
      let data: any = this.gbspec.vertices[id1 - 1];
      let lab: any = lab_parent.querySelector("#" + this.ern + "nl_" + i);
      if (!lab) {
        lab = this.addNodeLabel(i, data.btext, data.color, lab_parent);
      }
      let v1 = (obj[id1]);
      let s = dfx.getElementPosition(v1['mesh']);
      let m = dfx.getElementMesh(v1['mesh']);
      let op = 1;
      if (m.material.id.includes("transparent")) {
        op = 0.3;       
        // code for rectangular node transparent text
        // const found = this.transparentNodes.some(el => el === id1);
        // if (found) {
        //   op = 0.3;
        // } else {
        //   let isRectNode = this.isRectangleNode(id1);
        //   op = isRectNode ? 1 : 0.3;
        // }
      }
      // let oh=m.getBoundingInfo().boundingSphere.radiusWorld
      let mcam = BABYLON.Vector3.Distance(cam.position, m.position);
      if (lab != null) {
        if (s.visibility) {
          let pt = s.position2D;
          let offw = lab.clientWidth / 2;
          let isRectNode = this.isRectangleNode(id1);
          let textHeight = isRectNode ? 1 : 1.25;
          let offh = lab.clientHeight * textHeight;
          lab.style.left = (pt[0] - offw) + 'px';
          lab.style.bottom = (pt[1] - offh) + 'px';
          lab.style.display = 'inline-block';
          lab.style.opacity = op;
          lab.style.fontSize = (Math.floor(250 * 7 / mcam) || 1) + 'px';
          if(isRectNode){
            lab.style.zIndex = (Math.floor(250 * 7 / mcam) || 1); 
            lab.style.width = (Math.floor(2200 * 7 / mcam) || 90) + 'px';  
            lab.style.height = (Math.floor(350 * 7 / mcam) || 14) + 'px';
            lab.style.whiteSpace = "nowrap";
            lab.style.overflow = "hidden"; 
            lab.style.textOverflow = "ellipsis"; 
            const found = this.completedNodeList.some(el=> el.id === id1);
            if(found){
              lab.style.background = "#10c516"; 
            }           
          }  
        } else {
          lab.style.display = 'none';
        }
      }
    }
  }
  addNodeLabel(id, label,bgColor, parent) {
    let name = document.createElement("span");
    name.id = this.ern + 'nl_' + id;
    name.style.position = "absolute";
    name.style.color = "#fff";
    name.style.fontSize = "14px";         
    let isRectNode = this.isRectangleNode(id);
    if(isRectNode){     
        name.style.background = bgColor;               
    }     
    name.innerHTML = label;
    parent.appendChild(name);
    return name;
  }
  removeNodeLabels() {
    if (this.gbbuild) {
      if (!this.resetLabel) {
        let obj = this.gbbuild.graph.vertices;
        let keys = Object.keys(obj);
        let lab_parent = document.querySelector("#" + this.ern + "nodes_label");
        for (var i = 1; i < keys.length; i++) {
          let lab: any = lab_parent.querySelector("#" + this.ern + "nl_" + i);          
          if (lab) {
            lab_parent.removeChild(lab);
          }
        }
      }
    }
  }
  renderConnector() {
    let nl = document.querySelector("#" + this.ern + "nodes_label");
    let rec0 = nl.getBoundingClientRect();
    let h0 = rec0.height;
    let e1 = this.gbbuild.graph.graphicsManager.pickElem;
    if (this.nodeList) {
      e1 = this.nodeList;
    }
    // e1=this.nodeList;

    let conn: any = this.getConnectorNode();
    if (!e1) {
      if (conn != null) {
        conn.style.display = "none";
      }
      return
    }
    let props = this.dfx.props(e1);
    let vid = props['id'].split('_')[1];
    let pos1 = this.dfx.getElementPosition(e1).position2D;
    pos1[1] = h0 - pos1[1];
    let e2: any = document.getElementById(this.ern + "nmMaxLabel_" + vid);
    if (e2 != null) {
      if (e2.style.display === 'none') {
        e2 = null;
      }
    }
    if (!e2) {
      if (conn != null) {
        conn.style.display = "none";
      }
      return
    }
    let rect = e2.getBoundingClientRect()
    let pos2 = [rect.x, rect.y]
    let dy = pos2[1] - pos1[1];
    let dx = pos2[0] - pos1[0]
    let theta = Math.atan2(dy, dx);
    let deg = (theta * 180 / Math.PI).toFixed(2);
    let d = Math.sqrt(dx * dx + dy * dy);
    conn.style.width = d + "px";
    conn.style.transform = "rotate(" + deg + "deg)";
    conn.style.left = pos1[0] + "px";
    conn.style.top = pos1[1] + "px";
    conn.style.display = "block";
  }
  getConnectorNode() {
    // return document.querySelector(".network-comp-main .connector");
    return document.getElementById(this.ern + "connector");
  }
  addCone(n) {
    let dfx = this.datauxview.getDatascape();
    let top = this.gbbuild.graph.getVertex(n);
    let adj = this.processParentChild(n);
    let k = adj.children.length;
    let spos = n === 1 ? { x: 0, y: 0, z: 0 } : this.getParentPos(n);
    let npos = { x: 0, y: 0, z: 0 }
    this.coneNode[n] = { top, parent: adj.parent, base: adj.children, hasCone: false };
    if (k) {
      let h = this.coneProps.dheight;
      let r = this.coneProps.dradius;
      let ang = this.coneProps.dang;
      let rad = Math.PI * ang / 180;
      npos.y = spos.y - h;
      npos.x = spos.x;
      npos.z = spos.z;
      this.coneNode[n].hasCone = true;
      if (k == 1) {
        let nh = (this.getNodeH(n, adj.children[0])) * this.coneProps.dheight;
        npos.y = spos.y - nh;
        dfx.modify(this.getDFXElem(adj.children[0]), { geometry: { position: npos } });
      } else {

        let c = adj.children;
        let l = c.length;
        let dr = 2 * Math.PI / l;
        let sr = 0;
        c.forEach((i) => {
          let nh = (this.getNodeH(n, i)) * this.coneProps.dheight;
          npos.y = spos.y - nh;
          npos.x = spos.x + r * Math.cos(sr);
          npos.z = spos.z + r * Math.sin(sr);
          let el = this.getDFXElem(i);
          dfx.modify(el, { geometry: { position: npos } });
          sr += dr;
        })

      }
    }
  }
  getChildrenBaseWeight(m) {
    let c = this.coneNode[m].base;
    let w = 0
    c.forEach((n) => {
      w += this.coneNode[n].baseWeight;
    })
    return w
  }
  resetChildrenBaseWeight(m) {
    let c = this.coneNode[m].base;
    c.forEach((n) => {
      this.coneNode[n].baseWeight = this.coneNode[n].baseWeight || 1;
    })
  }
  getBaseSlots(n) {
    let bn = this.coneNode[n].base;
    let l = bn.length;
    let d = 0;
    for (let m = 0; m < l - 1; m++) {
      let _n = bn[m];
      let _s = bn[m + 1];
      d += this.coneNode[_n].baseWeight + this.coneNode[_s].baseWeight
    }
    return d;
  }
  updateConeGeom() {
    for (let m in this.coneNode) {
      this.getConeRadius(m);
    }
    /* for(let m in this.coneNode){
      let rw=this.resetChildrenBaseWeight(m);
    } */
    for (let m in this.coneNode) {
      let rw = this.getChildrenBaseWeight(m);
      this.coneNode[m].bw = rw;
      let c = (2 * (rw) * this.coneProps.dradius);
      c = Math.max(c, this.coneProps.minC);
      if (m == '2') {
        c = c * 0.75;
      }
      this.coneNode[m].radius = c / (2 * Math.PI);
    }
    for (let m in this.coneNode) {
      this.updateCone(m);
    }
  }
  updateCone(n) {
    let dfx = this.datauxview.getDatascape();
    let top = this.gbbuild.graph.getVertex(n);
    let mesh = top['mesh'];
    let cone = this.coneNode[n];
    let k = cone.base.length;
    let spos = n === 1 ? { x: 0, y: 0, z: 0 } : this.getParentPos(n);
    let npos = { x: 0, y: 0, z: 0 }

    if (cone.hasCone) {
      let h = this.coneProps.dheight;
      let r = cone.radius || this.coneProps.dradius;
      npos.y = spos.y - h;
      npos.x = spos.x;
      npos.z = spos.z;

      if (k == 1) {
        let nh = (this.getNodeH(n, cone.base[0])) * this.coneProps.dheight;
        npos.y = spos.y - nh;
        dfx.modify(this.getDFXElem(cone.base[0]), { geometry: { position: npos } });
      } else {

        let c = cone.base;
        let l = cone.length;
        let cir = 2 * Math.PI * r;
        //let bs=this.getBaseSlots(n);
        let ds = (cir * 0.5 / (cone.bw));
        let dt = ds / cone.radius;
        let sr = cone.sang || 0;
        let lastnode = 0
        c.forEach((i, idx) => {
          if (idx > 0) {
            let lr = this.getNodeR(lastnode);
            let cr = this.getNodeR(i)
            let nr = ((lr + cr) || 1) * dt;
            let _theta = nr;
            sr += _theta
          }
          let nh = (this.getNodeH(n, i)) * this.coneProps.dheight;
          npos.y = spos.y - nh;
          npos.x = spos.x + r * Math.cos(sr);
          npos.z = spos.z + r * Math.sin(sr);
          let el = this.getDFXElem(i);
          dfx.modify(el, { geometry: { position: npos } });
          lastnode = i;
          //sr+=dr;
        })

      }
    }
  }
  getNodeR(n) {
    let r = this.coneNode[n].baseWeight;
    return r;
  }
  getNodeH(n, i) {
    let data1: any = this.gbspec.vertices[n - 1];
    let data2: any = this.gbspec.vertices[i - 1];
    let r = data2.level - data1.level;
    return r || 1;
  }
  processParentChild(node) {
    let obj = this.gbbuild.graph.adjacencyList[node] || {};
    let keys = Object.keys(obj);
    let adj = { parent: null, children: [] };
    let c = 0;
    for (var m in obj) {
      var n = obj[m];
      if (n < node && node > 1) {
        adj.parent = n
      } else {
        if (!adj.children) {
          adj.children = []
        }
        adj.children.push(n)
      }
      c++
    }
    return adj
  }
  getParentPos(p) {
    let el = this.getDFXElem(p);
    let dfx = this.datauxview.getDatascape();
    let mesh = dfx.getElementMesh(el);
    return mesh.position;
  }
  getDFXElem(p) {
    let v = this.gbbuild.graph.getVertex(p);
    let el = v['mesh'];
    return el;
  }
  getConeRadius(n, p = null) {
    let base = this.coneNode[n].base;
    let bw = this.getBaseWeight(n);
    let _n = p || n;
    if (this.coneNode[_n].baseWeight === undefined) {
      this.coneNode[_n].baseWeight = 0
    }

    if (bw) {
      base.forEach((i) => {
        this.getConeRadius(i, _n);
      })
    } else {
      this.coneNode[_n].baseWeight += 1;
    }
    return 0;
  }
  getBaseWeight(n) {
    let l = this.coneNode[n].base.length;
    return l ? 1 : 0
  }

  /**
  * cone tree model -end
  */
  applyParentNodes() {
    let scope = this;
    let dfx = this.dfx
    let alist = this.alist;
    for (let i = 0; i < alist.length; i++) {
      let v1 = alist[i]
      let v = this.gbbuild.graph.getVertex(v1);
      let m = dfx.getElementMesh(v["mesh"]);
      let s = String(v1) === '1' ? 3 : 2
      m.scaling = new BABYLON.Vector3(s, s, s)
      /* if (s === 3) {
        dfx.modify(v["mesh"], { profile: 'red' })
      } else {
        dfx.modify(v["mesh"], { profile: 'yellow' })
      } */
      // this.applyLabel(v);
    }
  }
  applyRepNodes() {
    let alist = this.alist;
    for (let i = 1; i < alist.length - 1; i++) {
      let v1 = alist[i]
      for (let j = i + 1; j < alist.length; j++) {
        let v2 = alist[j];
        this.addJoint(v1, v2, 50)
      }
    }
  }
  /**
   * 5-march-2021 - working - but slow
   * @param n
   */
  applyPhyNodes_0(n) {
    let scope = this;
    let dfx = this.dfx
    let scene = this.scene;
    let o = this.gbbuild.graph.adjacencyList[n];
    let keys = Object.keys(o);
    if (keys.length < 2) {
      return;
    }
    let v = this.gbbuild.graph.getVertex(n);
    let root = n == 1
    let mass = root ? 0 : 1;
    let imp = root ? BABYLON.PhysicsImpostor.SphereImpostor : BABYLON.PhysicsImpostor.BoxImpostor
    let e1 = v["mesh"];
    let s = dfx.getElementMesh(e1);
    if (!s.physicsImpostor) {
      s.physicsImpostor = new BABYLON.PhysicsImpostor(s, imp, { mass, friction: 10, restitution: 0.0 }, scene);
    }

    for (var i = 0; i < keys.length; i++) {
      let v2 = this.gbbuild.graph.getVertex(o[keys[i]]);
      if (v2.vid <= n) {
        continue;
      }
      let root = v2.vid == 1;
      let mass = 1;
      let imp = BABYLON.PhysicsImpostor.BoxImpostor
      let e2 = v2["mesh"];
      let t = dfx.getElementMesh(e2);
      t.position.y += Math.random() * 10;
      if (!t.physicsImpostor) {
        t.physicsImpostor = new BABYLON.PhysicsImpostor(t, imp, { mass, friction: 10, restitution: 0.0 }, scene);
      }
      let len = this.slen;//(scope.gbbuild.graph.getShortestPath(1,v2.vid).length-1)*5
      var joint1 = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.SpringJoint, {
        length: len,
        stiffness: this.stiff,
        damping: this.damp
      });
      s.physicsImpostor.addJoint(t.physicsImpostor, joint1);
      if (n !== 1) {
        let l = (this.gbbuild.graph.getShortestPath(1, v2.vid).length - 1) * this.slen
        this.addJoint(1, v2.vid, l);
      }

      for (var j = i + 1; j < keys.length; j++) {
        let v3 = this.gbbuild.graph.getVertex(o[keys[j]]);
        if (v3.vid <= n) {
          continue;
        }
        let root = v3.vid == 1;
        let mass = 1;
        let imp = BABYLON.PhysicsImpostor.BoxImpostor
        let e3 = v3["mesh"];
        let u = dfx.getElementMesh(e3);
        u.position.y += Math.random() * 10;
        if (!u.physicsImpostor) {
          u.physicsImpostor = new BABYLON.PhysicsImpostor(u, imp, { mass, friction: 10, restitution: 0.0 }, scene);
        }
        let len = this.slen;//(scope.gbbuild.graph.getShortestPath(1,v3.vid).length-1)*5
        var joint2 = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.SpringJoint, {
          length: len,
          stiffness: this.stiff,
          damping: this.damp
        });
        t.physicsImpostor.addJoint(u.physicsImpostor, joint2);
      }

    }

  }
  //
  applyPhyNodes(n) {
    let scope = this;
    let dfx = this.dfx
    let scene = this.scene;
    let o = this.gbbuild.graph.adjacencyList[n];
    let keys = Object.keys(o);
    if (keys.length < 2) {
      if (!keys.length) {
        return;
      }
      let key = keys[0];
      let val = o[key];
      if (val < key) {
        return;
      }

    }
    let v = this.gbbuild.graph.getVertex(n);
    let root = n == 1
    let mass = root ? 0 : 1;
    let imp = root ? BABYLON.PhysicsImpostor.SphereImpostor : BABYLON.PhysicsImpostor.BoxImpostor
    let e1 = v["mesh"];
    let s = dfx.getElementMesh(e1);
    if (!s.physicsImpostor) {
      s.physicsImpostor = new BABYLON.PhysicsImpostor(s, imp, { mass, friction: 10, restitution: 0.0 }, scene);
    }
    for (var i = 0; i < keys.length; i++) {
      let v2 = this.gbbuild.graph.getVertex(o[keys[i]]);
      if (v2.vid <= n) {
        continue;
      }
      let root = v2.vid == 1;
      let mass = 1;
      let imp = BABYLON.PhysicsImpostor.BoxImpostor
      let e2 = v2["mesh"];
      let t = dfx.getElementMesh(e2);
      t.position.y -= Math.random() * 10;
      if (!t.physicsImpostor) {
        t.physicsImpostor = new BABYLON.PhysicsImpostor(t, imp, { mass, friction: 10, restitution: 0.0 }, scene);
      }
      let len = this.slen;//(scope.gbbuild.graph.getShortestPath(1,v2.vid).length-1)*5
      var joint1 = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.SpringJoint, {
        length: len,
        stiffness: this.stiff,
        damping: this.damp
      });
      s.physicsImpostor.addJoint(t.physicsImpostor, joint1);
      this.forcemap[n + "_" + v2.vid] = true;
      this.forcemap[v2.vid + "_" + n] = true;
      if (n !== 1) {
        let l = (this.gbbuild.graph.getShortestPath(1, v2.vid).length - 1) * this.slen
        this.addJoint(1, v2.vid, l);
      }

      for (var j = i + 1; j < keys.length; j++) {
        let v3 = this.gbbuild.graph.getVertex(o[keys[j]]);
        if (v3.vid <= n) {
          continue;
        }
        let root = v3.vid == 1;
        let mass = 1;
        let imp = BABYLON.PhysicsImpostor.BoxImpostor
        let e3 = v3["mesh"];
        let u = dfx.getElementMesh(e3);
        u.position.y -= Math.random() * 10;
        if (!u.physicsImpostor) {
          u.physicsImpostor = new BABYLON.PhysicsImpostor(u, imp, { mass, friction: 10, restitution: 0.0 }, scene);
        }

        let weight = this.getNodeWeight(v3.vid);
        let dfact = weight > 1 ? 2 : 1;
        let len = this.slen * dfact;//(scope.gbbuild.graph.getShortestPath(1,v3.vid).length-1)*5
        var joint2 = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.SpringJoint, {
          length: len,
          stiffness: this.stiff,
          damping: this.damp
        });
        this.forcemap[v3.vid + "_" + v2.vid] = true;
        this.forcemap[v2.vid + "_" + v3.vid] = true;
        t.physicsImpostor.addJoint(u.physicsImpostor, joint2);
      }
    }
  }
  applyRepulsiveForces() {
    let verts = this.gbspec.vertices;
    verts.forEach((p) => {
      this.applyRepulsiveForce(p.id);
    })

  }
  applyRepulsiveForce(v) {
    let verts = this.gbspec.vertices;
    verts.forEach((p) => {
      if (v > 1 && p.id > 1 && p.id !== v) {
        let w1 = this.getNodeWeight(v);
        let w2 = this.getNodeWeight(p.id);
        let rep = w1 > 2 && w2 > 2;//this.applyRepulsion(v, p.id);
        if (!this.hasForceMapped(v, p.id) && rep) {
          //console.log(v,p.id,w1,w2);
          let l = (this.gbbuild.graph.getShortestPath(v, p.id).length - 1) * this.slen
          this.addJoint(v, p.id, l);
        }
      }
    })
  }
  hasForceMapped(v1, v2) {
    return this.forcemap[v1 + "_" + v2] || this.forcemap[v2 + "_" + v1]
  }
  getNodeWeight(v) {
    let o = this.gbbuild.graph.adjacencyList[v];
    let keys = Object.keys(o);
    return keys.length;
  }
  getEdgeWeight(v1, v2) {
    let edgeSet = this.gbbuild.graph.getEdges(v1, v2);
    let edge = edgeSet[0];
    //console.log(edge);
    let weight = (edge === undefined) ? 1 : edge.getWeight();
    return weight
  }
  //
  applydistance(n) {
    let scope = this;
    let dfx = this.dfx
    let o = scope.gbbuild.graph.adjacencyList[n];
    let keys = Object.keys(o);
    if (keys.length < 2) {
      return;
    }
    let v = scope.gbbuild.graph.getVertex(n);
    let root = n == 1
    let e1 = v["mesh"];
    let s = dfx.getElementMesh(e1);
    let pos = s.position;
    let dx = 3;
    let ds = -(keys.length / 2) * dx + pos.x;
    for (var i = 0; i < keys.length; i++) {
      let v2 = scope.gbbuild.graph.getVertex(o[keys[i]]);
      if (v2.vid <= n) {
        continue;
      }
      let root = v2.vid == 1;
      let mass = 0.21;
      let imp = BABYLON.PhysicsImpostor.BoxImpostor
      let e2 = v2["mesh"];
      let t = dfx.getElementMesh(e2);
      let len = 5;//(scope.gbbuild.graph.getShortestPath(1,v2.vid).length-1)*10
      t.position.y = pos.y + len;
      t.position.x = ds + dx * i;
    }
  }
  //
  addJoint(i1, i2, len) {
    let scope = this;
    let dfx = this.dfx
    let v2 = scope.gbbuild.graph.getVertex(i1);
    let e2 = v2["mesh"];
    let t = dfx.getElementMesh(e2);
    let v3 = scope.gbbuild.graph.getVertex(i2);
    let e3 = v3["mesh"];
    let u = dfx.getElementMesh(e3);
    this.forcemap[i1 + "_" + i2] = true;
    this.forcemap[i2 + "_" + i1] = true;
    var joint2 = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.SpringJoint, {
      length: len || scope.slen,
      stiffness: this.stiff,
      damping: this.damp
    });
    t.physicsImpostor.addJoint(u.physicsImpostor, joint2);
  }
  startNetworkAnim() {
    this.startNetworkBuild();
  }
  repelBranches() {
    let scope = this;
    let dfx = this.dfx;
    let obj = scope.gbbuild.graph.vertices;
    let keys = Object.keys(obj);
    for (var i = 1; i < keys.length - 1; i++) {
      let id1 = keys[i];
      let v1 = (obj[id1]);
      let m1 = dfx.getElementMesh(v1['mesh']);
      for (var j = i + 1; j < keys.length; j++) {
        let id2 = keys[j];
        let v2 = (obj[id2]);

        let m2 = dfx.getElementMesh(v2['mesh']);
        let dist = BABYLON.Vector3.Distance(m1.position, m2.position)
        let rep = !scope.hasForceMapped(v1.vid, v2.vid);//scope.applyRepulsion(v1.vid, v2.vid);
        if (rep && dist < this.slen) {
          var d2 = BABYLON.Vector3.DistanceSquared(m1.position, m2.position);
          var force = this.slen;
          var vector = m1.position.subtract(m2.position).normalize().scale(force);
          m1.physicsImpostor.applyImpulse(vector, m1.getAbsolutePosition());

        }

      }
    }
  }
  applyRepulsion(v1, v2) {
    let scope = this;
    let a = scope.gbbuild.graph.getShortestPath(v1, v2);
    let l = a.length;
    if (l > 3) {
      let s = a[0];
      let e = a[l - 1];
      let m = a.sort()[0];
      return s > m && e > m;
    } else {
      return false;
    }
  }
  applyCoulombsLaw() {
    let scope = this;
    let dfx = this.datauxview.getDatascape();
    let obj = scope.gbbuild.graph.vertices;
    let keys = Object.keys(obj);
    for (var i = 1; i < keys.length - 1; i++) {
      let id1 = keys[i];
      let v1 = (obj[id1]);
      let m1 = dfx.getElementMesh(v1['mesh']);
      for (var j = i + 1; j < keys.length; j++) {
        let id2 = keys[j];
        let v2 = (obj[id2]);

        let m2 = dfx.getElementMesh(v2['mesh']);
        if (i !== j) {
          let d = m1.position.subtract(m2.position);
          let distance = d.length() + 0.1; // avoid massive forces at small distances (and divide by zero)
          let direction = d.normalize();

          let edgeSet = scope.gbbuild.graph.getEdges(v1.vid, v2.vid);
          let edge = edgeSet[0];
          //console.log(edge);
          let weight = (edge === undefined) ? 1 : edge.getWeight();

          // apply force to each end point
          m1.physicsImpostor.applyForce(direction.scale(0.5 * weight), m1.getAbsolutePosition());
          m2.physicsImpostor.applyForce(direction.scale(-0.5 * weight), m2.getAbsolutePosition());
        }
      };
    };
  };
  destroy() {
    cancelAnimationFrame(this.animID);
    this.clearConeTree()
    this.alist = [];
    this.arr = null;
    this.forcemap = {};
    this.gbspec = null;
    this.animID = null;
    /* this.gbbuild.destroy();
    this.gbbuild = null; */
    this.phy = null;
    this.dfx = null;
    this.datauxview = null;
    this.scene = null;
  }
  clearConeTree() {
    cancelAnimationFrame(this.animID);
    this.removeNodeLabels();
    this.removeNodeDrag();
    if (this.gbbuild) {
      this.gbbuild.destroy();
    }
    this.gbbuild = null;
    this.coneNode = {};
    this.showConnector = false;
  }
  
  clearAnimationFrame() {       
    cancelAnimationFrame(this.animID);
  }

  getParentEdge(n, obj) {
    for (let m in obj) {
      if (n > obj[m]) {
        return m
      }
    }
  }
  /**
  * method for is rectangle node/not
  */
  getNodeLevelShape(level) {
    let networkData = this.networkData['vertices'];
    const found = networkData.some(node => {
      if (node['level'] === level) {
        return node['shape'].toLowerCase() === 'rectangle';
      }
    })
    return found;
  }
  /**
   * show/hide branches of a level
   * @param boo
   * @param level
   */
  showHideBranch(boo, level, hiddenLevels) {
    let nodes = this.gbspec.vertices;
    let shapeFlag = this.getNodeLevelShape(level);
    nodes.forEach((node) => {
      let l = node.level;
      if (level == l) {
        let vtx = this.gbbuild.graph.getVertex(node.id);
        let adj = this.gbbuild.graph.adjacencyList[node.id];
        let edge = level === 0 ? null : this.gbbuild.graph.getEdge(this.getParentEdge(node.id, adj));
        let mesh_n = vtx.mesh;
        let mesh_e = edge ? edge.mesh : null;
        let props = this.dfx.props(mesh_n);
        let prf = props.tag.default_prf || 'regular';
        let profile;
        if (boo) {
          profile = prf
          if (shapeFlag) {
            const found = hiddenLevels.some(el => el === level);
            if (!found) {
              let idx = this.transparentNodes.findIndex(el => el === node['id']);
              if (idx > -1) {
                this.transparentNodes.splice(idx, 1);
              }
            }
          }
        } else {
          if (prf === 'regular') {
            profile = 'transparent';
          } else {
            profile = prf + '_transparent';
          }
          profile = 'transparent';
          if (shapeFlag) {
            const found = this.transparentNodes.some(el => el === node['id']);
            if (!found) {
              this.transparentNodes.push(node.id)
            }
          }
        }
        this.dfx.modify(mesh_n, { profile })
        let mat = this.scene.getMaterialByID(profile);
        if (mesh_e) {
          mesh_e.material = mat;
        }
      }
    })

    /* code for transparent rectangular node */
    // if (shapeFlag === true && boo === true) {
    //   this.transparentRectangular(true);
    //   this.transparentChartModel(true);
    // }
  }
  /**
   * show/hide sub-branch of a node
   * @param boo
   * @param vid
   * @returns
   */
  showHideSubBranch(boo, vid) {
    let cone = this.coneNode[vid];
    if (!cone) {
      return;
    }
    if (!cone.hasCone) {
      return;
    }
    let nodes = this.coneNode[vid].base;
    nodes.forEach((node) => {
      let vtx = this.gbbuild.graph.getVertex(node);
      let adj = this.gbbuild.graph.adjacencyList[node];
      let edge = this.gbbuild.graph.getEdge(this.getParentEdge(node, adj));
      let mesh_n = vtx.mesh;
      let mesh_e = edge ? edge.mesh : null;
      let props = this.dfx.props(mesh_n);
      let prf = props.tag.default_prf || 'regular';
      let profile;
      if (boo) {
        profile = prf
      } else {
        if (prf === 'regular') {
          profile = 'transparent';
        } else {
          profile = prf + '_transparent';
        }
        profile = 'transparent'
      }
      this.dfx.modify(mesh_n, { profile })
      let mat = this.scene.getMaterialByID(profile);
      if (mesh_e) {
        mesh_e.material = mat;
      }
      this.showHideSubBranch(boo, node);
    })
  }
  linkSubBranch(vid, p = null) {
    let cone = this.coneNode[vid];
    if (!cone.hasCone) {
      return;
    }
    let pmesh = p;
    if (!pmesh) {
      let parent = this.gbbuild.graph.getVertex(vid);
      let el = parent.mesh;
      pmesh = this.dfx.getElementMesh(el);
    }
    let nodes = this.coneNode[vid].base;

    nodes.forEach((node) => {
      let vtx = this.gbbuild.graph.getVertex(node);
      let adj = this.gbbuild.graph.adjacencyList[node];
      let edge = this.gbbuild.graph.getEdge(this.getParentEdge(node, adj));
      let el_n = vtx.mesh;
      let mesh_n = this.dfx.getElementMesh(el_n);
      let mesh_e = edge ? edge.mesh : null;
      let s1 = mesh_n.scaling.clone();
      let s2 = mesh_e.scaling.clone();
      mesh_n.setParent(pmesh);
      mesh_e.setParent(pmesh);
      mesh_n.scaling = s1.divide(pmesh.scaling);
      mesh_e.scaling = s2.divide(pmesh.scaling);
      this.linkSubBranch(node, pmesh);
    })
  }
  unlinkSubBranch(vid) {
    let cone = this.coneNode[vid];
    if (!cone.hasCone) {
      return;
    }
    let pmesh = null;
    let nodes = this.coneNode[vid].base;
    nodes.forEach((node) => {
      let vtx = this.gbbuild.graph.getVertex(node);
      let adj = this.gbbuild.graph.adjacencyList[node];
      let edge = this.gbbuild.graph.getEdge(this.getParentEdge(node, adj));
      let el_n = vtx.mesh;
      let mesh_n = this.dfx.getElementMesh(el_n);
      let mesh_e = edge ? edge.mesh : null;
      mesh_n.setParent(pmesh);
      mesh_e.setParent(pmesh);
      this.unlinkSubBranch(node);
    })
  }
  /**
   * get the edge of a node
   * @param node
   * @returns
   */
  getNodeEdge(node) {
    let vtx = this.gbbuild.graph.getVertex(node);
    let adj = this.gbbuild.graph.adjacencyList[node];
    let edge = this.gbbuild.graph.getEdge(this.getParentEdge(node, adj));
    return edge;
  }
  updateNodeEdge(e) {
    this.gbbuild.graph.graphicsManager.setEdgeMesh(e);
  }
  /**
   * hide all nodes except level 0(top node)
   */
  hideAllNodes() {
    this.showHideSubBranch(false, 1);
  }
  /**
   * show all nodes
   */
  showAllNodes() {
    this.showHideSubBranch(true, 1);
    // this.transparentRectangular(true);
  }
  /**
   * show/hide a node with or without edge
   * @param node  //id of vertex node
   * @param boo     //show/hide vertex node
   * @param shedge //show/hide edge
   */
  showNode(node, boo = true, shedge = false) {
    let vtx = this.gbbuild.graph.getVertex(node);
    let mesh_n = vtx.mesh;

    let adj = this.gbbuild.graph.adjacencyList[node];
    let edge = this.gbbuild.graph.getEdge(this.getParentEdge(node, adj));
    let mesh_e = edge ? edge.mesh : null;

    let props = this.dfx.props(mesh_n);
    let prf = props.tag.default_prf || 'regular';
    let profile;
    if (boo) {
      profile = prf
    } else {
      if (prf === 'regular') {
        profile = 'transparent';
      } else {
        profile = prf + '_transparent';
      }
      profile = 'transparent'
    }
    this.dfx.modify(mesh_n, { profile })
    if (shedge) {
      let mat = this.scene.getMaterialByID(profile);
      if (mesh_e) {
        mesh_e.material = mat;
      }
    }
  }
  rightclick
  nodeDragObj = {};
  dragID = null;
  levelRotMode = false;
  /**
   * enables draging a node and its sub branch
   * if levelRotMode is true then it will rotate the level to which it belongs
   * - (parent vertex node)
   */
  enableNodeDrag() {
    let dfx = this.dfx;
    let varr = this.gbspec.vertices;
    varr.forEach((v) => {
      let vid = v.id;
      let vtx = this.gbbuild.graph.getVertex(vid);
      let child = vtx.mesh;
      let mesh = dfx.getElementMesh(child)
      let b = dfx.getDragBehavior([1, 1, 1]);
      b.useObjectOrienationForDragging = false;
      b.updateDragPlane = false;
      mesh.addBehavior(b);
      let eid = null;
      let p;
      b.onDragStartObservable.add((event) => {
        if (this.isRightClick) {
          return
        }
        p = mesh.position.clone();
        let p0, s0, s1;
        let p0_id;
        if (this.levelRotMode) {
          p0_id = this.coneNode[vid].parent;
          let p0_vtx = this.gbbuild.graph.getVertex(p0_id);
          //let p0_el=p0_vtx.mesh;
          p0 = this.scene.pointerX;
          s0 = this.coneNode[p0_id].sang || 0;
          // s1=this.getVecAng(p0.position,p);
          b.moveAttached = false;
        } else {

          eid = this.getNodeEdge(vid);
        }
        let last = null;
        let sw = 720;//this.datauxview.rendererCanvas.nativeElement.width;
        let anim = (e) => {
          if (this.levelRotMode) {
            let a = (this.scene.pointerX - p0) * (360 / sw);
            let rang = a * Math.PI / 180;
            let dang = s0 + (rang);
            if (last != dang) {
              this.rotateSubBranch(p0_id, dang);
              this.gbbuild.graph.updateLayout();
            }
            last = dang;
          } else {
            this.displaceSubBranch(vid, mesh.position.subtract(p))
            this.gbbuild.graph.updateLayout();
            p = mesh.position.clone();
          }
          this.dragID = requestAnimationFrame(anim);
        }
        this.dragID = requestAnimationFrame(anim);
      })
      b.onDragEndObservable.add((event) => {
        cancelAnimationFrame(this.dragID);
        this.dragID = null;
        if (!this.levelRotMode) {
          this.displaceSubBranch(vid, mesh.position.subtract(p));
          p = mesh.position.clone();
        }
        this.gbbuild.graph.updateLayout();
      })
      b.onDragObservable.add((event) => {
        if (this.rightclick) {
          b.releaseDrag();
          this.rightclick = true;
          //this.moveCameraTo(child);
        }
      })
      this.nodeDragObj[vid] = [mesh, b];
    });
  }
  /**
   * remove Drag behaviour on all nodes
   */
  removeNodeDrag() {
    let keys = Object.keys(this.nodeDragObj);
    keys.forEach((key) => {
      if (this.nodeDragObj[key]) {
        this.nodeDragObj[key][0].removeBehavior(this.nodeDragObj[key][1]);
        this.nodeDragObj[key][1] = null;
      }
    });
  }
  /**
   *
   */
  initCanvas() {
    // var canvas = document;
    // canvas.addEventListener("pointerdown", (event) => {

    //   if (event.which == 1) {
    //     this.rightclick = false;
    //   }
    //  this.isRightClick=false;
    //   if(event.which==3){
    //     this.isRightClick=true;
    //   }
    // })
    // canvas.addEventListener("pointerup", (event) => {

    //   if (event.which == 1) {
    //     this.rightclick = true;
    //   }
    // })
  }
  /**
   * move a vertex node(used with drag functionality)
   * @param vid
   * @param disp
   * @returns
   */
  displaceSubBranch(vid, disp) {
    let cone = this.coneNode[vid];
    if (!cone.hasCone) {
      return;
    }
    let nodes = this.coneNode[vid].base;
    nodes.forEach((node) => {
      let vtx = this.gbbuild.graph.getVertex(node);
      let el_n = vtx.mesh;
      let mesh_n = this.dfx.getElementMesh(el_n);
      mesh_n.position.addInPlace(disp);
      this.displaceSubBranch(node, disp);
    })
  }
  /**
   * rotates a sub-branch
   * @param vid
   * @param rot
   * @returns
   */
  rotateSubBranch(vid, rot = null) {
    let cone = this.coneNode[vid];
    if (!cone.hasCone) {
      return;
    }
    if (rot) {
      this.coneNode[vid].sang = rot;
      this.updateCone(vid);
    }

    let nodes = this.coneNode[vid].base;
    nodes.forEach((node) => {
      this.updateCone(node);
      this.rotateSubBranch(node);
    })
  }
  /**
   * resets the tree to its initial state
   */
  resetConeTree() {
    for (let m in this.coneNode) {
      this.coneNode[m].sang = 0;
      this.updateCone(m);
    }
    this.gbbuild.graph.updateLayout();
  }
  /**
   * utility - get angle between two nodes
   * @param start
   * @param end
   * @param axis
   * @returns
   */
  getVecAng(start, end, axis = [0, 1, 0]) {
    let v3 = this.dfx.getVector();
    let direct = end.subtract(start);
    direct.normalize(); //unit vector
    let xvec = new v3(axis[0], axis[1], axis[2]);
    let angle = Math.acos(v3.Dot(direct, xvec));
    return angle;
  }
  /**
   * show the path of a node from selected to KHA (level 0)
   * @param vid
   */
  showNodePath(vid) {
    this.hideAllNodes();
    try {
      let p = this.gbbuild.graph.getShortestPath(vid, 1);
      p.forEach((n) => {
        if (n == 1) { return }
        this.showNode(n, true, true)
      })
      this.showHideSubBranch(true, vid);
    } catch { }
  }
}
