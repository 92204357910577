import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CommunicationService } from "../services/communication.service";

@Component({
  selector: 'app-progress-panel',
  templateUrl: './progress-panel.component.html',
  styleUrls: ['./progress-panel.component.scss']
})
export class ProgressPanelComponent implements OnInit {
  // firstFormGroup: FormGroup;
  // secondFormGroup: FormGroup;

  constructor( private communicationServ: CommunicationService) {}
  broadcastSubs:any;
  layoutData:any=[];
  sidepanelLayoutData:any=[];
  sidepanelNotesData:any=[];
  sidepanelStatusObject:any=[];
  ngOnInit() {
    // this.firstFormGroup = this._formBuilder.group({
    //   firstCtrl: ['']
    // });
    // this.secondFormGroup = this._formBuilder.group({
    //   secondCtrl: ['']
    // });
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if(data.src=="material"){
        if (data.event === 'updateLayoutData') {
          this.layoutData = data['data'];
        }
      }
      if(data.src=="sidepanel"){
        if(data.event == "reconstruct"){
            this.sidepanelLayoutData = data['layout'].length > 0 ? data['layout'].filter((arr, index, self) =>
            index === self.findIndex((t) => (t.layoutId == arr.layoutId))) : [];    
          console.log("reconstruct", this.sidepanelLayoutData );
          this.sidepanelNotesData = data['notes']
        }
      }  
      if(data.src=="navyApp")   {
        if (data.event === 'updateStatus') {
          this.sidepanelStatusObject = data.data;
          console.log("updateStatus", data.data);
        }
      }
    })
  }

    /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
    broadcastInfo(data: any) {
      this.communicationServ.getInstance().next(data);
    }
  
    filteredNotesList:any=[];
    selectedLayout:any;
    notesPanel:any=false;
      activeLayout(layout,i){
        this.selectedLayout = layout;
        this.notesPanel = true;
        console.log(layout,this.sidepanelNotesData );
        this.filteredNotesList = this.sidepanelNotesData.filter(e=>e.layoutId==layout.layoutId)
        console.log("layout",this.filteredNotesList );
      }

}

