import { Component, OnInit, ViewChild, AfterViewInit, HostListener, Input } from '@angular/core';
import { ShapeCreatorService } from '../services/shape-creator.service';
import { ConfigService } from '../services/config.service';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from "../services/controller.service";
import { DemoGameService } from '../services/demo-game.service';

declare var BABYLON;
declare var window;
var debug = false;
var _console = {
  "log": (...args) => {
    if (debug) {
      window.console.log(...args)
    }
  }
}

@Component({
  selector: 'app-game-view',
  templateUrl: './game-view.component.html',
  styleUrls: ['./game-view.component.scss']
})
export class GameViewComponent implements OnInit, AfterViewInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;

  _shapestatus = false;
  broadcastIframeSubs: any;
  positiontool = false;
  socketcontroler = false;
  shapeCreatorService: ShapeCreatorService;
  process_txt = "Processing...";
  controlsPosition: any = 'Right';
  pcsDemos = []
  selectedDemo = this.pcsDemos[0] || "";
  showAnim = true;
  showLoading = true;
  doreset = false;
  styleLength: any = '';
  disableTrans: boolean = false;
  controlPanelState: boolean = false;
  broadcastSubs: any;
  isAppLoaded: boolean = false;
  showExternalTools = false;

  showSSPanel: boolean = true;
  showDrawerPanel: boolean = false;

  @Input() set controls_position(v: any) {
    this.controlsPosition = v;
  }

  constructor(private ctrlServ: ControllerService, private configService: ConfigService, private communicationServ: CommunicationService) {
  }

  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
      })
  }

  /* *
   * sample code to load the application venue using different data source
   * * */
  ngAfterViewInit(): void {
    // load all the canvas settings from settings json file
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings-game', 'json', (data, settingstatus) => {
          var setting_status = settingstatus;
          this.datauxview.yaw_3d = this.datauxview.yaw_2d = 270;
          this.datauxview.pitch_2d = 15
          if (!setting_status) {
          } else {
            this.initObjects();
          }
        });
      }, (err) => { _console.log(err) });
    }, (err) => { alert("License not found!") });
  }
  track_label_tex = {
    "meshes": [{
      "position": [0, 0, 0],
      "rotation": [0, 0, 0],
      "scaling": [1, 1, 1],
      "isVisible": true,
      "isEnabled": true,
      "checkCollisions": false,
      "alphaIndex": 201,
      "billboardMode": 0,
      "receiveShadows": false,
      "name": "track",
      "id": "track",
      "positions": [],
      "normals": [],
      "uvs": [],
      "indices": []
    }]
  };

  /* * *
  * method for init objects
  * * */
  dfx
  scene
  gameManager
  initObjects() {
    window.scope = this;
    // load all the assets to be used information from socket
    this.datauxview.loadAssets('objects-game', 'json', (objectstatus) => {
      if (objectstatus) {
        this.datauxview.renderAssets('objects-game', 'json', (objectstatus) => {
          if (objectstatus) {
            this.dfx = this.datauxview.getDatascape();
            this.scene = this.dfx.getCamera().getScene();
            this.ctrlServ.init(this.datauxview);

            this.gameManager = new DemoGameService(this.datauxview, this.communicationServ);
            this.initCanvas();
            this.removeLoader();
            this.gameManager.initGameMode();

            this.isAppLoaded = true;
            setTimeout(() => {
              this.showExternalTools = true;
              this.showLoading = false;
            }, 1000);
          }
        }, () => { })
      }
    });
  }

  /* * *
  * method for init canvas
  * * */
  mousestate
  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      this.mousestate = 'down';

      if (!this.dfx) return;
      const hit = this.dfx.getHitPosition();
      if (!hit.hit) return;
      const hpt = hit.pickedPoint;
      console.log("Picked Point main : ", hit.pickedPoint);
      console.log("Picked Point: ", hpt.x, hpt.z);

    })
    canvas.addEventListener("pointerup", (event) => {
      this.mousestate = 'up';
    })
  }

  /* * *
   * method for remove loader
   * * */
  removeLoader() {
    let ldiv = document.getElementById("babylonjsLoadingDiv");
    if (ldiv) {
      ldiv.remove();
    }
  }

  /* * *
  * controls events for game
  * * */
  onMouseUpN() {
    this.gameManager.onMouseUpN();
  }

  onMouseDownN(val) {
    this.gameManager.onMouseDownN(val);
  }

  lookupaid
  lookdownaid
  turnleftaid
  turnrightaid
  lookUp() {
    let anim = () => {
      this.gameManager.lookUp();
      this.lookupaid = requestAnimationFrame(anim);
    }
    this.lookupaid = requestAnimationFrame(anim);
  }

  stopLookUp() {
    cancelAnimationFrame(this.lookupaid);
  }

  lookDown() {
    let anim = () => {
      this.gameManager.lookDown();
      this.lookdownaid = requestAnimationFrame(anim);
    }
    this.lookdownaid = requestAnimationFrame(anim);
  }

  stopLookDown() {
    cancelAnimationFrame(this.lookdownaid);
  }

  turnLeft() {
    let anim = () => {
      this.gameManager.turnLeft();
      this.turnleftaid = requestAnimationFrame(anim);
    }
    this.turnleftaid = requestAnimationFrame(anim);
  }

  stopTurnLeft() {
    cancelAnimationFrame(this.turnleftaid);
  }

  turnRight() {
    let anim = () => {
      this.gameManager.turnRight();
      this.turnrightaid = requestAnimationFrame(anim);
    }
    this.turnrightaid = requestAnimationFrame(anim);
  }

  stopTurnRight() {
    cancelAnimationFrame(this.turnrightaid);
  }

  restartAvatar(){
    this.gameManager.restartAvatar();
  }
}
